import React, { useCallback } from "react";
import axios from "axios";
import { findSpecUrl } from "utils/functions";
import { base_url, api_v } from "utils/settings";
import { debounce } from "lodash";

export const useFetch = (url, options) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const FetchData = async () => {
      try {
        const res = await axios.get(url, options);

        setResponse(res.data);
      } catch (error) {
        setError(error);
      }
    };
    FetchData();
  }, []);
  return { response, error };
};

export const useListFetch = (name, options) => {
  const [response, setResponse] = React.useState([]);
  const [error, setError] = React.useState(null);

  const targetUrl = findSpecUrl(name);

  React.useEffect(() => {
    const FetchData = async () => {
      try {
        const res = await axios.get(
          `${base_url}/${api_v}${targetUrl}`,
          options
        );

        setResponse(res.data["hydra:member"]);
      } catch (error) {
        setError(error);
      }
    };
    FetchData();
  }, []);
  return response;
};

export function useDebouncedCallback(callback, delay) {
  const d = callback;
  const callbackfunc = useCallback(debounce(d, delay), []);

  return [callbackfunc];
}
