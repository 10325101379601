import React from "react";
import logoPlaceholder from "theme/assets/img/my-ap.svg";

export default function CompanyLogo(props) {
  const { logo, height, width } = props;
  if (logo.length === 0) {
    return <img src={logoPlaceholder} alt="company-logo" height={height} width={width} />;
  }

  return <img src={logo[0].path} alt="company-logo" height={height} width={width}></img>;
}
