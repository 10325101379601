import React from "react";
import Icon from "../ui-elements/Icon";

const AccordionContext = React.createContext();

function useEffectAfterMount(cb, dependencies) {
  const justMounted = React.useRef(true);
  React.useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
  }, dependencies);
}

function Accordion(props) {
  const [on, setOn] = React.useState(false);
  const toggle = React.useCallback(() => setOn(oldOn => !oldOn), []);

  useEffectAfterMount(() => {
    props.onToggle(on);
  }, [on]);

  const value = React.useMemo(() => ({ on, toggle }), [on]);
  return (
    <AccordionContext.Provider value={value}>
      <div className="card">{props.children}</div>
    </AccordionContext.Provider>
  );
}

function useAccordionContext() {
  const context = React.useContext(AccordionContext);
  if (!context) {
    throw new Error(
      `Accordion compound components cannot be rendered outside the Accordion component`
    );
  }
  return context;
}

function Toggle({ children }) {
  const { on, toggle } = useAccordionContext();

  return (
    <div className="accordion-header" onClick={toggle}>
      <div className="accordion-header-content">{children}</div>
      <span className="accordion-indicator">
        <Icon name="angle-down" size={2}></Icon>
      </span>
    </div>
  );
}

function Panel({ children }) {
  const { on, toggle } = useAccordionContext();
  return on ? <div className="card-body">{children}</div> : null;
}

// for convenience, but totally not required...
Accordion.Toggle = Toggle;
Accordion.Panel = Panel;

export default Accordion;
