import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompany, clearCompany } from "actions/companyActions";
import { logoData } from "actions/dataActions";
import { updateNetworkCompany } from "actions/dataActions";
import CompanyHeader from "components/company-edit/CompanyHeader";
import BranchNav from "components/company-edit/Navigation";
import SkeletonLoading from "./SkeletonLoading";

class CompanyEdit extends Component {
  componentDidMount() {
    if (
      !this.props.loadedCompanyId ||
      this.props.loadedCompanyId !== this.props.companyId
    ) {
      const id = (this?.props?.companyId).toString();

      this.props.getCompany(this?.props?.companyId);
      if (this.props.auth?.logoData === undefined) {
        this.props.logoData(this.props.companyId);
      }
    }
  }

  /*componentWillUnmount() {
    if (!this.props.loading && this.props.data !== null) {
      this.props.updateNetworkCompany(this.props.data);
    }
  }*/

  render() {
    const { data, loading, toggleEdit } = this.props;
    let companyData;
    if (loading || data === null) {
      companyData = <SkeletonLoading />;
    } else {
      companyData = (
        <>
          <CompanyHeader
            name={data?.companyName}
            logo={data?.logo}
            country={data?.countries}
            city={data?.city || ""}
            companyId={data?.id}
            profileReadiness={data?.companySettings[0]?.profileReadiness}
            classification={data?.companySettings[0]?.classification}
            auth={this.props?.auth}
            toggleEdit={toggleEdit}
          ></CompanyHeader>
          <div className="container-fluid">
            <BranchNav branches={data?.branch} companyId={data?.id}></BranchNav>
          </div>
        </>
      );
    }

    return <div className="company-edit">{companyData}</div>;
  }
}

CompanyEdit.defaultProps = {
  companyId: 10,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loadedCompanyId: state.company.data === null ? null : state.company.data.id,
  data: state.company.data,
  loading: state.company.loading,
});

export default connect(mapStateToProps, {
  getCompany,
  logoData,
  clearCompany,
  updateNetworkCompany,
})(CompanyEdit);
