import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchCities } from "actions/dataActions";
import { updateBranch, updateBranchInfo } from "actions/branchActions";
import CustomSelect from "components/ui-elements/Select";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { Input, Form, Icon, Checkbox, message, Select, Popconfirm, Button, Skeleton } from "antd";
import { TitleForm } from "components/ui-elements/Typography";
import { DOMAIN } from "utils/enum";
import { fetchCountryAirports } from "actions/dataActions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const BranchDetails = React.memo(
  props => {
    const dispatch = useDispatch()
    const { branchName, branchCity, countryId, subIndustries, subIndustry, selectedSubIndustry, countryList, countryDialCode, isAdmin, deleteBranch, loading, branchId, networkLoading } = props;
    console.log("selectedSubIndustry>>", selectedSubIndustry)
    console.log("subIndustry.......", subIndustry)
    const {
      branchAddress1,
      branchAddress2,
      branchEmail,
      branchWeb,
      branchTel,
      telDialCode,
      qqDialCode,
      branchMob,
      branchFax,
      branchSkype,
      branchQq,
      branchAirport
    } = props.branchInfo;

    const [selectedSubIndustryIds, setSelectedSubIndustryIds] = useState([])
    const { domain } = useSelector((state) => state.common?.domain)
    const companySubIndustry = useSelector((state) => state.company.data.subIndustry)

    // Auto Save Inputs - Debounced
    // const countryList= useSelector(state=>state.data.countries)
    // const companyId= useSelector(state=>state.company.data)

    const saveBranch = async values => {
      if (values.branchCity) {
        values.branchCity = values.branchCity.cityAsciiname;
      }
      return await props.updateBranch(values, props.branchId);
    };

    const saveBranchInfo = async values => {
      if (values.branchAirport) {
        values.branchAirport = `/v1/airport/${values.branchAirport.id}`
      }
      return await props.updateBranchInfo(
        values,
        props.branchId,
        props.branchInfo.id
      );
    };
    const saveSubIndustries = async (values) => {
      if (values?.subIndustry) {
        let params = {
          "subIndustry": []
        }
        params["subIndustry"] = values.subIndustry.map((element) => "/v1/sub_industries/" + element);
        await props.updateBranch(
          params,
          props.branchId,
        ).then(value => {
          if (value) {
            return message.success("The information has been updated");
          }
        });
      }
    }
    /*   const saveSubIndustries = async (values) => {
        if (values) {
          let params = {
            "subIndustry": []
          }
          params["subIndustry"] = values.map((element) => "/v1/sub_industries/" + element);
          props.updateBranch(
            params,
            props.branchId,
          ).then(value => {
            if (value) {
  
              return message.success("The information has been updated");
            }
          });
        }
      } */

    //  let telCountryCodeSelect = (
    //       <Select showSearch style={{ width: 90 }}
    //         // onChange={handleCountryCodeSelect} size="large"
    //         >
    //         {countryList.map((item, index) => {
    //           return (
    //             <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
    //               {item.dialCode ? item.dialCode : `no code${item.id}`}
    //             </Option>
    //           );
    //         })}
    //       </Select>
    //     );
    let telCountryCodeSelect = (
      <FormField
        name="telDialCode"
      >
        <Select showSearch style={{ width: 100 }}
          value={telDialCode}
        // onChange={handleCountryCodeSelect} size="large"
        >
          {countryList.map((item, index) => {
            return (
              <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
                {item.dialCode ? item.dialCode : `no code${item.id}`}
              </Option>
            );
          })}
        </Select>
      </FormField>
    );
    let qqCountryCodeSelect = (
      <FormField
        name="qqDialCode"
      >
        <Select showSearch style={{ width: 100 }}
          value={qqDialCode}
        // onChange={handleCountryCodeSelect} size="large"
        >
          {countryList.map((item, index) => {
            return (
              <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
                {item.dialCode ? item.dialCode : `no code${item.id}`}
              </Option>
            );
          })}
        </Select>
      </FormField>
    );
    console.log("subIndustries in branch details", subIndustries)
    /* START:- Airport */
    const formatAirportOption = ({ airportName, iata, country }, { context }) => {
      if (context === "menu") {
        return (
          <div className="border-bottom">
            <div>
              {airportName} ({iata})
            </div>
            <span className="text-secondary">{country}</span>
          </div>
        );
      }
      return <div>{iata}</div>;
    };


    /* END:- Airport */

    return (
      <>{isAdmin && (
        <Popconfirm
          title={`Sure to delete ${branchName || branchCity} branch?`}
          placement="topRight"
          onConfirm={() => deleteBranch()}
        >
          <Button
            type="danger"
            size="small"
            loading={loading}
            // style={{ position: "fixed", right: 20, bottom: 20 }}
            // style={{position: 'absolute', bottom: '0'}}
            style={{
              marginBottom: '10px', position: 'relative',
              right: '226px',
              top: '117px'
            }}
            ghost
            className="delete-branch"
          >
            Delete branch
          </Button>
        </Popconfirm>
      )}
        <div className="card">
          <div className="card-header">
            <h2>About branch</h2>
          </div>
          <div className="card-body">
            {subIndustries?.length > 0 ? <AutoSaveWrapper
              onSubmit={saveSubIndustries}
              initialValues={{
                subIndustry: selectedSubIndustry
              }}
              subscription={{}}
            >
              {() => (
                <>
                  <AutoSave
                    debounce={1000}
                    save={saveSubIndustries}
                    debounced={["subIndustry"]}
                  />
                  <TitleForm variant="subtitle">Network</TitleForm>
                  <FormField
                    name="subIndustry"
                  // label="subIndustries"
                  // type="checkbox"
                  // validate={["required"]}
                  >
                    <Checkbox.Group
                      //  type="checkbox"
                      value={selectedSubIndustry}
                      //   onChange={saveSubIndustries}
                      className="expertise-box-group expertise-box-group-another"
                    >
                      {domain === DOMAIN.NEX
                        ?
                        subIndustries.map((element, index) => {
                          return (
                            <Checkbox value={element.id} key={`subGroup${index}`} className=/* {selectedSubIndustry.includes(element.id) ? "ant-checkbox-wrapper-checked": */"expertise-box-head"/* } */>
                              {element.industryLogo ?
                                <div className="logoAndBadge">
                                  <img
                                    src={
                                      element.industryLogo
                                    }
                                    className="logo-img"
                                  />
                                </div>
                                : "None"}
                            </Checkbox>
                          )
                        })
                        :
                        subIndustries.map((element, index) => {
                          return (
                            element?.name !== 'NeX Academy'
                            &&
                            <Checkbox value={element.id} key={`subGroup${index}`} className=/* {selectedSubIndustry.includes(element.id) ? "ant-checkbox-wrapper-checked": */"expertise-box-head"/* } */>
                              {element.industryLogo ?
                                <div className="logoAndBadge">
                                  <img
                                    src={
                                      element.industryLogo
                                    }
                                    className="logo-img"
                                  />
                                </div>
                                : "None"}
                            </Checkbox>
                          )
                        })
                      }
                    </Checkbox.Group>
                  </FormField>
                </>
              )}
            </AutoSaveWrapper> : ""}

            <AutoSaveWrapper
              onSubmit={saveBranch}
              initialValues={{
                branchName,
                branchCity: { cityAsciiname: branchCity }
              }}
              subscription={{}}
            >
              {() => (
                <>
                  <AutoSave
                    debounce={1000}
                    save={saveBranch}
                    debounced={["branchName", "branchCity"]}

                  />
                  <Form {...formItemLayout} style={{ maxWidth: 600 }}>
                    <TitleForm variant="subtitle">Branch Info</TitleForm>
                    <FormField
                      name="branchName"
                      label="Branch name"
                      validate={["required"]}
                    >
                      <Input></Input>
                    </FormField>

                    <FormField
                      label="City"
                      name="branchCity"
                      validate={["required"]}
                    >
                      <CustomSelect
                        async={true}
                        placeholder={"Select City"}
                        isDisabled={!countryId}
                        cacheOptions
                        loadOptions={(input, callback) =>
                          fetchCities(input, countryId, callback)
                        }
                        noOptionsMessage={input => "Type to search"}
                        getOptionLabel={option => option.cityAsciiname}
                        getOptionValue={option => option.id}
                      ></CustomSelect>
                    </FormField>
                  </Form>
                </>
              )}
            </AutoSaveWrapper>
            <AutoSaveWrapper
              onSubmit={saveBranchInfo}
              initialValues={{
                telDialCode: telDialCode ? telDialCode : countryDialCode,
                qqDialCode: qqDialCode ? qqDialCode : countryDialCode,
                branchAddress1,
                branchAddress2,
                branchEmail,
                branchWeb,
                branchTel,
                branchMob,
                branchFax,
                branchSkype,
                branchQq,
                branchAirport
              }}
              subscription={{}}
            >
              {() => (
                <>
                  <AutoSave
                    debounce={1000}
                    save={saveBranchInfo}
                    debounced={[
                      "telDialCode",
                      "qqDialCode",
                      "branchAddress1",
                      "branchAddress2",
                      "branchEmail",
                      "branchWeb",
                      "branchTel",
                      "branchMob",
                      "branchFax",
                      "branchSkype",
                      "branchQq",
                      "branchAirport"
                    ]}
                  />

                  <Form {...formItemLayout} style={{ maxWidth: 600 }}>
                    <TitleForm variant="subtitle">
                      Company address and contact
                    </TitleForm>

                    <FormField
                      name="branchAddress1"
                      label="Address line 1"
                      validate={["required"]}
                    >
                      <Input.TextArea autoSize={{ minRows: 2 }}></Input.TextArea>
                    </FormField>
                    <FormField name="branchAddress2" label="Address line 2">
                      <Input.TextArea autoSize={{ minRows: 2 }}></Input.TextArea>
                    </FormField>
                    {companySubIndustry.filter(item => item.id === 7).length === 0 ?
                      <FormField
                        label="Airport we operate at"
                        name="branchAirport"
                        validate={["required"]}
                      >
                        <CustomSelect
                          async={true}
                          placeholder={"Select Airport"}
                          isClearable={false}
                          noOptionsMessage={(input) => "Type to search"}
                          loadOptions={(input, callback) => fetchCountryAirports(input, countryId, callback)}
                          formatOptionLabel={formatAirportOption}
                          getOptionLabel={option => option.iata}
                          getOptionValue={option => option.id}
                        ></CustomSelect>
                      </FormField>
                      :
                      null}
                    <FormField
                      name="branchEmail"
                      label="Email"
                      validate={["required"]}
                    >
                      <Input></Input>
                    </FormField>

                    <FormField
                      name="branchTel"
                      label="Telephone"
                      validate={["required"]}
                    >
                      <Input addonBefore={telCountryCodeSelect} style={{ maxWidth: 250 }}></Input>
                    </FormField>

                    <FormField name="branchSkype" label="Skype">
                      <Input
                        style={{ maxWidth: 250 }}
                        addonBefore={
                          <Icon
                            type="skype"
                            theme="filled"
                            style={{ color: "rgba(0,0,0,.32)" }}
                          />
                        }
                      ></Input>
                    </FormField>

                    <FormField name="branchQq" label="Whatsapp">
                      <Input

                        addonBefore={qqCountryCodeSelect}
                        style={{ maxWidth: 250 }}
                      ></Input>
                    </FormField>
                  </Form>
                </>
              )}
            </AutoSaveWrapper>
          </div>
        </div >
      </>
    );
  },
  () => true
);

export default connect(null, { updateBranchInfo, updateBranch })(BranchDetails);