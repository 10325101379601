import axios from "axios";
import { base_url, api_v } from "../utils/settings";
import { storelogoData } from "./authActions";

import {
  GET_DATA,
  UPDATE_NETWORK_COMPANY,
  UPDATE_COMPANY_SETTINGS,
  ADD_COMPANY,
  DELETE_COMPANY,
  GET_FILTER_LISTS,
  GET_COUNTRIES,
  GET_CITIES,
  SET_SELECTED_COUNTRY,
  GET_FILTERS,
  CLEAR_FILTERS,
  SET_FIRST_TAB,
  SET_ALL_COMPANIES_TAB,
  GET_CERTIFICATE_LIST,
  GET_EXPERTISE_LIST,
  GET_SPECIALIZATION_LIST,
  GET_EXPERTISE,
  DATA_LOADING,
  GET_AIRLINES,
  GET_ORIGIN_AIRPORTS,
  GET_DESTINATION_AIRPORTS,
  GLOBAL_ERROR,
  CLEAR_GLOBAL_ERROR,
  SET_LOGODATA,
} from "./types";

export const getUsers = async (value, page) => {
  try {
    if (value) {
      const res = await axios.get(`${base_url}/${api_v}get-all-users?search=${value}&page=${page}`);
      return res.data;
    } else {
      const res = await axios.get(`${base_url}/${api_v}get-all-users?page=${page}`);
      return res.data;
    }

  } catch (err) {
    return false;
  }
};
// export const getUsersByInfo = async (value, page) => {
//   try {
//     const res = await axios.get(`${base_url}/${api_v}get-all-users?search=${value}&page=${page}`);
//     return res.data;
//   } catch (err) {
//     return false;
//   }
// };
export const updateSchedulerKey = async (data, id) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}key_personels/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const updateMySendleKey = async (key, id) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}key_personels/${id}`,
      {
        'parcelLoginKey' : key
      }
    );
    return res.data;
  } catch (err) {
    return false;
  }
};
// export const getUsers = () => async(dispatch) => {
//   axios
//     .get(`${base_url}/${api_v}get-all-users`)
//     .then((res) => {
//       console.log(res,"::::res")
//       return res.data;
//       // res.map((element)=>{
//       //   console.log(element,"element")
//       // })
//       // dispatch({
//       //   type: GET_DATA,
//       //   payload: res.data,
//       // });
//     })
//     .catch((err) =>
//       dispatch({
//         type: GLOBAL_ERROR,
//         payload: err,
//       })
//     );
// };

// Get Data (Network with filters, sort, pagination)
export const getData = (search) => (dispatch) => {
  dispatch(setDataLoading());
  axios
    .get(`${base_url}${search}`)
    .then((res) => {
      console.log("res.......", res)
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      })
    );
};
// export const storelogoData = (decoded) => {
//   return {
//     type: SET_LOGODATA,
//     payload: decoded,
//   };
// };
export const logoData = (CompanyId) => (dispatch) => {
  axios
    // BASEURL/v1/company/partners/{CopmayId}

    .get(`${base_url}/v1/company/partners/${CompanyId}`)

    .then((res) => {
      dispatch({
        type: SET_LOGODATA,
        payload: res.data,
      });
      return true;
    })
    .catch
    // (err) => {}
    // dispatch({
    //   type: GLOBAL_ERROR,
    //   payload: err,
    // })
    ();
};
// Get Invitations
export const getInvitations = async (page) => {
  try {
    const res = await axios.get(`${base_url}/${api_v}invitations?page=${page}`);

    return res.data;
  } catch (err) {
    return false;
  }
};

// Get CAS
export const getCas = async (page) => {
  try {
    const res = await axios.get(`${base_url}/${api_v}cas?page=${page}`);

    return res.data;
  } catch (err) {
    return false;
  }
};

// Get CAS
export const getAlerts = async (page, type) => {
  try {
    const res = await axios.get(
      `${base_url}/${api_v}alerts?page=${page}&type=${type}`
    );

    return res.data;
  } catch (err) {
    return false;
  }
};

// Update CAS
export const updateCas = async (data, id) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}cas/${id}`, data);
    return res.data;
  } catch (err) {
    return false;
  }
};

// Delete CAS
export const deleteCas = async (id) => {
  try {
    const res = await axios.delete(`${base_url}/${api_v}cas/${id}`);
    return res.data;
  } catch (err) {
    return false;
  }
};

// Get Dashboard data
export const getDashboardDataAction = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}dashboard/posts`);

    return res.data;
  } catch (err) {
    return false;
  }
};

// Get Promos
export const getPromos = async (page, date, isAdmin) => {
  try {
    const res = await axios.get(
      `${base_url}/${api_v}my_promos?page=${page}&endDate[after]=${date}${!isAdmin ? "&approved=1" : ""
      }`
    );

    return res.data;
  } catch (err) {
    return false;
  }
};
// export const getLogoData = async (CompanyId) => {
//   try {
//     const res = await axios.get(`${base_url}/v1/company/partners/${CompanyId}`);
//     return res.data;
//   } catch (err) {
//     return false;
//   }
// };
// Get Promos
export const getCompanyPromos = async (type) => {
  try {
    const res = await axios.get(`${base_url}/${api_v}promos?type=${type}`);

    return res.data;
  } catch (err) {
    return false;
  }
};

// Get Promos
export const getCompanySpecificPromos = async (companyId) => {
  try {
    const res = await axios.get(
      `${base_url}/${api_v}my_promos?company.id=${companyId}&status=Active`
    );

    return res.data;
  } catch (err) {
    return false;
  }
};

// Update Promos
export const updatePromo = async (data, id) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}my_promos/approve/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

// Update Promos
export const updatePromoAction = async (data, id) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}my_promos/${id}`, data);
    return res.data;
  } catch (err) {
    return false;
  }
};

// Delete Promos
export const deletePromoAction = async (id) => {
  try {
    const res = await axios.delete(`${base_url}/${api_v}my_promos/${id}`);
    return res.data;
  } catch (err) {
    return false;
  }
};

// Get Network new members
export const getNetworkNewMembers = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}network?sorting=approved`);
    return res.data;
  } catch (err) {
    return false;
  }
};

// Update companySettings in Network
export const updateCompanySettings =
  (data, companyId, id) => async (dispatch) => {
    try {
      const res = await axios.put(
        `${base_url}/${api_v}company_settings/${id}`,
        data
      );

      dispatch({
        type: UPDATE_COMPANY_SETTINGS,
        companyId,
        payload: res.data,
      });

      return true;
    } catch (err) {
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      });

      return false;
    }
  };

// Update Company Status in Network
export const updateCompanyStatus = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}company/updateStatus/${id}`,
      data
    );

    dispatch({
      type: UPDATE_COMPANY_SETTINGS,
      companyId: res.data.id,
      payload: res.data.companySettings,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Invite Member -------------------
export const inviteMember = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}invite`, data);
    dispatch({
      type: ADD_COMPANY,
      payload: res.data,
    });
    return true;
  } catch (err) {
    // throw "Member with this email allrady exists";
    throw err?.response?.data?.error
  }
};

// Update company in network
export const updateNetworkCompany = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_NETWORK_COMPANY,
    payload: data,
  });
};

// Delete company in network
export const deleteCompany = (id) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/${api_v}companies/${id}`);

    dispatch({
      type: DELETE_COMPANY,
      payload: id,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Get Selected Filters for Network (query strings)
export const getFilters = (search) => async (dispatch) => {
  const promises = [];

  let filters = {};

  if (search.hasOwnProperty("companyName")) {
    filters.companyName = search.companyName;
  }
  if (search.hasOwnProperty("fos_email")) {
    filters.fos_email = search.fos_email;
  }

  if (search.hasOwnProperty("importExport")) {
    filters.importExport = search.importExport;
  }

  if (search.hasOwnProperty("country")) {
    promises.push(
      Promise.resolve(
        axios
          .get(`${base_url}/${api_v}countries/${search.country}`)
          .then((res) => {
            filters.country = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("city")) {
    promises.push(
      Promise.resolve(
        axios.get(`${base_url}/${api_v}cities/${search.city}`).then((res) => {
          filters.city = res.data;
        })
      )
    );
  }

  if (search.hasOwnProperty("classification")) {
    filters.classification = search.classification;
  }

  if (search.hasOwnProperty("airIndustryExpertise")) {
    promises.push(
      Promise.resolve(
        axios
          .get(
            `${base_url}/${api_v}air_industry_expertises/${search.airIndustryExpertise}`
          )
          .then((res) => {
            filters.airIndustryExpertise = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("associateIndustryExpertise")) {
    promises.push(
      Promise.resolve(
        axios
          .get(
            `${base_url}/${api_v}associate_industry_expertises/${search.associateIndustryExpertise}`
          )
          .then((res) => {
            filters.associateIndustryExpertise = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("vendorIndustryExpertise")) {
    promises.push(
      Promise.resolve(
        axios
          .get(
            `${base_url}/${api_v}vendor_industry_expertises/${search.vendorIndustryExpertise}`
          )
          .then((res) => {
            filters.vendorIndustryExpertise = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("specializations")) {
    promises.push(
      Promise.resolve(
        axios
          .get(`${base_url}/${api_v}specializations/${search.specializations}`)
          .then((res) => {
            filters.specializations = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("importCountry")) {
    promises.push(
      Promise.resolve(
        axios
          .get(`${base_url}/${api_v}countries/${search.importCountry}`)
          .then((res) => {
            filters.importCountry = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("exportCountry")) {
    promises.push(
      Promise.resolve(
        axios
          .get(`${base_url}/${api_v}countries/${search.exportCountry}`)
          .then((res) => {
            filters.exportCountry = res.data;
          })
      )
    );
  }

  if (search.hasOwnProperty("originAirport")) {
    var array = search.originAirport.split(",");
    filters.originAirport = [];
    array.forEach((id) => {
      promises.push(
        Promise.resolve(
          axios.get(`${base_url}/${api_v}airport/${id}`).then((res) => {
            filters.originAirport.push(res.data);
          })
        )
      );
    });
  }

  if (search.hasOwnProperty("destinationAirport")) {
    var array = search.destinationAirport.split(",");
    filters.destinationAirport = [];
    array.forEach((id) => {
      promises.push(
        Promise.resolve(
          axios.get(`${base_url}/${api_v}airport/${id}`).then((res) => {
            filters.destinationAirport.push(res.data);
          })
        )
      );
    });
  }

  if (search.hasOwnProperty("airline")) {
    var array = search.airline.split(",");
    filters.airline = [];
    array.forEach((id) => {
      promises.push(
        Promise.resolve(
          axios.get(`${base_url}/${api_v}airline/${id}`).then((res) => {
            filters.airline.push(res.data);
          })
        )
      );
    });
  }

  await Promise.all(promises);

  dispatch({
    type: GET_FILTERS,
    payload: filters,
  });

  return filters;
};

export const clearFilters = () => (dispatch) => {
  window.history.replaceState({}, document.title, "/" + "network");
  dispatch(getData(`/${api_v}network`));
  dispatch({
    type: CLEAR_FILTERS,
  });
};
// setAllcompaniesTab
export const setFirstTab = (data) => (dispatch) => {
  dispatch({
    type: SET_ALL_COMPANIES_TAB,
    payload: data
  })
}
export const getFilterLists = () => async (dispatch) => {
  const promises = [];
  // let airImportCountryData=[]
  const data = {};

  promises.push(
    Promise.resolve(
      axios.get(`${base_url}/${api_v}specializations`).then((res) => {
        data.specializationList = res.data["hydra:member"];
      })
    )
  );

  promises.push(
    Promise.resolve(
      axios.get(`${base_url}/${api_v}countries`).then((res) => {
        data.countries = res.data["hydra:member"];
      })
    )
  );

  promises.push(
    Promise.resolve(
      axios.get(`${base_url}/${api_v}air_industry_expertises`).then((res) => {
        data.airIndustryExpertise = res.data["hydra:member"];
      })
    )
  );

  promises.push(
    Promise.resolve(
      axios
        .get(`${base_url}/${api_v}associate_industry_expertises`)
        .then((res) => {
          data.associateIndustryExpertise = res.data["hydra:member"];
        })
    )
  );

  promises.push(
    Promise.resolve(
      axios
        .get(`${base_url}/${api_v}vendor_industry_expertises`)
        .then((res) => {
          data.vendorIndustryExpertise = res.data["hydra:member"];
        })
    )
  );

  /* ------------------Air Import Countries-----------------  */
  promises.push(
    axios.get(`${base_url}/${api_v}all_air_import_countries`).then((res) => {
      let response = res.data;
      let airImportCountryData = response?.map((item) => item?.country)
      data.airImportCountries = airImportCountryData;
      return data.airImportCountries; // Return the processed data to the outer promise
    })
  );

  /* ------------------Air Export Countries-----------------  */
  promises.push(
    axios.get(`${base_url}/${api_v}all_air_export_countries`).then((res) => {
      let response = res.data;
      let airExportCountryData = response?.map((item) => item?.country)
      data.airExportCountries = airExportCountryData;
      return data.airExportCountries; // Return the processed data to the outer promise
    })
  );

  await Promise.all(promises);

  dispatch({
    type: GET_FILTER_LISTS,
    payload: data,
  });

  return true;
};

// Get Countries
export const getCountries = () => (dispatch) => {
  axios
    .get(`${base_url}/${api_v}countries`)
    .then((res) =>
      dispatch({
        type: GET_COUNTRIES,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

// Get Cities
export const getCities = () => (dispatch) => {
  axios
    .get(`${base_url}/${api_v}cities`)
    .then((res) =>
      dispatch({
        type: GET_CITIES,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};
// set Cities
export const setSelectedCountry = (value) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_COUNTRY,
    payload: value,
  })
};
// Get Cities
export const getCountryCitiesList =
  (countryFilter = "") =>
    (dispatch) => {
      axios
        .get(
          `${base_url}/${api_v}cities${countryFilter ? "?" + countryFilter : ""}`
        )
        .then((res) =>
          dispatch({
            type: GET_CITIES,
            payload: res.data,
          })
        )
        .catch((err) => {
          console.log(err);
        });
    };

// Get City

export const getCity = async (id) => {
  try {
    const res = await axios.get(`${base_url}/${api_v}cities/${id}`);
    return res;
  } catch (err) {
    return null;
  }
};

// Get expertise list
//Not used this
export const getExpertiseList = (classification) => (dispatch) => {
  let url = `/${api_v}air_industry_expertises`;
  if (classification === "Associate") {
    url = `/${api_v}associate_industry_expertises`;
  }
  if (classification === "Vendor") {
    url = `/${api_v}vendor_industry_expertises`;
  }

  axios
    .get(`${base_url}${url}`)
    .then((res) =>
      dispatch({
        type: GET_EXPERTISE_LIST,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

// Get subIndustry / subgroup list /networks list
export const getSubGroupList = () => (dispatch) => {
  let url = `/${api_v}sub_industries`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};
// Get Expertiese list
export const getNewExpertieseList = () => (dispatch) => {
  let url = `/${api_v}expertieses`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};


// Get specialization list
export const getSpecializations = () => (dispatch) => {
  axios
    .get(`${base_url}/${api_v}specializations`)
    .then((res) =>
      dispatch({
        type: GET_SPECIALIZATION_LIST,
        payload: res.data["hydra:member"],
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

// Get Airlines (?search = airline name || iata code)
export const getAirlines = (queryString) => (dispatch) => {
  axios
    .get(`${base_url}/${api_v}airlines${queryString}`)
    .then((res) =>
      dispatch({
        type: GET_AIRLINES,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

// Get Airports (?search = city name || iata code)
export const getAirports = (queryString, option) => (dispatch) => {
  axios
    .get(`${base_url}/${api_v}airports${queryString}`)
    .then((res) => {
      if (option === "origin") {
        dispatch({
          type: GET_ORIGIN_AIRPORTS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_DESTINATION_AIRPORTS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// Certificates -------------------------------------------------------------------------------

// Get Certificates
export const getCertificates = () => async (dispatch) => {
  try {
    const res = await axios.get(`${base_url}/${api_v}certificates`);

    dispatch({
      type: GET_CERTIFICATE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
  }
};

// DATA FOR SELECT -------------------------------------------------------------------------------

// Fetch airports (for react-select)
export const fetchAirports = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airports?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Fetch airports (for react-select)
export const fetchCountryAirports = (input, country, callback) => {
  axios
    .get(`${base_url}/${api_v}airports?country=${country}&search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};
// Fetch airlines(for react-select)
export const fetchAirlines = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airlines?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => callback([]));
};

// Fetch countries (for react-select)
export const fetchCountries = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}countries?countryname=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Fetch cities (for react-select)
export const fetchCities = (search, countryId, callback) => {
  axios
    .get(
      `${base_url}/${api_v}cities?cityAsciiname=${search}&countryId=${countryId}`
    )
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Get expertise details item
export const getExpertise = (id) => (dispatch) => {
  axios
    .get(`${base_url}`)
    .then((res) =>
      dispatch({
        type: GET_EXPERTISE,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

// Set loading state
export const setDataLoading = () => {
  return {
    type: DATA_LOADING,
  };
};

// CLear all global errors ( messages )
export const clearGlobalErrors = () => {
  return {
    type: CLEAR_GLOBAL_ERROR,
  };
};

export const addAlertAsyncActions = async (data) => {
  const action = `${base_url}/${api_v}alert`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(action, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateAlertAsyncActions = async (data, id) => {
  return await axios
    .put(`${base_url}/${api_v}cas/update/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addPromoAsyncActions = async (data) => {
  const action = `${base_url}/${api_v}promo`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(action, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};


export const getCompanyPaidInfo = async (value, page) => {
  try {
    if (value) {
      const res = await axios.get(`${base_url}/${api_v}get_company_paid_info?search=${value}&page=${page}`);
      return res.data;
    } else {
      const res = await axios.get(`${base_url}/${api_v}get_company_paid_info?page=${page}`);
      return res.data;
    }

  } catch (err) {
    return false;
  }
};

export const storeParentCompanyId = async (data, id) => {

  try {
    const res = await axios.put(
      `${base_url}/${api_v}company_infos/${id}`,
      {
        "parentCompany": `/v1/companies/${data.parentCompany}`
      }
    );
    return res.data;
  } catch (err) {  
    return err.response.data;
  }
};
