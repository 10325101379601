import React from "react";
import { Col } from "antd";
import importIcon from 'theme/assets/importIcon.svg';
import exportIcon from 'theme/assets/exportIcon.svg';

const CompanyImportExport = ({ importCountries, exportCountries }) => {
  let importData, exportData;

  if (importCountries.length === 0 && exportCountries.length === 0) {
    return null;
  }

  if (importCountries.length > 0 && importCountries[0].country) {
    importData = (
      <>
        <img src={importIcon} className="sectionIcon" />
        <h4>Top import countries</h4>
        <ul className="list-blank">
          {importCountries.map(a => (
            <li key={a.id}>{a.country?.countryname}</li>
          ))}
        </ul>
      </>
    );
  }

  if (exportCountries.length > 0 && exportCountries[0].country) {
    exportData = (
      <>
        <img src={exportIcon} className="sectionIcon" />
        <h4 className="">Top export countries</h4>
        <ul className="list-blank">
          {exportCountries.map(a => (
            <li key={a.id}>{a.country?.countryname}</li>
          ))}
        </ul>
      </>
    );
  }
  return (
    <>
      {
        importData &&
        <Col span={5}>
          {importData}
        </Col>
      }
      {
        exportData &&
        <Col span={5}>
          {exportData}
        </Col>
      }
    </>
  );
};

export default CompanyImportExport;
