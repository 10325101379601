import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  RESET_PASSWORD,
  REQUEST_RESET,
  AUTH_ERROR,
  CLEAR_AUTH,
  AUTH_LOADING,
  SET_LOGODATA,
} from "./types";
import { base_url } from "utils/settings";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  dispatch(setAuthLoading());
  axios
    .post(`${base_url}/login_check`, userData)
    .then((res) => {
      // Save to localStorage

      const { token, status, user } = res.data;
      const userSettings = user?.userSettings[0];
      const rolesUser = user.roles[0];
      const userEmail = user.email;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("status", status);
      localStorage.setItem("rolesUser", rolesUser);
      localStorage.setItem("userEmail", userEmail);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set additional params in decoded data
      decoded["status"] = status;
      localStorage.setItem(
        "loggedInUserCompanyId",
        JSON.stringify(userSettings?.company)
      );
      localStorage.setItem("companyInfo", JSON.stringify(userSettings));
      // Set current user
      dispatch(setCurrentUser(decoded));   
      localStorage.setItem("nexHub", res?.data?.user?.userSettings[0]?.nexHub)    
    })
    .catch((err) => {

      return dispatch({
        type: AUTH_ERROR,
        payload: err.response?.data.error,
      });
    });
};

// Reset Password - Send email link
export const resetPassword = (email) => (dispatch) => {
  axios
    .post("/api/users/reset-password", email)
    .then((res) => {
      dispatch({
        type: REQUEST_RESET,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Reset Password - Submit new password
export const newPassword = (email, resetData) => (dispatch) => {
  axios
    .post(`/api/users/reset-password/${email}`, resetData)
    .then((res) => {
      dispatch({
        type: RESET_PASSWORD,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({
    type: SET_LOGODATA,
    payload: undefined,
  });
};

// Clear Auth

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH,
  };
};
// Set loading state
export const setAuthLoading = () => {
  return {
    type: AUTH_LOADING,
  };
};

// Login - Get User Token
export const forgetPasswordRequest = (userData) => (dispatch) => {
  dispatch(setAuthLoading());
  return axios
    .post(`${base_url}/request_forget_password`, userData)
    .then((res) => {
      dispatch(clearAuth());
      return res;
    })
    .catch((err) => {

      return dispatch({
        type: AUTH_ERROR,
        payload: err.response.data.error,
      });
    });
};
export const resetPasswordRequest = (userData) => (dispatch) => {
  dispatch(setAuthLoading());
  return axios
    .put(`${base_url}/reset_password`, userData)
    .then((res) => {
      dispatch(clearAuth());
      return res;
    })
    .catch((err) => {
      return dispatch({
        type: AUTH_ERROR,
        payload: err.response.data.error,
      });
    });
};
