import { createReducer } from "redux-starter-kit";
import { getParameterByName } from "../utils/functions";
import { api_v } from "utils/settings";

const initialState = {
  companies: null,
  invitations: null,
  countries: [],
  cities: [],
  originAirports: [],
  destinationAirports: [],
  airlines: [],
  expertiseList: [],
  expertiseDetail: {},
  specializationList: [],
  certificateList: [],
  pageUrl: `/${api_v}network`,
  currentPage: 1,
  filters: {
    companyName: "",
    fos_email: '',
    sortOptions: "date",
    status: null,
    country: null,
    city: null,
    classification: "",
    airIndustryExpertise: null,
    associateIndustryExpertise: null,
    vendorIndustryExpertise: null,
    specializations: null,
    importCountry: null,
    exportCountry: null,
    originAirport: null,
    destinationAirport: null,
    airline: null
  },
  itemsPerPage: 10,
  totalItems: 0,
  loading: false
};

const dataReducer = createReducer(initialState, {
  GET_DATA: (state, action) => {
    state.loading = false;
    state.companies = action.payload["hydra:member"];
    state.totalItems = action.payload["hydra:totalItems"];
    state.pageUrl = action.payload["hydra:view"]["@id"];
    state.currentPage = Number(
      getParameterByName("page", action.payload["hydra:view"]["@id"])
    );
  },

  GET_INVITATIONS: (state, action) => {
    state.invitations = action.payload["hydra:member"];
  },

  GET_FILTER_LISTS: (state, action) => {
    state.airIndustryExpertise = action.payload.airIndustryExpertise;
    state.associateIndustryExpertise =
      action.payload.associateIndustryExpertise;
    state.countries = action.payload.countries;
    state.specializationList = action.payload.specializationList;
    state.vendorIndustryExpertise = action.payload.vendorIndustryExpertise;
    state.airImportCountries = action.payload.airImportCountries;
    state.airExportCountries = action.payload.airExportCountries
  },

  UPDATE_COMPANY_SETTINGS: (state, action) => {
    let companyIndex = state.companies.findIndex(
      a => a.id === action.companyId
    );

    state.companies[companyIndex].companySettings = action.payload;
  },

  DELETE_COMPANY: (state, action) => {
    state.companies = state.companies.filter(a => a.id !== action.payload);
  },

  ADD_COMPANY: (state, action) => {
    state.companies.unshift(action.payload);
  },

  GET_FILTERS: (state, action) => {
    state.filters = Object.assign(state.filters, action.payload);
  },

  GET_EXPERTISE: (state, action) => {
    state.expertiseDetail = action.payload["hydra:member"];
  },

  GET_SPECIALIZATION_LIST: (state, action) => {
    state.specializationList = action.payload;
  },

  GET_COUNTRIES: (state, action) => {
    state.countries = action.payload["hydra:member"];
  },

  GET_CITIES: (state, action) => {
    state.cities = action.payload["hydra:member"];
  },

  SET_SELECTED_COUNTRY: (state, action) => {
    state.selectedCountry = action.payload;
  },


  GET_EXPERTISE_LIST: (state, action) => {
    state.expertiseList = action.payload["hydra:member"];
  },
  GET_CERTIFICATE_LIST: (state, action) => {
    state.certificateList = action.payload["hydra:member"];
  },

  GET_AIRLINES: (state, action) => {
    state.airlines = action.payload["hydra:member"];
  },

  GET_ORIGIN_AIRPORTS: (state, action) => {
    state.originAirports = action.payload["hydra:member"];
  },

  GET_DESTINATION_AIRPORTS: (state, action) => {
    state.destinationAirports = action.payload["hydra:member"];
  },

  DATA_LOADING: (state, action) => {
    state.loading = true;
  },

  UPDATE_NETWORK_COMPANY: (state, action) => {
    const companyId = action.payload.id;
    const companyIndex = state.companies.findIndex(a => a.id === companyId);
    state.companies[companyIndex] = action.payload;
  },

  CLEAR_FILTERS: (state, action) => {
    state.filters = {
      companyName: "",
      fos_email: "",
      country: null,
      city: null,
      classification: "",
      airIndustryExpertise: null,
      associateIndustryExpertise: null,
      vendorIndustryExpertise: null,
      specializations: null,
      importCountry: null,
      exportCountry: null,
      originAirport: null,
      destinationAirport: null,
      airline: null,
      status: null
    };
  },
  SET_ALL_COMPANIES_TAB: (state, action) => {
    state.setTab = action.payload
  }
});

export default dataReducer;
