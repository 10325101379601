import React from "react";
import ExpertiseHoverItem from "./ExpertiseHoverItem";
import AirCargoNeutral from "./AirCargoNeutral";
import CustomToolTip from '../../CustomTooltip'
import { NETWORK } from "utils/enum"
import industryIcon from "theme/assets/industryIcon.svg";

const ExpertiseListing = ({
  subIndustry,
  expertise,
  classification,
  containerWidth,
  companyId,
}) => {

  let content = [],
    skip = [];

  let subIndustryContent = [];

  const colorMap = {
    [NETWORK.NAC_NEUTRAL_AIR_CONSOLIDATOR]: "blue",
    [NETWORK.NAV_NEUTRAL_AVIATION_SPECIALIST]: "orange",
    [NETWORK.NEX_E_COMMERCE]: "red",
    [NETWORK.NAX_24_BY_7]: "purple",
    [NETWORK.NAX_FRESH]: "green",
    [NETWORK.NAF]: "yellow", // color not provided by client end,
    [NETWORK.VENDORS]: "black", // color not provided by client end
  };

  const blockColor = (id) => colorMap[id] || "";
  // if (classification === "Air Partner") {
  //   debugger
  //   const expertiseIds = expertise.map(a => a.id);

  //   // AirCargoSalesAgent && Neutral Air Consolidator
  //   if (expertiseIds.indexOf(1) > -1 && expertiseIds.indexOf(2) > -1) {
  //     content.push(
  //       <ExpertiseHoverItem
  //         title="Air Cargo Sales Agent / Neutral Air Consolidator"
  //         companyId={companyId}
  //         containerWidth={containerWidth}
  //         neutral={true}
  //         air={true}
  //         key={expertise.indexOf(1) + expertise.indexOf(2)}
  //       ></ExpertiseHoverItem>
  //     );
  //     skip = [1, 2];
  //   } else {
  //     // Only AirCargoSales
  //     if (expertiseIds.indexOf(1) > -1) {
  //       content.push(
  //         <ExpertiseHoverItem
  //           title="Air Cargo Sales Agent"
  //           containerWidth={containerWidth}
  //           companyId={companyId}
  //           key={expertise.indexOf(1)}
  //           air={true}
  //         ></ExpertiseHoverItem>
  //       );

  //       skip = [1];
  //     } else {
  //       // Only NeutralAirConsolidator
  //       if (expertiseIds.indexOf(2) > -1) {
  //         content.push(
  //           <ExpertiseHoverItem
  //             title="Neutral Air Consolidator"
  //             containerWidth={containerWidth}
  //             companyId={companyId}
  //             key={expertise.indexOf(1)}
  //             neutral={true}
  //           ></ExpertiseHoverItem>
  //         );
  //         skip = [2];
  //       }
  //     }
  //   }
  // }

  if (subIndustry) {
    subIndustry.forEach((sub) => {
      const className = blockColor(sub.id)
      const data = expertise.map((item) => item.subIndustry.id)
      const uniqueArray = data.filter(function (item, pos) {
        return data.indexOf(item) == pos;
      })

      // if (skip.indexOf(expertise.id) > -1) {
      //   return;
      // }
      // if (classification === "Air Partner") {
      //   return content.push(
      //     <ExpertiseHoverItem
      //       key={expertise.id}
      //       containerWidth={containerWidth}
      //       companyId={companyId}
      //       title={expertise.industryExpertise}
      //       expertiseId={expertise.id}
      //       item={expertise}
      //     ></ExpertiseHoverItem>
      //   );
      // }
      {
        sub.industryLogo ?
          subIndustryContent.push(<li key={sub.id}>
            {(expertise?.length !== 0 && uniqueArray.includes(sub?.id))
              ?
              < CustomToolTip
                className={className}
                isDom={true}
                fileStatus="networkSidebar"
                title="Our Expertise"
                subIndustryName={sub.name}
                subId={sub.id}
                expertise={expertise}
                position="right"
                containerClass="tooltipStyleChange"
              >
                <img src={sub.industryLogo} />
              </CustomToolTip>
              :
              <img src={sub.industryLogo} />
            }
          </li >) : subIndustryContent.push(<li key={sub.id}>None</li>);
      }

    });
  }
  // if (expertise) {
  //   expertise.forEach((expertise) => {
  //     if (skip.indexOf(expertise.id) > -1) {
  //       return;
  //     }
  //     if (classification === "Air Partner") {
  //       return content.push(
  //         <ExpertiseHoverItem
  //           key={expertise.id}
  //           containerWidth={containerWidth}
  //           companyId={companyId}
  //           title={expertise.industryExpertise}
  //           expertiseId={expertise.id}
  //           item={expertise}
  //         ></ExpertiseHoverItem>
  //       );
  //     }
  //     content.push(<li key={expertise.id}>{expertise.industryExpertise}</li>);
  //   });
  // }

  return (
    <div>
      <img src={industryIcon} className="sectionIcon" />
      <h4>Networks</h4>
      <div className="f">
        <ul className="list-blank">{subIndustryContent}</ul>
        {/* <ul className="list-blank">{content}++</ul> */}
      </div>
    </div>
  );
};

export default ExpertiseListing;
