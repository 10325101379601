import React from "react";

const Title = ({
	name,
	city,
	country,
	classification
}) => {
	return (
		<div className="ml-3">
			<h1 className="inline">{name}</h1>{/*  | <em>{classification}</em> */}
			<br />
			<span className="text-secondary">
				{city}, {country.countryname}
			</span>
		</div>
	);
};

export default Title;
