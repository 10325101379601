import axios from "axios";

const setAuthToken = token => {
  if (token) {
    // Apply to every request (authenticated)
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.post["Accept"] = "application/json";
    axios.defaults.headers.put["Accept"] = "application/json";
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
