import { Button, Col, Divider, Icon, Row, Skeleton } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import isEmpty from "utils/isEmpty";
import { api_v, base_url } from "utils/settings";
import { CALL_ACTION, QUESTION_ANSWER_TYPE } from "../../../../../utils/enum";


const QuestionAnswer = (props) => {
    let counter = 0;
    const { subIndusryId, expertise, companyData, subindustryItem } = props;
    const [loading, setLoading] = useState(true)
    // const [linkingQuestion, setLinkingQuestion] = useState([])
    const [questionAnswerList, setQuestionAnswerList] = useState([])

    const fetchData = async () => {
        let questionIds = []
        /*  eslint no-unused-expressions: off   */
        subindustryItem.questionRelations?.map((value) => questionIds.push(value.question.id))
        expertise.map((value) => value.questionRelations.map((item) => questionIds.push(item.question.id)))

        const filterQuestionAnswer = companyData.expQuizAnswers.filter((element) => {
            if (questionIds.includes(element.question.id) && element.answer !== "") {
                return element;
            }
        });

        let questionAnswerData = [];
        for (const value of filterQuestionAnswer) {
           if ((value.question.QuestionType.name === QUESTION_ANSWER_TYPE.RADIO /* || value.question.QuestionType.name === QUESTION_ANSWER_TYPE.SINGLE_SELECT */) && value.question.callAction == "") {            
                for (const item of value.question.questionOptions) {
                    if (item.id === Number(value.answer)) {                      
                        questionAnswerData.push({
                            question: value.question.placeholder,
                            answer: item.label,
                            callAction: value.question.callAction,
                            questionType: value.question.QuestionType.name,
                            linking: value.question.linkedQuestion,
                            questionId: value.question.id
                        });
                    }
                }
            } else if (value.question.QuestionType.name === QUESTION_ANSWER_TYPE.CHECKBOX) {
                questionAnswerData.push({
                    question: value.question.placeholder,
                    answer: value.expQuizOptAnswers,
                    callAction: value.question.callAction,
                    questionType: value.question.QuestionType.name,
                    linking: value.question.linkedQuestion,
                    questionId: value.question.id
                });
            } else if (value.question.callAction !== "") {
                let AnswerFromAPI;
                let ApiType = value.question.callAction;
                if (ApiType === CALL_ACTION.COUNTRY) {
                    if (value.multiSelectAnswer.length !== 0) {
                        let multiCountry = [];
                        await Promise.all(
                            value.multiSelectAnswer.map(async (element) => {
                                try {
                                    const dataResponse = await Axios.get(
                                        `${base_url}/${api_v}countries/${element.id}`
                                    );
                                    let response = dataResponse.data.countryname;
                                    multiCountry.push(response);
                                } catch (error) {
                                    AnswerFromAPI = "";
                                }
                            })
                        );
                        AnswerFromAPI = String(multiCountry)
                    }
                    else {
                        /*  try {
                             const dataResponse = await Axios.get(`${base_url}/${api_v}countries/${Number(value.answer)}`);
                             AnswerFromAPI = dataResponse.data.countryname;
                         } catch (error) {
                             AnswerFromAPI = "";
                         } */
                        AnswerFromAPI = value.answer;
                    }
                } else if (ApiType === CALL_ACTION.CITY) {
                    if (value.multiSelectAnswer.length !== 0) {
                        let multiCities = [];
                        await Promise.all(
                            value.multiSelectAnswer.map(async (element) => {
                                try {
                                    const dataResponse = await Axios.get(
                                        `${base_url}/${api_v}cities/${element.id}`
                                    );
                                    let response = dataResponse.data.cityName;
                                    multiCities.push(response);
                                } catch (error) {
                                    AnswerFromAPI = "";
                                }
                            })
                        );
                        AnswerFromAPI = String(multiCities)
                    }
                    else {
                        /*   try {
                              const dataResponse = await Axios.get(`${base_url}/${api_v}cities/${Number(value.answer)}`);
                              AnswerFromAPI = dataResponse.data.cityName;
                          } catch (error) {
                              AnswerFromAPI = "";
                          } */
                        AnswerFromAPI = value.answer;
                    }
                } else if (ApiType === CALL_ACTION.AIRLINE) {
                    // console.log("value...", value)
                    if (value.multiSelectAnswer.length !== 0) {
                        let multiAirlines = [];
                        await Promise.all(
                            value.multiSelectAnswer.map(async (element) => {
                                try {
                                    const dataResponse = await Axios.get(
                                        `${base_url}/${api_v}airline/${element.airline.id}`
                                    );
                                    // console.log("dataResponse..", dataResponse)
                                    let response = dataResponse.data.iata;
                                    multiAirlines.push(response);
                                } catch (error) {
                                    AnswerFromAPI = "";
                                }
                            })
                        );
                        AnswerFromAPI = String(multiAirlines)
                    }
                    else {
                        AnswerFromAPI = value.answer;
                        /*  try {
                             const dataResponse = await Axios.get(`${base_url}/${api_v}airline/${Number(value.answer)}`);
                             AnswerFromAPI = dataResponse.data.iata;
                         } catch (error) {
                             AnswerFromAPI = "";
                         } */
                    }
                } else if (ApiType === CALL_ACTION.AIRPORT) {
                    if (value.multiSelectAnswer.length !== 0) {
                        let multiAirports = [];
                        await Promise.all(
                            value.multiSelectAnswer.map(async (element) => {
                                try {
                                    const dataResponse = await Axios.get(
                                        `${base_url}/${api_v}airport/${element.airport.id}`
                                    );
                                    let response = dataResponse.data.iata;
                                    multiAirports.push(response);
                                } catch (error) {
                                    AnswerFromAPI = "";
                                }
                            })
                        );
                        AnswerFromAPI = String(multiAirports)
                    }
                    else {
                        /*  try {
                             const dataResponse = await Axios.get(`${base_url}/${api_v}airport/${Number(value.answer)}`);
                             AnswerFromAPI = dataResponse.data.iata;
                         } catch (error) {
                             AnswerFromAPI = "";
                         } */
                        AnswerFromAPI = value.answer;
                    }
                } else {
                    AnswerFromAPI = value.answer;
                }
                questionAnswerData.push({
                    question: value.question.placeholder,
                    answer: AnswerFromAPI,
                    callAction: value.question.callAction,
                    questionType: value.question.QuestionType.name,
                    linking: value.question.linkedQuestion,
                    questionId: value.question.id
                });
            } else { 
                console.log("hello",value)
                questionAnswerData.push({
                    question: value.question.placeholder,
                    answer: value.answer,
                    callAction: value.question.callAction,
                    questionType: value.question.QuestionType.name,
                    linking: value.question.linkedQuestion,
                    questionId: value.question.id
                });
            }
        }
        setQuestionAnswerList(questionAnswerData);
        setLoading(false)
    }
    console.log("questionAnswerList", questionAnswerList)
    const answers = (item) => {
        console.log("ite....", item)
        let type = item.questionType
        // Radio Button
        if (type === QUESTION_ANSWER_TYPE.RADIO) {

            let labelData = item.answer.trim().toLowerCase();
            if (labelData === 'yes') {
                return (
                    <Icon className="quesIcon" type="check" />
                )
            } else {
                return (
                    <Icon className="quesIcon" type="close" />
                )
            }
        }
        // Multi Select SearchAble & Multi Select
        else if (type === QUESTION_ANSWER_TYPE.MULTI_SELECT_SEARCH || type === QUESTION_ANSWER_TYPE.MULTI_SELECT || type === QUESTION_ANSWER_TYPE.SINGLE_SELECT_SEARCH) {
            if (item.callAction === "") { // only for country list in case of MultiSelect
                console.log(" elsellllllllllllllllllll", item)
                let str = item.answer;
                let arr = str.split(',');
                return <div className="btnGrp">{arr.map((value) => {
                    return (
                        <div className="multiSelectBlueBtn">
                            {value}
                        </div>
                    )
                })}</div>
            } else { // Whwn we get call Action 
                if (item.callAction === CALL_ACTION.AIRLINE || item.callAction === CALL_ACTION.AIRPORT) {
                    // console.log("else..", item)
                    if (item.questionType !== "DropDown(Single Select SearchAble)") {
                        let str = item.answer;
                        let arr = str.split(',');
                        return <div className="btnGrp">{arr.map((value, index) => {
                            return (
                                <div className="multiSelectGrayBtn">
                                    {value}
                                </div>
                            )
                        })}</div>
                    } else {
                        return <div className="btnGrp">
                            <div className="multiSelectGrayBtn">
                                {item.answer}
                            </div>
                        </div>
                    }
                } else {
                    console.log("last else", item)
                    let str = item.answer;
                    let arr = str.split(',');
                    return <div className="btnGrp">{arr.map((value) => {
                        return (
                            <div style={{ color: 'blue' }} className="multiSelectBlueBtn">
                                {value}
                            </div>)
                    })}
                    </div>
                }
            }
        }/*  else if (type === "PhoneNo") {
            return <div>{item.answer}</div>
        }  else if (type === QUESTION_ANSWER_TYPE.EMAIL) {
            return <div>{item.answer}</div>
        }*/
        else if (type === QUESTION_ANSWER_TYPE.CHECKBOX) {
            const checkboxAnsData = item.answer
            return (
                <div className="checkbox-parent">
                    {checkboxAnsData?.map((value) => {
                        return (
                            <div id="tagcustom" className="checkbox">{value?.options?.label}</div>
                        )
                    })}
                </div>
            )
        }
        else {
            //if (type === "Percentage Slider" || type === "Number" || type === "Number Format" || type==="Password")
            return <div id="tagcustom">{item?.answer}</div>
        }
    }
    function StaticKeyContactHandle(props) {

        const keyContact = props.getLinkedQuestion.filter((item) => item.question == 'Key contact')
        const keyTele = props.getLinkedQuestion.filter((item) => item.question == 'Telephone')
        const keyEmail = props.getLinkedQuestion.filter((item) => item.question == 'Email')
        return (<Row>
            {props.counter !== 0 && <Divider className=" mt-0"></Divider>}
            <Col span={24} className="questionBox">
                <div className="questionBoxInner">
                    {props.answers(props.item)}{""}
                    <p>{props.item.question}</p>
                </div>
            </Col >
            <Col span={24} style={{ paddingLeft: "54px" }} className="contactInfo">
                <p className="m-0">
                    <b>{keyContact[0]?.answer}</b>
                </p>
                <p className="m-0">
                    {"Tel: "}{keyTele[0]?.answer}
                </p>
                <p className="m-0">
                    {"Email: "}{keyEmail[0]?.answer}
                </p>
            </Col>

            <div style={{
                display: 'none'
            }}>{counter = props.counter + 1}</div>
        </Row>);
    }

    useEffect(() => {
        fetchData();
    }, [companyData, subIndusryId, expertise])
    // const linkingQuestion=questionAnswerList?.filter((item)=>item.linking !== null)
    const linkingQuestion = [];
    // console.log("questionAnswerList", questionAnswerList)
    const questionAnswerListCopy = [...questionAnswerList]; // Create a copy of the original array

    for (let i = questionAnswerListCopy.length - 1; i >= 0; i--) {
        const item = questionAnswerListCopy[i];
        if (item.linking !== null) {
            linkingQuestion.push(item);
            questionAnswerListCopy.splice(i, 1); // Remove the filtered item from the original array
        }
    }

    console.log("questionAnswerListCopy", questionAnswerListCopy)

    return (
        <Row className="questionArea">
            {loading ?
                <Skeleton active />
                :
                questionAnswerListCopy.length !== 0 ?
                    questionAnswerListCopy.map((item, index) => {
                        const hasLinking = linkingQuestion?.some(linkItem => linkItem.linking === item.questionId);
                        //Here, handle linked Question answer
                        if (hasLinking) {
                            const getLinkedQuestion = linkingQuestion?.filter(linkItem => linkItem.linking === item.questionId);
                            //Here, handle linked QuestionAsnwer && Value of Parent Question (Radio Button)  is only 'yes'
                            if (item.questionType === "Radio Button") {
                                if (item.answer.trim().toLowerCase() !== 'no') {
                                    if (item.questionId == 551) {
                                        return (<StaticKeyContactHandle getLinkedQuestion={getLinkedQuestion} answers={answers} counter={counter} item={item}></StaticKeyContactHandle>)
                                    } else if (item.questionId === 539) {
                                        return (
                                            <Row>
                                                {counter !== 0 && <Divider className=" mt-0" ></Divider>}
                                                <Col span={24} className="questionBox">
                                                    <div className="questionBoxInner">
                                                        {answers(item)}{""}
                                                        <p>{item.question}</p>
                                                    </div>
                                                </Col>
                                                <div className="parent-539">
                                                    {getLinkedQuestion?.map((getItem) => {
                                                        return (
                                                            <Col span={24} className="questionBox">
                                                                <div className={`${getItem.questionType === "Text Box" ? 'otherCarrier' : 'questionBoxInner'}`}>
                                                                    {answers(getItem)}{""}
                                                                    <p style={{ marginBottom: '7px' }}>{getItem.question}</p>
                                                                </div>
                                                            </Col>

                                                        )
                                                    }
                                                    )}
                                                </div>
                                                <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                            </Row>
                                        )
                                    } else {
                                        return (
                                            <Row>
                                                {counter !== 0 && <Divider className=" mt-0" ></Divider>}
                                                <Col span={24} className="questionBox">
                                                    <div className="questionBoxInner">
                                                        {answers(item)}{""}
                                                        <p>{item.question}</p>
                                                    </div>
                                                </Col>
                                                {getLinkedQuestion?.map((getItem) => {
                                                    return (
                                                        <Col span={24} className="questionBox">
                                                            <div className="questionBoxInner">
                                                                {answers(getItem)}{""}
                                                                <p>{getItem.question}</p>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                )}
                                                <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                            </Row>
                                        )
                                    }
                                }
                            } else if (item.questionType === "DropDown(Multi Select)") {
                                //here, handle if parent question is DropDown(Multi Select) (i.e Pharma, Medical Science) and this linked with Radio Button(Child ques.)   
                                let answerValue = item.answer.trim().toLowerCase()
                                return (
                                    <Row>
                                        {counter !== 0 && <Divider className=" mt-0"></Divider>}
                                        <Col span={24} className="questionBox">
                                            <div className="questionBoxInner">
                                                {answers(item)}{""}
                                                <p>{item.question}</p>
                                            </div>
                                        </Col>
                                        <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                        {(answerValue.split(',').includes('pharma') || answerValue.split(',').includes('medical science')) &&
                                            /* -----here, handle if selected Pharma and Medical science from (parent ques)------ */
                                            getLinkedQuestion?.map((getItem) => {
                                                if (getItem.questionType === "Radio Button") { // here, handle if answer will be ge "No" (from Radio Button )
                                                    let value = getItem.answer.trim().toLowerCase()
                                                    if (value !== 'no') {
                                                        return (
                                                            <Col span={24} className="questionBox">
                                                                <div className="questionBoxInner">
                                                                    {answers(getItem)}{""}
                                                                    <p>{getItem.question}</p>
                                                                </div>
                                                            </Col>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </Row>
                                )
                            } else {
                                //here, handle all linked questionAnswer except 'RadioType' && Child QuestionAnswer value is only 'yes'
                                return (
                                    <Row>
                                        {counter !== 0 && <Divider className=" mt-0"></Divider>}
                                        <>
                                            <Col span={24} className="questionBox">
                                                <div className="questionBoxInner">
                                                    {answers(item)}{""}
                                                    <p>{item.question}</p>
                                                </div>
                                            </Col>
                                            <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                            {/* Child Question Answer */}
                                            {getLinkedQuestion?.map((getItem) => {
                                                if (getItem.questionType === "Radio Button") { // here, handle if answer will be ge "No" (from Radio Button )
                                                    let value = getItem.answer.trim().toLowerCase()
                                                    if (value !== 'no') {
                                                        return (
                                                            <Col span={24} className="questionBox">
                                                                <div className="questionBoxInner">
                                                                    {answers(getItem)}{""}
                                                                    <p>{getItem.question}</p>
                                                                </div>
                                                            </Col>
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Col span={24} className="questionBox">
                                                            <div className="questionBoxInner">
                                                                {answers(getItem)}{""}
                                                                <p>{getItem.question}</p>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </>
                                    </Row>
                                )
                            }
                        } else { // here, handle QuestionAnswer without linkings
                            // let listLengh = questionAnswerListCopy.length;     

                            if (item.questionType === "Radio Button") { // hrere, handle if answer will be ge "No" (from Radio Button )
                                let value = item.answer.trim().toLowerCase()

                                return (
                                    <Row>
                                        {value === 'yes' &&
                                            <>
                                                {counter !== 0 && <Divider className=" mt-0"></Divider>}
                                                <Col span={24} className="questionBox">
                                                    <div className="questionBoxInner">
                                                        {answers(item)}{""}
                                                        <p>{item.question}</p>
                                                    </div>
                                                </Col>
                                                <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                            </>
                                        }
                                    </Row>
                                )
                            }
                            else {
                                return (
                                    <Row>
                                        {counter !== 0 && <Divider className=" mt-0"></Divider>}
                                        {item.questionId === 542 ?
                                            <Col span={24} >
                                                <Col span={24} className="questionBox">
                                                    <div className={`${item.questionType === "Text Box" ? 'otherCarrier' : 'questionBoxInner'}`}>
                                                        {answers(item)}{""}
                                                        <p style={{ marginBottom: '7px' }}>{item.question}</p>
                                                    </div>
                                                </Col>
                                            </Col>
                                            : <Col span={24} className="questionBox">
                                                <div className="questionBoxInner">
                                                    {answers(item)}{""}
                                                    <p>{item.question}</p>
                                                </div>
                                            </Col>}
                                        <div style={{ display: 'none' }}>{counter = counter + 1}</div>
                                    </Row>
                                )
                            }
                        }
                    }
                    )
                    :
                    <Col span={24} style={{ textAlign: 'center' }}>
                        {/*     No Data */}
                    </Col>

            }
        </Row>
    )
}
export default QuestionAnswer