import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Skeleton } from 'antd'
import { DOMAIN } from 'utils/enum';

const Payment = () => {

    const { logo, domain } = useSelector((state) => state?.common?.domain)

    const handleSyncPayment = (domain) => {
        let link;
        if (domain === DOMAIN.NEX || domain === DOMAIN.NAP) {
            link = "https://lee.neutralairpartner.com/xero/payment/check"
        } else {
            link = "https://staging.neutralairpartner.com/xero/payment/check"
        }
        window.open(link, "_blank");
    }
    return (
        <Row className='payment-card-parent' gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
            <Col className="gutter-row login-logo-parent" span={24}>
                <img className="loginLogo" src={logo} />
            </Col>
            <Col className="gutter-row connection-card-parent sync-payment-btn-parent" span={24}>
                <Button type="primary" className="create-btn sync-payments" onClick={() => handleSyncPayment(domain)}>Sync payments</Button>
            </Col>
        </Row>
    )
}
export default Payment;