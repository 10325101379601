import { createReducer } from "redux-starter-kit";

const initialState = {
  data: null,
  loading: false,
  updating: false
};

const companyReducer = createReducer(initialState, {

  SAVE_ANSWER: (state, action) => {
    state.data.expQuizAnswers = action.payload.expQuizAnswers
  },

  // Company
  GET_COMPANY: (state, action) => {
    state.loading = false;
    state.data = action.payload;
  },
  /* It is not in used
    GET_COMPANY_EVENT_LINKS: (state, action) => {   
      state.loading = false;
      state.data.companyEventLinks = action.payload;
    }, */

  UPDATE_COMPANY: (state, action) => {
    if (action.key) {
      state.data[action.key] = action.payload[action.key];
    } else {
      state.data = action.payload;
    }
  },

  UPDATE_COMPANY_EXPERTISE: (state, action) => {
    state.data['expertiese'] = action.payload.expertiese;
    state.data['expQuizAnswers'] = action.payload.expQuizAnswers;
  },
  UPDATE_COMPANY_DETAILS: (state, action) => {
    state.updating = false;
  },

  UPDATE_COMPANY_DATA: (state, action) => {
    state.data.companyData[0] = action.payload;
  },

  UPDATE_COMPANY_INFO: (state, action) => {
    state.data.companyInfo[0] = action.payload;
  },

  UPDATE_COMPANY_AVATAR: (state, action) => {
    state.data.logo = [action.payload];
  },

  // Users (Key Personel)

  UPDATE_USER: (state, action) => {
    // Branch User
    if (action.branchId) {
      let branchIndex = state.data.branch.findIndex(
        a => a.id === action.branchId
      );

      let userIndex = state.data.branch[branchIndex].users.findIndex(
        a => a.id === action.payload.id
      );

      state.data.branch[branchIndex].users[userIndex] = action.payload;
      return;
    }

    // HQ user
    let index = state.data.userSettings.findIndex(
      a => a.id === action.payload.id
    );

    state.data.userSettings[index] = action.payload;
  },

  UPDATE_USER_INFO: (state, action) => {
    // Branch User

    if (action.branchId) {
      let branchIndex = state.data.branch.findIndex(
        a => a.id === action.branchId
      );

      let userIndex = state.data.branch[branchIndex].users.findIndex(
        a => a.user.userInfo[0].id === action.payload.id
      );
      state.data.branch[branchIndex].users[userIndex].user.userInfo[0] =
        action.payload;
      return;
    }

    // HQ user
    let index = state.data.userSettings.findIndex(
      a => a.user.userInfo[0].id === action.payload.id
    );

    state.data.userSettings[index].user.userInfo[0] = action.payload;
  },

   CREATE_KEY_PERSONEL: (state, action) => {
    if (action.branchId) {
      const branchIndex = state.data.branch.findIndex(
        a => a.id === action.branchId
      );
      state.data.branch[branchIndex].users.push(action.payload);
      return;
    }
    state.data.userSettings.push(action.payload);
  },

  UPDATE_KEY_PERSONEL: (state, action) => {
    // Branch User
    if (action.branchId) {
      let branchIndex = state.data.branch.findIndex(
        a => a.id === action.branchId
      );

      let userIndex = state.data.branch[branchIndex].users.findIndex(
        a => a.user.id === action.payload.id
      );

      state.data.branch[branchIndex].users[userIndex] = {
        ...action.payload,
        user: state.data.branch[branchIndex].users[userIndex]
      };
      return;
    }

    let index = state.data.userSettings.findIndex(
      a => a.id === action.payload.id
    );

    state.data.userSettings[index] = {
      ...action.payload,
      user: state.data.userSettings[index].user
    };
  },

  DELETE_USER: (state, action) => {
    if (action.branchId) {
      const branchIndex = state.data.branch.findIndex(
        a => a.id === action.branchId
      );
      state.data.branch[branchIndex].users = state.data.branch[
        branchIndex
      ].users.filter(a => a.user.id !== action.payload);
      return state;
    }
    state.data.userSettings = state.data.userSettings.filter(
      a => a.user.id !== action.payload
    );
  },

  // Specializations

  CREATE_SPECIALIZATION: (state, action) => {
    state.data[action.key].push(action.payload);
  },

  UPDATE_SPECIALIZATION: (state, action) => {
    let index = state.data[action.key].findIndex(a => a.id === action.id);

    state.data[action.key][index] = action.payload;
  },

  DELETE_SPECIALIZATION: (state, action) => {
    state.data[action.key] = state.data[action.key].filter(
      a => a.id !== action.payload
    );
  },

  // Document
  ADD_DOCUMENT: (state, action) => {
    state.data.document.unshift(action.payload);
  },

  DELETE_DOCUMENT: (state, action) => {
    state.data.document = state.data.document.filter(
      a => a.id !== action.payload
    );
  },

  // Fees
  ADD_FEE: (state, action) => {
    state.data[action.key].push(action.payload);
  },

  UPDATE_FEE: (state, action) => {
    let index = state.data[action.key].findIndex(
      fee => fee.id === action.payload.id
    );
    state.data[action.key][index] = action.payload;
  },

  DELETE_FEE: (state, action) => {
    state.data[action.key] = state.data[action.key].filter(
      rate => rate.id !== action.payload.id
    );
  },

  // Bank
  UPDATE_BANK_LEGAL: (state, action) => {
    state.data.companyBankInfo = action.bankInfo;
    state.data.companyInfo = action.companyInfo;
  },

  UPDATE_BANK: (state, action) => {
    state.data.companyBankInfo[0] = action.payload;
  },

  // licences
  ADD_LICENCE: (state, action) => {
    state.data.licences.push(action.payload);
  },

  UPDATE_LICENCE: (state, action) => {
    let index = state.data.licences.findIndex(
      licence => licence.id === action.payload.id
    );
    state.data.licences[index].refNo = action.payload.refNo;
    state.data.licences[index].acreditation = action.payload.acreditation;
  },

  DELETE_LICENCE: (state, action) => {
    state.data.licences = state.data.licences.filter(
      licence => licence.id !== action.payload
    );
  },

  // Branches --------------------------------------------

  CREATE_BRANCH: (state, action) => {
    state.data.branch.push(action.payload);
  },

  UPDATE_BRANCH_DETAILS: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );
    state.data.branch[branchIndex].branchName = action.payload.branchName;
    state.data.branch[branchIndex].branchCity = action.payload.branchCity;
    state.data.branch[branchIndex].subIndustry = action.payload.subIndustry
  },

  UPDATE_BRANCH_INFO: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );

    state.data.branch[branchIndex].branchInfo[0] = action.payload;
  },

  UPDATE_BRANCH_DATA: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );
    state.data.branch[branchIndex].branchData[0] = action.payload;
  },

  DELETE_BRANCH: (state, action) => {
    state.data.branch = state.data.branch.filter(a => a.id !== action.payload);
  },

  // Fees
  ADD_BRANCH_FEE: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );
    state.data.branch[branchIndex][`${action.key}branch`].push(action.payload);
  },

  UPDATE_BRANCH_FEE: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );
    const index = state.data.branch[branchIndex][
      `${action.key}branch`
    ].findIndex(fee => fee.id === action.payload.id);
    state.data.branch[branchIndex][`${action.key}branch`][index] =
      action.payload;
  },

  DELETE_BRANCH_FEE: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );
    state.data.branch[branchIndex][`${action.key}branch`] = state.data.branch[
      branchIndex
    ][`${action.key}branch`].filter(rate => rate.id !== action.payload.id);
  },

  // Branch Bank
  UPDATE_BRANCH_BANK: (state, action) => {
    const branchIndex = state.data.branch.findIndex(
      a => a.id === action.branchId
    );

    state.data.branch[branchIndex].branchBankInfo[0] = action.payload;
  },

  // States
  CLEAR_COMPANY: (state, action) => {
    state.data = null;
  },
  COMPANY_UPDATING: (state, action) => {
    state.updating = true;
  },
  COMPANY_LOADING: (state, action) => {
    state.loading = true;
  }
});


export default companyReducer;
