import React from 'react';
import { Button, Col, Modal, Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import downloadIconWhite from "theme/assets/downloadIconWhite.png";
import cancelGreyIcon from "theme/assets/cancel-icon.svg";
import envelopeIcon from "theme/assets/envelope-icon.svg";
import fromToIcon from "theme/assets/fromToIcon.png";
import promoIcon from "theme/assets/promoIcon.svg";
import editIcon from 'theme/assets/editIcon.svg';
import deleteIconRed from 'theme/assets/deleteIconRed.svg';

const imageExtns = ['JPEG', 'GIF', 'PNG', 'GIF'];

const { confirm } = Modal;

const PromoCard = ({ promoDetails, index, activeTab, editPromoCallback, canclePromoCallback, deletePromoCallback, isSendMessage, isEditCancle, isDelete }) => {
    const { company, category, originAirport, destinationAirport, endDate, createDate, promosAirline, contact, promoDocument } = promoDetails;

    let promoCompanyLogo = "";
    let promoContactUserIcon = "";
    const { logo } = company;
    if (logo.length > 0) {
        promoCompanyLogo = logo[0].path;
    }

    let isImageDoc = false;
    if (promoDocument) {
        let _promoDocument = promoDocument.split('.');
        let docLength = _promoDocument.length - 1;
        let docExtn = _promoDocument[docLength]?.toUpperCase();
        if (imageExtns.indexOf(docExtn) > -1) {
            isImageDoc = true;
        }
    }

    const showConfirm = (key, id) => {
        let data;

        confirm({
            title: `Are you sure you want to ${key.toUpperCase()} selected promo`,
            content: "",
            onOk: () => {
                switch (key) {
                    case "cancle":
                        canclePromoCallback(id);
                        break;

                    case "delete":
                        deletePromoCallback(id);
                        break;
                    default:
                        data = false;
                }
            },
            onCancel() { }
        });
    };

    const createTime = (createdAt) => {
        createdAt = createdAt.split("+")[0];
        let createdOn = moment(createdAt).format("DD/MM/YYYY");

        var startDate = moment(createdAt);
        var currDate = moment(new Date());

        var diff = currDate.diff(startDate, 'days');

        if (diff < 1) {
            let hourDiff = currDate.diff(startDate, 'hours');
            createdOn = hourDiff + ' hours ago';
            if (hourDiff < 1) {
                let minDiff = currDate.diff(startDate, 'minutes');         
                createdOn = minDiff + ' min ago';

                if (minDiff < 1) {
                    createdOn = 'Just now'
                }
            }
        }
        return createdOn;
    }

    return (
        <Row key={`promo_${index}`} className="promoListItem">
            <Col xs={24} sm={24} lg={24}>

                <Row>
                    <Col xs={2}>
                        <img src={promoCompanyLogo} className="companyLogo" />
                    </Col>
                    <Col xs={22} className="promoheader">
                        <div className="companyInfo">
                            <div className="companyTitle">
                                <Tooltip placement="right" title={company.companyName}>
                                    {company.companyName}
                                </Tooltip>
                            </div>
                            <div className="location">
                                {company.city}, {company.countries?.value}
                            </div>
                        </div>
                        <div className="userUpdateInfo">
                            <div className="updateAt">
                                <Tooltip placement="right" title={`${contact.firstName} ${contact.lastName}`}>
                                    <div className="userName">
                                        {contact.firstName} {contact.lastName}
                                    </div>
                                </Tooltip>
                                <div className="createdInfo">
                                    {createTime(createDate)}
                                </div>
                            </div>
                            <img src={promoIcon} className="userIcon" />
                        </div>
                    </Col>
                </Row>
                <Row className="promoDetails">
                    <Col xs={4}>
                        <div className="fieldsetSection">
                            <fieldset className="fieldSet">
                                <legend className="legend">{category.category}</legend>
                                <div className="legendContent">
                                    <div className="airlineSection">
                                        <div className="fromSection">
                                            <div className="airportName">
                                                {originAirport.iata}
                                            </div>
                                        </div>
                                        <div className="toSection">
                                            <div className="airportName">
                                                {destinationAirport.iata}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="directionSection">
                                        <Tooltip placement="right" title={promosAirline.airlineName}>
                                            <img src={fromToIcon} className="fromToIcon" />
                                            {promosAirline.iata}
                                        </Tooltip>
                                    </div>
                                    <div className="endSection">
                                        Ends: {moment(endDate).format("DD/MM/YYYY")}
                                    </div>
                                    <div className="promoStatus">
                                        {promoDetails.status === "Archive" && <h4>Canceled</h4>}
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </Col>
                    <Col xs={14} className="">
                        <div className="promoDetailSection">
                            {promoDetails.additionalNotes}
                        </div>
                    </Col>
                    <Col xs={6} className="">
                        <div>
                            {
                                isImageDoc && promoDocument &&
                                <img src={promoDocument} onClick={() => window.open(promoDocument,"_blank")} className="promoCover" />
                            }

                            <Row className="promoActions">
                                <Col xs={24} sm={24} lg={24}>
                                    {
                                        !isImageDoc && promoDocument &&
                                        <Link to={{ pathname: promoDocument }} target="_blank" className="ant-btn ant-btn-primary">
                                            <span>
                                                DOWNLOAD PDF
                                            </span>
                                            <img src={downloadIconWhite} className="promoActionIcon" />
                                        </Link>
                                    }
                                </Col>

                            </Row>
                        </div>
                        {/* <Col xs={24} sm={12} lg={12} className="rightActionSection"> */}
                        {
                            isEditCancle &&
                            <>
                                <Button type="link" className="linkBtn" onClick={() => editPromoCallback(promoDetails)}>
                                    <img src={editIcon} className="promoActionIcon deleteIcon" />
                                    Edit Promo
                                </Button>
                                {/* <Popconfirm
                                    title={`Are you sure you want to cancel selected promo`}
                                    placement="topRight"
                                    onConfirm={() => canclePromoCallback(promoDetails.id)}
                                    okText="Yes"
                                    cancelText="No"
                                > */}
                                <Button type="link" className="linkBtn greyTxt" onClick={() => showConfirm("cancle", promoDetails.id)}>
                                    <img src={cancelGreyIcon} className="promoActionIcon deleteIcon" />
                                    Cancel Promo
                                </Button>
                                {/* </Popconfirm> */}
                            </>
                        }
                        {
                            isSendMessage &&
                            <div className={!(promoDocument.length) && "noImgSendMsg"}>
                                <a className="linkBtn sendMsg" href={`mailto:${contact.email}`}>
                                    <img src={envelopeIcon} className="promoActionIcon" />Send Message
                                </a>
                            </div>
                        }
                        {
                            isDelete &&
                            // <Popconfirm
                            //     title={`Are you sure you want to delete selected promo`}
                            //     placement="topRight"
                            //     onConfirm={() => deletePromoCallback(promoDetails.id)}
                            //     okText="Yes"
                            //     cancelText="No"
                            // >
                            <Button type="link" className={promoDocument.length ? "linkBtn deleteBtn" : "linkBtn promoDel"} onClick={() => showConfirm("delete", promoDetails.id)}>
                                <img src={deleteIconRed} className="promoActionIcon deleteIcon" />
                                Delete Promo
                            </Button>
                            // </Popconfirm>
                        }

                        {/* </Col> */}
                    </Col>
                </Row>
                {/* <Row className="promoActions">
                    <Col xs={24} sm={12} lg={12}>
                        {
                            !isImageDoc && promoDocument &&
                            <Link to={{ pathname: promoDocument }} target="_blank" className="ant-btn ant-btn-primary">
                                <span>
                                    DOWNLOAD PDF
                                </span>
                                <img src={downloadIconWhite} className="promoActionIcon" />
                            </Link>
                        }
                    </Col>

                </Row> */}
            </Col>
        </Row>

    )
}

export default PromoCard;