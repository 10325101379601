import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  AutoComplete,
  Icon,
  Select,
  Tabs,
  Empty,
  Pagination,
  Input,
  Carousel,
} from "antd";
import partnerIcon from "theme/assets/myap-icon.svg";
import creditAlertIcon from "theme/assets/creditAlertIcon.svg";
import promoIcon from "theme/assets/promoIcon.svg";

import Insuremytrans from "theme/assets/partnerLogo/Insuremytrans.png";
import naxExpress from "theme/assets/partnerLogo/nax-express.png";
import paycargo from "theme/assets/partnerLogo/paycargo.png";
import tiacaPortal from "theme/assets/partnerLogo/tiaca-portal.png";
import wecargo from "theme/assets/partnerLogo/wecargo.png";
import cargoclaims from "theme/assets/cargoclaims-logo-64.png";
import userIcon from "theme/assets/userIcon.png";
import mobileSC from "theme/assets/mobileSC.jpeg";

import localeInfo from "rc-pagination/lib/locale/en_US";

import { Link, NavLink } from "react-router-dom";
import AnalyticContainer from "components/ui-elements/AnalyticContainer";
import {
  getDashboardDataAction,
  getPromos,
  logoData,
} from "actions/dataActions";
import PromoCard from "components/ui-elements/PromoCard";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import { DOMAIN } from "utils/enum";

const { Option } = Select;
const { TabPane } = Tabs;

const Dashboard = (props) => {
  const { domain } = useSelector((state) => state.common.domain)
  const [isAdmin, setIsAdmin] = useState(null);
  const [promosList, setPromosList] = useState([]);
  const [promosCount, setPromosCount] = useState(0);
  const [sentAlerts, setSentAlerts] = useState(0);
  const [recvdAlerts, setRecvdAlerts] = useState(0);
  const [postedPromo, setPostedPromo] = useState(0);
  const [activeMembers, setActiveMembers] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState({});
  const [page, setPage] = useState(1);
  const [newMembers, setNewMembers] = useState([]);
  const [discontinuedMembers, setDiscontinuedMembers] = useState([]);
  const [suspendedMembers, setSuspendedMembers] = useState([]);
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  const logoContent = useSelector((state) => state.auth.logoData);
  const role = localStorage.getItem("rolesUser");
  const [logoArray, setLogoArray] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (logoContent === undefined) {
      dispatch(logoData(companyInfo.company));
    }
    getDashboardData();
    localStorage.removeItem("Valid")
    const decoded = jwt_decode(localStorage.jwtToken);
    const { roles } = decoded;
    if (roles && roles.length > 0) {
      if (roles.indexOf("ROLE_ADMIN") > -1) {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);
  useEffect(() => {
    if (logoContent?.length > 0) {
      const array = [];
      const element = logoContent.forEach((element) =>
        element?.status === "1" ? array.push(element) : []
      );
      setLogoArray(array);
    }
  }, [logoContent]);
  useEffect(() => {
    if (isAdmin != null) {
      fetchData(1);
    }
  }, [isAdmin]);

  const getDashboardData = () => {
    getDashboardDataAction().then((data) => {
      if (data) {
        const {
          news,
          activePromosCount,
          incomingAlertsCount,
          events,
          activeMembersCount,
          outAlertsCount,
          newMembers,
          discontinuedMembers,
          suspendedMembers,
        } = data;
        setNews(news);
        setSentAlerts(outAlertsCount);
        setRecvdAlerts(incomingAlertsCount);
        setPostedPromo(activePromosCount);
        setActiveMembers(activeMembersCount);
        setEvents(events);
        setNewMembers(newMembers);
        setDiscontinuedMembers(discontinuedMembers);
        setSuspendedMembers(suspendedMembers);
      }
    });
  };

  const fetchData = (page) => {
    const date = moment().format("YYYY-MM-DD");
    getPromos(page, date, isAdmin).then((data) => {
      if (data) {
        setPromosList(data["hydra:member"]);
        setPromosCount(data["hydra:totalItems"]);
      }
    });
  };

  const changePage = (page) => {
    setPage(page);
    window.scrollTo(0, 0);
    fetchData(page);
  };

  const displayUserSlide = (user) => {
    return (
      <>
        {/* <img src={userIcon} className="userSlideIcon" /> */}
        <div className="userSlideInfo">
          <div className="memberCompanyName">{user.companyName}</div>
          <div className="companyLoc">
            {user.cityName}, {user.countryname}
          </div>
        </div>
      </>
    );
  };

  const networkRedirect = (e) => {
    if (e && e.key === "Enter") {
      props.history.push(`/network?page=1&companyName=${searchString}`);
    }
  };

  return (
    <Row className="dashboardContainer">
      <Col lg={5} sm={24} xs={24}>
        <div className="dashboardLeftPanel">
          <Link to={"network"}>
            <AnalyticContainer
              type="analyticContainerpartner"
              title="MY PARTNER"
              // bgColor="#BB2648"
              icon={partnerIcon}
              countArr={[
                {
                  count: activeMembers + 51,
                  title: "ACTIVE MEMBERS",
                },
              ]}
            />
          </Link>
          <Link to={"credit-alerts"}>
            <AnalyticContainer
              type="analyticContainercredit"
              title="CREDIT ALERTS"
              // bgColor="#EE2841"
              icon={creditAlertIcon}
              countArr={[
                {
                  count: sentAlerts,
                  title: "SENT ALERTS",
                },
                {
                  count: recvdAlerts,
                  title: "RECEIVED ALERTS",
                },
              ]}
            />
          </Link>
          <Link to={"promos"}>
            <AnalyticContainer
              type="analyticContainerpromo"
              title="PROMOTIONS"
              // bgColor="#DBE120"
              icon={promoIcon}
              countArr={[
                {
                  count: postedPromo,
                  title: "POSTED PROMOS",
                },
              ]}
            />
          </Link>
          {(logoContent?.length > 0 && logoContent.some(item => item.showInDashboard)) ? (
            <div className="partnersSection">
              <div className="partnersTitle">
                <span>Our tools</span>
              </div>
              <div className="partnersList">
                <nav className="nav main__nav">
                  <Row>
                    {domain === DOMAIN.NEX ?
                      logoContent?.map((element) => {
                        return (
                          element?.showInDashboard &&
                          <Col xs={12} sm={12} lg={12}>
                            <NavLink
                              className="nav-item"
                              to={{
                                pathname: `${element.partnerLink}`,
                              }}
                              target="_blank"
                            >
                              <img
                                src={element.partnerLogo}
                                alt={element.partnerName}
                                className="partnerIcon"
                              />
                            </NavLink>
                          </Col>
                        );
                      })
                      :
                      logoContent?.map((element) => {
                        return (
                          (element?.partnerName !== "NeXHUB" && element?.showInDashboard) &&
                          <Col xs={12} sm={12} lg={12}>
                            <NavLink
                              className="nav-item"
                              to={{
                                pathname: `${element.partnerLink}`,
                              }}
                              target="_blank"
                            >
                              <img
                                src={element.partnerLogo}
                                alt={element.partnerName}
                                className="partnerIcon"
                              />
                            </NavLink>
                          </Col>
                        );
                      })
                    }
                  </Row>
                </nav>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
      <Col lg={14} sm={24} xs={24}>
        <div className="dashboardCenterPanel">
          <div className="searchField">
            <Input
              placeholder="Company name"
              onChange={(e) => setSearchString(e.target.value)}
              onKeyDown={(e) => networkRedirect(e)}
            />
            {/* <AutoComplete
                            placeholder="Company name"
                        >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                        </AutoComplete> */}
            <Icon
              type="search"
              onClick={() =>
                props.history.push(
                  `/network?page=1&companyName=${searchString}`
                )
              }
            />
          </div>
          <div className="dashboardContent">
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Carousel effect="scrollx" autoplay>
                  {events.map((event, index) => {
                    return (
                      <div className="contentSlide">
                        <img src={event.thumbnail} className="bannerImage" />
                        <div className="slideDetails">
                          <div
                            className="slideTitle"
                            dangerouslySetInnerHTML={{ __html: event.title }}
                          />
                          <div
                            className="newsContent"
                            dangerouslySetInnerHTML={{ __html: event.content }}
                          />
                          <Link
                            className="ant-btn create-btn colorwhite"
                            to={{ pathname: event.url }}
                            target="_blank"
                          >
                            EVENT DETAILS
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Tabs defaultActiveKey="2">
                  <TabPane tab="Member Promos" className="contentPanel" key="1">
                    {promosList.map((promo, index) => {
                      return (
                        <PromoCard
                          key={`promoCard_${index}`}
                          index={index}
                          promoDetails={promo}
                          isSendMessage={true}
                          isEditCancle={false}
                          isDelete={false}
                        />
                      );
                    })}
                    {promosList?.length === 0 ? (
                      <Empty />
                    ) : (
                      <Pagination
                        onChange={changePage}
                        locale={localeInfo}
                        current={page}
                        defaultPageSize={10}
                        total={promosCount}
                      ></Pagination>
                    )}
                  </TabPane>
                  <TabPane tab="NAP News" key="2">
                    <div className="newsTitle">
                      {news.url && (
                        <Link to={{ pathname: news.url }} target="_blank" className="add-branch-btn">
                          {news.title}
                        </Link>
                      )}
                    </div>
                    <div className="newsDate">{news.date}</div>
                    <div className="newsThumbnail">
                      <img src={news.thumbnail} />
                    </div>
                    <div
                      className="newsContent"
                      dangerouslySetInnerHTML={{ __html: news.content }}
                    />
                    <div className="newsActionContainer">
                      <Link
                        to={{
                          pathname: "https://neutralairpartner.com/posts/",
                        }}
                        target="_blank"
                        className="ant-btn ant-btn-primary allNews create-btn"
                      >
                        READ ALL NEWS
                      </Link>
                    </div>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col lg={5} sm={24} xs={24}>
        <div className="dashboardRightPanel">
          {/* <div className="appStoreIcons">
                        <img src={playStore} className="playStore" />
                        <img src={appleStore} className="appleStore" />
                    </div> */}
          {
            domain == undefined ? <SkeletonLoading itemsPerPage='1' /> :
              domain === DOMAIN.NEX ?
                <div
                  className="mobileSCSection"
                  onClick={() =>
                    window.open(
                      "https://nex-network.com/webrout2/",
                      "_blank"
                    )
                  }
                >
                  <img src={"https://nex-network.com/downloads/nex-network-1.jpg"} />
                </div> :
                <div
                  className="mobileSCSection"
                  onClick={() =>
                    window.open(
                      "https://neutralairpartner.com/webapprout5/",
                      "_blank"
                    )
                  }
                >
                  <img src={"https://neutralairpartner.com/download/app-dl.jpg"} />
                </div>
          }

          {/* <div className="memberSection">
            <Tabs defaultActiveKey="1">
              <TabPane tab="New members" key="1">
                {newMembers.map((user, index) => {
                  return (
                    <div className="memberSlide" key={`newMembers_${index}`}>
                      {displayUserSlide(user)}
                    </div>
                  );
                })}
                {newMembers.length === 0 && <Empty />}
              </TabPane>
              <TabPane tab="Suspended" key="2">
                {suspendedMembers.map((user, index) => {
                  return (
                    <div
                      className="memberSlide"
                      key={`suspendedMembers_${index}`}
                    >
                      {displayUserSlide(user)}
                    </div>
                  );
                })}
                {suspendedMembers.length === 0 && <Empty />}
              </TabPane>
              <TabPane tab="Discontinued" key="3">
                {discontinuedMembers.map((user, index) => {
                  return (
                    <div
                      className="memberSlide"
                      key={`discontinuedMembers_${index}`}
                    >
                      {displayUserSlide(user)}
                    </div>
                  );
                })}
                {discontinuedMembers.length === 0 && <Empty />}
              </TabPane>
            </Tabs>
          </div> */}
          <div className="downloadSection">
            <div className="partnersTitle">
              <span>Downloads</span>
            </div>
            <div className="downloadList">
              <object
                data="https://neutralairpartner.com/filemanager/?autologin_code=LFpTEiGGnQLZ4sCATHTplSaPDruNbmkT"
                style={{ width: "100%" }}
                width="700"
                height="720"
                type="text/html"
              ></object>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Dashboard;
