import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CompanyLogo from "components/ui-elements/CompanyLogo";
import { CheckFocusKeys, findSubIndustry } from "utils/functions";
import { Col, Row } from "antd";
import moment from "moment";
import NAPlogo from "theme/assets/logo.svg";
import CompanyStatus from "./CompanyStatus";

const CompanyHeader = ({
  companySettings,
  name,
  logo,
  city,
  classification,
  country,
  focus,
  toggleEdit,
  status,
  approved,
  id,
  auth,
  userCompanyId,
  roles,
  licences,
  subIndustry,
  itemAccounting,
  selectCard
}) => {
  let isEditable = true;
  let history = useHistory();
  let location = useLocation();
  const [acreditationData, setAcreditationData] = useState([]);
  // Approved
  // Pending
  // Not Finished

  // isApproved
  if (
    localStorage.status !== "Approved" ||
    (status !== "Not Finished" && !approved)
  ) {
    isEditable = false;
  }

  const showEdit = (subIndustry) => {
    if (!isEditable) {
      return;
    }

    handleEdit();
  };
  useEffect(() => {
    if (licences?.length > 0) {
      const result = licences.filter(
        (item) =>
          item.acreditation === "IATA" &&
          item.subject === "licences" &&
          item.imageUrl !== ""
      );

      setAcreditationData(result);
    }
  }, [licences]);
  const handleEdit = () => {
    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

    if (isAdmin) {
      toggleEdit(id);
    } else {
      history.push(`/company-profile/${id}`);
    }
  };
  return (
    <div className="card-header">
      <div
        // className={"f network-card-edit"}
        className={classnames("f", { "network-card-edit": isEditable })}
        onClick={() => showEdit(subIndustry)}
      >
        <div className="f-c-c inline-image">
          <CompanyLogo logo={logo}></CompanyLogo>
        </div>
        <div>
          <h3 className="network-card-title">{name}</h3>
          <span className="text-secondary">
            {/*  {classification} | */} {city}, {country}
          </span>
          {/* {roles === "ROLE_ADMIN" ? ( */}
          {userCompanyId ? (
            <div className="company-id">NAP Code: {userCompanyId}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* remove exports imports details in case of accountuing tab */}
        <ul className="network-card-focus list-blank">
          {acreditationData?.length > 0 ? (
            <li className="network-card-focus-item f-c-c-col">
              <img
                src={acreditationData?.[0]?.imageUrl}
                alt={"acreditation"}
              ></img>
            </li>
          ) : (
            ""
          )}
          {/* return true if all keys in focus is having null | undefined | "" */}
          {!CheckFocusKeys(focus) ? (
            <>
              <li className="network-card-focus-item f-c-c-col">
                {focus?.focusExports}%
                <span className="text-secondary">Export</span>
              </li>
              <li className="network-card-focus-item f-c-c-col">
                {focus?.focusImports}%
                <span className="text-secondary">Import</span>
              </li>
              <li className="network-card-focus-item f-c-c-col">
                {focus?.focusCrossTrade}%
                <span className="text-secondary">Cross trade</span>
              </li>
              <li className="network-card-focus-item f-c-c-col">
                {focus?.focusEcommerce}%
                <span className="text-secondary">E-commerce</span>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
    </div>
  );
};

export default CompanyHeader;
