import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Branch from "./Branch";
import CompanyHq from "./CompanyHq";
import { Tabs } from "antd";
import { getSubGroupList } from "actions/commonActions";

const { TabPane } = Tabs;

const BranchNav = ({ branches }) => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("hq");
  const [showBranch, setShorBranch] = useState(true);
  const didMountRef = useRef(false);
  const [subIndustries, setSubIndustries] = useState([])

  useEffect(() => {
    const companyInfo = JSON.parse(localStorage?.companyInfo);
    const { editBranch } = companyInfo;
    setShorBranch(editBranch);
  }, []);
  useEffect(() => {
    if (didMountRef.current) {
      if (branches?.length > 0) {
        setActiveTab(`${branches[branches?.length - 1]?.id}`);
      } else {
        setActiveTab("hq");
      }
    } else didMountRef.current = true;
  }, [branches?.length]);

  const initialFunction = async () => {
    // const response = await dispatch(getSubGroupList());
    // setSubIndustries(response);
  };
  const onTabChange = (id) => {
    console.log("id...", id)
    setActiveTab(`${id}`);
    // initialFunction()
    dispatch(getSubGroupList());
  };

  return (
    <Tabs activeKey={activeTab} onChange={onTabChange} className="abc">
      <TabPane tab="Company HQ" key="hq">
        <CompanyHq></CompanyHq>
      </TabPane>
      {showBranch &&
        branches?.map((branch, i) => {
          return (
            <TabPane tab={branch?.branchCity} key={branch?.id}>
              <div className="section">
                <Branch branchId={branch?.id} activeTab={activeTab} subIndustries={subIndustries}></Branch>
              </div>
            </TabPane>
          );
        })}
    </Tabs>
  );
};

const mapStateToProps = (state) => ({
  branches: state.company.data.branch,
});

export default connect(mapStateToProps, {})(BranchNav);
