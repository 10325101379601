import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Button, Icon, Row, Col, Checkbox, Skeleton } from "antd";
import { loginUser, clearAuth, logoutUser } from "actions/authActions";
import background from "theme/assets/background.png";
import naplogo from "theme/assets/nap-logo.svg";
import footerIcon from 'theme/assets/footerIcon.png'
import MobileAlert from "components/ui-elements/MobileAlert";
import { DOMAIN } from "utils/enum";
class Login extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {

      let isAdmin = false;
      if (this.props.auth.user.roles.length > 0) {
        isAdmin = true;
      }
      this.handleRedirect(localStorage.status, isAdmin);
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated) {
      let isAdmin = false;
      if (this.props.auth.user.roles.length > 0) {
        isAdmin = true;
      }
      this.handleRedirect(this.props.auth.user.status, isAdmin);
    }
  }

  componentWillUnmount() {
    this.props.clearAuth();
  }

  handleRedirect = (status, isAdmin) => {
    switch (status) {
      case 'Pending':
        if (isAdmin) {
          this.props.history.push("/network");
        }
        else {
          this.props.history.push("/dashboard");
        }
        break;
      case 'Not Finished':
        this.props.history.push("/register");
        break;
      case 'Blocked':
        alert('Your account has been Blocked');
        this.props.logoutUser();
        break;
      case 'Suspended':
        alert("your account has been Suspended");
        this.props.logoutUser();
        break;
      case 'Resigned':
        alert('Your company profile has been Discontinued!');
        this.props.logoutUser();
        break;
      case 'InReview':
        this.props.history.push("/register");
        break;
      default:
        if (isAdmin) {
          this.props.history.push("/network");
        }
        else {
          this.props.history.push("/dashboard");
        }
        break;
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.loginUser(values);
    });
  };

  loginFun = (domain) => {

    let link;
    if (domain == undefined) {
      link = "https://neutralairpartner.com/webapprout/"
    } else {
      if (domain === DOMAIN.NEX) {
        link = "https://nex-network.com/webrout1/"
      } else {
        link = "https://neutralairpartner.com/webapprout/"
      }
    }
    window.open(link, "_blank");
    // return window.location.href = link


  }
  render() {
    const { loading, error } = this.props.auth;
    const { logo, coverImage, domain } = this.props.common;
    const { getFieldDecorator } = this.props.form;
    const date = new Date()

    if (!localStorage.continueDesktop || localStorage.continueDesktop === "1") {
      return (
        <div className="noPaddingTop">
          <Row>
            <Col className="leftSection" lg={15} md={15} xs={0} onClick={() => this.loginFun(domain)}>
              {/*   <img src={coverImage} className="coverImageLogin" /> */}
              {/* <div className="promoSection">
              <h4>NAP Promo</h4>
              <p>
                Image and link from Wp, if none are supplied than dummy screen is shown
              </p>
            </div>
              <div className="message">
                Discover your Air Cargo DNA
              </div> */}

            </Col>
            <Col className="formSection" lg={9} md={9} xs={24}>
              <div className="centerContent">
                <div className="mainContent">
                  {logo === undefined
                    ?
                    <Skeleton className="logo-skeleton" size="default" active paragraph={{ rows: 0 }} />
                    :
                    <img src={logo ? logo : ''} alt="logo" className="loginLogo" />
                  }
                  <div className="centerForm">
                    <div className="pageTitle">
                      <span className="pageTitleTxt">
                        Login
                      </span>
                    </div>
                    {error && (
                      <h4 className="text-error">
                        {error}
                      </h4>
                    )}
                    <Form
                      onSubmit={this.onSubmit}
                      autoComplete="off"
                    >
                      <Form.Item>
                        {getFieldDecorator("username", {
                          rules: [
                            { required: true, message: "Please input your username!" }
                          ]
                        })(
                          <Input
                            prefix={
                              <Icon type="user" className="loginforminputIcon" />
                            }
                            size="large"
                            placeholder="Username admin or user"
                          />
                        )}
                      </Form.Item>

                      <Form.Item>
                        {getFieldDecorator("password", {
                          rules: [
                            { required: true, message: "Please input your Password!" }
                          ]
                        })(
                          <Input.Password
                            prefix={
                              <Icon type="lock" className="loginforminputIcon" />
                            }
                            size="large"
                            type="password"
                            placeholder="Password"
                          />
                        )}
                      </Form.Item>
                      <div className="centerSection">
                        <Form.Item
                          name="remember"
                          wrapperCol={{
                            span: 10,
                          }}
                        >
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Link to="/forgotPassword" className="forgotLink">Forgot your password?</Link>
                      </div>

                      <Button
                        className="submit-btn"
                        // type="primary"
                        size="large"
                        loading={loading}
                        htmlType="submit"
                      // style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                      {/* <div className="bottomSection">
                      <div className="socialSignIn">
                        <span className="socialLbl">Quick Sign-in:</span>
                        <Icon type="facebook" theme="filled" style={{ color: "#000", fontSize: "30px" }} />
                        <Icon type="linkedin" theme="filled" style={{ color: "#000", fontSize: "30px" }} />
                      </div>
                      <div className="signUpSection">
                        <Link to="/register">Create new account</Link>
                      </div>
                    </div> */}
                    </Form>
                  </div>
                  <img src={footerIcon} alt="logo" className="footerIcon" />
                </div>
                <div className="footerContent">
                  Copyright ©{date.getFullYear()} Produced by Neutral Air  Partner
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    else {
      return (
        <>
          <MobileAlert />
        </>
      );
    }

  }
}

const WrappedLogin = Form.create({ name: "normal_login" })(Login);

const mapStateToProps = state => ({
  auth: state.auth,
  common: state.common.domain
});

export default connect(mapStateToProps, { loginUser, clearAuth, logoutUser })(WrappedLogin);
