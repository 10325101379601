import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { Row, Form, Button, Divider } from 'antd';

import InputField from './Input';
import { QUESTION_TYPE } from 'utils/enum';
import { getSubGroupList } from "actions/dataActions";

import MultiSelectInputField from './multiSelectInputField'
import { AddQuestion, getQuestionTypesList, getExpertieseList, deleteOption } from "actions/questionAction";
import { ObjectToArray, checkValidity, checkValidityType, updateObject } from 'utils/functions';
import { createQuestion, callActionField, optionField, MinMaxFields, callFunctionField, linkingFunctionField, linkingActionField, fileTypeField } from "./fields";

export const AddQuestionForm = ({ /* subId, expertId, */ highestSequence, closeDrawer, setLoading, data, question }) => {
    const dispatch = useDispatch();
    const [editId, setEditId] = useState('');
    const [fields, setFields] = useState([]);
    const [options, setOptions] = useState({});
    const [inputType, setInputType] = useState([]); // for storing question types list
    const [listing, setlisting] = useState(question);
    const [minMaxField, setMinMaxField] = useState({ ...MinMaxFields });
    const [networkList, setNetworkList] = useState([]); // Networks == subIndustries
    const [optionsArray, setOptionsArray] = useState([]);
    const [callOption, setCallOption] = useState([]);
    const [linkingOption, setLinkingOption] = useState([]);
    const [expertieseList, setExpertieseList] = useState([]);
    const [editOptions, setEditOptions] = useState([]);
    const [minMaxFieldArray, setMinMaxFieldArray] = useState([]);
    const [filteredExpertiese, setFilteredExpertiese] = useState([]);
    const [filteredExpertieseId, setFilteredExpertieseId] = useState([]);
    const [filteredSubIndustries, setFilteredSubIndustries] = useState([]);
    const [filteredSubIndustriesId, setFilteredSubIndustriesId] = useState([]);

    const [formFields, setFormFields] = useState({ ...createQuestion });

    useEffect(() => {
        localStorage.removeItem("type");
        initialFunction()
    }, [])

    const initialFunction = async () => {
        const networkListResponse = await dispatch(getSubGroupList());
        const questionTypesListResponse = await dispatch(getQuestionTypesList());
        const expertieseListResponse = await dispatch(getExpertieseList());
        setNetworkList(networkListResponse)
        setExpertieseList(expertieseListResponse)
        setInputType(questionTypesListResponse)
    }

    useEffect(() => {
        let questionData = []
        /* eslint no-unused-expressions:off  */
        listing?.map((item) => {
            questionData.push({ id: item.id, name: item.question })
        })
        setLinkingOption(questionData)
    }, [listing])

    useEffect(() => {
        if (data?.questionRelations?.length) {
            const filteredExpertiese = data.questionRelations.filter((value) => value.expertiese !== null && value.expertiese.id)
            setFilteredExpertiese(filteredExpertiese)

            const filteredSubIndustries = data.questionRelations.filter((value) => value.subIndustry !== null && value.subIndustry.id)
            setFilteredSubIndustries(filteredSubIndustries)
        }
    }, [data])

    useEffect(() => {
        let expertieseId = [];
        let subIndustryId = [];

        filteredSubIndustries.map((value) => {
            if (value.subIndustry !== null) {
                subIndustryId.push(value.subIndustry.id)
            }
        })

        filteredExpertiese.map((value) => {
            if (value.expertiese !== null) {
                expertieseId.push(value.expertiese.id)
            }
        })

        let uniqueSubIndustryId = subIndustryId.filter((c, index) => {
            return subIndustryId.indexOf(c) === index;
        });
        let uniqueExpertieseId = expertieseId.filter((c, index) => {
            return expertieseId.indexOf(c) === index;
        });

        setFilteredExpertieseId(uniqueExpertieseId)
        setFilteredSubIndustriesId(uniqueSubIndustryId)
    }, [filteredExpertiese, filteredSubIndustries])

    useEffect(() => {
        if (data && inputType.length && networkList.length && expertieseList.length) {
            setEditId(data.id)
            const [, , , number] = data.QuestionType.split("/");
            let updatedFormElement = updateObject(formFields['QuestionType'], {
                elementConfig: { options: inputType },
                value: Number(number),
                valid: true
            });
            let updateQuestionElement = updateObject(formFields['question'], {
                value: data.question,
                valid: true
            });
            let updatePlaceholderElement = updateObject(formFields['placeholder'], {
                value: data.placeholder,
                valid: true
            });
            let updateMandatoryElement = updateObject(formFields['mandatory'], {
                value: data.mandatory,
                valid: true
            });
            let updateActiveElement = updateObject(formFields['active'], {
                value: data.active,
                valid: true
            });

            let updatedNetworkElement = updateObject(formFields['subIndustries'], {
                value: filteredSubIndustriesId,
                valid: true
            });
            let updatedExpertieseElement = updateObject(formFields['expertiese'], {
                value: filteredExpertieseId,
                valid: true
            });

            let updatedMinElement = updateObject(MinMaxFields
            ['minLimit'], {
                value: data.minLimit,
                valid: true
            });
            let updatedMaxElement = updateObject(MinMaxFields
            ['maxLimit'], {
                value: data.maxLimit,
                valid: true
            });
            let updatedFileUploadElement = updateObject(fileTypeField, {
                value: data.fileType,
                valid: true
            })

            let updateLinkingFunctionElement;
            let updateLinkingActionElement;
            if (data.linkedQuestion !== null) {
                updateLinkingFunctionElement = updateObject(linkingFunctionField,
                    {
                        value: true,
                        valid: true
                    });

                updateLinkingActionElement = updateObject(linkingActionField,
                    {
                        value: data.linkedQuestion,
                        valid: true,
                        elementConfig: {
                            options: linkingOption,
                            placeholder: 'Call Stack'
                        }
                    });
            } else {
                updateLinkingFunctionElement = updateObject(linkingFunctionField,
                    {
                        value: false,
                        valid: true
                    });
                // delete 

            }
            let updateCallFunctionElement;
            let updateCallActionElement;

            if (data.callAction !== '') {
                updateCallFunctionElement = updateObject(callFunctionField,
                    {
                        value: true,
                        valid: true
                    });

                updateCallActionElement = updateObject(callActionField,
                    {
                        value: data.callAction,
                        valid: true,
                        /* elementConfig: {
                            options: linkingOption,
                            placeholder: 'Call Stack'
                        } */
                    });
                /*   updateCallActionElement = updateObject(updatedFormFields, { callAction: { ...callActionField } }); */
            } else {
                updateCallFunctionElement = updateObject(callFunctionField,
                    {
                        value: false,
                        valid: true
                    });
            }

            if ((data.linkedQuestion !== null && data.callAction !== '')) {
                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    linkedQuestion: updateLinkingActionElement,
                    callFunction: updateCallFunctionElement,
                    callAction: updateCallActionElement
                });
                setFormFields(updatedOrderForm);
            } else if ((data.linkedQuestion == null && data.callAction !== '')) {

                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    //  linkedQuestion: updateLinkingActionElement,
                    callFunction: updateCallFunctionElement,
                    callAction: updateCallActionElement
                });
                setFormFields(updatedOrderForm);
            }
            else if ((data.linkedQuestion !== null && data.callAction == '')) {
                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    linkedQuestion: updateLinkingActionElement,
                    callFunction: updateCallFunctionElement,
                    // callAction: updateCallActionElement
                });
                setFormFields(updatedOrderForm);
            } else if ((data.linkedQuestion === null && data.callAction === '' && data.fileType.length > 0)) {
                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    callFunction: updateCallFunctionElement,
                    fileType: updatedFileUploadElement
                });
                setFormFields(updatedOrderForm);
            }/* else if ((data.linkedQuestion === null && data.callAction === '')) {
             
                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    callFunction: updateCallFunctionElement,
                    // fileType: updatedFileUploadElement
                });
                setFormFields(updatedOrderForm);
            } */
            else {
                const updatedOrderForm = updateObject(formFields, { //Update/Edit CreateQuestion object
                    QuestionType: updatedFormElement,
                    question: updateQuestionElement,
                    placeholder: updatePlaceholderElement,
                    mandatory: updateMandatoryElement,
                    active: updateActiveElement,
                    subIndustries: updatedNetworkElement,
                    expertiese: updatedExpertieseElement,
                    linkingFunction: updateLinkingFunctionElement,
                    callFunction: updateCallFunctionElement,
                    //  fileType: updatedFileUploadElement
                });
                setFormFields(updatedOrderForm);
            }
            const updatedMinMaxOrderForm = updateObject(MinMaxFields, { //Update/Edit MinMax Object 
                minLimit: updatedMinElement,
                maxLimit: updatedMaxElement
            })

            handleEditOptions(data)
            // setFormFields(updatedOrderForm);
            setMinMaxField(updatedMinMaxOrderForm)
        } else {
            let updatedFormElement = updateObject(formFields['QuestionType'], {
                elementConfig: { options: inputType }
            });
            let updatedNetworkElement = updateObject(formFields['subIndustries'], {
                elementConfig: { options: networkList },
            });
            let updatedExpertieseElement = updateObject(formFields['expertiese'], {
                elementConfig: { options: expertieseList },
            });
            const updatedOrderForm = updateObject(formFields, {
                QuestionType: updatedFormElement,
                subIndustries: updatedNetworkElement,
                expertiese: updatedExpertieseElement

            });
            setFormFields(updatedOrderForm);
        }
    }, [data, inputType, networkList, expertieseList])

    useEffect(() => {
        let fields = ObjectToArray(formFields);
        setFields(fields);
    }, [formFields])

    useEffect(() => {
        if (formFields?.QuestionType?.value) {
            let formFieldsCopy = { ...formFields };
            delete formFieldsCopy.callAction; // Remove call Action
            delete formFieldsCopy.linkedQuestion;

            delete formFieldsCopy.callFunction // Remove call function 
            delete formFieldsCopy.linkingFunction // Remove linking function 


            // IF Input type is Checkbox/Radio/Dropdown, add Call action and linking function

            if (addDynamicField()) {
                if (data === undefined) {
                    // in case of add
                    // handling type text on linking time
                    if (formFields?.QuestionType?.value === 1) {
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...linkingFunctionField } });
                    } else {
                        formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...callFunctionField } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...linkingFunctionField } });
                    }

                } else if (data !== undefined && data.questionOptions.length !== 0) {
                    // in case of edit
                    // handling linking of radio type.
                    // if question is type radio then we will add switch linking 
                    if (data.QuestionType == "/v1/question_types/9") {
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        if (formFields?.linkedQuestion?.value) {
                            // if question linked question having value then we will linked questions 
                            formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                        }

                    } else if (data.QuestionType == "/v1/question_types/10") {
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...callFunctionField } });
                        if (formFields?.linkedQuestion?.value) {
                            // if question linked question having value then we will linked questions 
                            formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                        }

                    } else {
                        formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...callFunctionField } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...linkingFunctionField } });
                    }

                } else if (data !== undefined && data.questionOptions.length === 0 && data.callAction !== '' && data.linkedQuestion === null) {
                    formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...formFields.callFunction } });
                    formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...linkingFunctionField } });
                    formFieldsCopy = updateObject(formFieldsCopy, { callAction: { ...formFields.callAction } });
                } else if (data !== undefined && data.questionOptions.length === 0 && data.callAction == '' && data.linkedQuestion !== null) {
                    if (data.QuestionType == "/v1/question_types/1") {
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                    } else {
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                        formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...callFunctionField } });
                    }
                }
                else {
                    if (data.QuestionType == "/v1/question_types/1" && data !== undefined && data.questionOptions.length === 0 && data.callAction == '' && data.linkedQuestion == null) {
                        if (data.linkedQuestion) {
                            formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                        }
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        // code commented because of edit functionality issue in linking  
                    } else {
                        formFieldsCopy = updateObject(formFieldsCopy, { callFunction: { ...formFields.callFunction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkingFunction: { ...formFields.linkingFunction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { callAction: { ...formFields.callAction } });
                        formFieldsCopy = updateObject(formFieldsCopy, { linkedQuestion: { ...formFields.linkedQuestion } })
                    }

                }
            }

            // IF Input type is SINGLE_FILE_UPLOAD & MULTI_FILE_UPLOAD, then  add file type dropdown
            if (data === undefined) {
                if (addDynamicField(["SINGLE_FILE_UPLOAD", "MULTI_FILE_UPLOAD"])) {
                    formFieldsCopy = updateObject(formFieldsCopy, { fileType: { ...fileTypeField } });
                } else {
                    delete formFieldsCopy.fileType;
                }
            }

            setFormFields(formFieldsCopy);            
            if (data === undefined) {
                addOption(2); // Add options
            }

            // Add min max fields Dynamically
            if (addDynamicField(['TEXTBOX',
                'TEXTAREA',
                'NUMBER',
                'PASSWORD',
                'EMAIL',
                'PHONE_NUMBER',
                'CHECKBOX',
                'MULTI_SELECT',
                'MULTI_SELECT_SEARCH',
                'SINGLE_FILE_UPLOAD',
                'MULTI_FILE_UPLOAD',
                'PERCENTAGE_SLIDER',
                'NUMBER_FORMAT',
                /*    'DATE_TIME',
                   'DATE',
                   'TIME',
                   'RADIO',
                   'SINGLE_SELECT',
                   'SINGLE_SELECT_SEARCH', */
                //  "TEXTBOX", "TEXTAREA", "NUMBER", "RADIO", "CHECKBOX", "SINGLE_SELECT", "MULTI_SELECT"
            ])) {
                if (minMaxField.minLimit?.value === null && minMaxField.maxLimit?.value === null) {
                    setMinMaxField({ ...MinMaxFields }); //set initial min/max value
                } else {
                    setMinMaxField(minMaxField) // set updated value when we edit field
                }
            } else {
                setMinMaxField({});
                // delete minMaxField
            }
        }
    }, [formFields?.QuestionType?.value])

    useEffect(() => {
        setOptionsArray(ObjectToArray(options));
    }, [options])

    useEffect(() => {
        setMinMaxFieldArray(ObjectToArray(minMaxField));
    }, [minMaxField])


    const inputChangedHandler = async (event, inputIdentifier, value) => {
        let actualValue;
        let valueObj = [];

        if (formFields[inputIdentifier].elementType == 'multiple_autocomplete') {

            actualValue = [];
            value.forEach(item => {
                actualValue.push(item.props.value);
                valueObj.push({ id: item.props.value, name: item.props.label })
            });
        }
        else if (formFields[inputIdentifier].elementType == 'multiSelect') {
            actualValue = [];
            value.forEach(item => {
                /*   if (item._owner.pendingProps?.label === "Networks") {
                      actualValue.push(`/v1/sub_industries/${item.props.value}`);
                  } else {
                      actualValue.push(`/v1/expertiese/${item.props.value}`);
                  } */
                actualValue.push(item.props.value);
                valueObj.push({ id: item.props.value, name: item.props.label })

            });
        } else if (inputIdentifier === 'QuestionType' || inputIdentifier === 'callAction' || formFields[inputIdentifier].elementType == 'switch' || inputIdentifier === 'linkedQuestion') {

            actualValue = event;
            if (inputIdentifier === 'QuestionType') {
                localStorage.setItem("type", actualValue);
            }
        } else {
            actualValue = value == undefined ? event?.target?.value : value.value;
            actualValue = actualValue == undefined ? event?.target?.checked : actualValue;

        }

        const updatedFormElement = updateObject(formFields[inputIdentifier], {
            value: actualValue,
            valueObj: valueObj,
            valid: checkValidity(actualValue, formFields[inputIdentifier].validation),
            touched: true,
            error: checkValidityType(actualValue, formFields[inputIdentifier].validation) == '' ? '' : formFields[inputIdentifier].errorMessage[checkValidityType(actualValue, formFields[inputIdentifier].validation)],
        });

        let updatedFormFields;
        updatedFormFields = updateObject(formFields, {
            [inputIdentifier]: updatedFormElement
        });


        if (inputIdentifier === 'callFunction') {
            if (actualValue) {
                updatedFormFields = updateObject(updatedFormFields, { callAction: { ...callActionField } });
                setOptions({});
            } else {
                // delete updatedFormFields.linkedQuestion;
                delete updatedFormFields.callAction;
                addOption(2);
            }
        }
        if (inputIdentifier === 'linkingFunction') {
            let linkingActionFieldCopy = updateObject(linkingActionField, {
                elementConfig: {
                    options: linkingOption,
                    placeholder: 'Call Stack'
                }
            });
            if (actualValue) {
                updatedFormFields = updateObject(updatedFormFields, { linkedQuestion: { ...linkingActionFieldCopy } });
                if (!localStorage.getItem("type") === 6) {
                    setOptions({});
                }
            } else {
                delete updatedFormFields.linkedQuestion;
                if (formFields.QuestionType.value != 9) {
                    addOption(2);
                }

            }
        }

        await setFormFields(updatedFormFields);
    }

    const minMaxChangedHandler = async (event, inputIdentifier, value) => {
        let actualValue;
        let valueObj = [];

        if (minMaxField[inputIdentifier].elementType == 'multiple_autocomplete') {
            actualValue = [];
            value.forEach(item => {
                actualValue.push(item.props.value);
                valueObj.push({ id: item.props.value, name: item.props.label })
            });
        } else {
            actualValue = value == undefined ? event?.target?.value : value.value;
            actualValue = actualValue == undefined ? event?.target?.checked : actualValue;
        }

        const updatedFormElement = updateObject(minMaxField[inputIdentifier], {
            value: actualValue === "" ? null : Number(actualValue),
            valueObj: valueObj,
            valid: checkValidity(actualValue, minMaxField[inputIdentifier].validation),
            touched: true,
            error: checkValidityType(actualValue, minMaxField[inputIdentifier].validation) == '' ? '' : minMaxField[inputIdentifier].errorMessage[checkValidityType(actualValue, minMaxField[inputIdentifier].validation)],
        });

        let updatedMinMaxField;
        updatedMinMaxField = updateObject(minMaxField, {
            [inputIdentifier]: updatedFormElement
        });

        setMinMaxField(updatedMinMaxField);
    }

    const handleEditOptions = (data) => {
        const createDynamicOption = data.questionOptions.map((val, index) => {
            let optionKey = `option_${index}`

            const optionObject = {
                [optionKey]: {
                    "id": val.id,
                    "elementType": "input",
                    "label": "",
                    "col": 8,
                    "fullRow": 4,
                    "error": "",
                    "divider": 0,
                    "heading": "",
                    "elementConfig": {
                        "type": "text",
                        "placeholder": "Option"
                    },
                    "value": val.label,
                    "validation": {
                        "required": true
                    },
                    "errorMessage": {
                        "required": "Option Field is required"
                    },
                    "valid": true,
                    "touched": false,
                    "isModelBtn": false,
                    "configureMeta": {},
                }
            };
            return optionObject
        })

        const dynamicOptionObject = { ...createDynamicOption }
        const key_ary = []
        const value_ary = []

        for (let a in dynamicOptionObject) {
            key_ary.push(...Object.keys(dynamicOptionObject[a]))
            value_ary.push(...Object.values(dynamicOptionObject[a]))
        }

        let customOptionObject = {}

        for (let i = 0; i < key_ary.length; i++) {
            customOptionObject[key_ary[i]] = value_ary[i];
        }

        setOptions({ ...options, ...customOptionObject })
    }

    const addOption = (from = 1) => { // Add Options Dynamically
        let optionF = optionField(QUESTION_TYPE[formFields.QuestionType.value]);

        if (optionF == '') {
            setOptions({});
            return;
        }

        if (from == 2) {
            let options = {
                ['option_0']: optionF,
                ['option_1']: optionF
            };
            setOptions(options);
        } else {
            console.log("else........", options)
            let optionlength = Object.keys(options).length;
            const updatedOptions = updateObject(options, {
                ['option_' + optionlength]: optionF
            });
            console.log("updatedOptions>>>>", updatedOptions)
            setOptions(updatedOptions);
        }
    }

    // Add Call Function & Linking Function Dynamically
    const addDynamicField = (enabled_type = ["RADIO", "CHECKBOX", "SINGLE_SELECT", "MULTI_SELECT", "SINGLE_SELECT_SEARCH", "MULTI_SELECT_SEARCH", "TEXTBOX"]) => {
        let id = formFields?.QuestionType?.value;
        let typeObj = formFields?.QuestionType?.elementConfig?.options?.filter(item => item.id == id);
        if (typeObj?.length > 0) {
            return enabled_type.includes(QUESTION_TYPE[typeObj[0].id]);
        }
        return false;
    }

    const optionChangedHandler = (event, inputIdentifier, value) => {
        let valueObj = [];
        let actualValue = value == undefined ? event?.target?.value : value.value;
        actualValue = actualValue == undefined ? event?.target?.checked : actualValue;

        const updatedFormElement = updateObject(options[inputIdentifier], {
            value: actualValue,
            valueObj: valueObj,
            valid: checkValidity(actualValue, options[inputIdentifier].validation),
            touched: true,
            error: checkValidityType(actualValue, options[inputIdentifier].validation) == '' ? '' : options[inputIdentifier].errorMessage[checkValidityType(actualValue, options[inputIdentifier].validation)],
        });

        let updatedOptions;
        updatedOptions = updateObject(options, {
            [inputIdentifier]: updatedFormElement
        });

        setOptions(updatedOptions);
    }

    const handleDeleteOption = (event, inputIdentifier) => {
        let stateOptionObject = { ...options };
        const optionId = stateOptionObject[inputIdentifier]?.id
        dispatch(deleteOption(optionId))
        delete stateOptionObject[inputIdentifier];
        setOptions(stateOptionObject);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isFormValid()) {
            return;
        }

        let formData = { /* Expertiese: expertId, SubIndustry: subId, */ id: Number(editId) };

        for (let el in formFields) {
            if (el !== "callFunction") {
                formData[el] = formFields[el].value;
            }
        };

        let optionsList = [];
        let index = 1;
        for (let key in options) {
            optionsList.push({ label: options[key].value, position: index, id: options[key].id ? options[key].id : null })
            index = index + 1;
        }

        formData.questionOptions = optionsList;

        for (let el in minMaxField) {
            formData[el] = minMaxField[el].value;
        };

        if ((formData.subIndustries && formData.expertiese) || formData.subIndustries || formData.expertiese) {
            let relData = {
                subIndustry: formData.subIndustries ? formData.subIndustries : null,
                expertiese: formData.expertiese ? formData.expertiese : null
            }
            formData.questionRelations = [relData]
        }
        delete formData.expertiese;
        delete formData.subIndustries;
        if (editId) {
            formData.sequence = data?.sequence
        } else {
            formData.sequence = highestSequence + 1;
        }

        await dispatch(AddQuestion(formData));
        closeDrawer()
        setLoading(true)
    }

    const isFormValid = () => {
        let formisValid = true;
        for (let inputIdentifier in formFields) {
            if (!formFields[inputIdentifier].valid) {
                formisValid = formFields[inputIdentifier].valid && formisValid;
            }
        }

        for (let inputIdentifier in options) {
            if (!options[inputIdentifier].valid) {
                formisValid = options[inputIdentifier].valid && formisValid;
            }
        }

        for (let inputIdentifier in minMaxField) {
            if (!minMaxField[inputIdentifier].valid) {
                formisValid = minMaxField[inputIdentifier].valid && formisValid;
            }
        }
        return formisValid;
    }

    return (
        <Row>
            <Form onSubmit={handleSubmit} layout="vertical" style={{ padding: "0 10%" }}>
                {fields?.map((formElement, index) => {
                    return (<InputField
                        key={`field_${index}`}
                        edit={editId/*  ? editId : 'noEdit' */}
                        isradio={formElement?.config?.isradio == 1 ? 1 : 0}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        col={formElement.config.col}
                        label={formElement.config.label}
                        defaultValue={formElement.config.defaultValue}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        fullRow={formElement.config.fullRow}
                        heading={formElement.config.heading}
                        divider={formElement.config?.divider}
                        leftText={formElement.config?.leftText}
                        error={formElement.config.error}
                        changed={(event, value) => inputChangedHandler(event, formElement.id, value)}
                        successMsg={formElement.config?.successMsg}
                    />)
                }
                )}
                {optionsArray?.length ?
                    <>{optionsArray?.map((formElement, index) => {
                        let option = <InputField
                            key={`option_${index}`}
                            isCrossBtn={index + 1 == optionsArray?.length ? index <= 1 ? 0 : 1 : 0}
                            isradio={0}
                            edit={editId}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            col={formElement.config.col}
                            label={formElement.config.label}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            fullRow={formElement.config.fullRow}
                            heading={formElement.config.heading}
                            divider={formElement.config.divider}
                            leftText={formElement.config.leftText}
                            error={formElement.config.error}
                            changed={(event, value) => optionChangedHandler(event, formElement.id, value)}
                            deleteMethod={(event) => handleDeleteOption(event, formElement.id)}
                        />;
                        return option;
                    })}
                        {addDynamicField() && optionsArray.length < 40 && (<div className="form-group col-md-12">
                            <div className="btn btn-primary btn-sm font-weight-bolder text-uppercase" type="submit" onClick={e => addOption()} >Add Option<i className="fa fa-plus-circle ml-1 p-0"></i></div>
                        </div>)}</>
                    : ""}
                {minMaxFieldArray?.map((formElement, index) => <InputField
                    key={`maxMin_${index}`}
                    edit={editId}
                    isradio={formElement?.config?.isradio == 1 ? 1 : 0}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    col={formElement.config.col}
                    label={formElement.config.label}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    fullRow={formElement.config.fullRow}
                    heading={formElement.config.heading}
                    divider={formElement.config?.divider}
                    leftText={formElement.config?.leftText}
                    error={formElement.config.error}
                    changed={(event, value) => minMaxChangedHandler(event, formElement.id, value)}
                    successMsg={formElement.config?.successMsg}
                />
                )}

                <Divider></Divider>
                {fields?.map((formElement, index) => {
                    return (
                        <MultiSelectInputField
                            key={`field_${index}`}
                            isradio={formElement?.config?.isradio == 1 ? 1 : 0}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            col={formElement.config.col}
                            label={formElement.config.label}
                            defaultValue={formElement.config.defaultValue}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            fullRow={formElement.config.fullRow}
                            heading={formElement.config.heading}
                            divider={formElement.config?.divider}
                            leftText={formElement.config?.leftText}
                            error={formElement.config.error}
                            changed={(event, value) => inputChangedHandler(event, formElement.id, value)}
                            successMsg={formElement.config?.successMsg}
                        />
                    )
                }
                )}
                <Button htmlType="submit" className="invite-user-cancel-btn" >
                    Save
                </Button>
            </Form>
        </Row>
    )
}
