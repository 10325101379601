import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { object } from "@amcharts/amcharts4/core";
import { cleanObjectWithId } from 'utils/functions';

const Bank = ({ companyBankInfo, companyInfo }) => {
	const [companyBankInfoData, setCompanyBankInfoData] = useState([])
	const [companyInfoData, setCompanyInfoData] = useState([])
	const companyBankInfoKeyArray = ['bankName', 'bankCountry', 'bankPerson', 'bankCityName', 'bankEmail', 'dialCode', 'bankTel', 'referenceBankAddress']
	const companyInfoKeyArray = ['holidays']

	useEffect(() => {
		const cpyCompanyBankInfoData = { ...companyBankInfo[0] };
		cleanObjectWithId(cpyCompanyBankInfoData)
		let bankInfofield = []
		for (var keyName in cpyCompanyBankInfoData) {
			if (companyBankInfoKeyArray.includes(keyName)) {
				bankInfofield.push({ [keyName]: cpyCompanyBankInfoData[keyName] })
			}
		}

		let finalCompanyBankInfoObj = {};
		for (let i = 0; i < bankInfofield?.length; i++) {
			Object.assign(finalCompanyBankInfoObj, bankInfofield[i]);
		}
		setCompanyBankInfoData(Object.keys(finalCompanyBankInfoObj).length !== 0 ? [finalCompanyBankInfoObj] : [])


		const cpyCompanyInfoData = { ...companyInfo[0] };
		cleanObjectWithId(cpyCompanyInfoData)

		let companyInfofield = []
		for (var keyName in cpyCompanyInfoData) {
			if (companyInfoKeyArray.includes(keyName)) {
				companyInfofield.push({ [keyName]: cpyCompanyInfoData[keyName] })
			}
		}

		let finalCompanyInfoObj = {};
		for (let i = 0; i < companyInfofield?.length; i++) {
			Object.assign(finalCompanyInfoObj, companyInfofield[i]);
		}
		setCompanyInfoData(Object.keys(finalCompanyInfoObj).length !== 0 ? [finalCompanyInfoObj] : [])
	}, [companyBankInfo, companyInfo])

	return (
		((companyBankInfoData?.length !== 0) || (companyInfoData?.length !== 0))
			?
			<>
				<Row gutter={16} className="mt-8">
					<Col>
						<h3>BANK AND HOLIDAYS</h3>
					</Col>
					{companyBankInfoData?.length !== 0
						?
						companyBankInfoData?.map((bankInfo) =>
							<Col span={12} key={bankInfo.id}>
								<h3>Bank Details</h3>
								{bankInfo?.bankName ? <><span><strong>Bank Name:</strong> {bankInfo.bankName}</span> <br /></> : ''}

							{/* 	{bankInfo?.referenceBankAccount ? <><span><strong>Account Name:</strong> {bankInfo.referenceBankAccount}</span><br /></> : ''} */}

								{bankInfo?.bankCountry?.countryname ? <><span><strong>Country:</strong> {bankInfo?.bankCountry?.countryname}</span><br /></> : ''}

								{bankInfo?.bankPerson ? <><span><strong>City:</strong> {bankInfo?.bankPerson}</span><br /></> : ''}

								{bankInfo?.bankCityName ? <><span><strong>Contact person:</strong> {bankInfo?.bankCityName}</span><br /></> : ''}

								{bankInfo?.bankEmail ? <><span><strong>Email:</strong> {bankInfo?.bankEmail}</span><br /></> : ''}

								{bankInfo?.dialCode ? <><span><strong>Telephone:</strong> {bankInfo?.dialCode} {bankInfo?.bankTel}</span><br /></> : ''}


								{/* <span><strong>Account Number:</strong> {bankInfo.referenceBankAccnumber}</span>
						<br /> */}
								{/* <span><strong>SWIFT:</strong> {bankInfo.referenceBankSwift}</span>
						<br /> */}
								{bankInfo?.referenceBankAddress ? <><span><strong>Address:</strong> {bankInfo.referenceBankAddress}</span><br /></> : ''}
							</Col>)
						:
						''
					}
					{companyInfoData?.length !== 0
						?
						companyInfoData?.map((info) =>
							<Col span={12} key={info.id}>
								{info.holidays ?
									<>
										<h3>Public Holidays</h3>
										<span>{info.holidays}</span>
									</>
									:
									''
								}
							</Col>)
						:
						''
					}
				</Row>
				<Divider />
			</>
			:
			''
	);
};

export default Bank;
