import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  getData,
  getFilters,
  getFilterLists,
  clearFilters,
  setFirstTab
} from "actions/dataActions";
import { logoData } from "actions/dataActions";
import { SET_ALL_COMPANIES_TAB } from "actions/types"
import { generateApiUrl, ApiUrlAdd, ApiUrlRemove, ApiUrlExpiredRemove, ApiUrlExpired, ApiUrlExpired2 } from "utils/functions";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import PageHeader from "components/ui-elements/PageHeader";
import CompanyCard from "components/network/company-card/CompanyCard";
import NetworkSidebar from "components/network/NetworkSidebar";
import NetworkSubHeader from "components/network/NetworkSubHeader";
import CompanyEdit from "components/company-edit/CompanyEdit";
import AddMember from "components/network/AddMember";
import { Drawer, Button } from "antd";
import partnerIcon from "theme/assets/myap-icon.svg";
import partnerIcongray from "theme/assets/goback-icon.svg";
import debounce from "lodash/debounce";
import qs from "qs";
import { api_v } from "utils/settings";
import { DOMAIN } from "utils/enum";
// if (localStorage.status === 'Not Finished') {
//   // this.props.history.push("/register");
//   window.location.href = "/register";
// }
class Network extends Component {
  constructor(props) {
    super(props);
    this.listingContainer = React.createRef();
  }

  state = {
    showEdit: false,
    selectedCompany: null,
    containerWidth: 650,
    drawerWidth: 1100,
    clearFilterStatus: false
  };
  colorMap = {
    'Blocked': 'red',
    'Not Finished': 'yellow',
    'InReview': 'yellow',
    'Approved': 'green',
    'Pending': 'blue',
    'Suspended': 'gray',
    'resigned': 'gray'
  };
  onPageChange = (page) => {
    if (this.props.data.currentPage === page) {
      return;
    }
    const url = this.filterResults("page", page);
    this.updateURLAndData(url);
  };

  toggleEdit = (id) => {
    this.setState((prevState) => ({
      showEdit: !prevState.showEdit,
      selectedCompany: id,
    }));
  };

  toggleAddMember = () => {
    this.setState((prevState) => ({
      showAddMember: !prevState.showAddMember,
    }));
  };

  closeEdit = () => {
    this.setState({
      showEdit: false,
    });
    this.props.getData(`/${api_v}network` + this.props.history.location.search);
  };
  resetFilters = () => {
    this.props.clearFilters();
    this.props.setFirstTab({ type: SET_ALL_COMPANIES_TAB, payload: 0 })
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: '',
      state: {}
    });
  };

  // Query Strings (pagination, sorting, filtering)
  filterResults = (label, value, action, url) => {
    console.log("action///////////////////////////...", label, value, action, url)
    // URL for API
    let updatedUrl,
      currentUrl = url || this.props.data.pageUrl;
    switch (action) {
      case "add":
        updatedUrl = ApiUrlAdd(label, value, currentUrl);
        break;
      case "remove":
        updatedUrl = ApiUrlRemove(label, currentUrl);
        break;
      case "expired":
        updatedUrl = ApiUrlExpired(label, value, currentUrl);
      case "expiredNoPaidDate":
        updatedUrl = ApiUrlExpired2(label, value, currentUrl);
        break;
      default:
        updatedUrl = generateApiUrl(label, value, currentUrl);
    }
    return updatedUrl;
  };

  updateURLAndData = (url) => {
    // Fetch new data from API
    this.props.getData(url);
    // Add query parameters to history object
    this.props.history.push(this.props.history.location.pathname + url.split("network")[1]);
    window.scrollTo({ top: 0, left: 0 });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  setContainerWidth = () => {
    if (this.listingContainer.current) {
      this.setState({
        containerWidth: this.listingContainer.current.offsetWidth,
      });
    }
  };
  handleResize = () => {
    if (window.innerWidth <= 1024) {
      this.setState({ drawerWidth: '100%' });
    } else {
      this.setState({ drawerWidth: 1100 }); // Set the desired width for the Drawer
    }
  };

  componentDidMount() {
    localStorage.removeItem("Valid")
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const roles = localStorage.getItem("rolesUser");
    // Calc width of container for dynamic tooltip width
    this.setContainerWidth();
    window.addEventListener("resize", debounce(this.setContainerWidth, 50));
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    // Get data with Query Strings
    if (this.props.history.location.search === "") {
      this.resetFilters()
    } else {
      this.props.getData(
        `/${api_v}network` + this.props.history.location.search
      );
    }
    // {
    //   roles === "ROLE_ADMIN"
    //     ? this.props.logoData("nap_partners")
    if (this.props.auth?.logoData === undefined) {
      this.props.logoData(companyInfo.company);
    }
    // }
    // Get filter lists
    this.props.getFilterLists();

    // If URL contains query strings
    if (this.props.history.location.search) {
      const searchParams = qs.parse(
        this.props.history.location.search.slice(1)
      );

      this.props.getFilters(searchParams);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setContainerWidth);
    window.addEventListener('resize', this.handleResize);
  }

  borderColor = (statusValue) => this.colorMap[statusValue.status] || "";
  render() {
    const { companies, totalItems, itemsPerPage, currentPage, loading } =
      this.props.data;
    const { domain } = this?.props?.common;
    const { showEdit, containerWidth, showAddMember, selectedCompany, drawerWidth } =
      this.state;

    const { user } = this.props.auth;
    let compainesData;
    if (loading || companies === null) {
      compainesData = <SkeletonLoading itemsPerPage={itemsPerPage} />;
    } else {
      if (companies.length === 0) {
        compainesData = (
          <div
            className="f-c-c-col"
            style={{ marginBottom: 40, marginTop: 50 }}
          >
            <p style={{ fontSize: 20 }}>No results for search criteria</p>
            <Button type="primary" className="create-btn" onClick={() => this.resetFilters()}>
              Reset all filters
            </Button>
          </div>
        );
      } else {
        compainesData = companies.map((item) => {
          const borderClass = this.borderColor(item.companySettings[0])
          return (
            <CompanyCard
              key={item.id}
              item={item}
              toggleEdit={this.toggleEdit}
              containerWidth={containerWidth}
              auth={this.props.auth}
              borderClass={borderClass}
            />
          )
        }
        );
      }
    }

    return (
      <div className="network">
        <PageHeader
          className="pageIconblended"
          // className="grey-images"
          // icon={domain===DOMAIN.NEX ? partnerIcongray : partnerIcon}
          icon={partnerIcon}
          title={`MY PARTNER`}
          canAdd={true}
          toggleAddMember={this.toggleAddMember}
        />
        <div className="network-main mx-4">
          <NetworkSidebar
            // clearFilterStatus={this.state.clearFilterStatus}
            filterResults={this.filterResults}
            updateURLAndData={this.updateURLAndData}
          />
          <div className="network-listing bg-white" ref={this.listingContainer}>
            <NetworkSubHeader
              totalItems={totalItems}
              filterResults={this.filterResults}
              updateURLAndData={this.updateURLAndData}
            ></NetworkSubHeader>
            {compainesData}
            <Pagination
              onChange={this.onPageChange}
              locale={localeInfo}
              current={currentPage}
              pageSize={itemsPerPage}
              total={totalItems}
            />
          </div>
        </div>

        <Drawer
          width={drawerWidth}
          destroyOnClose={true}
          closable={false}
          onClose={this.closeEdit}
          visible={showEdit}
        >
          <CompanyEdit
            key={selectedCompany}
            companyId={selectedCompany}
            toggleEdit={this.toggleEdit}
          ></CompanyEdit>
        </Drawer>

        <Drawer
          width={700}
          closable={false}
          onClose={this.toggleAddMember}
          visible={showAddMember}
          destroyOnClose={true}
        >
          <AddMember closeDrawer={this.toggleAddMember}></AddMember>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    data: state.data,
    common: state.common.domain,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getData,
    getFilters,
    getFilterLists,
    clearFilters,
    setFirstTab,
    logoData,
  })
)(Network);
