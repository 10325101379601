import React from "react";
import { Row, Col, Card, Avatar } from "antd";
import userIcon from 'theme/assets/dummyIcon.svg';

const Personnel = ({ company }) => {
	return (
		<div className="px-8">
			{company.userSettings.length &&
				<Card title="HQ Key Personnel" style={{ width: '100%' }}>

					{company.userSettings.map((userSetting) =>

						<Row key={userSetting.id} className="mt-3">
							<Col span={13}>
								<Avatar className="imageofprofile" size={64} src={userSetting.user.userAvatar[0] ? userSetting.user.userAvatar[0].path : userIcon} />
								<div className="inline-block ml-4">
									<div><strong>{userSetting.user.firstName + ' ' + userSetting.user.lastName}</strong></div>
									<div>{userSetting.user.userInfo[0].companyFunction}</div>
									<div >{userSetting?.user?.subIndustry.map((subIndustry) =>
										<><img className="mt-1" src={subIndustry.industryLogo} /><br /></>
									)}
									</div>

								</div>

							</Col>
							{userSetting.user.userInfo.length && userSetting.user.userInfo.map((userInfo) => {
								return (<Col span={11} key={userInfo.id}>
									{userInfo.telephone && <><span><strong>Phone:</strong>
										{`${(userInfo.telDialCode !== null && userInfo.telDialCode !== "") ? userInfo.telDialCode : ""} ${userInfo.telephone}`}
									</span><br /></>}
									{userInfo.cellphone && <><span><strong>Cellphone:</strong>
										{`${(userInfo.cellDialCode !== null && userInfo.cellDialCode !== "") ? userInfo.cellDialCode : ""} ${userInfo.cellphone}`}</span><br /></>}
									{userSetting.user.email && <><span><strong>E-mail:</strong> <a className="add-branch-btn" href={"mailto:" + userSetting.user.email}>{userSetting.user.email}</a></span><br /></>}
									{userInfo.skype && <span><strong>Skype:</strong> <a  className="add-branch-btn" href={"skype:" + userInfo.skype + "?call"}>{userInfo.skype}</a></span>}
								</Col>)
							})}
						</Row>)}
				</Card>
			}
		</div>
	);
};

export default Personnel;
