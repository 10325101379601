import React, { useEffect, useReducer, useState } from "react";
import { updatePromoAction, getCompanyPromos, getPromos, updatePromo, deletePromoAction } from "actions/dataActions";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import moment from "moment";
import originIcon from "theme/assets/img/origin-icon.svg";
import destinationIcon from "theme/assets/img/destination-icon.svg";
import plusIcon from "theme/assets/plus-icon.svg";

import promoIcon from "theme/assets/promoIcon.svg";
import allPromo from "theme/assets/allPromo.png";
import myPromo from "theme/assets/myPromo.png";
import leftActiveArrowPromo from "theme/assets/leftActiveArrowPromo.png";
import jwt_decode from "jwt-decode";
import styled from "@emotion/styled";
import {
  Table,
  Icon,
  Tag,
  Tooltip,
  Badge,
  Divider,
  Button,
  Popconfirm,
  message,
  Col,
  Row,
  Drawer,
  Empty
} from "antd";
import { variables } from "utils/settings";
import produce from "immer";
import PageHeader from "components/ui-elements/PageHeader";
import AddPromo from "./AddPromo";
import { Link } from "react-router-dom";
import PromoCard from "components/ui-elements/PromoCard";

const { Column } = Table;

const FixedBottom = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #d7d7d7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const initialState = {
  promos: null,
  selectedRowKeys: [],
  loading: true,
  updating: false,
  contact: false,
  page: 1,
  total: 100
};

function reducer(state, action) {
  switch (action.type) {
    case "GET_DATA":
      return {
        ...state,
        promos: action.payload["hydra:member"],
        loading: false,
        total: action.payload["hydra:totalItems"],
        page: action.page
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true
      };

    case "SET_UPDATING":
      return {
        ...state,
        updating: true
      };

    case "UPDATE_PROMO":
      return produce(state, draftState => {
        const promoIndex = state.promos.findIndex(
          a => a.id === action.payload.id
        );
        draftState.promos[promoIndex].approved = action.payload.approved;
      });

    case "SET_SELECTED":
      return {
        ...state,
        selectedRowKeys: action.payload
      };

    case "CLEAR_SELECTED":
      return {
        ...state,
        selectedRowKeys: [],
        updating: false
      };
    default:
      throw new Error();
  }
}

const CreditAlerts = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isAdd, setIsAdd] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [editData, setEditData] = useState({});
  const [isAdmin, setIsAdmin] = useState(null);

  const { promos, loading, updating, page, selectedRowKeys, total } = state;

  // Fetch data based on PageNumber
  const fetchData = page => {
    dispatch({
      type: "SET_LOADING"
    });
    const date = moment().format("YYYY-MM-DD");

    getPromos(page, date, isAdmin).then(data => {
      if (data) {
        dispatch({
          type: "GET_DATA",
          payload: data,
          page
        });
      }
    });
  };
  // Fetch data on mount
  useEffect(() => {
    const decoded = jwt_decode(localStorage.jwtToken);
    const { roles } = decoded;
    if (roles && roles.length > 0) {
      if (roles.indexOf("ROLE_ADMIN") > -1) {
        setIsAdmin(true);
      }
    }
    else {
      setIsAdmin(false);
    }

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const { useTools } = companyInfo;
    if (!useTools) {
      props.history.push("/");
    }

  }, []);

  useEffect(() => {
    if (isAdmin != null) {

      fetchData(page);
    }

  }, [isAdmin])

  // Switch Pages
  const changePage = page => {
    window.scrollTo(0, 0);
    if (activeTab === 0) {
      fetchData(page);
    }
    else {
      fetchCompanyPromos(page);
    }
  };

  // Approve selected
  const approveSelected = () => {
    const data = {
      approved: true
    };
    updateSelectedPromos(data);
  };

  // Decline selected
  const declineSelected = () => {
    const data = {
      approved: false
    };
    updateSelectedPromos(data);
  };

  // Update all selected promos
  const updateSelectedPromos = data => {
    dispatch({
      type: "SET_UPDATING"
    });
    const promises = [];
    selectedRowKeys.forEach(id => {
      promises.push(
        Promise.resolve(
          updatePromo(data, id).then(res =>
            dispatch({ type: "UPDATE_PROMO", payload: res })
          )
        )
      );
    });

    Promise.all(promises)
      .then(() => {
        message.success("Promos updated successfully");
        dispatch({
          type: "CLEAR_SELECTED"
        });
      })
      .catch(err => {
        message.error("Couldn't finish task, please try again");
        dispatch({
          type: "CLEAR_SELECTED"
        });
      });
  };

  // Handle row selection (click on row)
  const selectRow = record => {
    const newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.indexOf(record.id) >= 0) {
      newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(record.id), 1);
    } else {
      newSelectedRowKeys.push(record.id);
    }
    dispatch({
      type: "SET_SELECTED",
      payload: newSelectedRowKeys
    });
  };
  // Handle checkbox select
  const onSelectedRowKeysChange = selectedRowKeys => {
    dispatch({
      type: "SET_SELECTED",
      payload: selectedRowKeys
    });
  };

  const showFile = file => {
    if (file === "") {
      return null;
    }
    var extension = file.substr(file.lastIndexOf(".") + 1);
    if (!/(jpg|jpeg|png|gif)$/gi.test(extension)) {
      return (
        <a href={file} target="_blank" rel="noopener noreferrer">
          <Icon type="file-pdf"></Icon> Download PDF{" "}
        </a>
      );
    } else {
      return <img src={file} alt="promo" style={{ maxWidth: "300px" }}></img>;
    }
  };

  const printStatus = (approved, status) => {
    if (!approved && status === "Active") {
      return <Tag color={variables.colors.info}>Pending | {status}</Tag>;
    } else {
      if (!approved && status === "Draft") {
        return <Tag color={variables.colors.danger}>Declined | {status}</Tag>;
      } else {
        return <Tag color={variables.colors.success}>Approved | {status}</Tag>;
      }
    }
  };

  // Render View -------------------------------------------------------------------------

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange
  };

  const deletePromo = (promoId) => {
    const promises = [];
    promoId.forEach(id => {
      promises.push(
        Promise.resolve(
          deletePromoAction(id).then(res => {
            fetchData(page)
          }
          )
        )
      );
    });

    Promise.all(promises)
      .then(() => {
        message.success("Promos deleted successfully");
        dispatch({
          type: "CLEAR_SELECTED"
        });
      })
      .catch(err => {
        message.error("Couldn't finish task, please try again");
        dispatch({
          type: "CLEAR_SELECTED"
        });
      });

  }

  let promosContent = <SkeletonLoading itemsPerPage={30} />;

  if (promos && !loading) {
    promosContent = (
      <>
        <Table
          className="adminPromoTable"
          dataSource={promos}
          rowSelection={rowSelection}
          rowKey="id"
          pagination={false}
          onRow={record => ({
            onClick: () => {
              selectRow(record);
            }
          })}
        >
          <Column
            title="Company"
            dataIndex="company"
            key="company.id"
            width="40%"
            render={(company, row) => (
              <>
                <div className="f-s-b" style={{ marginBottom: 10 }}>
                  <span>
                    <Tag color="blue">NEW</Tag>
                    <Tag>{row.category.category}</Tag>
                  </span>
                </div>

                {row.originAirport && row.destinationAirport && (
                  <div className="f-s-b" style={{ width: "80%" }}>
                    <Tooltip
                      title={row.originAirport.airportName}
                      placement="right"
                    >
                      <div className="f-c-c-col">
                        <img
                          src={originIcon}
                          alt="origin"
                          style={{ width: 50 }}
                        ></img>
                        <p>{row.originAirport.iata}</p>
                      </div>
                    </Tooltip>
                    <div className="f-c-c-col" style={{ flex: 2 }}>
                      <Tooltip
                        title={row.promosAirline.airlineName}
                        placement="top"
                      >
                        <Tag style={{ marginBottom: 5 }}>
                          {row.promosAirline.iata}
                        </Tag>
                      </Tooltip>
                      <div
                        style={{
                          height: 1,
                          width: "100%",
                          backgroundColor: "#eee"
                        }}
                      ></div>
                    </div>
                    <Tooltip
                      title={row.destinationAirport.airportName}
                      placement="left"
                    >
                      <div className="f-c-c-col">
                        <img
                          src={destinationIcon}
                          alt="destination"
                          style={{ width: 50 }}
                        ></img>
                        <p>{row.destinationAirport.iata}</p>
                      </div>
                    </Tooltip>
                  </div>
                )}
                <h3 className="network-card-title">{company.companyName}</h3>
                <span className="text-secondary">
                  {company.city}, {company.countries.countryname}
                </span>
                <br />
                <span className="text-secondary">
                  <Icon type="clock-circle"></Icon> Ends{" "}
                  {moment(row.endDate).format("DD/MM/YYYY")}
                </span>
              </>
            )}
          ></Column>
          <Column title="Notes" dataIndex="additionalNotes" key="note"></Column>
          <Column
            title="Document"
            dataIndex="promoDocument"
            key="doc"
            width="300px"
            render={doc => showFile(doc)}
          ></Column>

          <Column
            title="Status"
            dataIndex="approved"
            key="status"
            width="100px"
            render={(approved, row) => {
              return (printStatus(approved, row.status))
            }}
          ></Column>
        </Table>

        <Pagination
          onChange={changePage}
          locale={localeInfo}
          current={page}
          defaultPageSize={10}
          total={total}
        ></Pagination>

        <FixedBottom
          className={`move-down-enter ${selectedRowKeys.length > 0 &&
            "move-down-enter-active"}`}
        >
          <div className="container">
            <div className="f-s-b" style={{ height: 64 }}>
              <span>
                <Badge
                  count={selectedRowKeys.length}
                  style={{
                    backgroundColor: variables.colors.primary
                  }}
                ></Badge>
                <span style={{ marginLeft: 6 }}>promo items selected.</span>
                <Divider type="vertical"></Divider>
                <Button
                  style={{ marginRight: 5 }}
                  onClick={() => approveSelected()}
                >
                  Approve
                </Button>
                <Button onClick={() => declineSelected()}>Decline</Button>
                {updating && (
                  <>
                    <Divider type="vertical"></Divider>
                    <Icon type="loading" />
                  </>
                )}
              </span>
              <span>
                <Popconfirm
                  title={`Are you sure you want to delete ${selectedRowKeys?.length} selected promos`}
                  placement="topRight"
                  onConfirm={() => deletePromo(selectedRowKeys)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" disabled={selectedRowKeys?.length ? false : true}>
                    Delete
                  </Button>
                </Popconfirm>
              </span>
            </div>
          </div>
        </FixedBottom>
      </>
    );
  }

  const tabsList = [
    {
      icon: allPromo,
      title: 'ALL PROMOTIONS',
      tabIndex: 0
    },
    {
      icon: myPromo,
      title: 'MY PROMOTIONS',
      tabIndex: 1
    },
    // {
    //   icon: expiredPromo,
    //   title: 'EXPIRED',
    //   tabIndex: 2
    // }
  ]

  const subTabsList = [
    {
      title: 'Active',
      tabIndex: 0,
      type: 1
    },
    {
      title: 'Pending',
      tabIndex: 1,
      type: 0
    },
    {
      title: 'Archive / Expired',
      tabIndex: 2,
      type: 2
    }
  ]


  const fetchCompanyPromos = type => {
    dispatch({
      type: "SET_LOADING"
    });
    const date = moment().format("YYYY-MM-DD");

    getCompanyPromos(type).then(data => {
      if (data) {
        dispatch({
          type: "GET_DATA",
          payload: data,
          page
        });
      }
    });
  };

  const switchTab = (tabIndex) => {
    setActiveSubTab(0);
    setActiveTab(tabIndex);
    if (tabIndex === 0) {
      fetchData(1);
    }
    else if (tabIndex === 1) {
      fetchCompanyPromos(1);
    }
  }

  const switchSubTab = (tabIndex, type) => {
    setActiveSubTab(tabIndex);
    // if (type !== -1) {
    fetchCompanyPromos(type);
    // }
  }

  const tabHeader = (icon, text) => {
    return (
      <div className={`leftPanelTitle`}>
        <div className="tabDetails">
          <img src={icon} className="panelIcon" />
          <span>
            {text}
          </span>
        </div>
        <img src={leftActiveArrowPromo} className="activeIcon" />
      </div>
    )
  }

  const onAddUpdate = () => {
    switchTab(1);
    switchSubTab(1);
    const _activeSubTab = subTabsList.filter(s => s.tabIndex === 1)[0];
    fetchCompanyPromos(_activeSubTab.type);
    setIsAdd(false);
    setEditData({});
  }

  const closeDrawer = () => {
    setEditData({});
    setIsAdd(false);
  }



  const canclePromo = (promoId) => {
    const data = {
      status: "Archive",
      id: `/v1/my_promos/${promoId}`,
    };
    updatePromoAction(data, promoId).then(res => {
      fetchCompanyPromos(activeSubTab);
      dispatch({ type: "UPDATE_PROMO", payload: res })
    }
    )
  }

  const editPromo = (promo) => {
    setEditData(promo);
    setIsAdd(true);
  }

  return (
    <div className="pageContainer">
      <PageHeader icon={promoIcon} title={`MY PROMOTIONS`} />
      {/* <div className="network-header">
        <h1 className="page-title">Promos</h1>
      </div> */}
      <Row className="user-promo-wrap">
        {
          !isAdmin &&
          <Col sm={24} xs={24} lg={4} className="leftPanel">
            <div className="leftPanelNav">
              <Button type="default" className="addBtn promo" onClick={() => setIsAdd(true)}>
                ADD PROMO
                <img src={plusIcon} />
              </Button>
              <div className="ant-tabs-nav ant-tabs-nav-animated alertTabs">
                {
                  tabsList.map((tab, index) => {
                    return (
                      <div className={`${activeTab === tab.tabIndex ? "ant-tabs-tab-active" : ""} ant-tabs-tab`} key={`tab_${index}`} onClick={() => switchTab(tab.tabIndex)}>
                        {tabHeader(tab.icon, tab.title)}
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="leftPanelLink">
              {/* <Link to="#">
                CAS Terms and conditions
              </Link> */}
            </div>
          </Col>
        }
        <Col sm={24} xs={24} lg={isAdmin ? 24 : 20} className="contentPanel promosPanel" >
          <div className={`ant-tabs-nav ant-tabs-nav-animated subtabs ${!isAdmin && activeTab === 1 ? "" : "subtabs-disable"}`}>
            {
              !isAdmin && activeTab === 1 &&
              subTabsList.map((tab, index) => {
                return (
                  <div className={`${activeSubTab === tab.tabIndex ? "ant-tabs-tab-active" : ""} ant-tabs-tab`} key={`tab_${index}`} onClick={() => switchSubTab(tab.tabIndex, tab.type)}>
                    <div className="tabDetails">
                      <span>
                        {tab.title}
                      </span>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            isAdmin
              ?
              promosContent
              :
              loading
                ?
                <SkeletonLoading itemsPerPage={30} />
                :
                <div className="container">
                  {
                    promos?.map((promo, index) => {
                      return (
                        <PromoCard
                          promoDetails={promo}
                          index={index}
                          activeTab={activeTab}
                          editPromoCallback={(data) => editPromo(data)}
                          canclePromoCallback={(id) => canclePromo(id)}
                          deletePromoCallback={(id) => deletePromo(id)}
                          displayActions={true}
                          key={`promoCard_${index}`}
                          isSendMessage={activeTab === 0 ? true : false}
                          isEditCancle={activeTab === 1 ? activeSubTab === 0 || activeSubTab === 1 : false}
                          isDelete={activeTab === 1 ? activeSubTab === 2 : false}
                        />
                      )
                    })
                  }
                  {
                    promos?.length === 0
                      ?
                      <Empty />
                      :
                      !isAdmin &&
                      <Pagination
                        onChange={changePage}
                        locale={localeInfo}
                        current={page}
                        defaultPageSize={10}
                        total={total}
                      ></Pagination>
                  }

                </div>
          }
        </Col>
        <Drawer
          width={800}
          destroyOnClose={true}
          closable={false}
          onClose={() => closeDrawer()}
          visible={isAdd}
        >
          <AddPromo onAddUpdateCallback={onAddUpdate} editableInfo={editData} />
        </Drawer>
      </Row>
    </div>
  );
};

export default CreditAlerts;
