import React, { Component } from "react";
import { Col } from "antd";
import { printCityName } from "components/helper/Format";
import branchIcon from 'theme/assets/branches.svg'

export default class CompanyBranches extends Component {
  render() {
    const { branches } = this.props;

    if (!branches) {
      return <></>;
    }
    if (branches.length === 0) {
      return <></>;
    }

    return (
      <Col>
        <img src={branchIcon} className="sectionIcon" />
        <h4>Branches</h4>
        <ul className="list-blank">
          {branches.map(branch => (
            <li key={branch.id}>{printCityName(branch.branchCity)}</li>
          ))}
        </ul>
      </Col>
    );
  }
}
