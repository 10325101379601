const regex = {
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    GENERAL_REGER: /^[A-Za-z0-9 _@.\/!@#$^\*,.+&()<>-]*$/,
    ALPHANUMERIC: /^[A-Za-z0-9]*$/,
    ALPHANUMERICWITHSPACES: /^[A-Za-z0-9 ]*$/,
    ALPHABETSONLY: /^[A-Za-z]*$/,
    ALPHABETSONLYWITHSPACES: /^[A-Za-z ]*$/,
    // EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    EMAIL_WITH_SPECIAL_SYMBOL: /^[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]+@[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]+\.[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]+$/,
    PHONE: /^\d{10,15}$/,
    PHONE_WITH_SPECIALSYMBOLS: /^[A-Za-z0-9 +-]*$/,
    DESCRIPTION: /^[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> ]{0,1000}$/,
    ONLYNUMERIC: /^\d*$/,
    DECIMALNUMBER: /^\d*\.?\d*$/,
    NUMBER: /^\d+(\.\d+)?$/,
    NUMBER1: /^\$?[0-9]+\.?[0-9]*$/,
    // ALPHANUMERICWITHSPECIAL_SYMBOL: /^[ A-Za-z0-9_@./#&+-,=()*^%$!`'":;|?><~[]{}]*$/,
    ALPHANUMERICWITHSPECIAL_SYMBOL: /^[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]*$/,
    WEBSITE: /(http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/,
    WEBSITE_WITH_SPECIAL_SYMBOL:  /(http:\/\/|https:\/\/)?[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]{1,256}\.[A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]{1,6}\b([A-Za-z0-9_@.\/!@#$^\*,.:?;\n\-()'"+&<> \[ \] \{ \} \\ \ % ]*)/,
}

export default regex;