import { QUESTION_TYPE } from '../../utils/enum';
import { useSelector, useDispatch } from 'react-redux';
import { getCountries } from "../../actions/dataActions";
import { useEffect, useState } from 'react';

export const Dynamic_Fields = (fieldObj, expertises, columnWidth = 12, isEdit = false, isViewMode = false) => {
  // const countries = useSelector((state) => state.data.countries);
  let validation = {};
  let errorMessage = {};
  let elementConfig = {};
  let isradio = 0;
  let maxLimit = null;
  let minLimit = null;
  let elementType = 'input';
  let answer = fieldObj.answer != null ? fieldObj.answer : '';
  let isValid = !fieldObj.mandatory;
  let disable;
  // let changed=()=>{}
  const [, , , fieldObjtype] = fieldObj?.QuestionType?.split("/");

  if (fieldObj?.mandatory) {
    /* START:- Remove validation when selected any 3 expertise of nex from (Gateway custom brokers, Tect and solutions and Consoltants & Trainer)  */
    validation = { required: (!expertises?.includes(24) && !expertises?.includes(31) && !expertises?.includes(32)) ? true : false };
    /* END:- Remove validation when selected any 3 expertise of nex from (Gateway custom brokers, Tect and solutions and Consoltants & Trainer)  */
    errorMessage = { required: "This field is required" };
  }


  disable = fieldObj?.disable

  if (QUESTION_TYPE[fieldObjtype] == "CHECKBOX" || QUESTION_TYPE[fieldObjtype] == "RADIO" || QUESTION_TYPE[fieldObjtype] == "SINGLE_SELECT" || QUESTION_TYPE[fieldObjtype] == "SINGLE_SELECT_SEARCH" || QUESTION_TYPE[fieldObjtype] == "MULTI_SELECT" || QUESTION_TYPE[fieldObjtype] == "MULTI_SELECT_SEARCH") {
    const options = []
    for (let i = 0; i < fieldObj.questionOptions?.length; i++) {
      options.push({
        value: fieldObj?.questionOptions[i].id + '',
        displayValue: fieldObj?.callAction ? fieldObj?.questionOptions[i].value : fieldObj?.questionOptions[i].label
      });
    }
    elementConfig = {
      options: options
    }
  }
  if (QUESTION_TYPE[fieldObjtype] == "TEXTBOX") {
    elementType = 'TEXTBOX';
    elementConfig = {
      type: "text",
      placeholder: fieldObj.placeholder
    };
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "TEXTAREA") {
    columnWidth = 12;
    elementType = 'TEXTAREA';
    elementConfig = {
      placeholder: fieldObj.placeholder
    };
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "CHECKBOX") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'CHECKBOX';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "RADIO") {
    isradio = 1;
    columnWidth = columnWidth;
    elementType = 'RADIO';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "SINGLE_SELECT") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'SINGLE_SELECT';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "SINGLE_SELECT_SEARCH") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'SINGLE_SELECT_SEARCH';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "MULTI_SELECT") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'MULTI_SELECT';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "MULTI_SELECT_SEARCH") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'MULTI_SELECT_SEARCH';
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit;
    // error=
  } else if (QUESTION_TYPE[fieldObjtype] == "NUMBER") {
    isradio = 0;
    columnWidth = columnWidth;
    elementType = 'NUMBER';
    elementConfig = {
      type: "number",
      placeholder: fieldObj.placeholder
    };
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "EMAIL") {
    columnWidth = 12;
    elementType = 'EMAIL';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "PASSWORD") {
    columnWidth = 12;
    elementType = 'PASSWORD';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "PERCENTAGE_SLIDER") {
    columnWidth = 12;
    elementType = 'PERCENTAGE_SLIDER';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "NUMBER_FORMAT") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'NUMBER_FORMAT';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "DATE") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'DATE';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  }
  else if (QUESTION_TYPE[fieldObjtype] == "TIME") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'TIME';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "DATE_TIME") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'DATE_TIME';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "SINGLE_FILE_UPLOAD") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'SINGLE_FILE_UPLOAD';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "MULTI_FILE_UPLOAD") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'MULTI_FILE_UPLOAD';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  } else if (QUESTION_TYPE[fieldObjtype] == "PHONE_NUMBER") {
    isradio = 0;
    columnWidth = 12;
    elementType = 'PHONE_NUMBER';
    elementConfig = {
      placeholder: fieldObj.placeholder
    }
    maxLimit = fieldObj.maxLimit;
    minLimit = fieldObj.minLimit
  }

  return ({
    disable: disable,
    elementType: elementType,
    label: fieldObj.question,
    col: columnWidth,
    fullRow: 0,
    error: '',
    divider: 0,
    heading: '',
    isradio: isradio,
    changed: fieldObj.changed,
    loadOptions: fieldObj.loadOptions,
    getOptionLabel: fieldObj.getOptionLabel,
    getOptionValue: fieldObj.getOptionValue,
    // formatOptionLabel:fieldObj.formatOptionLabel,
    elementConfig: elementConfig,
    value: answer,
    validation: validation,
    errorMessage: errorMessage,
    valid: isEdit ? true : isValid,
    touched: false,
    maxLimit: maxLimit,
    minLimit: minLimit
  });
};

// const mapStateToProps = state => ({
//   auth: state.auth,
//   data: state.company.data,
//   loading: state.company.loading,
//   form1Submitted: state.common.form1Submited,
//   form2Submited: state.common.form2Submited
// });

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCountries: () => getCountriesList(dispatch),
//   }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Dynamic_Fields);




