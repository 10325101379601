import React from "react";
import { Row, Col, Button } from "antd";
import VendorBackground from 'theme/assets/img/vendor-cover.jpg';

const Contacts = (props) => {
	const { company } = props;

	return (
		<Col span={24} className="h-screen vendor-right-pane" style={{ backgroundImage: "url(" + VendorBackground + ")", backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}>
			<div className="p-12 text-white">
				{company.document.length
					? <ul className="mb-1 pl-0" style={{ listStyle: 'none' }}>
						{company.document.map(doc => (
							<li key={doc.id} className="f-s-b download-item">
								<p>{doc.originalName}</p>
								<Button  className="download-btn" type="link" icon="download" size="large" ghost onClick={() => window.open(doc.path)} />
							</li>
						))}
					</ul>
					: null}
				<Row gutter={16}>
					<Col span={12} className="vendor-contacts">
						{company.coordinator ?
							<>
								<h4 className="text-white">NAP CO-ORDINATOR</h4>
								<address>
									<p className="mb-0">{company.coordinator.name}</p>
									{company.coordinator.email ? <p className="mb-0"><strong>E. </strong><a className="add-branch-btn" href={"mailto:" + company.coordinator.email}>{company.coordinator.email}</a></p>:""}
									{company.coordinator.telephone ? <p className="mb-0"><strong>T. </strong>{company.coordinator.telephone}</p>:""}

								</address>
							</>
						:null}
						{company.companyInfo.length ?
							<>
								<h4 className="text-white">HQ CONTACTS</h4>
								<address>
									{company.companyInfo[0].companyTel ? <p className="mb-0"><strong>T. </strong><a className="add-branch-btn" href={"call:" + company.companyInfo[0].companyTel}>{company.companyInfo[0].companyTel}</a></p>:''}
									{company.companyInfo[0].companyFax ? <p className="mb-0"><strong>F. </strong><a className="add-branch-btn" href={"call:" + company.companyInfo[0].companyFax}>{company.companyInfo[0].companyFax}</a></p>:''}
									{company.companyInfo[0].companyEmail ? <p className="mb-0"><strong>E. </strong><a className="add-branch-btn" href={"mailto:" + company.companyInfo[0].companyEmail}>{company.companyInfo[0].companyEmail}</a></p>:''}
									{company.companyInfo[0].companyWebsite ? <p className="mb-0"><strong>W. </strong><a className="add-branch-btn" href={"http://" + company.companyInfo[0].companyWebsite} target="_blank">{company.companyInfo[0].companyWebsite}</a></p>:''}
									{company.companyInfo[0].companySkype ? <p className="mb-0"><strong>S. </strong><a className="add-branch-btn" href={"skype:" + company.companyInfo[0].companySkype}>{company.companyInfo[0].companySkype}</a></p>:''}
								</address>
							</>:null}
					</Col>
					{company.companyInfo.length ?
						<Col span={12} className="vendor-address">
							<h4 className="text-white">ADDRESS</h4>
							<address>
								<span>{company.companyName}</span>
								<br />
								<span>{company.companyInfo[0].address1}</span>
								<br />
								<span>{company.companyInfo[0].address2}</span>
								<br />
								{company.city}, {company.countries.countryname}
							</address>
						</Col>:null
					}
				</Row>
			</div>
		</Col>
	);
};

export default Contacts;
