import React, { useState } from "react";
import styled from "@emotion/styled";
import { updateCas } from "actions/dataActions";
import { variables } from "utils/settings";
import { Modal, Icon, Menu, Dropdown, Button, Input, message } from "antd";

const { confirm } = Modal;

const MenuItem = styled(Menu.Item)`
  i {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

const StatusButton = styled(Button)`
  border-radius: 20px;
  border: none;
  box-shadow: none;
  background-color: ${variables.colors.bgInfo};
`;

const StatusText = styled("div")`
border-radius: 20px;
height: 32px;
line-height: 32px;
text-align: center;
padding: 0 10px;
${props =>
  props.status === "Canceled" &&
  `background-color: ${variables.colors.bgDanger};
  `}

  ${props =>
    props.status === "Declined" &&
    `background-color: ${variables.colors.light};
    `}

${props =>
  props.status === "Resolved" &&
  `background-color: ${variables.colors.bgSuccess};
    `}

${props =>
  props.status === "In Progress" &&
  `background-color: ${variables.colors.bgWarning};
    `}

`;

export default function CasStatus({ status, handleUpdate, id }) {
  const [updating, setUpdating] = useState(false);

  const printContent = key => {
    let data;
    switch (key) {
      case "approve":
        data = "Approve message example...";
        break;

      case "decline":
        data = (
          <>
            <label htmlFor="block-message">
              Reason for declining this credit alert
            </label>
            <Input.TextArea id="block-message"></Input.TextArea>
          </>
        );
        break;

      case "resolve":
        data = "Resign message example...";
        break;
    }

    return data;
  };

  const showConfirm = key => {
    let data;

    confirm({
      title: `Are you sure you want to ${key.toUpperCase()} `,
      content: printContent(key),
      onOk: () => {
        setUpdating(true);

        switch (key) {
          case "approve":
            data = {
              status: "In Progress",
              attention: true
            };
            break;

          case "decline":
            data = {
              status: "Declined",
              attention: false
            };
            break;

          case "resolve":
            data = {
              status: "Resolved",
              attention: false
            };
            break;
          default:
            data = false;
        }

        if (!data) {
          return;
        }

        updateCas(data, id).then(res => {
          message.success("Status updated successfully");
          setUpdating(false);
          handleUpdate(res);
        });
      },
      onCancel() {}
    });
  };

  const menu = () => {
    // Resolved
    if (status === "Resolved") {
      return <></>;
    }

    // In Progress
    if (status === "In Progress") {
      return (
        <Menu>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <MenuItem key="resolve" onClick={() => showConfirm("resolve")}>
            Resolve
          </MenuItem>
        </Menu>
      );
    }

    // Declined
    if (status === "Declined") {
      return <></>;
    }

    // Canceled
    if (status === "Canceled") {
      return <></>;
    }

    // Pending
    if (status === "Pending") {
      return (
        <Menu>
          <MenuItem key="approve" onClick={() => showConfirm("approve")}>
            Approve
          </MenuItem>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <MenuItem key="resolve" onClick={() => showConfirm("resolve")}>
            Resolve
          </MenuItem>
        </Menu>
      );
    }
  };

  return status === "Pending" || status === "In Progress" ? (
    <Dropdown className="actionDropdown"
      overlay={menu}
      trigger={["click"]}
      // placement="topRight"
      overlayStyle={{ minWidth: 200 }}
    >
      <StatusButton
        status={status}
        loading={updating}
        className="ant-dropdown-link"
      >
        {status} <Icon type="down" />
      </StatusButton>
    </Dropdown>
  ) : (
    <StatusText status={status}>{status}</StatusText>
  );
}
