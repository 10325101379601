import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateBranchData,
  deleteBranch,
  createBranchFee,
  updateBranchFee,
  deleteBranchFee,
  updateBranchBank,
} from "actions/branchActions";
import Tabs from "components/ui-elements/Tabs";
import UsersEdit from "./users/UsersEdit";
import { Button, Popconfirm, message } from "antd";
import BranchDetails from "./details/BranchDetails";
import { getSubGroupList } from "actions/dataActions";

const Branch = (props) => {
  const dispatch = useDispatch()
  const { auth } = props;
  const { branchName, branchCity, branchInfo, users, subIndustry } = props.branch;
  console.log(" props.branch>>", props.branch)

  const branchId = props.branch.id;
  const { companyId } = props;
  const countryId = props.branch.country && props.branch.country.id;
  const [loading, setLoading] = useState(false);
  const [selectedSubIndustry, setSelectedSubIndustry] = useState([])
  const countryList = useSelector(state => state.data.countries)
  const companyIdDialCode = useSelector(state => state.company.data)
  const isAdmin = auth.user.roles.includes("ROLE_ADMIN");
  const [network, setNetwork] = useState([])
  const d = useSelector((state) => state.common.allSubindustry)
  console.log("daaaa....", d)
  console.log("branchId", branchId)
  useEffect(() => {
    setNetwork(d)
  }, [d])
  // Branch---------------------------------------------------------------------
  const deleteBranch = () => {
    setLoading(true);
    props.deleteBranch(branchId).then((success) => {
      if (success) {
        message.success("Branch is deleted successfuly");
      }
    });
  };
  // const [subIndustries, setSubIndustries] = useState([])
  const [companyData, setCompanyData] = useState({})
  const companyInfo = useSelector((state) => {
    if (isAdmin) {
      return state.company;
      // return state.data;
    } else {
      return state.company;
    }
  });

  useEffect(() => {
    if (companyInfo) {
      if (isAdmin) {
        const { data } = companyInfo

        setCompanyData(data)
        // const { companies } = companyInfo
        // const companyData = companies?.filter((item) => item.id === props.companyId)
        // setCompanyData(companyData[0])

      } else {
        const { data } = companyInfo
        setCompanyData(data)
      }
    }
  }, [companyInfo])

  /*   useEffect(() => {
      console.log("companyData", companyData)
      if (Object.keys(companyData)?.length) {
        const { subIndustry } = companyData;
        // const subIndustries = companyData?.filter((item) => item.id === props.companyId)
  
        if (subIndustry?.length > 0) {
          setSubIndustries(subIndustry)
        }
  
      }
    }, [companyData]) */
  /*  const initialFunction = async () => {
     const response = await dispatch(getSubGroupList());
     setSubIndustries(response);
   }; */
  /* 
    useEffect(() => {
      initialFunction()
    }, []) */

  useEffect(() => {
    // console.log("subIndustry",subIndustry)
    if (subIndustry?.length) {
      const data = subIndustry.map((selectedElement, index) => {
        return selectedElement.id
        //  setSelectedSubIndustry.push(selectedElement.id)
      })
      setSelectedSubIndustry(data)
    }
  }, [subIndustry])

  return (
    <>
      {/*  {isAdmin && (
        <Popconfirm
          title={`Sure to delete ${branchName || branchCity} branch?`}
          placement="topRight"
          onConfirm={() => deleteBranch()}
        >
          <Button
            type="danger"
            size="small"
            loading={loading}
            // style={{ position: "fixed", right: 20, bottom: 20 }}
            // style={{position: 'absolute', bottom: '0'}}
            style={{ marginBottom: '10px' }}
            ghost
          >
            Delete branch
          </Button>
        </Popconfirm>
      )} */}
      <Tabs
        activeTab={{
          id: 0,
        }}
        vertical={true}
      >
        <Tabs.Tab title="Members" icon="group" id={0}>
          <UsersEdit
            branchId={branchId}
            companyId={companyId}
            users={users}
            branchName={branchName}
            branchCity={branchCity}
            isAdmin={isAdmin}
            deleteBranch={deleteBranch}
            loading={loading}
          ></UsersEdit>
        </Tabs.Tab>
        <Tabs.Tab title="Branch Details" icon="briefcase" id={4}>
          <BranchDetails
            countryDialCode={companyIdDialCode.countries.dialCode}
            countryList={countryList}
            subIndustry={subIndustry}
            selectedSubIndustry={selectedSubIndustry}
            // subIndustries={props?.subIndustries}
            subIndustries={network}
            companyId={companyId}
            branchId={branchId}
            branchInfo={branchInfo[0]}
            branchName={branchName}
            branchCity={branchCity}
            countryId={countryId}
            isAdmin={isAdmin}
            deleteBranch={deleteBranch}
            loading={loading}
          ></BranchDetails>
        </Tabs.Tab>
        {/*   {isAdmin && (
          <Popconfirm
            title={`Sure to delete ${branchName || branchCity} branch?`}
            placement="topRight"
            onConfirm={() => deleteBranch()}
          >
            <Button
              type="danger"
              size="small"
              loading={loading}
              // style={{ position: "fixed", right: 20, bottom: 20 }}
              // style={{position: 'absolute', bottom: '0'}}
              style={{ marginBottom: '10px' }}
              ghost
              className="delete-branch"
            >
              Delete branch
            </Button>
          </Popconfirm>
        )} */}
      </Tabs>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  branch: state.company.data.branch.find(
    (branch) => branch.id === ownProps.branchId
  ),
  companyId: state.company.data.id,
  auth: state.auth,
  // network:state.common
});
export default connect(mapStateToProps, {
  updateBranchData,
  deleteBranch,
  createBranchFee,
  updateBranchFee,
  deleteBranchFee,
  updateBranchBank,
})(Branch);