import React, { useCallback } from "react";
import { Button } from "antd";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 20px;
  border-width: 2px;
  border-radius: 6px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function Dropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    props.handleDrop(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });

  return (
    <div className="dropzone">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here</p>
        <Button className="choose-file-btn" onClick={open}>Choose files</Button>
      </Container>
    </div>
  );
}

export default Dropzone;
