import { useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { clearGlobalErrors } from "actions/dataActions";
import { logoutUser } from "actions/authActions";

import store from "../../store";
const GlobalError = ({ errors, clearGlobalErrors }) => {
  useEffect(() => {
    errors.forEach(error => {
      if (!error) {
        return message.error("Something went wrong");
      }
      if (error.data.hasOwnProperty("violations")) {
        return error.data.violations.forEach(violation => {
          message.error(violation.message);
        });
      }
      if (error.status==401) {        
        store.dispatch(logoutUser());
        window.location.href = "/login";
      }

      message.error(error.statusText);
      clearGlobalErrors();
    });
  }, [errors]);

  return null;
};

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps, { clearGlobalErrors })(GlobalError);
