import React from "react";
import History from "./History";
import Offices from "./Offices";
import Destination from "./Destination";
import Bank from "./Bank";
import OtherDetails from "./OtherDetails";

const Company = ({ company, auth }) => {
	
	return (
		<div className="px-8">
			{company?.companyInfo[0]?.description ? <History companyInfo={company.companyInfo} /> : ''}
			<Offices company={company} />

			<Destination companyData={company.companyData} />

			{/* {(company?.companyBankInfo?.length || company?.companyInfo?.length) ? */} <Bank companyBankInfo={company.companyBankInfo} companyInfo={company.companyInfo} /> {/* : ''} */}

			{auth.user.roles.includes("ROLE_ADMIN") && <OtherDetails companyInfo={company.companyInfo} companyBankInfo={company.companyBankInfo} />}
		</div>
	);
};

export default Company;
