import { Row, Col, Select, Button, Icon, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { addPromoAsyncActions, fetchAirlines, fetchAirports, updatePromoAction } from "../../../actions/dataActions";
import jwt_decode from "jwt-decode";
import plusIcon from "theme/assets/plus-icon.svg";
import addPromoBG from "theme/assets/addPromoBG.svg";
import AsyncSelect from "react-select/async";
import { connect } from 'react-redux';
import { getAllCompanies, getAllCurrencies, getAllPromoContacts } from 'actions/companyActions';
import TextArea from 'antd/lib/input/TextArea';
import { formatAirlineOption, formatAirportOption } from 'components/helper/Format';
import moment from "moment";

const dateFormat = "DD/MM/YYYY";

const { Option } = Select;
const { RangePicker } = DatePicker;

const categories = [
    {
        id: 1,
        name: "My airfreight promotion"
    },
    {
        id: 2,
        name: "Full charter request"
    },
    {
        id: 3,
        name: "Part charter promotion"
    },
    {
        id: 4,
        name: "Empty leg promotion"
    },
    {
        id: 5,
        name: "General promo"
    },
    {
        id: 6,
        name: "Full charter promotion"
    }
]

const AddPromo = (props) => {
    const [promoType, setPromoType] = useState(5);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [airline, setAirline] = useState(null);
    const [contact, setContact] = useState(null);
    const [promoDesc, setPromoDesc] = useState('');
    const [loading, setLoading] = useState(false);

    const [promoId, setPromoId] = useState(0);
    const [status, setStatus] = useState('Active');
    const [approved, setApproved] = useState(0);


    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [value, setValue] = useState();

    const [contacts, setContacts] = useState([]);

    const [imageFlyer, setImageFlyer] = useState(null);

    const { onAddUpdateCallback, editableInfo, getAllPromoContactsAction } = props;

    useEffect(() => {
        getContactsList();
    }, [])

    const getContactsList = async () => {
        const response = await getAllPromoContactsAction();
     
        setContacts(response);
    }

    useEffect(() => {
        if (editableInfo.id) {
            const { company, endDate, user, additionalNotes, category, originAirport, destinationAirport, promosAirline, contact, status, approved, startDate, id } = editableInfo;

            const dateRange = moment(startDate);//, moment(endDate)];

            setPromoId(id);
            setPromoType(category.id);
            setValue(dateRange);
            setOrigin(originAirport);
            setDestination(destinationAirport);
            setAirline(promosAirline);
            setContact(contact.id);
            setPromoDesc(additionalNotes);
            setStatus(status);
            setApproved(approved);
        }
    }, [editableInfo])

    // const getAirportsList = async () => {
    //     const response = await getAllAirports();
    //     setAirportsList(response);
    // }

    // const getCurrencyList = async () => {
    //     const response = await getAllCurrenciesAction();
    //     setCurrencies(response["hydra:member"]);
    // }

    const updateCreditAlert = async () => {
        setLoading(true);
        const endDate = value[1];

        const companyInfo = JSON.parse(localStorage.companyInfo);
        const companyId = companyInfo.company;

        // Decode token and get user info and exp
        const decoded = jwt_decode(localStorage.jwtToken);

        const data = {
            company: `v1/companies/${companyId}`,
            endDate: endDate,
            user: `v1/users/${decoded.id}`,
            additionalNotes: promoDesc,
            category: `v1/categories/${promoType}`,
            originAirport: `/v1/airport/${origin.id}`,
            destinationAirport: `/v1/airport/${destination.id}`,
            promosAirline: `/v1/airline/${airline.id}`,
            contact: `/v1/users/${contact}`,
            id: `/v1/my_promos/${promoId}`,
        }
        const response = await updatePromoAction(data, promoId);

        if (response) {
            setLoading(false);
            onAddUpdateCallback();
        }
    }

    const addMyPromo = async () => {
        // const startDate = value[0];
        const endDate = value;
        const data = new FormData();
        setLoading(true);
        const companyInfo = JSON.parse(localStorage.companyInfo);
        const companyId = companyInfo.company;

        // Decode token and get user info and exp
        const decoded = jwt_decode(localStorage.jwtToken);

        data.append("company", companyId);
        data.append("endDate", endDate);
        data.append("user", decoded.id);
        data.append("additionalNotes", promoDesc);
        data.append("category", promoType);
        data.append("originAirport", origin.id);
        data.append("destinationAirport", destination.id);
        data.append("promosAirline", airline.id);
        data.append("contact", contact);
        if (imageFlyer) {
            data.append("promoDocument", imageFlyer);
        }

        data.append("status", status);
        data.append("approved", approved);

        const response = await addPromoAsyncActions(data);
      
        if (response) {
            setLoading(false);
            onAddUpdateCallback();
        }
    }

    const selectFile = async (e, fileType) => {
        let file = e.currentTarget.files[0];
        let reader = new FileReader();
        // reader.addEventListener("load", () => {
        //     let _logo = { ...logo };
        //     _logo["path"] = reader.result;
        //     setLogo(_logo);
        // }, false);
        if (file) {
            reader.readAsDataURL(file);
            if (fileType === "image") {
                setImageFlyer(file);
            }
        }
    }

    const disabledDate = current => {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        return current && current.valueOf() < d;
    };

    const onOpenChange = open => {

        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };
    
    return (
        <>
            <Row className="drwaerHeader addPromoHeader">
                <Col xs={24} sm={24} lg={24} >
                    <div className="drwaerHeaderInner" >
                        <img src={addPromoBG} className="titleBG" />
                        <div className="addAlertHeading">
                            NEW PROMOTION
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} lg={24} className="addPromoContainer">
                    <div className="addAlertForm">
                        <Row>
                            <Col xs={24} sm={24} lg={24}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Select promo type
                                    </label>
                                    <Select
                                        showSearch
                                        placeholder="Select promo type"
                                        optionFilterProp="children"
                                        onChange={setPromoType}
                                        value={promoType}
                                        id="company"
                                        defaultValue={promoType}
                                    >
                                        {
                                            categories.map((category, index) => {
                                                return (
                                                    <Option value={category.id} key={`category_${index}`}>{category.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} lg={24}>
                                <div className="itemField" id="rangePickerCal">
                                    <label className="requiredLbl">
                                        End Date
                                    </label>
                                    <DatePicker
                                        getContainer={false}
                                        value={hackValue || value}
                                        defaultValue={hackValue || value}
                                        disabledDate={disabledDate}
                                        // onCalendarChange={val => setDates(val)}
                                        onChange={val => setValue(val)}
                                        // onOpenChange={onOpenChange}
                                        format={dateFormat}
                                        getCalendarContainer={() => document.getElementById("rangePickerCal")}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Origin
                                    </label>
                                    <AsyncSelect
                                        async={true}
                                        placeholder={`Origin Airport`}
                                        cacheOptions
                                        loadOptions={fetchAirports}
                                        formatOptionLabel={formatAirportOption}
                                        isClearable={false}
                                        noOptionsMessage={input =>
                                            "Please enter 2 or more characters"
                                        }
                                        getOptionValue={option => option.id}
                                        value={origin}
                                        id="airport"
                                        defaultValue={origin}
                                        onChange={value => setOrigin(value)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Destination
                                    </label>
                                    <AsyncSelect
                                        placeholder={`Destination Airport`}
                                        async={true}
                                        cacheOptions
                                        loadOptions={fetchAirports}
                                        formatOptionLabel={formatAirportOption}
                                        isClearable={false}
                                        noOptionsMessage={input =>
                                            "Please enter 2 or more characters"
                                        }
                                        getOptionValue={option => option.id}
                                        value={destination}
                                        id="airport"
                                        defaultValue={destination}
                                        onChange={value => setDestination(value)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Airline
                                    </label>
                                    <AsyncSelect
                                        placeholder={"Airline"}
                                        noOptionsMessage={input => "Please enter 2 or more characters"}
                                        loadOptions={(input, callback) => fetchAirlines(input, callback)}
                                        formatOptionLabel={formatAirlineOption}
                                        getOptionValue={option => option.id}
                                        isClearable={false}
                                        value={airline}
                                        id={`expAirline`}
                                        defaultValue={airline}
                                        onChange={value => setAirline(value)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Contact
                                    </label>
                                    <Select
                                        showSearch
                                        placeholder="Contacts"
                                        // placeholder="Select a Company"
                                        optionFilterProp="children"
                                        value={contact}
                                        defaultValue={contact}
                                        onChange={setContact}
                                        id="company"
                                    >
                                        {
                                            contacts?.map((contact, index) => {
                                                return (
                                                    <Option value={contact.id} key={`contact_${index}`}>{contact.firstName} {contact.lastName}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        {
                            !promoId &&
                            <>
                                <Row className="document">
                                    <Col xs={12} sm={12} lg={12}>
                                        <div className="itemField">
                                            <label className="lbl">
                                                Image/Flyer
                                            </label>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} lg={12}>
                                        <div className="itemField">
                                            <div className="fileUploader">
                                                {
                                                    <>
                                                        <Icon type="upload" />
                                                        <div style={{ marginTop: 8 }}>{imageFlyer ? imageFlyer.name : "Click to Upload"}</div>
                                                    </>
                                                }
                                                <input type="file" className="fileSelector" onChange={e => selectFile(e, "image")} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col xs={24} sm={24} lg={24}>
                                <div className="itemField">
                                    <label className="requiredLbl">
                                        Details
                                    </label>
                                    <TextArea rows={4} value={promoDesc} onChange={e => setPromoDesc(e.target.value)} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} sm={24} xs={24} className="addAlertAction">
                                {
                                    promoId
                                        ?
                                        <Button loading={loading} type="danger" className="addBtn" disabled={!promoType || !origin || !destination || !airline || !contact || !promoDesc} onClick={() => updateCreditAlert()}>
                                            UPDATE PROMO
                                        </Button>
                                        :
                                        <Button loading={loading} type="danger" className="addBtn" disabled={!promoType || !origin || !destination || !airline || !contact || !promoDesc} onClick={() => addMyPromo()}>
                                            CREATE PROMO
                                            {!promoId && <img src={plusIcon} />}
                                        </Button>
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllPromoContactsAction: () => getAllPromoContacts(),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPromo);