import { Button, Form, Input, Radio, Select, Switch, Skeleton } from 'antd';
import React from 'react';

const MultiSelectInputField = (props) => {
    let inputElement = null;
    /*  switch (props.elementType) {       
              case ('multiSelect'):
             inputElement =
                 props.elementConfig.options.length === 0
                     ?
                     <Skeleton active />
                     : <Select
                         mode="multiple"
                         style={{
                             width: '100%',
                         }}
                         placeholder={props.elementConfig.placeholder}
                         defaultValue={props.valueObj}
                         onChange={props.changed}
                         optionLabelProp="label">
                         {
                             props.elementConfig.options.map((element, index) =>
                                 <Select.Option value={element.id} label={element.name} key={`multi_auto_${index}`}>
                                     {element.name}
                                 </Select.Option>)}
                     </Select>
             break;
         default:
             inputElement = <></>;
     } */
    if (props.elementType === 'multiSelect') {
        inputElement =
            props.elementConfig.options.length === 0
                ?
                <Skeleton active />
                :
                <Select
                    mode="multiple"
                    style={{
                        width: '100%',
                    }}
                    placeholder={props.elementConfig.placeholder}
                    value={props.value.length ? props.value : []}
                    //   defaultValue={props.valueObj}
                    defaultValue={[]}
                    onChange={props.changed}
                    optionLabelProp="label">
                    {
                        props.elementConfig.options.map((element, index) =>
                            <Select.Option value={element.id} label={element.name} key={`multi_auto_${index}`}>
                                {element.name}
                            </Select.Option>)}
                </Select>
    }
    return <>
        {props?.elementType === 'multiSelect'
            ?
            <Form.Item label={props.label} key={props} required={props?.shouldValidate?.required}>{inputElement}
            </Form.Item>
            :
            ""
        }
        {/*  {props.error != '' && props.error != undefined && (<p className="error">{props.error}</p>)}
        {props.isCrossBtn == 1 && (
            <Button onClick={props.deleteMethod}>X</Button>
        )} */}
    </>;
};

export default MultiSelectInputField;