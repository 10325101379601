import React from "react";
import { connect } from "react-redux";
import Badge from "components/ui-elements/Badge";
import { Row, Col } from "antd";
import ShowData from "./ShowData";
import { useListFetch } from "components/helper/Hooks";

const AirCargoNeutral = props => {
  const {
    air,
    neutral,
    nacAirlineRevenue,
    nacOriginAirports,
    expNacTopAirline,
    airCargoSalesAgent,
    expNacDestAirport,
    expNacAgreementAirline,
    airImportAirport,
    airExportCountry,
    airImportCountry,
    neutralAirConsolidators,
    retail,
    wholesale,
    isOperatingExclusively,
    isConductingBsaCpaContracts,
    isOperatingRegularlyScheduled,
    isAgentDealingDirectly,
    divisionOrCompanyName
  } = props;

  const neutralAirConsolidatorsList = useListFetch("neutralAirConsolidators")
    .filter(a => neutralAirConsolidators.includes(a.id))
    .map(b => ({ item: b.neutralAirConsolidators }));

  const leftCol = (
    <>
      {airExportCountry &&
        <ShowData
          label="Top 5 countries we export to"
          data={airExportCountry}
        ></ShowData>
      }
      {
        airImportAirport &&
        <ShowData
          label="Top 5 countries we import from"
          data={airImportCountry}
        ></ShowData>
      }
      {
        nacAirlineRevenue &&
        <div className="section">
          <p
            className="text-secondary"
            style={{ marginBottom: 3, marginTop: 14 }}
          >
            Annual gross airfreight revenue (US$)
        </p>
          <Badge>{nacAirlineRevenue}</Badge>
        </div>
      }

      {air && (
        <ShowData
          label="Air cargo sales agent services our company is specialized in"
          data={airCargoSalesAgent}
        ></ShowData>
      )}
      {
        nacOriginAirports && <ShowData label="Airport branches" data={nacOriginAirports}></ShowData>
      }
      {
        expNacTopAirline &&
        <ShowData
          label="Top airlines we export with (Min 1 - Max 5)"
          data={expNacTopAirline}
        ></ShowData>
      }
      {
        expNacAgreementAirline &&
        <ShowData
          label="Top 3 airlines where we hold a BSA or CPA agreement (Min 1 - Max 3)"
          data={expNacAgreementAirline}
        ></ShowData>
      }
      {
        airImportAirport &&
        <ShowData
          label="Top 5 airports we import from"
          data={airImportAirport}
        ></ShowData>
      }
      {
        expNacDestAirport &&
        <ShowData
          label="Top destinations we export to (Min 1 - Max 5)"
          data={expNacDestAirport}
        ></ShowData>
      }

    </>
  );

  const rightCol = (
    <>
      {
        neutralAirConsolidatorsList &&
        <ShowData
          label="Neutral air consolidator services our company is specialized in"
          data={neutralAirConsolidatorsList}
        ></ShowData>
      }
      {
        retail && wholesale &&
        <div className="section">
          <p
            className="text-secondary"
            style={{ marginBottom: 3, marginTop: 14 }}
          >
            Percentage of our commercial retail and neutral master loading
            wholesale business
        </p>
          <Badge>
            {retail}% Retail, {wholesale}% Wholesale
        </Badge>
        </div>
      }
      {
        isOperatingExclusively &&
        <ShowData
          label="Operates exclusively as a neutral freight wholesaler to the local freight forwarding market?"
          data={isOperatingExclusively}
        ></ShowData>
      }
      {
        isConductingBsaCpaContracts &&
        <ShowData
          label="Conducting BSA CPA contracts with carriers?"
          data={isConductingBsaCpaContracts}
        ></ShowData>
      }
      {
        isOperatingRegularlyScheduled &&
        <ShowData
          label="Operating regularly scheduled consolidations?"
          data={isOperatingRegularlyScheduled}
        ></ShowData>
      }
      {
        isAgentDealingDirectly &&
        <ShowData
          label="commercial retail freight agent dealing directly with shippers or consignees?"
          data={isAgentDealingDirectly}
        ></ShowData>
      }
      {isAgentDealingDirectly && isAgentDealingDirectly ? (
        <ShowData
          label="Separate division or a company of your group handling exclusively neutral wholesale freight business?"
          data={`Yes (${divisionOrCompanyName})`}
        ></ShowData>
      ) : null}
    </>
  );

  if (air && !neutral) {
    return leftCol;
  }

  return (
    <Row gutter={16}>
      <Col span={12}>{leftCol}</Col>

      <Col span={12}>{rightCol}</Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  // ID for actions
  companyId: state.company.data.id,
  // Annual gross airfreight revenue (US$)* --------------------------------------------------------------
  nacAirlineRevenue: state.company.data.companyData[0].nacAirlineRevenue,
  // Name your airport branches*
  nacOriginAirports: state.company.data.nacOriginAirports.map(item => ({
    location: item
  })),
  // Please select one or more of the following air cargo sales agent services your company is specialized*
  airCargoSalesAgent: state.company.data.airCargoSalesAgent.map(item => ({
    item: item.airCargoSalesAgent
  })),
  // Name your top destinations you export to (Min 1 - Max 5)* ]
  expNacDestAirport: state.company.data.expNacDestAirport.map(item => ({
    tonsPerYear: item.destinationAirportTonsPerYear,
    location: item.airport
  })),
  // Name your top airlines you export with (Min 1 - Max 5)* ]
  expNacTopAirline: state.company.data.expNacTopAirline.map(item => ({
    tonsPerYear: item.topAirlineTonsPerYear,
    location: item.airline
  })),
  //Name your top 3 airlines where you hold a BSA (Blocked space) or CPA (Capacity purchase) agreement (Min 1 - Max 3)
  expNacAgreementAirline: state.company.data.expNacAgreementAirline.map(
    item => ({
      tonsPerYear: item.agreementTonsPerYear,
      location: item.airline
    })
  ),
  // Name your top 5 airports you import from*
  airImportAirport: state.company.data.airImportAirport.map(item => ({
    location: item.airport
  })),
  // Name your Top 5 countries you export to *
  airExportCountry: state.company.data.airExportCountry.map(item => ({
    location: item.country
  })),
  // Name your Top 5 countries you import from *
  airImportCountry: state.company.data.airImportCountry.map(item => ({
    location: item.country
  })),
  // Neutral Air Consolidator -------------------------------------------
  // Please select one or more of the following neutral air consolidator services your company is specialized*
  neutralAirConsolidators: state.company.data.neutralAirConsolidators.map(
    a => a.id
  ),
  // What is the percentage of your commercial retail business and your neutral master loading wholesale business?
  retail: Number(state.company.data.companyData[0].retail),
  wholesale: Number(state.company.data.companyData[0].wholesale),
  // Is your company operating exclusively as a neutral freight wholesaler to the local freight forwarding market?
  isOperatingExclusively:
    state.company.data.companyData[0].isOperatingExclusively,
  // Is your company conducting BSA CPA contracts with carriers?
  isConductingBsaCpaContracts:
    state.company.data.companyData[0].isConductingBsaCpaContracts,
  // Is your company operating regularly scheduled consolidations?
  isOperatingRegularlyScheduled:
    state.company.data.companyData[0].isOperatingRegularlyScheduled,
  // Is your company a commercial retail freight agent dealing directly with shippers or consignees?
  isAgentDealingDirectly:
    state.company.data.companyData[0].isAgentDealingDirectly,
  // do you have a separate division or a company of your group handling exclusively neutral wholesale freight business?
  isSeparateDivision: state.company.data.companyData[0].isSeparateDivision,
  // please name your division or company name of the group (MISSING!)
  divisionOrCompanyName: state.company.data.companyData[0].divisionOrCompanyName
});
export default connect(mapStateToProps, {})(AirCargoNeutral);
