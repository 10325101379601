import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompany } from "actions/companyActions";
import { expertiseMaping } from "utils/functions";
import ShowData from "./ShowData";
import { Row, Col } from "antd";

class ExpertiseItem extends Component {
  render() {
    const { expertiseId, data } = this.props;

    let companyData;

    const col1 = [],
      col2 = [];

    expertiseMaping(data, expertiseId).sections.forEach((section, i) => {
      if (i % 2 === 0) {
        if (section.data) {
          col1.push(
            <ShowData
              key={i}
              data={section.data}
              label={section.label}
            ></ShowData>
          )
        }
      } else {
        if (section.data) {
          col2.push(
            <ShowData
              key={i}
              data={section.data}
              label={section.label}
            ></ShowData>
          );
        }
      }
    });

    companyData = (
      <Row gutter={16}>
        <Col span={12}>{col1}</Col>
        <Col span={12}>{col2}</Col>
      </Row>
    );

    return <>{companyData}</>;
  }
}

const mapStateToProps = state => ({
  data: state.company.data
});
export default connect(mapStateToProps, { getCompany })(ExpertiseItem);
