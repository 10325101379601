import React from "react";
import { findAvatar } from "utils/functions";
import userIcon from 'theme/assets/dummyIcon.svg';

const Avatar = ({ avatar, name }) => {
  const activeAvatar = findAvatar(avatar);

  const placeholder = (
    <div className="avatar avatar-placeholder">{name[0]}</div>
  );

  if (activeAvatar) {
    if (activeAvatar.path[activeAvatar.path.length - 1] === "/") {
      return placeholder;
    }
    return <img src={activeAvatar.path || userIcon} alt={name} className="avatar"></img>;
  }
  return placeholder;
};

export default Avatar;
