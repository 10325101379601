import React from "react";
import Badge from "components/ui-elements/Badge";

const findLocationLabel = item => {
  if (typeof item === "string" || item instanceof String) {
    return item;
  }

  if (item) {
    if (item.hasOwnProperty("iata")) {
      return item.iata;
    }

    if (item.hasOwnProperty("countryname")) {
      return item.countryname;
    }

    return "/";
  }

  return "/";
};
const ListExpertise = ({ data, label }) => {
  let content;
  if (!data) {
    content = "/";
  }
  if (typeof data === "string" || data instanceof String) {
    content = <Badge>{data}</Badge>;
  }

  if (typeof data === "boolean") {
    content = data ? <Badge>Yes</Badge> : <Badge>No</Badge>;
  }

  if (Array.isArray(data)) {
    if (data.length === 0) {
      return null;
    }

    content = data.map((item, i) => {
      if (item.hasOwnProperty("tonsPerYear")) {
        return (
          <Badge key={i} customClass="badge-stats">
            {findLocationLabel(item.location)}
            <p className="badge-count">{item.tonsPerYear || 0}</p>
            <span>tons / year</span>
          </Badge>
        );
      }
      if (item.hasOwnProperty("awbPerYear")) {
        return (
          <Badge key={i} customClass="badge-stats">
            {findLocationLabel(item.location)}
            <p className="badge-count">{item.awbPerYear || 0}</p>
            <span>AWB / year</span>
          </Badge>
        );
      }

      if (item.hasOwnProperty("item")) {
        return <Badge key={i}>{item.item}</Badge>;
      }

      // Regular badge with label (proprety name vary)
      return <Badge key={i}>{findLocationLabel(item.location)}</Badge>;
    });
  }

  return (
    <div className="section">
      <p className="text-secondary" style={{ marginBottom: 3, marginTop: 14 }}>
        {label}
      </p>
      {content}
    </div>
  );
};

export default ListExpertise;
