import { GLOBAL_ERROR, CLEAR_GLOBAL_ERROR } from "../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_ERROR: {
      return [...state, action.payload.response];
    }

    case CLEAR_GLOBAL_ERROR:
      return [];
    default:
      return state;
  }
}
