/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { inviteMember } from "actions/dataActions";
import { Form, Row, Col, Input, Radio, Button, Select, message, Checkbox } from "antd";
import { TitleForm } from "components/ui-elements/Typography";
import CustomSelect from "components/ui-elements/Select";
import { fetchCities, getCountries, fetchCountries, getSubGroupList } from "actions/dataActions";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
const { Option } = Select;
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddMember extends Component {
  state = {
    classification: "Air Partner",
    loading: false,
    selectedCheckboxes: [],
  };
  handleCheckboxChange = (checkedValues) => {
    if (checkedValues.length > 3) {
      return; // Disable further checkbox selection if more than three checkboxes are selected
    }

    this.setState({
      selectedCheckboxes: checkedValues,
    });
  };
  renderCheckboxes = () => {
    const { selectedCheckboxes, subGroup } = this.state;
    // const { subGroup } = this.props;

    if (!subGroup?.length) {
      return <SkeletonLoading itemsPerPage={1} />;
    }

    return (
      <Checkbox.Group
        buttonStyle="solid"
        size="small"
        name="SpecialityGroups"
        onChange={this.handleCheckboxChange}
        value={selectedCheckboxes}
      // disabled={selectedCheckboxes.length > 2} // Disable if more than three checkboxes are selected
      >
        {subGroup.map((element, index) => (
          <Checkbox
            disabled={selectedCheckboxes.length >= 3 && (!selectedCheckboxes.includes(element.id))}
            value={element.id} key={`subGroup${index}`}>
            {element.industryLogo ? (
              <div className='logoAndBadge'><img src={element.industryLogo} alt="Logo" /></div>
            ) : (
              "None"
            )}
          </Checkbox>
        ))}
      </Checkbox.Group>
    );
  };
  isObject(value) {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    );
  }

  handleCountryCodeSelect = (value) => {
    if (this.isObject(value)) {
      this.setState({ dialCode: value.dialCode });
    } else {
      this.setState({ dialCode: value });
    }
  }
  subGroupList = async () => {
    const { getSubGroupList } = this.props;
    let SubGroupList = await getSubGroupList();

    if (SubGroupList) {
      this.setState({ subGroup: SubGroupList });
    }
  };
  // getCountryList = async () => {
  //   const { getCountries } = this.props;
  //   let CountryLists = await getCountries();

  //   if (CountryLists) {
  //     this.setState({ countryList: CountryLists });
  //   }
  // };

  async componentDidMount() {
    this.subGroupList();
    // this.getCountryList();
  }

  /*   findExpertiseName = () => {
      if (this.state.classification === "Air Partner") {
        return "airIndustryExpertise";
      }
      if (this.state.classification === "Associate") {
        return "associateIndustryExpertise";
      }
      return "vendorIndustryExpertise";
    }; */

  handleClassificationSelect = e => {
    this.setState({
      classification: e.target.value
    });

    this.props.form.setFields({
      expertises: {
        value: []
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      if (!err) {
        this.setState({
          loading: true
        });

        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          companyName: values.companyName,
          subIndustries: values.subIndustries,
          classification: "Air Partner",
          country: values.country.id,
          city: values.city.id,
          dialCode: this.state.dialCode,
          companyFunction: values.companyFunction,
          telephone: values.telephone
          // industryExpertise: values.expertises.map(a => a.id)
        };

        if (values.branches !== undefined) {
          data.branches = values.branches.map(a => a.id)
        } else {
          data.branches = values.branches
        }

        this.props
          .inviteMember(data)
          .then(() => {
            this.setState({ loading: false });
            this.props.closeDrawer();
            message.success("Successfuly invited new member");
          })
          .catch(err => {
            // console.log("err.", err)
            this.setState({ loading: false });
            if (err) {
              message.error(err);
            }
            /* if (err.error === 'User already exists!') {
              this.props.form.setFields({
                email: {
                  value: values.email,
                  errors: [new Error("This email is already taken")]
                }
              });
            } */ else {
              message.error("Something went wrong");
            }
          });
      }
    });
  };
  //   handleChange = async (id) => {

  //     // this.setState({ selectedSubGroupId: id });
  //     // setSelectedSubGroupId(id);
  //     // if (id) {
  //     //     let params = {
  //     //         "subIndustryRels": []
  //     //     }
  //     //     params["subIndustryRels"] = id.map((element) => "/v1/sub_industries/" + element);
  //     //     await dispatch(updateCompany(params, companyId));
  //     // }
  // }


  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { classification, subGroup, dialCode } = this.state;
    const selectedCountry = this.props.form.getFieldValue("country");

    let CountryCodeSelect = (
      <Select showSearch style={{ width: 90 }}
        value={dialCode}
        onChange={this.handleCountryCodeSelect} size="large">
        {this.props.country.map((item, index) => {
          return (
            <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
      </Select>
    );
    return (
      <Form
        onSubmit={this.handleSubmit}
        layout="vertical"
        style={{ padding: "0 10%" }}
      >
        <h2 className="drawer-form-title">Add new member</h2>

        <TitleForm variant="subtitle">Company information</TitleForm>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label="First name">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: true,
                    message: "Please input first name",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Company function">
              {getFieldDecorator("companyFunction", {
                rules: [
                  {
                    required: true,
                    message: "Please input Company function",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Country">
              {getFieldDecorator("country", {
                rules: [
                  {
                    required: true,
                    type: "object",
                    message: "Please choose country"
                  }
                ]
              })(
                <CustomSelect
                  onChange={this.handleCountryCodeSelect}
                  async={true}
                  isClearable={true}
                  placeholder={"Select country"}
                  cacheOptions
                  loadOptions={(input, callback) =>
                    fetchCountries(input, callback)
                  }
                  noOptionsMessage={input => "Type to search"}
                  getOptionLabel={option => option.countryname}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>

            <Form.Item label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail"
                  },
                  {
                    required: true,
                    message: "Please input E-mail"
                  }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Company name">
              {getFieldDecorator("companyName", {
                rules: [
                  {
                    required: true,
                    message: "Please input company name"
                  }
                ]
              })(<Input />)}
            </Form.Item>

          </Col>

          <Col span={12}>
            <Form.Item label="Last name">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "Please input last name",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input password"
                  },
                  {
                    min: 6,
                    message: "Minimum 6 characters!"
                  }
                ]
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="City">
              {getFieldDecorator("city", {
                rules: [
                  {
                    required: true,
                    type: "object",
                    message: "Please choose city"
                  }
                ]
              })(
                <CustomSelect
                  async={true}
                  isClearable={true}
                  isDisabled={!selectedCountry}
                  placeholder={
                    selectedCountry ? "Select city" : "Select country to enable"
                  }
                  cacheOptions
                  loadOptions={(input, cb) =>
                    fetchCities(input, selectedCountry.id, cb)
                  }
                  noOptionsMessage={input => "Type to search"}
                  getOptionLabel={option => option.cityAsciiname}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>
            <Form.Item label="Telephone">
              {getFieldDecorator("telephone", {
                rules: [
                  {
                    required: true,
                    message: "Please input telephone",
                    whitespace: true
                  }
                ]
              })(<Input addonBefore={CountryCodeSelect} placeholder="Enter phone number" />)}
            </Form.Item>
            <Form.Item label="Branches">
              {getFieldDecorator(
                "branches",
                {}
              )(
                <CustomSelect
                  async={true}
                  isMulti={true}
                  isDisabled={!selectedCountry}
                  placeholder={
                    selectedCountry ? "Select branches" : "Select country to enable"
                  }
                  cacheOptions
                  loadOptions={(input, cb) =>
                    fetchCities(input, selectedCountry.id, cb)
                  }
                  noOptionsMessage={input => "Type to search"}
                  getOptionLabel={option => option.cityAsciiname}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>


          </Col>
        </Row>

        <TitleForm variant="subtitle">Company Settings</TitleForm>

        <Form.Item label="Network" className="imagesAndCheckboxes">
          {getFieldDecorator("subIndustries", {
            // initialValue: classification,
            rules: [
              {
                required: true,
                message: "Please choose company network"
              }
            ]
          })(this.renderCheckboxes())}
        </Form.Item>

        {/* <Form.Item label="Classification">
          {getFieldDecorator("classification", {
            initialValue: classification,
            rules: [
              {
                required: true,
                message: "Please choose company classification"
              }
            ]
          })(
            <Radio.Group onChange={this.handleClassificationSelect}>
 
              <Radio.Button value="Air Partner">Air Partner</Radio.Button>
              <Radio.Button value="Associate">Associate</Radio.Button>
              <Radio.Button value="Vendor">Vendor</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item> */}

        {/* <Form.Item label="Expertise">
          {getFieldDecorator("expertises", {
            rules: [
              {
                type: "array",
                required: true,
                message: "Please choose atleast 1 expertise"
              }
            ]
          })(
            <CustomSelect
              placeholder={`${classification} expertises`}
              isMulti={true}
              name={this.findExpertiseName()}
              isSearchable={false}
              options={this.props[this.findExpertiseName()]}
              getOptionLabel={option => option.industryExpertise}
              getOptionValue={option => option.id}
            ></CustomSelect>
          )}
        </Form.Item> */}

        <div style={{ textAlign: "right", marginTop: 15 }}>
          <Button
            className="cancel-btn"
            type="link"
            style={{ marginRight: 8 }}
            htmlType="button"
            onClick={this.props.closeDrawer}
          >
            Cancel
          </Button>
          <Button
            className="create-btn"
            type="primary"
            size="large"
            htmlType="submit"
            loading={this.state.loading}
            disabled={hasErrors(getFieldsError())}
          >
            Save company
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedAddMember = Form.create({ name: "addMember" })(AddMember);

const mapStateToProps = state => ({
  // airIndustryExpertise: state.data.airIndustryExpertise,
  // associateIndustryExpertise: state.data.associateIndustryExpertise,
  // vendorIndustryExpertise: state.data.vendorIndustryExpertise,
  country: state.data.countries,

});

export default connect(mapStateToProps, { inviteMember, getSubGroupList, getCountries })(WrappedAddMember);
