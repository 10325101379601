import React from "react";
import tradelaneIcon2 from 'theme/assets/tradelaneIcon2.svg';

const TopCountriesAndTradelanes = (props) => {
    const { title, data } = props;
  
    return (
        <div>
            <div style={{ display: 'flex' }}>
            {title ==='Top tradelanes' &&   <span className="accrediation-icon">
                  <img src={tradelaneIcon2} className="tradelane-icon" />
                </span>
               /*  : <span className="accrediation-icon-no-border"></span> */}
                <div>
                    <h3 className="accrediation-title">{title}</h3>
                    {data?.map((item) => (
                        <React.Fragment key={item.id}>
                            <span className="country-airline">
                                {title === 'Top countries' ? item.country.countryname : item?.specialization}
                            </span>
                            <br />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TopCountriesAndTradelanes