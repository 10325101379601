import { form1Submited, form2Submited, getCountriesList, getCountryCities, getIndustryExpertiseList, getSpecializationList, submitForm1, submitForm2 } from "actions/commonActions";
import { getCompany, getCompanyInfo } from "actions/companyActions";
import { getCertificates } from "actions/dataActions";
import { Button, Form, LocaleProvider } from "antd";
import FormWithNavigation from "components/ui-elements/FormWithNavigation";
import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import logo from "theme/assets/logo.svg";
import CompanyInfo from "./CompanyInfo";
import Expertise from './ExpertiseInfo';
import FinalInfo from "./FinalInfo";
import jwt_decode from "jwt-decode";
import { clearCurrentProfile } from "actions/profileActions";
import { logoutUser } from "actions/authActions";

const Register = (props) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [countries, setCountries] = useState([]);
  const [bankCities, setBankCitites] = useState([]);
  const [profileName, setProfileName] = useState('');

  const { getCompanyDetails, data, auth, getCountries, getCertificatesList, submitForm1Action, submitForm2Action, form1Submitted, form1SubmittedAction, form2SubmittedAction, form2Submited, logoutUserAction, common } = props;


  useEffect(() => {
    if (localStorage.jwtToken) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const { firstName, lastName } = decoded;
      setProfileName(firstName + " " + lastName);
      getCountryList();
      getCertificatesList();
      getCompanyId();
    }
    else {
      props.history.push("/login");
    }
  }, []);

  useEffect(() => {  
    if (form1Submitted) {
      setActiveIndex(2);
    }
  }, [form1Submitted])

  useEffect(() => {
    if (form2Submited) {
      // setActiveIndex(3);
      // props.history.push("/network");
    }
  }, [form2Submited])

  const onClickLogout = async () => {
    await localStorage.removeItem("status");
    await localStorage.removeItem("companyInfo");
    await localStorage.removeItem("jwtToken");
    logoutUserAction();
    props.history.push("/login");
  };

  const getCompanyId = async () => {
    if (auth.isAuthenticated) {
      const companyDetails = JSON.parse(localStorage.companyInfo);
      let companyId = companyDetails.company;
      await getCompanyDetails(companyId);
    }
    else {
      props.history.push("/login");
    }
  }

  // useEffect(() => {
  //   if (data) {
  //     setCompanyDetails(data);
  //   }
  // }, [data])

  const getCountryList = async () => {
    const response = await getCountries();
    if (response) {
      setCountries(response["hydra:member"]);
    }
  }

  const getBankCities = async (value) => {
    const response = await getCountryCities(value);
    if (response) {
      setBankCitites(response["hydra:member"])
    }
  }

  const tabs = [
    {
      name: 'Company Info',
      tabIndex: 1,
      component: () => {
        return (
          <CompanyInfo {...props} />
        )
      }
    },
    {
      name: 'Expertise',
      tabIndex: 2,
      component: () => <Expertise {...props} />
    },
    {
      name: 'Done',
      tabIndex: 3,
      component: () => <FinalInfo />
    }
  ]

  const switchTab = (index) => {
    if (index === 2) {
      submitForm1Action(true);
    }
    if (index === 3) {
      submitForm2Action(true);
    }
    // setActiveIndex(index);
  }

  const switchTabBack = (index) => {
    if (index === 1) {
      submitForm1Action(false);
      form1SubmittedAction(false);
    }
    if (index === 2) {
      submitForm2Action(false);
      form2SubmittedAction(false);
    }

    setActiveIndex(index);
  }

  const register = () => {
  }

  return (
    <div className="customContainer noPaddingTop">
      <div className="signUpHeader">
        <img src={common?.logo} alt="logo" className="loginLogo" />
        <div className="nameSection">
          <span className="profileName">
            Welcome {profileName}
          </span>
          <Button className="add-branch-btn" type="link" onClick={() => onClickLogout()}>
            Sign Out
          </Button>
        </div>
      </div>
      <div className="signUpFormSection">
        {/* <Form> */}
        <FormWithNavigation
          activeIndex={activeIndex}
          tabs={tabs}
          onTabSwitch={(index) => switchTab(index)}
          onBackClick={(index) => switchTabBack(index)}
          onSubmit={() => register()} {...props}
          countryList={countries}
          bankCityList={bankCities}
          onChangeBankCountryCallBack={(value) => getBankCities(value)}
        />
        {/* </Form> */}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  data: state.company.data,
  common: state.common.domain,
  loading: state.company.loading,
  form1Submitted: state.common.form1Submited,
  form2Submited: state.common.form2Submited
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => getCountriesList(dispatch),
    getCompanyDetails: (id) => dispatch(getCompany(id)),
    getCertificatesList: () => dispatch(getCertificates()),
    submitForm1Action: (value) => submitForm1(dispatch, value),
    submitForm2Action: (value) => submitForm2(dispatch, value),
    form1SubmittedAction: (value) => form1Submited(dispatch, value),
    form2SubmittedAction: (value) => form2Submited(dispatch, value),
    clearCurrentProfileAction: () => clearCurrentProfile(),
    logoutUserAction: () => dispatch(logoutUser())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);