import React, { Component } from "react";
import { variables } from "../../utils/settings";
import styled from "@emotion/styled";
import Icon from "./Icon";
import Tab from "./Tab";

const ListTabs = styled.ul(
  {
    paddingLeft: 0,
    listStyle: "none",
    margin: 0
  },
  props => {
    return (
      props.vertical && {
        width: "230px",
        position: "sticky",
        alignSelf: "flex-start",
        top: "15px"
      }
    );
  }
);

const TabTitleItem = styled("li")`
  display: ${props => (props.vertical ? "block" : "inline-block")};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: ${props => (props.vertical ? "13px 30px 13px 0" : "16px 30px")};
  padding: ${props => props.small && "5px 5px 10px 5px"};
  margin: ${props => props.vertical && "3px 0"};
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  opacity: ${props => (props.isActiveTab ? "1" : "0.7")};
  background-color: ${props =>
    props.isActiveTab && props.vertical && "rgba(79,182,182, .1)"};
  margin-left: ${props => props.isActiveTab && props.vertical && "-20px"};
  padding-left: ${props => props.isActiveTab && props.vertical && "20px"};
  a {
    color: ${variables.colors.text};
    color: ${props =>
      props.isActiveTab && props.vertical && variables.colors.primary};
    font-weight: ${props =>
      props.vertical && !props.isActiveTab ? "400" : "600"};
  }
`;

const TabIcon = styled(Icon)`
  font-size: 1.5em;
  margin-right: 0.7em;
`;
const ActiveTabBorder = styled("div")`
  background-color: ${variables.colors.primary};
  position: absolute;
  bottom: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  will-change: left, width;
  width: ${props => {
    if (props.activeTabElement && !props.vertical) {
      return props.activeTabElement.offsetWidth;
    }
    return "4";
  }}px;
  left: ${props => {
    if (props.activeTabElement && !props.vertical) {
      return props.activeTabElement && props.activeTabElement.offsetLeft;
    }
    return "0";
  }}px;
  height: 4px;
`;

const TabAnchorItem = styled("a")`
  text-transform: capitalize;
`;

const TabsContainer = styled("div")`
  position: relative;
  border-bottom: ${props => (props.vertical ? "none" : " 1px solid #ccc")};
  margin-bottom: 31px;
  margin-right: ${props => props.vertical && "30px"};
`;

const ReactTabs = styled("div")`
  position: relative;
  display: ${props => props.vertical && "flex"};
  align-items: ${props => props.vertical && "flex-start"};
`;

const TabContent = styled("div")`
  flex-grow: ${props => props.vertical && "2"};
`;

class Tabs extends Component {
  static Tab = Tab;

  state = {
    tabs: [],
    prevActiveTab: {},
    activeTab: this.props.activeTab,
    tabsElements: []
  };

  addTab = newTab => {
    let isNewTabFound;

    for (let i in this.state.tabs) {
      let tab = this.state.tabs[i];

      if (tab.id === newTab.id) {
        isNewTabFound = true;
        break;
      }
    }

    if (!isNewTabFound) {
      this.setState((prevState, props) => {
        return {
          tabs: prevState.tabs.concat(newTab)
        };
      });
    }
  };

  removeTab = tabId => {
    this.setState((prevState, props) => {
      return {
        tabs: prevState.tabs.filter(tab => tab.id !== tabId)
      };
    });
  };

  onClick = tab => event => {
    this.setState((prevState, props) => {
      return {
        prevActiveTab: prevState.activeTab,
        activeTab: tab
      };
    });

    this.props.onChange && this.props.onChange(tab);
  };

  render() {
    return (
      <ReactTabs vertical={this.props.vertical}>
        <TabsContainer vertical={this.props.vertical}>
          <ListTabs vertical={this.props.vertical}>
            {this.state.tabs.map((tab, index) => (
              <TabTitleItem
              className="list-item"
                key={index}
                onClick={this.onClick(tab)}
                id={tab.id}
                ref={tabElement => {
                  if (!this.state.tabsElements[tab.id]) {
                    this.setState((prevState, props) => {
                      const tabsElements = prevState.tabsElements;
                      tabsElements[tab.id] = tabElement;

                      return {
                        tabsElements
                      };
                    });
                  }
                }}
                isActiveTab={this.state.activeTab.id === tab.id}
                vertical={this.props.vertical}
                small={this.props.small}
              >
                <TabAnchorItem vertical={this.props.vertical} className="my-tab">
                  {tab.icon && <TabIcon name={tab.icon} />}
                  {tab.title}
                </TabAnchorItem>
              </TabTitleItem>
            ))}
          </ListTabs>

          {this.props.vertical ? null : (
            <ActiveTabBorder
              activeTabElement={
                this.state.tabsElements[this.state.activeTab.id]
              }
              vertical={this.props.vertical}
            />
          )}
        </TabsContainer>

        <TabContent className="fade-in" vertical={this.props.vertical}>
          {React.Children.map(this.props.children, child => {
            if (child === null) {
              return;
            }
            return React.cloneElement(child, {
              activeTab: this.state.activeTab,
              addTab: this.addTab
            });
          })}
        </TabContent>
      </ReactTabs>
    );
  }
}

export default Tabs;
