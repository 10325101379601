import React from "react";
import { connect } from "react-redux";
import Dropzone from "components/ui-elements/Dropzone";
import FilePreview from "components/ui-elements/FilePreview";
import { generateId } from "utils/functions";
import { uploadDocument } from "actions/uploadActions";
import { api_v } from "utils/settings";
import {
  deleteDocument,
} from "actions/companyActions";

const VendorDirectory = props => {
  const { companyId, documents } = props;
  // Handle Dropzone
  const uploadFiles = files => {
    let data;

    files.forEach(file => {
      // Data for Upload
      data = new FormData();
      data.append("file", file);
      data.append("source", "directory");

      // Data for Preview and progress
      const previewData = {
        id: generateId(),
        progress: 0,
        size: file.size,
        text: file.name.replace(/\.[^/.]+$/, ""),
        type: file.type
      };

      // Must include all prev documents
      const prevDocuments = props.documents.map(
        prevDoc => `/${api_v}documents/${prevDoc.id}`
      );
      // API request
      props.uploadDocument(data, previewData, prevDocuments, companyId);
    });
  };

  // Delete File
  const deleteDocument = id => {
    return props.deleteDocument(id);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>Documents</h4>
      </div>

      <div className="card-body">
        {documents.map(document => (
          <FilePreview
            key={document.id}
            id={document.id}
            onDelete={deleteDocument}
            name={document.originalName}
            link={document.path}
          />
        ))}
        <Dropzone handleDrop={uploadFiles} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  companyId: state.company.data.id,
  documents: state.company.data.document
});

export default connect(mapStateToProps, { uploadDocument ,deleteDocument})(VendorDirectory);
