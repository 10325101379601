import React, { Component } from "react";
import { Input, Radio, Button, Drawer } from "antd";
import Logo from "../css/logo.png";
import { Row, Col } from "antd";
import Iframe from "react-iframe";

const { Search } = Input;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { showDrawer: false };
  }

  closeDrawer = () => {
    this.setState({ showDrawer: false });
  };
  render() {
    const { showDrawer } = this.state;
    return (
      <>
        <aside className="insurance-sidebar">
          <div className="insurance-header">
            <img className="insurance-logo" src={Logo} alt="BigCo Inc. logo" />
            <p className="insurance-side-paragraph">
              Welcome to Cargo Insurance Quote and Bind Platform, Which is
              powered by IMT Group AB in partnership with its Insurance
              Partners, facilitated on the NAP platform
            </p>
            <button
              className="insurance-anchor"
              onClick={() => {
                this.setState({ showDrawer: true });
              }}
            >
              HELP GUIDE
            </button>
            <Drawer
              width={800}
              destroyOnClose={true}
              closable={false}
              onClose={() => this.closeDrawer()}
              visible={showDrawer}
              className="iframe-outer"
            >
              <Iframe
                url="https://neutralairpartner.com/imt-guide/"
                width="750px"
                height="750px"
                id="imtGuideIFrame"
                display="initial"
                position="relative"
              />
            </Drawer>
          </div>
          {/* <div className="insurance-bottom">
                    <div className="note-edit">
                      <p className="i-note">NOTE</p>
                      <Row>
                        <Col className="insurance-note-paragraph" span={16}>
                          <p className="insurance-note-paragraph">Following details will be used on shipment</p>
                        </Col>
                        <Col span={8}>
                          <button className="i-button"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</button>
                        </Col>
                      </Row>
                     
                     
                    </div>
                    <div className="insurance-footer">
                        <p className="footer-name">Panda Logistics Co.,Ltd.</p>
                        <p className="footer-Caddress">Room 301,3F,Huaya Building C2 District,Huaya Industrail Park,jihua Road, Guangdong,china</p>
                        <p className="insurance-tele">
                          <span className="i-tele">t.</span> 
                            <a href="">+86 – 755 – 6685568</a>
                          </p>
                        <p className="insurance-tele mar-top">
                          <span className="i-tele">e.</span>
                            <a href="">joann@pandalog.com</a>
                          </p>
                    </div>
               </div> */}
        </aside>
      </>
    );
  }
}

export default Sidebar;
