import React from "react";
import { connect } from "react-redux";
import { Table, Input, Popconfirm, Button, Icon, Form } from "antd";
import { updateCompany } from "actions/companyActions";
import { generateId } from "utils/functions";
import CustomSelect from "components/ui-elements/Select";
import NumberFormat from "react-number-format";
import { fetchAirlines, fetchCountries, fetchAirports } from "actions/dataActions";
import { Label } from "components/ui-elements/Typography";

const EditableContext = React.createContext();

const formatAirlineOption = ({ airlineName, iata, country }, { context }) => {
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airlineName} ({iata})
        </div>
        <span className="text-secondary">{country}</span>
      </div>
    );
  }
  return <div>{iata}</div>;
};

const formatAirportOption = ({ airportName, iata, country }, { context }) => {
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airportName} ({iata})
        </div>
        <span className="text-secondary">{country}</span>
      </div>
    );
  }
  return <div>{iata}</div>;
};

class EditableCell extends React.Component {
  renderCell = ({ getFieldDecorator }) => {
    const findFormElement = type => {
      switch (type) {
        case "input":
          return (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],
                initialValue: record[dataIndex]
              })(<Input placeholder={`Enter ${this.props.title}`} />)}
            </Form.Item>
          );

        case "input-number":
          return (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],
                initialValue: record[dataIndex]
              })(
                <NumberFormat
                  customInput={Input}
                  placeholder={`Enter ${this.props.title}`}
                  thousandSeparator={true}
                  style={{ maxWidth: 300 }}
                  minLength={2}
                  maxLength={6}
                ></NumberFormat>
              )}
            </Form.Item>
          );

        case "async-select-countries":
          return (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],

                initialValue: record[dataIndex]
              })(
                <CustomSelect
                  async={true}
                  cacheOptions
                  loadOptions={(input, callback) =>
                    fetchCountries(input, callback)
                  }
                  noOptionsMessage={input =>
                    "Please enter 2 or more characters"
                  }
                  getOptionLabel={option => option.countryname}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>
          );

        case "async-select":
          return (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],

                initialValue: record[dataIndex]
              })(
                <CustomSelect
                  async={true}
                  cacheOptions
                  loadOptions={(input, callback) =>
                    fetchAirlines(input, callback)
                  }
                  noOptionsMessage={input =>
                    "Please enter 2 or more characters"
                  }
                  formatOptionLabel={formatAirlineOption}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>
          );
        
        case "async-select-airports":
          return (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],

                initialValue: record[dataIndex]
              })(
                <CustomSelect
                  async={true}
                  cacheOptions
                  loadOptions={(input, callback) =>
                    fetchAirports(input, callback)
                  }
                  noOptionsMessage={input =>
                    "Please enter 2 or more characters"
                  }
                  formatOptionLabel={formatAirportOption}
                  getOptionValue={option => option.id}
                ></CustomSelect>
              )}
            </Form.Item>
          );
      }
    };

    const {
      editing,
      dataIndex,
      isNew,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing || isNew ? findFormElement(inputType) : children}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newItems: [], editingKey: "", loading: {}, deleting: {} };
    this.columns = [
      ...props.columns,
      {
        title: "Operation",
        align: "right",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <>
              {record.isNew ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <Button
                        type="primary"
                        loading={this.state.loading[`${record.id}`]}
                        onClick={() => this.createNew(form, record.id, record)}
                      >
                        Create
                      </Button>
                    )}
                  </EditableContext.Consumer>
                  <Button type="link" onClick={() => this.removeNew(record.id)}>
                    Cancel
                  </Button>
                </span>
              ) : (
                <span>
                  {editable ? (
                    <span>
                      <Button
                        type="link"
                        onClick={() => this.cancel(record.id)}
                      >
                        Cancel
                      </Button>
                      <EditableContext.Consumer>
                        {form => (
                          <Button
                            type="primary"
                            loading={this.state.loading[`${record.id}`]}
                            onClick={() => this.save(form, record.id, record)}
                          >
                            Save
                          </Button>
                        )}
                      </EditableContext.Consumer>
                    </span>
                  ) : (
                    <span>
                      <Button
                        disabled={editingKey !== ""}
                        type="link"
                        shape="circle"
                        className="btn-edit"
                        onClick={() => this.edit(record.id)}
                      >
                        <Icon type="edit" theme="filled"></Icon>
                      </Button>

                      <Popconfirm
                        title="Sure to delete?"
                        placement="topRight"
                        onConfirm={() => this.delete(record.id)}
                      >
                        <Button
                          disabled={editingKey !== ""}
                          className="btn-edit"
                          loading={this.state.deleting[`${record.id}`]}
                          shape="circle"
                          type="link"
                        >
                          <Icon type="delete" theme="filled"></Icon>
                        </Button>
                      </Popconfirm>
                    </span>
                  )}
                </span>
              )}
            </>
          );
        }
      }
    ];
  }

  isEditing = record => record.id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  add = () => {
    const id = generateId();

    this.setState({
      newItems: [
        ...this.state.newItems,
        { id, isNew: true, ...this.props.skeleton }
      ]
    });
  };

  removeNew = id => {
    this.setState({
      newItems: this.state.newItems.filter(item => item.id !== id)
    });
  };

  // CREATE NEW
  createNew = (form, id, record) => {
    form.validateFields(async (error, row) => {
      if (error) {
        return;
      }
      // Set loading for ID
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true }
      });

      await this.props.createItem(row, id, this.props.name).then(() => {
        this.setState(prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [`${id}`]: false
          },
          newItems: prevState.newItems.filter(newItem => newItem.id !== id)
        }));
      });
    });
  };

  // DELETE
  delete = async id => {
    this.setState({
      deleting: { ...this.state.deleting, [`${id}`]: true }
    });
    await this.props.deleteItem(id, this.props.name);
  };

  // UPDATE
  save(form, id, record) {
    form.validateFields(async (error, row) => {
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true }
      });

      await this.props.updateItem(row, id, record, this.props.name);
      // Clean up after
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`${id}`]: false
        },
        editingKey: ""
      }));
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const { items, title } = this.props;

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          isNew: record.isNew,
          inputType: col.type,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Label style={{ margin: "20px 0 10px 0" }}>{title}</Label>

        <Table
          components={components}
          rowKey="id"
          dataSource={items.concat(this.state.newItems)}
          columns={columns}
          pagination={false}
          rowClassName="editable-row"
        />
        <div className="card-body">
          <Button
            type="dashed"
            disabled={
              items.length + this.state.newItems.length >= this.props.max
            }
            onClick={this.add}
            style={{ width: "40%" }}
          >
            <Icon type="plus" /> Add new
          </Button>
        </div>
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

const mapStateToProps = state => ({
  companyId: state.company.data.id
});

export default connect(mapStateToProps, { updateCompany })(EditableFormTable);
