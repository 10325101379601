import React, { useEffect, useState } from "react";
import { Row, Col, Icon, Tooltip } from "antd";
import { cleanObjectWithId } from 'utils/functions';

const OtherDetails = ({ companyInfo, companyBankInfo }) => {
	console.log("companyBankInfo", companyBankInfo)
	const [companyBankInfoData, setCompanyBankInfoData] = useState([])
	const [companyInfoData, setCompanyInfoData] = useState([])
	const companyBankInfoKeyArray = ['bankName', 'bankCountry', 'bankCityName', 'bankPerson', 'bankEmail', 'bankTel']
	const companyInfoKeyArray = ['employesNumber', 'establishement', 'registeredCapital', 'fiscalTurnover']

	useEffect(() => {
		const cpyCompanyBankInfoData = { ...companyBankInfo[0] };
		console.log("cpyCompanyBankInfoData", cpyCompanyBankInfoData)
		cleanObjectWithId(cpyCompanyBankInfoData)
		let bankInfofield = []
		for (var keyName in cpyCompanyBankInfoData) {
			if (companyBankInfoKeyArray.includes(keyName)) {
				bankInfofield.push({ [keyName]: cpyCompanyBankInfoData[keyName] })
			}
		}

		let finalCompanyBankInfoObj = {};
		for (let i = 0; i < bankInfofield?.length; i++) {
			Object.assign(finalCompanyBankInfoObj, bankInfofield[i]);
		}
		setCompanyBankInfoData(Object.keys(finalCompanyBankInfoObj).length !== 0 ? [finalCompanyBankInfoObj] : [])



		const cpyCompanyInfoData = { ...companyInfo[0] };
		cleanObjectWithId(cpyCompanyInfoData)

		let companyInfofield = []
		for (var keyName in cpyCompanyInfoData) {
			if (companyInfoKeyArray.includes(keyName)) {
				companyInfofield.push({ [keyName]: cpyCompanyInfoData[keyName] })
			}
		}

		let finalCompanyInfoObj = {};
		for (let i = 0; i < companyInfofield?.length; i++) {
			Object.assign(finalCompanyInfoObj, companyInfofield[i]);
		}
		setCompanyInfoData(Object.keys(finalCompanyInfoObj).length !== 0 ? [finalCompanyInfoObj] : [])
	}, [companyBankInfo, companyInfo])

	return (
		<Row gutter={16} className="mt-8">
			{companyInfoData?.length
				?
				companyInfoData?.map((info) =>
					<Col span={8} key={info?.id}>
						<h3>Company Insights</h3>
						{info?.employesNumber ? <><span><strong>Employes number:</strong> {info?.employesNumber}</span>
							<br /></> : ""}
						{info?.establishement ? <><span><strong>Year of est.:</strong> {info?.establishement}</span>
							<br /></> : ""}
						{info?.registeredCapital ? <><span><strong>Registered capital:</strong> {info?.registeredCapital}</span>
							<br /></> : ""}
						{info?.fiscalTurnover ? <span><strong>Last years annual turnover:</strong> {info?.fiscalTurnover}</span> : ""}
					</Col>
				)
				:
				''
			}
			{companyBankInfoData?.length !== 0 ?
				companyBankInfoData?.map((bankInfo) => {
					return (
						<Col span={8} key={bankInfo?.id}>
							<h3><Icon type="bank" className="mr-2" />Bank Information</h3>
							{bankInfo?.bankName ? <><span><strong>Bank Name:</strong> {bankInfo?.bankName}</span>
								<br /></> : ""}
							{bankInfo?.bankCountry?.countryname ? <><span><strong>Bank Country:</strong> {bankInfo?.bankCountry?.countryname}</span>
								<br /></> : ""}
							{bankInfo?.bankCityName ? <><span><strong>Bank City:</strong> {bankInfo?.bankCityName}</span>
								<br /></> : ""}
							{bankInfo?.bankPerson ? <><span><strong>Contact Person:</strong> {bankInfo?.bankPerson}</span>
								<br /></> : ""}
							{bankInfo?.bankEmail ? <><Tooltip title={bankInfo?.bankEmail}><span className="companyEmailParent"><strong>Email:</strong> <span className="companyEmail">{bankInfo?.bankEmail}</span></span>
							</Tooltip></> : ""}
							{bankInfo?.bankTel ? <span><strong>Telephone:</strong> {bankInfo?.bankTel}</span> : ""}
						</Col>
					)
				}
				)
				:
				''}

			{companyInfo?.length !== 0
				?
				companyInfo?.map((info) => {
					return (
						(info?.legalRepresentative || info?.legalRepresentativeEmail)
							?
							<Col span={8} key={info?.id}>
								<h3>Legal representative</h3>
								{info?.legalRepresentative ? <><span><strong>Name:</strong> {info?.legalRepresentative}</span>
									<br /></> : ""}
								{info?.legalRepresentativeEmail ? <><Tooltip title={info?.legalRepresentativeEmail}><span className="companyEmailParent"><strong>Email:</strong><span className="companyEmail"> {info?.legalRepresentativeEmail}</span> </span></Tooltip> </> : ""}
							</Col>
							:
							""
					)
				}
				)
				:
				''}
		</Row>
	);
};

export default OtherDetails;
