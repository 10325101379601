import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class Map extends Component {
	componentDidMount() {
		// Create map instance
		let map = am4core.create("mapdiv", am4maps.MapChart);

		// Set map definition
		map.geodata = am4geodata_worldLow;

		// Set projection
		map.projection = new am4maps.projections.Mercator();

		// Add zoom control
		map.zoomControl = new am4maps.ZoomControl();
		map.chartContainer.wheelable = false;

		// Create map polygon series
		var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
		polygonSeries.exclude = ["AQ"];
		polygonSeries.useGeodata = true;
		polygonSeries.mapPolygons.template.nonScalingStroke = true;

		// Stroke Colors etc
		var polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.tooltipText = "{name}";
		polygonTemplate.stroke = am4core.color("#282E45");
		polygonTemplate.fill = am4core.color("#9497A2");

		const countries = this.getCountries(this.props.country, this.props.importCountries, this.props.exportCountries);

		countries.forEach(function (country) {
			country.fill = am4core.color(country.fill);
		});

		// Data for selected countries
		polygonSeries.data = countries;

		polygonTemplate.propertyFields.fill = "fill";
	
		this.map = map;
	}

	componentDidUpdate(prevProps) {
		if (this.props.cities.length && prevProps.cities !== this.props.cities) {
			this.drawGeoLines();
		}
	}

	componentWillUnmount() {
		if (this.map) {
			this.map.dispose();
		}
	}

	drawGeoLines = () => {
		var interfaceColors = new am4core.InterfaceColorSet();

		// Add lines
		var lineSeries = this.map.series.push(new am4maps.MapLineSeries());
		lineSeries.dataFields.multiGeoLine = "multiGeoLine";

		var lineTemplate = lineSeries.mapLines.template;
		lineTemplate.nonScalingStroke = true;
		lineTemplate.arrow.nonScaling = true;
		lineTemplate.arrow.width = 4;
		lineTemplate.arrow.height = 6;
		lineTemplate.strokeWidth = 1;
		lineTemplate.stroke = interfaceColors.getFor("background");
		lineTemplate.fill = interfaceColors.getFor("background");
		lineTemplate.line.strokeOpacity = 0.6;

		const geoLines = this.getGeoLines(this.props.cityId, this.props.cities, this.props.importCountries, this.props.exportCountries);

		lineSeries.data = geoLines;
	}

	getGeoLines = (cityId, cities, importCountries, exportCountries) => {
		const geoLinesArr = [];
		let city = {};
		let importCities = [];
		let exportCities = [];

		city = cities.filter(
			city => city.id === Number(cityId)
		);

		importCountries.forEach(function (country) {
			importCities = cities.filter(city => 
				city.countryCode === country.country.isoCode && 
				city.cityAsciiname === country.country.countryCapital && 
				city.countryId === country.country.id
			);

			importCities.forEach(function (importCity) {
				geoLinesArr.push(
					{
						"multiGeoLine": [
							[
								{ "latitude": city[0]?.latitude, "longitude": city[0]?.longitude },
								{ "latitude": importCity.latitude, "longitude": importCity.longitude }
							]
						]
					}
				);
			});
		});

		exportCountries.forEach(function (country) {
			exportCities = cities.filter(
				city => city.countryCode === country.country.isoCode && 
				city.cityAsciiname === country.country.countryCapital && 
				city.countryId === country.country.id
			);

			exportCities.forEach(function (exportCity) {
				geoLinesArr.push(
					{
						"multiGeoLine": [
							[
								
								{ "latitude": exportCity?.latitude, "longitude": exportCity?.longitude },
								{ "latitude": city[0]?.latitude, "longitude": city[0]?.longitude }
							]
						]
					}
				);
			});
		});

		return geoLinesArr;
	}

	getCompanyCityLatLong = (cityId) => {}
	
	getCountries = (country, importCountries, exportCountries) => {
		const countries = [];

		this.pushInCountries(countries, country, '#dddee2');

		if (importCountries) {
			importCountries.forEach(importCountry => this.pushInCountries(countries, importCountry.country, '#FFA500'));
		}

		if (exportCountries) {
			exportCountries.forEach(exportCountry => this.pushInCountries(countries, exportCountry.country, '#67caca'));
		}

		return countries;
	}

	pushInCountries = (countries, country, fill) => countries.push({
		id: country.isoCode,
		name: country.countryname,
		fill: fill
	});
	
	render() {
		return (
			<div id="mapdiv" style={{ width: "100%", height: "500px" }}></div>
		)
	}
};

export default Map;
