import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  getData,
  getFilters,
  getFilterLists,
  clearFilters,
  getCountries
} from "actions/dataActions";
import { updateUser, deleteUser, updateKeyPersonel } from "actions/userActions";
import EmptyList from "components/ui-elements/EmptyList";
import UserDetails from "./UserDetails";
import NewUser from "./NewUser";
import moment from "moment";
import Accordion from "components/ui-elements/Accordion";
import Avatar from "components/ui-elements/Avatar";
import { Row, Col, Button, Tag, Popconfirm } from "antd";
import { Drawer } from "antd";
import { indexOf } from "@amcharts/amcharts4/.internal/core/utils/Array";

class UsersEdit extends Component {

  state = {
    newUserForm: false,
    subIndustries: [],
    companyData: [],
    userIdData: null
  };

  /* START:- integrate countries api for telephone area code in NewUser component */
  getCountryList = async () => {
    const { getCountries } = this.props;
    await getCountries();
  };

  /* END:- integrate countries api for telephone area code in NewUser component */
  componentDidMount() {
    this.getCountryList();
    const { data, companyInfo, auth } = this.props;
    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

    if (isAdmin) {
      const { data } = companyInfo
      if (Object.keys(data)?.length) {
        const { subIndustry } = data;
        if (subIndustry?.length > 0) {
          this.setState({ subIndustries: subIndustry });
        }
      }
      // const { companies } = data;

      // const companyData = companies?.filter((item) => item.id === this.props.companyId)

      // if(Object.keys(companyData?.[0])?.length){
      //   const {subIndustry} = companyData[0];
      //   if (subIndustry?.length > 0) {
      //     this.setState({ subIndustries: subIndustry });
      //   }
      // }
    } else {
      const { data } = companyInfo
      if (Object.keys(data)?.length) {
        const { subIndustry } = data;
        if (subIndustry?.length > 0) {
          this.setState({ subIndustries: subIndustry });
        }
      }
    }

    // if (data) {
    //   const { companies } = data;
    //   const companyData = companies?.map((item) => {
    //     return item;
    //   });

    //   const subIndustries = companyData?.filter((item) => item.id === this.props.companyId)
    //   if (subIndustries?.length > 0) {
    //     this.setState({ subIndustries: subIndustries[0]?.subIndustry });
    //   }
    //   // this.setState({ subIndustries: subIndustries[0]?.subIndustry });
    // }
  }

  /*   componentDidUpdate(prevProps, prevState) {
  
      if (prevProps.data.companies !== prevState.companyData) {
        if (prevProps.data.companies.length) {
          const subIndustries = prevProps.data.companies.filter((item) => item.id === prevProps.companyId)
        
          //  setSubIndustries(subIndustries[0].subIndustry)
          this.setState({
            subIndustries: subIndustries[0].subIndustry,
          });
        }
      }
     
    }
   */
  // componentDidUpdate(prevProps, prevState) {

  /*

  if (prevProps.data.companies !== this.props.data.companies) {
    this.setState({
      companyData: this.props.data.companies,
    });
  } */
  // }

  toggleNewUserForm = () => {
    this.setState(prevState => ({
      newUserForm: !prevState.newUserForm
    }));
  };

  /*   saveSubIndustries = async (values, userId) => {

      if (values.branchSubIndustries.length) {
        
        return await this.props.updateUser(values, userId, this.props.branchId);
      }
    } */

  render() {
    const { users, companyId, branchId, isAdmin, deleteBranch, loading, branchName,
      branchCity } = this.props;
    console.log("users>>", users)
    const { companies } = this.props.data;
    const { subIndustries, companyData, userId } = this.state

    let userListing;
    if (users.length === 0) {
      userListing = (
        <EmptyList>
          No users specified. HQ Company key personel will be listed on branch
          profile
        </EmptyList>
      );
    } else {
      userListing = (
        <div className="user-edit-listing">
          <div className="accordion-table-header">
            <Row>
              <Col span={14}>Name</Col>
              <Col span={5}>Role</Col>
              <Col span={5}>Last Activity</Col>
            </Row>
          </div>
          {users.map(user => (
            <Accordion key={user.id} onToggle={() => { }}>
              <Accordion.Toggle>
                <Row>
                  <Col span={14}>
                    {" "}
                    <div className="f user-card-info">
                      <Avatar
                        avatar={user?.user?.userAvatar}
                        name={user?.user?.firstName}
                      ></Avatar>
                      <div>
                        <span
                          className="text-primary"
                          style={{ marginRight: 8 }}
                        >
                          {user?.user?.firstName} {user?.user?.lastName}
                        </span>

                        {/* {user.keyPersonel && (
                          <Tag color="orange">Key Person</Tag>
                        )} */}
                        {user.companyAdmin && (
                          <Tag color="geekblue">Company Admin</Tag>
                        )}

                        <br />
                        <span className="text-secondary text-small">
                          {user?.user?.email}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={5}>Some Role</Col>
                  <Col span={5}>{moment(user?.user?.lastLogin).fromNow()}</Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Panel>
                <UserDetails user={user} branchId={branchId} saveSubIndustries={this.saveSubIndustries} subIndustries={subIndustries} companyId={companyId}></UserDetails>
              </Accordion.Panel>
            </Accordion>
          ))}
        </div>
      );
    }

    return (
      <>
        {isAdmin && (
          <Popconfirm
            title={`Sure to delete ${branchName || branchCity} branch?`}
            placement="topRight"
            onConfirm={() => deleteBranch()}
          >
            <Button
              type="danger"
              size="small"
              loading={loading}
              // style={{ position: "fixed", right: 20, bottom: 20 }}
              // style={{position: 'absolute', bottom: '0'}}
              style={{
                marginBottom: '10px', position: 'relative',
                right: '226px',
                top: '117px'
              }}
              ghost
              className="delete-branch"
            >
              Delete branch
            </Button>
          </Popconfirm>
        )}
        <div className="f-s-b section-header">
          <h2>Company members</h2>
          <Button type="link" className="add-new-user-btn" icon="user" onClick={this.toggleNewUserForm}>
            Add New User
          </Button>
        </div>

        {userListing}

        <Drawer
          width={700}
          closable={false}
          destroyOnClose={true}
          onClose={this.toggleNewUserForm}
          visible={this.state.newUserForm}
        >
          <NewUser
            subIndustries={subIndustries}
            companyId={companyId}
            branchId={branchId}
            closeDrawer={this.toggleNewUserForm}
          ></NewUser>
        </Drawer>
      </>
    );
  }
}

// export default UsersEdit;

const mapStateToProps = (state, ownProps) => ({
  data: state.data,
  companyInfo: state.company,
  auth: state.auth
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getData,
    updateUser,
    getCountries
  })
)(UsersEdit);