import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col, DatePicker, Radio, message, Select } from "antd";
import moment from "moment";
// import { debounce } from "utils/functions";
import { fetchCountries, fetchCities, fetchCountryAirports } from "actions/dataActions";
import {
  updateCompanyInfo,
  updateCompany,
  updateCompanyAvatar,
} from "actions/companyActions";
import { getAirports } from "actions/commonActions";
import CustomSelect from "components/ui-elements/Select";
import ImageUpload from "components/ui-elements/ImageUpload";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { Input, Form, Icon, Tooltip } from "antd";
import NumberFormat from "react-number-format";
import { TitleForm } from "components/ui-elements/Typography";
import { api_v } from "utils/settings";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const dateFormat = "DD/MM/YYYY";
const employesNumberList = [
  {
    label: "1-10",
    value: "1-10",
  },
  {
    label: "11-25",
    value: "11-25",
  },
  {
    label: "26-50",
    value: "26-50",
  },
  {
    label: "50-100",
    value: "50-100",
  },
  {
    label: "100-200",
    value: "100-200",
  },
  {
    label: "200+",
    value: "200+",
  },
];

const CompanyDetails = (props) => {

  const [city, setCity] = useState({ cityAsciiname: props.city, id: 999999 });
  const [country, setCountry] = useState({
    countryname: props.countryName,
    id: 999999,
  });
  const [airport, setAirport] = useState(props.companyAirport)

  const countryList = useSelector((state) => state.data.countries);
  const companyId = useSelector((state) => state.company.data);


  // Auto Save Inputs - Debounced
  const save = async (values) => {
    if (values.companyName) {
      return await props.updateCompany(values, props.companyId);
    }
    return await props.updateCompanyInfo(values, props.id);
  };

  // On image upload
  const onUpload = (file) => {
    props.updateCompanyAvatar(
      { logo: [`/${api_v}logos/${file.response.id}`] },
      file.response,
      props.companyId
    );
  };
  // Manual for React-select :(
  // Country selection
  const handleCountrySelect = (selected) => {
    setCountry(selected);
    const data = {
      id: `/${api_v}company/${props.companyId}/profile`,
      countries: {
        id: `v1/countries/${selected.id}`,
      },
    };

    Promise.resolve(
      props
        .updateCompany(data, props.companyId)
        .then(() => {
          message.success("Country has been updated");
        })
        .catch((err) => {
          message.error("Something went wrong");
        })
    );
  };
  // City selection
  const handleCitySelect = (selected) => {
    setCity(selected);
    const promise = props.updateCompany(
      { city: `${selected.id}` },
      props.companyId
    );
    promise
      .then((res) => {
        message.success("City has been updated");
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };
  // Date (estamblisment)
  const selectDate = (_, stringDate) => {
    props.updateCompanyInfo(
      { establishement: stringDate },
      props.companyInfoId
    );
  };
  const {
    companyName,
    description,
    logo,
    telDialCode,
    qqDialCode,
    companyWebsite,
    companyEmail,
    companyTel,
    companyFax,
    companySkype,
    qq,
    address1,
    address2,
    employesNumber,
    establishement,
    fiscalTurnover,
    registeredCapital,
    holidays,
    auth,
    subIndustry
  } = props;
  let logoUrl;
  if (logo) {
    logoUrl = logo.path;
  }
  const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

  let telCountryCodeSelect = (
    <FormField name="telDialCode">
      <Select
        showSearch
        style={{ width: 90 }}
        value={telDialCode}
      // onChange={handleCountryCodeSelect} size="large"
      >
        {countryList.map((item, index) => {
          return (
            <Option
              value={item.dialCode ? item.dialCode : `no code${item.id}`}
              key={`dial_${item.id}`}
            >
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
      </Select>
    </FormField>
  );
  let qqCountryCodeSelect = (
    <FormField name="qqDialCode">
      <Select
        showSearch
        style={{ width: 90 }}
        value={qqDialCode}
      // onChange={handleCountryCodeSelect} size="large"
      >
        {countryList.map((item, index) => {
          return (
            <Option
              value={item.dialCode ? item.dialCode : `no code${item.id}`}
              key={`dial_${item.id}`}
            >
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
      </Select>
    </FormField>
  );

  const changeVal = (event) => {
    if (event.target.name === "description") {
      save({
        description: event.target.value,
      });
    } else if (event.target.name === "companyName") {
      save({
        companyName: event.target.value,
      });
    } else if (event.target.name === "companyWebsite") {
      save({
        companyWebsite: event.target.value,
      });
    } else if (event.target.name === "companyEmail") {
      save({
        companyEmail: event.target.value,
      });
    } else if (event.target.name === "companyTel") {
      save({
        companyTel: event.target.value,
      });
    } else if (event.target.name === "address1") {
      save({
        address1: event.target.value,
      });
    } else if (event.target.name === "address2") {
      save({
        address2: event.target.value,
      });
    } else if (event.target.name === "companyFax") {
      save({
        companyFax: event.target.value,
      });
    } else if (event.target.name === "companySkype") {
      save({
        companySkype: event.target.value,
      });
    } else if (event.target.name === "qq") {
      save({
        qq: event.target.value,
      });
    } else if (event.target.name === "holidays") {
      save({
        holidays: event.target.value,
      });
    } else if (event.target.name === "fiscalTurnover") {
      save({
        fiscalTurnover: event.target.value,
      });
    } else if (event.target.name === "registeredCapital") {
      save({
        registeredCapital: event.target.value,
      });
    }
  };

  const debouncedOnChange = (callback, delay) => {
    const timerClear = () => (clear = true);
    var clear = true;
    return (event) => {
      if (clear) {
        clear = false;
        setTimeout(timerClear, delay);
        callback(event);
      }
    };
  };
  /* START:- Airport */

  const formatAirportOption = ({ airportName, iata, country }, { context }) => {
    if (context === "menu") {
      return (
        <div className="border-bottom">
          <div>
            {airportName} ({iata})
          </div>
          <span className="text-secondary">{country}</span>
        </div>
      );
    }
    return <div>{iata}</div>;
  };
  const handleAirportSelect = async (selectedAirport) => {
    setAirport(selectedAirport)
    const promise = props.updateCompanyInfo(
      {
        "companyAirport": `/v1/airport/${selectedAirport.id}`
      },
      props.companyId
    );
    promise
      .then((res) => {
        message.success("Airport has been updated");
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  }
  /* END:- Airport */

  return (
    <AutoSaveWrapper
      onSubmit={save}
      initialValues={{
        telDialCode: telDialCode ? telDialCode : companyId.countries?.dialCode,
        qqDialCode: qqDialCode ? qqDialCode : companyId.countries?.dialCode,
        companyName,
        description,
        companyWebsite,
        companyEmail,
        companyTel,
        companyFax,
        companySkype,
        qq,
        address1,
        address2,
        employesNumber,
        establishement,
        fiscalTurnover,
        registeredCapital,
        holidays,
      }}
      subscription={{}}
    >
      {() => (
        <div className="card">
          <AutoSave
            debounce={1000}
            save={save}
            debounced={[
              "telDialCode",
              "qqDialCode",
              "companyName",
              "description",
              "companyWebsite",
              "companyEmail",
              "companyTel",
              "companyFax",
              "companySkype",
              "qq",
              "address1",
              "address2",
              "employesNumber",
              "fiscalTurnover",
              "registeredCapital",
              "holidays",
            ]}
          />
          <div className="card-header">
            <h2>About Company</h2>
          </div>
          <div className="card-body">
            <Form>
              <Row gutter={30}>
                <Col span={16}>
                  <label>
                    <span style={{ color: "red" }}>*</span> Short description
                  </label>
                  <Input.TextArea
                    className={`${description === "" ? "custom-input-valid" : ""
                      }`}
                    rows={7}
                    name="description"
                    defaultValue={description}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input.TextArea>
                  {description === "" ? (
                    <p style={{ color: "red" }} className="required">Required</p>
                  ) : (
                    ""
                  )}

                  {/*  <FormField
                    name="description"
                    label="Short description"
                    validate={["required"]}
                  >
                    <Input.TextArea rows={7}></Input.TextArea>
                  </FormField>
                  </FormField> */}
                </Col>
                <Col span={8}>
                  <Form.Item label="Company logo" required={true}>
                    <ImageUpload
                      img={logoUrl}
                      target="logos"
                      onUpload={onUpload}
                    ></ImageUpload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Form
              {...formItemLayout}
              style={{ maxWidth: 600 }}
              className="customForm"
            >
              <TitleForm variant="subtitle">
                Company address and contact
              </TitleForm>
              {/*   <FormField
                name="companyName"
                label="Company name"
                validate={["required"]}
              >  */}

              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Company name
                  </label>
                  <div className="inputHolder">
                    <Input
                      disabled={isAdmin ? false : true} //Only super admin can change the name
                      className={`${companyName === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="companyName"
                      defaultValue={companyName}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></Input>

                    {!isAdmin && // Only super admin can change the name
                      <div className="companyName-note">Please contact <b>bdm@neutralairpartner.com</b> to change the company name.</div>
                    }
                    {companyName === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/*     </FormField> */}

              {/*  <FormField
                name="companyWebsite"
                label="Website"
                validate={["required"]}
              > */}
              {/* <Input addonBefore="Http://"></Input> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Website
                  </label>
                  <div className="inputHolder">
                    <Input
                      addonBefore="Http://"
                      className={`${companyWebsite === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="companyWebsite"
                      defaultValue={companyWebsite}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></Input>
                    {companyWebsite === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {/*      </FormField> */}

              {/* <FormField
              name="companyEmail"
              label="Email"
              validate={["required"]}
            > */}
              {/* <Input></Input> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Email
                  </label>
                  <div className="inputHolder">
                    <Input
                      className={`${companyEmail === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="companyEmail"
                      defaultValue={companyEmail}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></Input>
                    {companyEmail === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div>
              {/*      </FormField> */}

              {isAdmin && (
                <Form.Item label="Country" required={true}>
                  <CustomSelect
                    async={true}
                    placeholder={"Select country"}
                    key={country}
                    value={country}
                    cacheOptions
                    loadOptions={(input, callback) =>
                      fetchCountries(input, callback)
                    }
                    noOptionsMessage={(input) => "Type to search"}
                    getOptionLabel={(option) => option.countryname}
                    getOptionValue={(option) => option.id}
                    onChange={handleCountrySelect}
                  ></CustomSelect>
                </Form.Item>
              )}
              <Form.Item label="City" required={true}>
                <CustomSelect
                  async={true}
                  placeholder={"Select City"}
                  key={city}
                  value={city}
                  cacheOptions
                  loadOptions={(input, callback) =>
                    fetchCities(input, props.countryId, callback)
                  }
                  noOptionsMessage={(input) => "Type to search"}
                  getOptionLabel={(option) => option.cityAsciiname}
                  getOptionValue={(option) => option.id}
                  onChange={handleCitySelect}
                ></CustomSelect>
              </Form.Item>
              {/*   <FormField
              name="companyTel"
              label="Telephone"
              validate={["required"]}
            > */}
              {/*     <Input></Input> */}
              {/*  <Input addonBefore={telCountryCodeSelect}></Input> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Telephone
                  </label>
                  <div className="inputHolder">
                    <Input
                      addonBefore={telCountryCodeSelect}
                      className={`${companyTel === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="companyTel"
                      defaultValue={companyTel}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></Input>
                    {companyTel === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div>
              {/*     </FormField> */}

              {/* <FormField
                name="address1"
                label="Address line 1"
                validate={["required"]}
              >
                <Input></Input>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Address line 1
                  </label>
                  <div className="inputHolder">
                    <Input
                      className={`${address1 === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="address1"
                      defaultValue={address1}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></Input>
                    {address1 === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div>

              {/*    <FormField name="address2" label="Address line 2">
                <Input></Input>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Address line 2</label>
                  <Input
                    className="input-field"
                    rows={7}
                    name="address2"
                    defaultValue={address2}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input>
                </div>
              </div>
              {subIndustry.filter(item => item.id === 7).length === 0 ?
                <Form.Item label="Airport we operate at" required={true}>
                  <CustomSelect
                    async={true}
                    name="airport"
                    placeholder={"Select Airport"}
                    isClearable={false}
                    value={airport}
                    noOptionsMessage={(input) => "Type to search"}
                    loadOptions={(input, callback) => fetchCountryAirports(input, props.countryId, callback)}
                    formatOptionLabel={formatAirportOption}
                    getOptionLabel={option => option.iata}
                    getOptionValue={option => option.id}
                    onChange={handleAirportSelect}
                  /* validateStatus={airport === null ? "Required" : ""}
                  help={airport === null && "Required"} */
                  ></CustomSelect>
                </Form.Item>
                : null
              }
              {/*   <FormField name="companyFax" label="Fax">
                <Input style={{ maxWidth: 250 }}></Input>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Fax</label>
                  <Input
                    style={{ maxWidth: 250 }}
                    className="input-field"
                    rows={7}
                    name="companyFax"
                    defaultValue={companyFax}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input>
                </div>
              </div>

              {/*   <FormField name="companySkype" label="Skype name">
                <Input
                  style={{ maxWidth: 250 }}
                  addonBefore={
                    <Icon
                      type="skype"
                      theme="filled"
                      style={{ color: "rgba(0,0,0,.32)" }}
                    />
                  }
                ></Input>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Skype name</label>
                  <Input
                    style={{ maxWidth: 250 }}
                    addonBefore={
                      <Icon
                        type="skype"
                        theme="filled"
                        style={{ color: "rgba(0,0,0,.32)" }}
                      />
                    }
                    className="input-field"
                    rows={7}
                    name="companySkype"
                    defaultValue={companySkype}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input>
                </div>
              </div>

              {/*   <FormField name="qq" label="Whatsapp">
                <Input
                  style={{ maxWidth: 250 }}
                  placeholder="123456789"
                  addonBefore={
                    qqCountryCodeSelect
                    /*  <Icon type="qq" style={{ color: "rgba(0,0,0,.32)" }} /> */
              }
              {/*  suffix={
                    <Tooltip title="Format 123456789">
                      <Icon
                        type="info-circle"
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                ></Input>
              </FormField>  */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Whatsapp</label>
                  <Input
                    style={{ maxWidth: 250 }}
                    placeholder="123456789"
                    addonBefore={
                      qqCountryCodeSelect
                      /*  <Icon type="qq" style={{ color: "rgba(0,0,0,.32)" }} /> */
                    }
                    suffix={
                      <Tooltip title="Format 123456789">
                        <Icon
                          type="info-circle"
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                    className="input-field"
                    rows={7}
                    name="qq"
                    defaultValue={qq}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input>
                </div>
              </div>
            </Form>

            <Form {...formItemLayout} style={{ maxWidth: 600 }} className="customForm">
              <TitleForm variant="subtitle">Other details</TitleForm>
              <FormField
                name="employesNumber"
                label="Number of employees"
                validate={["required"]}
              >
                <Radio.Group buttonStyle="solid" size="small">
                  {employesNumberList.map((a) => (
                    <Radio.Button key={a.value} value={a.value}>
                      {a.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </FormField>
              {/* <CustomSelect
                  required={true}
                  placeholder={"Company size"}
                  defaultValue={selectedEmployesNumber}
                  options={employesNumberList}
                  onChange={selectCompanySize}
                ></CustomSelect> */}
              <Form.Item label="Date of establishment" required={true}>
                <DatePicker
                  onChange={selectDate}
                  allowClear={false}
                  defaultValue={moment(establishement, dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>

              {/* <div className="input-field-inner-parent"> */}
              <FormField
                name="registeredCapital"
                label="Registered capital"
                validate={["required"]}
              >
                <NumberFormat
                  customInput={Input}
                  thousandSeparator={true}
                  prefix={"$"}
                  style={{ maxWidth: 300 }}
                ></NumberFormat>

              </FormField>
              {/* </div> */}

              {/* } */}
              {/*  <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>
                    <span style={{ color: "red" }}>*</span> Registered capital
                  </label>
                  <div className="inputHolder">
                    <NumberFormat
                      customInput={Input}
                      thousandSeparator={true}
                      prefix={"$"}
                      style={{ maxWidth: 300 }}
                      className={`${registeredCapital === "" ? "custom-input-valid" : ""
                        } input-field`}
                      rows={7}
                      name="registeredCapital"
                      defaultValue={registeredCapital}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></NumberFormat>
                    {registeredCapital === "" ? (
                      <div style={{ color: "red" }} className="required">
                        Required
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div> */}
              {/* <FormField
                name="fiscalTurnover"
                label="Last years annual turnover"
                validate={["required"]}
              >
                <NumberFormat
                  customInput={Input}
                  thousandSeparator={true}
                  prefix={"$"}
                  style={{ maxWidth: 300 }}
                ></NumberFormat>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Last years annual turnover</label>
                  <div className="inputHolder">
                    <NumberFormat
                      customInput={Input}
                      thousandSeparator={true}
                      prefix={"$"}
                      style={{ maxWidth: 300 }}
                      className="input-field"
                      rows={7}
                      name="fiscalTurnover"
                      defaultValue={fiscalTurnover}
                      onKeyUp={debouncedOnChange(changeVal, 1000)}
                    ></NumberFormat>
                  </div>
                </div>
              </div>

              {/* <FormField name="holidays" label="Public holidays">
                <Input.TextArea autoSize={{ minRows: 2 }}></Input.TextArea>
              </FormField> */}
              <div className="input-field-parent">
                <div className="input-field-inner-parent">
                  <label>Public holidays</label>
                  <Input.TextArea
                    autoSize={{ minRows: 2 }}
                    className="input-field"
                    rows={7}
                    name="holidays"
                    defaultValue={holidays}
                    onKeyUp={debouncedOnChange(changeVal, 1000)}
                  ></Input.TextArea>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </AutoSaveWrapper>
  );
};

const mapStateToProps = (state) => ({
  ...state.company.data.companyInfo[0],
  companyName: state.company.data.companyName,
  countryId: state.company.data.countries.id,
  countryName: state.company.data.countries.countryname,
  companyAirport: state.company.data.companyInfo[0].companyAirport,
  subIndustry: state.company.data.subIndustry,
  city: state.company.data.city,
  logo: state.company.data.logo[state.company.data.logo.length - 1],
  cities: state.data.cities,
  companyId: state.company.data.id,
  companyInfoId: state.company.data["companyInfo"][0]["id"],
  auth: state.auth,
});
export default connect(mapStateToProps, {
  updateCompanyInfo,
  updateCompany,
  updateCompanyAvatar,
  getAirports
})(CompanyDetails);
