import React from "react";
import {
    OverviewOutlined,
    CompanyOutlined,
    PersonnelOutlined,
    RatesFeesOutlined,
    ActivityOutlined
} from "components/ui-elements/Icons";

const airPartnerTabs = [
    {
        name: 'Overview',
        key: 'overview',
        icon: <OverviewOutlined />,
        iconSize: 20,
    },
    {
        name: 'Company',
        key: 'company',
        icon: <CompanyOutlined />,
        iconSize: 18
    },
    {
        name: 'Personnel',
        key: 'personnel',
        icon: <PersonnelOutlined />,
        iconSize: 25
    },
    {
        name: 'Rates & Fees',
        key: 'ratesFees',
        icon: <RatesFeesOutlined />,
        iconSize: 12
    },
    {
        name: 'Promos',
        key: 'activity',
        icon: <ActivityOutlined />,
        iconSize: 14
    }
];

const associateTabs = [
    {
        name: 'Overview',
        key: 'overview',
        icon: <OverviewOutlined />,
        iconSize: 20
    },
    {
        name: 'Company',
        key: 'company',
        icon: <CompanyOutlined />,
        iconSize: 18
    },
    {
        name: 'Personnel',
        key: 'personnel',
        icon: <PersonnelOutlined />,
        iconSize: 25
    },
    {
        name: 'Rates & Fees',
        key: 'ratesFees',
        icon: <RatesFeesOutlined />,
        iconSize: 12
    },
    {
        name: 'Promos',
        key: 'activity',
        icon: <ActivityOutlined />,
        iconSize: 14
    }
];

const vendorTabs = [
    {
        name: 'Company',
        key: 'vendorCompany',
        icon: <CompanyOutlined />,
        iconSize: 18
    },
    {
        name: 'Personnel',
        key: 'personnel',
        icon: <PersonnelOutlined />,
        iconSize: 25
    }
];

export { airPartnerTabs, associateTabs, vendorTabs };
