export const base_url = process.env.REACT_APP_API_URL;

export const api_v = process.env.REACT_APP_API_V;

export const variables = {
  colors: {
    primary: "#4cb6b6",
    danger: "red",
    warning: "yellow",
    success: "green",
    info: "blue",
    text: "#45474c",
    textSecondary: "#999",
    light: "rgba(0,0,0,.07)",
    border: "#e0e0e0",
    bgDanger: "#FFF5F4",
    bgSuccess: "#ebffea",
    bgWarning: "#fafaeb",
    bgInfo: "#f1f3ff"
  },

  borderRadius: {
    regular: "6px"
  }
};
