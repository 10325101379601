import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Row, Col, Skeleton, Divider, message, Popconfirm } from 'antd';
import InputFieldList from './InputFieldList';
import { Dynamic_Fields } from "./DynamicFields";
import { AddQuestionForm } from './AddQuestionForm';
import PageHeader from "components/ui-elements/PageHeader";
// import { getCountriesList } from "actions/commonActions";
import { QUESTION_TYPE } from '../../utils/enum'
import { getQuestionList, getCountriesList, getCitiesList, fetchCountries, fetchCities, fetchAirports, fetchAirlines, deleteQuestion, AddQuestion, SeqPutQuestion } from '../../actions/questionAction';
import { DeleteOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { useRef } from 'react';
import Connection from './Connection'
import Payment from './Payment'
import ParentCompanyRelation from './ParentCompanyRelation'
import Event from "../company-edit/event/Event"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from "@emotion/styled";
import { getNetworkBrevoList } from '../../actions/questionAction';
import MySettings from '../../theme/assets/img/my-settings.svg';

const FixedBottom = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #d7d7d7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;


const Form = (props) => {

  const tabsList = [
    {
      title: 'CONNECTION',
      tabIndex: 0
    },
    {
      title: 'APP',
      tabIndex: 1
    },
    {
      title: 'APPLICATION',
      tabIndex: 2
    },
  ]

  const roles = localStorage.getItem("rolesUser");
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({}) // set question data when we edit question
  const [listing, setlisting] = useState([]);
  const [cityListing, setCityListing] = useState([])
  const [countryListing, setCountryListing] = useState([])
  const [loading, setLoading] = useState(true);
  const [drawer, setDrawer] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const scrollRef = useRef(null); // Create a scrollRef
  const state = useSelector((state) => state);
  const [highestSequence, setHighestSequence] = useState()

  // const [question, setQuestion] = useState()
  // const [formsubmit, setFormSubmit] = useState(false);
  // const [selectedSubGroupId, setSelectedSubGroupId] = useState([])
  // const subGroup = useSelector(state => state.company.data?.subIndustry)
  // const companyId = useSelector(state => state.company.data?.id)

  useEffect(() => {
    if (!(roles === "ROLE_ADMIN")) {
      props.history.push("/network");
    }
    initialFunction()
  }, [])

  const sortBySequenceNumber = async (res) => {
    const sortedData = [...res].sort((a, b) => a.sequence - b.sequence);
    return sortedData
    // Update state or perform any further operations with the sortedData
  };
  // Get All Question list
  const initialFunction = async () => {
    const res = await dispatch(getQuestionList());
    const countryListResponse = await dispatch(getCountriesList())
    //  const citiesListResponse = await dispatch(getCitiesList())
    setCountryListing(countryListResponse)
    // setCityListing(citiesListResponse)
    const sortedList = await sortBySequenceNumber(res)
    setlisting(sortedList)
    setHighestSequence(Math.max(...sortedList.map(obj => parseInt(obj.sequence))));
    setLoading(false)
  }

  useEffect(() => {
    if (listing.length > 0) {
      setHighestSequence(Math.max(...listing.map(obj => parseInt(obj.sequence))));
    }

  }, [listing])

  /* const toggleAddMember = (data) => {
    setDrawer((current) => !current)
    setData(data)
    setFormSubmit(state => !state)
  }; */

  // Open Add Question Drawer
  const handleOpenDrawer = (data) => {
    setDrawer(true)
    setData(data)
    // setFormSubmit(state => !state)
  }

  // Close Add Question Drawer
  const handleCloseDrawer = (data) => {
    setDrawer(false)
    setLoading(false)
    initialFunction()
    //   setData(data)
    //  setFormSubmit(state => !state)
  }
  //-------------------Drag Drop------------------------
  /*   const handleDragStart = (e, index) => {
      setDraggedItem(listing[index]);
    }; */

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   const container = scrollRef.current;
  //   const yOffset = 50; // Adjust this value for scrolling sensitivity

  //   if (e.clientY < container.offsetTop + yOffset) {
  //     container.scrollTop -= yOffset; // Scroll up
  //   } else if (e.clientY > container.offsetHeight - yOffset) {
  //     container.scrollTop += yOffset; // Scroll down
  //   }
  // };
  /*   const handleDragOver = (e) => {
      e.preventDefault();
      const container = scrollRef.current;
      const yOffset = 50; // Adjust this value for scrolling sensitivity
      const { clientY, offsetHeight, scrollTop } = e;
      const screenHeight = window.innerHeight;
  
      if (clientY < yOffset) {      
        window.scrollTo(0, Math.max(scrollTop - yOffset, 0)); // Scroll up
      } else if (clientY > screenHeight - yOffset) {
        const scrollHeight = container.scrollHeight - offsetHeight;
        window.scrollTo(0, Math.min(scrollTop + yOffset, scrollHeight)); // Scroll down
      }
    }; */

  /*   const handleDrop = async (e, index) => {
  
     
      // const newItems = [...listing];
      // newItems.splice(index, 0, draggedItem);
      // const draggedItemIndex = newItems.indexOf(draggedItem);
      // if (draggedItemIndex !== -1) {
      //   newItems.splice(draggedItemIndex, 1);
      // }
      // setlisting(newItems);
      e.preventDefault(); // Prevent default behavior
    
  
      const newItems = [...listing];
  
   
      const draggedItemIndex = newItems.indexOf(draggedItem);
      newItems.splice(draggedItemIndex, 1);
    
      newItems.splice(index, 0, draggedItem);
  

      setlisting(newItems);
      // draggedItem['sequence'] = index;
      // let [, , , questionTypeNumber] = draggedItem.QuestionType.split("/");
      // draggedItem['QuestionType'] = questionTypeNumber
      // const data={
      //   id: draggedItem.id,
      //   sequence: index
      // }
      // await dispatch(SeqPutQuestion(data));
     
    }; */

  //-------------------New Drag Drop------------------------
  async function handleOnDragEnd(result) {

    if (!result.destination) return;

    const items = Array.from(listing);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setlisting(items);
    await dispatch(SeqPutQuestion(items));
  }
  const inputChangedHandler = async (event, inputIdentifier, value) => {
  }
  const handleDeleteQuestion = async (questionId) => {
    await dispatch(deleteQuestion(questionId?.id))
    setLoading(true)
    initialFunction()
    message.success("Question deleted successfully");
  }

  const switchTab = (tabIndex) => {
    // setActiveSubTab(0);
    setActiveTab(tabIndex);
    /*  if (tabIndex === 0) {
       fetchData(1);
     }
     else if (tabIndex === 1) {
       fetchCompanyPromos(1);
     } */
  }
  const tabHeader = (text) => {
    return (
      <div className={`leftPanelTitle`}>
        <div className="tabDetails">
          <span>
            {text}
          </span>
        </div>
        {/* <img src={leftActiveArrowPromo} className="activeIcon" /> */}
      </div>
    )
  }

  return (
    // <div className='formMain'>
    <div className="pageContainer">
      {/* <PageHeader icon='/static/media/myap-icon.eade4463.svg' title={`APPLICATION FORM`} /> */}
      <PageHeader icon={MySettings} title={`SETTINGS`} />
      {/*  <div className="formheader">
        <img src='/static/media/myap-icon.eade4463.svg' alt='img' className="pageIconblended" />
        <h4>APPLICATION FORM</h4> */}
      <Row className={`form-container app-module-row-main  ${activeTab === 2 && "application-tab-active"}`}>
        <Col span={5} className="leftPanelNavSidebar">
          <div className="leftPanelNav">
            <div className="ant-tabs-nav ant-tabs-nav-animated alertTabs">
              {
                tabsList.map((tab, index) => {
                  return (
                    <div className={`${activeTab === tab.tabIndex ? "ant-tabs-tab-active" : ""} ant-tabs-tab`} key={`tab_${index}`} onClick={() => switchTab(tab.tabIndex)}>
                      {tabHeader(tab.title)}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Col>
        <Col span={18} /* sm={24} xs={24} *//*  lg={isAdmin ? 24 : 20} *//*  lg={20} */ >
          {activeTab === 2 &&
            <div className='formMaininner application-wrap'>
              {loading
                ?
                <>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </>

                :
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="listing">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {listing?.map((item, index) => {
                          let itemNew = []
                          let changed = () => { }
                          let loadOptions = () => { }
                          let getOptionLabel = () => { }
                          let getOptionValue = () => { }

                          if (item.callAction != "") {
                            let [, , , questionTypeNumber] = item.QuestionType.split("/");

                            if (item.callAction === "CountryList" && (QUESTION_TYPE[questionTypeNumber] === 'CHECKBOX' || QUESTION_TYPE[questionTypeNumber] === 'SINGLE_SELECT' || QUESTION_TYPE[questionTypeNumber] === 'MULTI_SELECT')) {
                              changed = (value) => {
                                // dispatch(setSelectedCountry(value))
                              }
                              itemNew = {
                                ...item,
                                // [`questionOptions`]: state.data.countries,
                                [`questionOptions`]: countryListing,
                                [`changed`]: changed,
                              }
                            } else if (item.callAction === "CountryList" && (QUESTION_TYPE[questionTypeNumber] === 'SINGLE_SELECT_SEARCH' || QUESTION_TYPE[questionTypeNumber] === 'MULTI_SELECT_SEARCH')) {
                              loadOptions = (input, cb) => fetchCountries(input, cb)
                              getOptionLabel = option => option.countryname ? option.countryname : ''
                              getOptionValue = option => option.id ? option.id : ''
                              itemNew = {
                                ...item,
                                [`questionOptions`]: state.data.countries
                              }
                            }

            /* else if (item.callAction == "CityList" && (QUESTION_TYPE[questionTypeNumber] === 'CHECKBOX' || QUESTION_TYPE[questionTypeNumber] === 'SINGLE_SELECT' || QUESTION_TYPE[questionTypeNumber] === 'MULTI_SELECT')) {
              changed = (value) => {
                // dispatch(setSelectedCountry(value))
              }
              itemNew = {
                ...item,
                [`questionOptions`]: state.question.citiesData,
                //   [`questionOptions`]: cityListing,
                [`changed`]: changed,
              }
            } */ else if (item.callAction === "CityList") {
                              loadOptions = (input, cb) => fetchCities(input, 3, cb)
                              getOptionLabel = option => option.cityAsciiname ? option.cityAsciiname : ''
                              getOptionValue = option => option.id ? option.id : ''
                              itemNew = {
                                ...item,
                                // [`questionOptions`]: state.data.countries
                                [`questionOptions`]: []
                              }
                            }
                            else if (item.callAction === "AirportsList") { // Pending functionality 

                              loadOptions = (input, cb) => fetchAirports(input, cb)
                              getOptionLabel = option => option.airportName
                              getOptionValue = option => option.id
                              itemNew = {
                                ...item,
                                [`questionOptions`]: []
                              }
                            }

                            else if (item.callAction === "AirlinesList") { // Pending functionality
                              loadOptions = (input, cb) => fetchAirlines(input, cb)
                              getOptionLabel = option => option.airlineName
                              getOptionValue = option => option.id
                              itemNew = {
                                ...item,
                                [`questionOptions`]: []
                              }
                            }
                          } else {
                            itemNew = item;
                          }

                          let questionItem = { ...Dynamic_Fields(itemNew) }
                          const [, , , number] = item.QuestionType.split("/");

                          return (
                            // <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                            //   {(provided) => (
                            //     <div
                            //       ref={provided.innerRef}
                            //       {...provided.draggableProps}
                            //       {...provided.dragHandleProps}
                            //     >
                            <Row className="appFormRowMain">
                              <Col span={1} className="serial"><p>{index + 1}</p></Col>
                              <Col span={23}>
                                {/* {index !== 0 ? <Divider /> : ""} */}
                                <div
                                  key={`item_${index}`}
                                  // draggable
                                  // onDragStart={(e) => handleDragStart(e, index)}
                                  // onDragOver={(e) => handleDragOver(e)}
                                  // onDrop={(e) => handleDrop(e, index)}
                                  // ref={scrollRef}
                                  id="groupForm"
                                >
                                  <InputFieldList
                                    key={`field_${item.id}`}
                                    isradio={questionItem.isradio == 1 ? 1 : 0}
                                    elementType={questionItem.elementType}
                                    elementConfig={questionItem.elementConfig}
                                    col={questionItem.col}
                                    label={questionItem.label}
                                    value={questionItem.value}
                                    invalid={!questionItem.valid}
                                    shouldValidate={questionItem.validation}
                                    touched={questionItem.touched}
                                    fullRow={questionItem.fullRow}
                                    heading={questionItem.heading}
                                    divider={questionItem.divider}
                                    // changed={questionItem.changed}
                                    changed={(event, value) => inputChangedHandler(event, item.id, value)}
                                    loadOptions={loadOptions}
                                    getOptionLabel={getOptionLabel}
                                    getOptionValue={getOptionValue}
                                    leftText={questionItem.leftText}
                                    error={questionItem.error}
                                    maxLimit={questionItem.maxLimit}
                                    minLimit={questionItem.minLimit}
                                  />

                                  <div className="btnGroup">
                                    <Button className="borderless-btn" onClick={() => { handleOpenDrawer(item) }}>
                                      Edit<EditOutlined />
                                    </Button>

                                    <Popconfirm
                                      title={`Sure to delete this question?`}
                                      placement="topRight"
                                      onConfirm={() => { handleDeleteQuestion(item) }}
                                    >
                                      <Button id="delete" className="borderless-btn" >
                                        Delete<DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            //     </div>
                            //   )}
                            // </Draggable>
                          )

                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              }
            </div>
          }

          {activeTab === 0
            &&
            <div>
              <h1>NEWSLETTER</h1>
              <Connection />
              <h1>PAYMENTS</h1>
              <div className="formMaininner appformMaininner"><Payment /> <ParentCompanyRelation /> </div>
            </div>
          }
          {activeTab === 1 && <div><h1>APP SETTINGS</h1><div className='formMaininner'><Event /></div></div>}

        </Col>
      </Row>
      {/*  </div> */}
      {/* <FixedBottom> */}
      {activeTab === 2 &&
        <div className="AddQuestionBtn">
          <Button type="primary" className="create-btn  borderless-btn" onClick={() => (handleOpenDrawer())}>Add question<FileAddOutlined /></Button>
        </div>
      }
      {/* </FixedBottom> */}



      <Drawer
        width={700}
        closable={false}
        visible={drawer}
        destroyOnClose={true}
        onClose={() => handleCloseDrawer()}
      >
        <AddQuestionForm data={data} question={listing} highestSequence={highestSequence} closeDrawer={() => handleCloseDrawer()} setLoading={setLoading}></AddQuestionForm>
      </Drawer>
    </div >
  );
};

export default Form;