import React from "react"
import Expertise from "./Expertise"
import { NETWORK } from "../../../../../utils/enum"

const Network = (props) => {
    const { companyData ,networkStatus} = props;

    const colorMap = {
        [NETWORK.NAC_NEUTRAL_AIR_CONSOLIDATOR]: "blue",
        [NETWORK.NAV_NEUTRAL_AVIATION_SPECIALIST]: "orange",
        [NETWORK.NEX_E_COMMERCE]: "red",
        [NETWORK.NAX_24_BY_7]: "purple",
        [NETWORK.NAX_FRESH]: "green",
        [NETWORK.NAF]: "yellow", // color not provided by client end,
        [NETWORK.VENDORS]: "black", // color not provided by client end
    };

    const blockColor = (id) => colorMap[id] || "";

    return (
        <div className="paddingBottomExpertise">
            {
                companyData?.subIndustry?.map((subindustryItem,index) => {
                    const className = blockColor(subindustryItem.id)
                    return (
                        <div className={`${className} Network-parent`}>
                            <div className="industryLogo">
                                <img src={subindustryItem.industryLogo} />
                                <div className="line"></div>
                            </div>
                            <div className="ExpertiseBox">
                                <Expertise subIndusryId={subindustryItem.id} subindustryItem={subindustryItem} companyData={companyData} networkStatus={networkStatus} />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Network