import { Row, Col, Input, Select, Radio, Switch, Button, Icon } from "antd";
import ImageUpload from "components/ui-elements/ImageUpload";
import React, { useEffect, useState } from "react";
import {
  addAlertAsyncActions,
  getData,
  updateAlertAsyncActions,
} from "../../actions/dataActions";
import jwt_decode from "jwt-decode";
import plusIcon from "theme/assets/plus-icon.svg";
import addCasBG from "theme/assets/addCasBG.svg";
import { connect } from "react-redux";
import {
  getAllCompanies,
  getAllCurrencies,
  getAllCurrenciesList,
} from "actions/companyActions";

const { Option } = Select;

const AddAlert = (props) => {
  const [companyName, setCompanyName] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState();
  const [dueOver60, setDueOver60] = useState(0);
  const [btw6090Days, setBtw6090Days] = useState(0);
  const [over15K, setOver15K] = useState(0);
  const [confirmInfo, setConfirmInfo] = useState(0);
  const [loading, setLoading] = useState(false);

  const [alertId, setAlertId] = useState(0);
  const [status, setStatus] = useState("");
  const [attention, setAttention] = useState(false);

  const [statementDoc, setStatementDoc] = useState(null);
  const [correspondenceDoc, setCorrespondenceDoc] = useState(null);
  const [payableDoc, setPayableDoc] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const {
    getAllCompaniesAction,
    getAllCurrenciesAction,
    onAddUpdateCallback,
    editableInfo,
  } = props;

  useEffect(() => {
    getCompanyList();
    getCurrencyList();
  }, []);

  useEffect(() => {
    if (editableInfo.id) {
      const {
        selectedCompany,
        currency,
        amount,
        overdueInvoices,
        monthsEndStatement,
        invoiceOver15k,
        id,
        status,
        attention,
      } = editableInfo;
      setCompanyName(selectedCompany.id);
      setCurrency(currency.id);
      setAmount(amount);
      setDueOver60(overdueInvoices ? 1 : 0);
      setBtw6090Days(monthsEndStatement ? 1 : 0);
      setOver15K(invoiceOver15k ? 1 : 0);
      setAlertId(id);

      setStatus(status);
      setAttention(attention);
    }
  }, [editableInfo]);

  const getCompanyList = async () => {
    const response = await getAllCompaniesAction();
    setCompanies(response["hydra:member"]);
  };

  const getCurrencyList = async () => {
    const response = await getAllCurrenciesAction();
    setCurrencies(response);
  };

  const updateCreditAlert = async () => {
    setLoading(true);
    const data = {
      selectedCompany: `v1/companies/${companyName}`,
      amount: parseInt(amount),
      overdueInvoices: dueOver60 === 1,
      monthsEndStatement: btw6090Days === 1,
      invoiceOver15k: over15K === 1,
      currency: `v1/currencies/${currency}`,
      status,
      attention,
    };
    const response = await updateAlertAsyncActions(data, alertId);
    if (response) {
      setLoading(false);
      onAddUpdateCallback();
    }
  };

  const addCreditAlert = async () => {
    const data = new FormData();

    const companyInfo = JSON.parse(localStorage.companyInfo);
    const companyId = companyInfo.company;
    setLoading(true);

    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    data.append("companyId", companyId);
    data.append("selectedCompanyId", companyName);
    data.append("userId", decoded.id);
    data.append("overdueInvoices", dueOver60);
    data.append("monthsEndStatement", btw6090Days);
    data.append("invoiceOver15k", over15K);
    data.append("currencyId", currency);
    data.append("amount", amount);
    if (statementDoc) {
      data.append("statementDocument", statementDoc);
    }
    if (correspondenceDoc) {
      data.append("correspondenceDocument", correspondenceDoc);
    }
    if (payableDoc) {
      data.append("payablesDocument", payableDoc);
    }

    const response = await addAlertAsyncActions(data);
    if (response) {
      setLoading(false);
      onAddUpdateCallback();
    }
  };

  const selectFile = async (e, fileType) => {
    let file = e.currentTarget.files[0];
    let reader = new FileReader();
    // reader.addEventListener("load", () => {
    //     let _logo = { ...logo };
    //     _logo["path"] = reader.result;
    //     setLogo(_logo);
    // }, false);
    if (file) {
      reader.readAsDataURL(file);
      if (fileType === "statement") {
        setStatementDoc(file);
      } else if (fileType === "correspondence") {
        setCorrespondenceDoc(file);
      } else if (fileType === "payable") {
        setPayableDoc(file);
      }
    }
  };

  return (
    <>
      <Row className="drwaerHeader">
        <Col xs={24} sm={24} lg={24}>
          <div className="drwaerHeaderInner">
            <img src={addCasBG} className="titleBG" />
            <div className="addAlertHeading">NEW ALERT</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} lg={24} className="addAlertContainer">
          <div className="addAlertForm">
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <div className="itemField">
                  <label className="requiredLbl">Company name</label>
                  <Select
                    showSearch
                    placeholder="Select a Company"
                    optionFilterProp="children"
                    onChange={setCompanyName}
                    value={companyName}
                    id="company"
                    defaultValue={companyName}
                  >
                    {companies?.map((company, index) => {
                      return (
                        <Option value={company.id} key={`company_${index}`}>
                          {company.value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <div className="itemField">
                  <label className="requiredLbl">Currency</label>
                  <Select
                    showSearch
                    placeholder="Select a Company"
                    optionFilterProp="children"
                    value={currency}
                    defaultValue={currency}
                    onChange={setCurrency}
                    id="company"
                  >
                    {currencies?.map((currency, index) => {
                      return (
                        <Option value={currency.id} key={`currency_${index}`}>
                          {currency.code}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <div className="itemField">
                  <label className="requiredLbl">Amount</label>
                  <Input
                    size="large"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    id="companyName"
                  />
                </div>
              </Col>
            </Row>
            {!alertId && (
              <>
                <Row className="document">
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <label className="lbl">Statement document</label>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <div className="fileUploader">
                        {
                          <>
                            <Icon type="upload" />
                            <div style={{ marginTop: 8 }}>
                              {statementDoc
                                ? statementDoc.name
                                : "Click to Upload"}
                            </div>
                          </>
                        }
                        <input
                          type="file"
                          className="fileSelector"
                          onChange={(e) => selectFile(e, "statement")}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="document">
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <label className="lbl">Correspondence document</label>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <div className="fileUploader">
                        {
                          <>
                            <Icon type="upload" />
                            <div style={{ marginTop: 8 }}>
                              {correspondenceDoc
                                ? correspondenceDoc.name
                                : "Click to Upload"}
                            </div>
                          </>
                        }
                        <input
                          type="file"
                          className="fileSelector"
                          onChange={(e) => selectFile(e, "correspondence")}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="document">
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <label className="lbl">Payables document</label>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    <div className="itemField">
                      <div className="fileUploader">
                        {
                          <>
                            <Icon type="upload" />
                            <div style={{ marginTop: 8 }}>
                              {payableDoc ? payableDoc.name : "Click to Upload"}
                            </div>
                          </>
                        }
                        <input
                          type="file"
                          className="fileSelector"
                          onChange={(e) => selectFile(e, "payable")}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <div className="itemField">
                  <label className="requiredLbl">
                    Please answer questions below with YES or NO
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24} sm={24} xs={24} className="yesNoOption">
                <div className="itemField extraWidth">
                  <label className="lbl">
                    Are you reporting any single invoice that is overdue over 60
                    days?
                  </label>
                  <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={dueOver60 === 1}
                    onChange={(e) => setDueOver60(e ? 1 : 0)}
                  />
                </div>
              </Col>
              <Col lg={24} sm={24} xs={24} className="yesNoOption">
                <div className="itemField extraWidth">
                  <label className="lbl">
                    Are you reporting between 60 - 90 days from month’s end
                    statement?
                  </label>
                  <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={btw6090Days === 1}
                    onChange={(e) => setBtw6090Days(e ? 1 : 0)}
                  />
                </div>
              </Col>
              <Col lg={24} sm={24} xs={24} className="yesNoOption">
                <div className="itemField extraWidth">
                  <label className="lbl">
                    Are you reporting any single invoice that is over 15.000 USD
                    per invoice/transaction?
                  </label>
                  <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={over15K === 1}
                    onChange={(e) => setOver15K(e ? 1 : 0)}
                  />
                </div>
              </Col>
              <Col lg={24} sm={24} xs={24} className="yesNoOption">
                <div className="itemField extraWidth">
                  <label className="requiredLbl lbl">
                    I hereby confirm that all above information is true and
                    correct and agree to FSG plan terms & conditions
                  </label>
                  <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    checked={confirmInfo === 1}
                    onChange={(e) => setConfirmInfo(e ? 1 : 0)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24} sm={24} xs={24} className="addAlertAction">
                {alertId ? (
                  <Button
                    loading={loading}
                    type="danger"
                    className="addBtn"
                    disabled={
                      !confirmInfo || !companyName || !currency || !amount
                    }
                    onClick={() => updateCreditAlert()}
                  >
                    UPDATE ALERT
                  </Button>
                ) : (
                  <Button
                    loading={loading}
                    type="danger"
                    className="addBtn"
                    disabled={
                      !confirmInfo || !companyName || !currency || !amount
                    }
                    onClick={() => addCreditAlert()}
                  >
                    CREATE ALERT
                    {!alertId && <img src={plusIcon} />}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCompaniesAction: () => getAllCompanies(),
    getAllCurrenciesAction: () => getAllCurrenciesList(),
  };
};

export default connect(null, mapDispatchToProps)(AddAlert);
