import React, { Component } from "react";
import { connect } from "react-redux";
import { createUser } from "actions/userActions";
import { Form, Input, Button, Typography, message, Select, Checkbox } from "antd";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
const { Option } = Select;
const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class NewUser extends Component {
  state = {
    confirmDirty: false,
    loading: false,
    selectedCheckboxes: []
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        const userData = {
          roles: [],
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          companyId: this.props.companyId,
          companyFunction: values.companyFunction,
          telephone: values.telephone,
          dialCode: this.state.dialCode,
          subIndustry: values?.subIndustries.map(
            (element) => "/v1/sub_industries/" + element
          ),
        };

        if (this.props.branchId) {
          userData["branch"] = this.props.branchId;
        }

        this.props
          .createUser(userData, this.props.branchId)
          .then(user => {
            this.setState({
              loading: false
            });

            this.props.closeDrawer();
            message.success("Successfuly created new user");
          })
          .catch(err => {
            this.setState({
              loading: false
            });

            this.props.form.setFields({
              email: {
                value: values.email,
                errors: [new Error("This email is allready taken")]
              }
            });
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  isObject(value) {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    );
  }
  handleCountryCodeSelect = (value) => {
    console.log(value)
    if (this.isObject(value)) {
      this.setState({ dialCode: value.dialCode });
    } else {
      this.setState({ dialCode: value });
    }
  }
  handleCheckboxChange = (checkedValues) => {
    this.setState({
      selectedCheckboxes: checkedValues,
    });
  };
  renderCheckboxes = () => {
    const { selectedCheckboxes } = this.state;
    const { subIndustries } = this.props;

    if (!subIndustries?.length) {
      return <SkeletonLoading itemsPerPage={1} />;
    }

    return (
      <Checkbox.Group
        buttonStyle="solid"
        size="small"
        name="SpecialityGroups"
        onChange={this.handleCheckboxChange}
        value={selectedCheckboxes}
      >
        {subIndustries?.map((element, index) => {
          return (
            <Checkbox value={element.id} key={`subGroup${index}`} className="expertise-box-head">
              {element.industryLogo ?
                <div className="logoAndBadge">
                  <img
                    src={
                      element.industryLogo
                    }
                    className="logo-img"
                  />
                </div>
                : "None"}
            </Checkbox>
          )
        })
        }
      </Checkbox.Group>
    );
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { loading, dialCode } = this.state;
    let CountryCodeSelect = (
      <Select showSearch style={{ width: 90 }}
        value={dialCode}
        onChange={this.handleCountryCodeSelect} size="large">
        {this.props.country.map((item, index) => {
          return (
            <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
      </Select>
    );
    return (
      <div style={{ maxWidth: 500, margin: "0 auto" }}>
        <Title level={2}>Invite new user</Title>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="First name">
            {getFieldDecorator("firstName", {
              rules: [
                {
                  required: true,
                  message: "Please input first name",
                  whitespace: true
                }
              ]
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Last name">
            {getFieldDecorator("lastName", {
              rules: [
                {
                  required: true,
                  message: "Please input last name",
                  whitespace: true
                }
              ]
            })(<Input />)}
          </Form.Item>

          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail"
                },
                {
                  required: true,
                  message: "Please input E-mail"
                }
              ]
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Telephone">
            {getFieldDecorator("telephone", {
              rules: [
                {
                  required: false,
                }
              ]
            })(<Input addonBefore={CountryCodeSelect} placeholder="Enter phone number" />)}
          </Form.Item>

          <Form.Item label="Company function">
            {getFieldDecorator("companyFunction", {
              rules: [
                {
                  required: true,
                  message: "Please input Company function",
                  whitespace: true
                }
              ]
            })(<Input />)}
          </Form.Item>
          {/* <div {...tailFormItemLayout}>
            <Title level={4}>Login details</Title>
            <Text type="secondary">
              If you wish to allow this person to add/edit company information
              prease specify details below.
            </Text>
          </div> */}
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please input password"
                },
                {
                  min: 6,
                  message: "Minimum 6 characters"
                },
                {
                  validator: this.validateToNextPassword
                }
              ]
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "Please confirm password"
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>

          <Form.Item label="Network" className="imagesAndCheckboxes">
            {getFieldDecorator("subIndustries", {
              // initialValue: classification,
              rules: [
                {
                  required: true,
                  message: "Please choose company network"
                }
              ]
            })(this.renderCheckboxes())}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              className="invite-user-cancel-btn"
              type="secondary"
              style={{ marginRight: 8 }}
              htmlType="button"
              onClick={this.props.closeDrawer}
            >
              Cancel
            </Button>
            <Button
              className="create-btn"
              type="primary"
              loading={loading}
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Create user
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedNewUser = Form.create({ name: "newUser" })(NewUser);

const mapStateToProps = state => ({
  country: state.data.countries,

});

export default connect(mapStateToProps, { createUser })(WrappedNewUser);
