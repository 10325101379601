import React from 'react';
import { Row, Col } from 'antd';

const AnalyticContainer = ({ bgColor, title, countArr, icon, type }) => {

    return (
        <div className={type} >
            <Row>
                <Col xs={24} sm={24} lg={24} className="headerSection">
                    <img src={icon} className="analyticsIcon" />
                    <div className="analyticTitle">
                        {title}
                    </div>
                </Col>
            </Row>
            <div className={`${title === "CREDIT ALERTS" && 'countsRowMedia'} countsRow`}>
                {
                    countArr.map((count, index) => {
                        return (
                            <div key={`count_${index}`}>
                                <div className={`${title == "MY PARTNER" ? 'myPartner' : 'creditAlert'} countSection`}>
                                    <div className="count">
                                        {count.count}
                                    </div>
                                    <div className={`${title == "MY PARTNER" && 'countTitleMedia'} countTitle`}>
                                        {count?.title}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default AnalyticContainer;