import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { DOMAIN } from "utils/enum";

const PublicRoute = (props) => {
    const { component, path, ...rest } = props;
    const { domain } = useSelector((state) => state.common)

    useEffect(() => {
        let logo;
        let tabTitle;
 
        if (domain?.domain === undefined) {
            logo = "loading.png";
            tabTitle = "loading..."
        } else {
            if (domain?.domain === DOMAIN.NEX) {             
                logo = "nex-icon.png"
                tabTitle = "Nex Portal"
            } else {            
                logo = "nap-icon.png"
                tabTitle = "Nap Portal"
            }
        }
        document.title = tabTitle
        let iconLink = document.querySelector("link[rel='shortcut icon']")
        iconLink.href = logo
    }, [domain]);

    return (
        <Route  path={path} component={component} {...rest}/>
    )
}

export default PublicRoute