import { variables } from "utils/settings";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const TitleForm = props => {
  if (props.variant === "subtitle")
    return (
      <h4
        css={css`
          padding: 5px 0 8px 0;
          margin-bottom: 25px;
          color: rgba(0, 0, 0, 0.6);
          letter-spacing: 0.08em;
          text-transform: uppercase;
          border-bottom: 1px solid ${variables.colors.border};
        `}
      >
        {props.children}
      </h4>
    );

  return <h2>{props.children}</h2>;
};

export const Title = props => {
  switch (props.level) {
    case 5:
      return <h5>{props.children}</h5>;
    default:
      return <h5>{props.children}</h5>;
  }
};

export const Label = ({ children, size, ...rest }) => {
  switch (size) {
    case "large":
      return (
        <h5
          {...rest}
          css={css`
            font-size: 16px;
            font-weight: 500;
          `}
        >
          {children}
        </h5>
      );

    default:
      return (
        <label
          {...rest}
          css={css`
            font-size: 0.9rem;
            color: rgba(0, 0, 0, 0.85);
          `}
        >
          {children}
        </label>
      );
  }
};
