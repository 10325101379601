export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const CLEAR_GLOBAL_ERROR = "CLEAR_GLOBAL_ERROR";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LOGODATA = "SET_LOGODATA";
export const AUTH_LOADING = "AUTH_LOADING";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_AVATAR = "EDIT_AVATAR";

export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REQUEST_RESET = "REQUEST_RESET";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_AUTH = "CLEAR_AUTH";

// ------- NETWORK ---------------------------------------------------------------------------------
export const GET_DATA = "GET_DATA";
export const GET_INVITATIONS = "GET_INVITATIONS";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_EXPERTISE = "GET_EXPERTISE";
export const UPDATE_NETWORK_COMPANY = "UPDATE_NETWORK_COMPANY";
export const DATA_LOADING = "DATA_LOADING";

// Filters data
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const GET_EXPERTISE_LIST = "GET_EXPERTISE_LIST";
export const GET_SPECIALIZATION_LIST = "GET_SPECIALIZATION_LIST";
export const GET_AIRLINES = "GET_AIRLINES";
export const GET_ORIGIN_AIRPORTS = "GET_ORIGIN_AIRPORTS";
export const GET_DESTINATION_AIRPORTS = "GET_DESTINATION_AIRPORTS";

export const GET_FILTERS = "GET_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_ALL_COMPANIES_TAB = 'SET_ALL_COMPANIES_TAB';
export const GET_FILTER_LISTS = "GET_FILTER_LISTS";

// ------- COMPANY ---------------------------------------------------------------------------------
export const GET_COMPANY = "GET_COMPANY";
// export const GET_COMPANY_EVENT_LINKS = "GET_COMPANY_EVENT_LINKS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_TRIGGER = "UPDATE_TRIGGER";
export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const UPDATE_COMPANY_SETTINGS = "UPDATE_COMPANY_SETTINGS";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const UPDATE_COMPANY_AVATAR = "UPDATE_COMPANY_AVATAR";
export const UPDATE_COMPANY_EXPERTISE = "UPDATE_COMPANY_EXPERTISE";
export const UPDATE_DESK_SERVICE = "UPDATE_DESK_SERVICE";
export const DELETE_COMPANY = "DELETE_COMPANY";

// Users
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const CREATE_KEY_PERSONEL = "CREATE_KEY_PERSONEL";
export const UPDATE_KEY_PERSONEL = "UPDATE_KEY_PERSONEL";
export const DELETE_USER = "DELETE_USER";

// Fees
export const ADD_FEE = "ADD_FEE";
export const DELETE_FEE = "DELETE_FEE";
export const UPDATE_FEE = "UPDATE_FEE";

// Bank
export const UPDATE_BANK_LEGAL = "UPDATE_BANK_LEGAL";
export const UPDATE_BANK = "UPDATE_BANK";

// Licences
export const GET_CERTIFICATE_LIST = "GET_CERTIFICATE_LIST";
export const DELETE_LICENCE = "DELETE_LICENCE";
export const ADD_LICENCE = "ADD_LICENCE";
export const UPDATE_LICENCE = "UPDATE_LICENCE";

// Specializations
export const CREATE_SPECIALIZATION = "CREATE_SPECIALIZATION";
export const DELETE_SPECIALIZATION = "DELETE_SPECIALIZATION";
export const UPDATE_SPECIALIZATION = "UPDATE_SPECIALIZATION";

// Expertise

// Documents
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

// Generic
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const COMPANY_UPDATING = "COMPANY_UPDATING";
export const COMPANY_LOADING = "COMPANY_LOADING";

// ------- BRANCH ---------------------------------------------------------------------------------
export const CREATE_BRANCH = "CREATE_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_DETAILS = "UPDATE_BRANCH_DETAILS";
export const UPDATE_BRANCH_DATA = "UPDATE_BRANCH_DATA";
export const UPDATE_BRANCH_INFO = "UPDATE_BRANCH_INFO";
export const DELETE_BRANCH = "DELETE_BRANCH";

// Fees
export const ADD_BRANCH_FEE = "ADD_BRANCH_FEE";
export const UPDATE_BRANCH_FEE = "UPDATE_BRANCH_FEE";
export const DELETE_BRANCH_FEE = "DELETE_BRANCH_FEE";

// Bank
export const UPDATE_BRANCH_BANK = "UPDATE_BRANCH_BANK";

// Documents
export const ADD_BRANCH_DOCUMENT = "ADD_BRANCH_DOCUMENT";
export const DELETE_BRANCH_DOCUMENT = "DELETE_BRANCH_DOCUMENT";

// ------- UPLOAD FILES ---------------------------------------------------------------------------------
export const SET_FILE_UPLOAD = "SET_FILE_UPLOAD";
export const UPDATE_FILE_UPLOAD = "UPDATE_FILE_UPLOAD";
export const CLEAR_FILE_UPLOAD = "CLEAR_FILE_UPLOAD";


// ------- Common ---------------------------------------------------------------------------------
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_SUBINDUSTRY = "SET_SUBINDUSTRY";
export const SET_QUESTION = "SET_QUESTION";
export const NEX_HUB_LADING = "NEX_HUB_LADING";
export const SET_DOMAIN = "SET_DOMAIN";
export const CLEAR_COMMON_QUESTION = "CLEAR_COMMON_QUESTION";

// ------- Registeration --------------------------------------------------------------------------
export const SUBMIT_FORM1 = "SUBMIT_FORM1";
export const FORM1_SUBMITED = "FORM1_SUBMITED";
export const SUBMIT_FORM2 = "SUBMIT_FORM2";
export const FORM2_SUBMITED = "FORM2_SUBMITED";
export const REGISTER_LOADING = "REGISTER_LOADING";

// ------- Registeration --------------------------------------------------------------------------
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const SAVE_ANSWER = "SAVE_ANSWER";

export const GET_CITIES_LIST = "GET_CITIES_LIST"