import React, { useState } from "react";
import { resetPassword } from "actions/userActions";
import { Form, Input, Button, message } from "antd";
import { connect } from "react-redux";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
};

const PasswordReset = props => {
  const [state, setState] = useState({
    confirmDirty: false,
    loading: false,
    autoCompleteResult: []
  });
  const handleSubmit = e => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = {
          old: values.currentPassword,
          new: values.newPassword,
          id: props.userId
        };
        setState({ ...state, loading: true });

        props.resetPassword(data).then(success => {
          if (success) {
            message.success("Password reseted");
            return props.handleCancel();
          }

          setState({ ...state, loading: false });
        });
      }
    });
  };

  const validateCurrentPassword = (rule, value, callback) => {
    const { form } = props;
    callback();
  };
  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && state.confirmDirty) {
      form.validateFields(["confirmNewPassword"], { force: true });
    }
    callback();
  };

  const { getFieldDecorator } = props.form;

  return (
    <div style={{ marginTop: 20 }}>
      <Form
        {...formItemLayout}
        style={{ maxWidth: 600 }}
        onSubmit={handleSubmit}
      >
        <Form.Item label="Current password" hasFeedback>
          {getFieldDecorator("currentPassword", {
            rules: [
              {
                required: true,
                message: "Please input your password!"
              },
              {
                min: 6,
                message: "Minimum 6 characters!"
              },
              {
                validator: validateCurrentPassword
              }
            ]
          })(<Input.Password />)}
        </Form.Item>

        <Form.Item label="New password" hasFeedback>
          {getFieldDecorator("newPassword", {
            rules: [
              {
                required: true,
                message: "Please input your password!"
              },
              {
                min: 6,
                message: "Minimum 6 characters!"
              },
              {
                validator: validateToNextPassword
              }
            ]
          })(<Input.Password />)}
        </Form.Item>

        <Form.Item label="Confirm new password" hasFeedback>
          {getFieldDecorator("confirmNewPassword", {
            rules: [
              {
                required: true,
                message: "Please input your password!"
              },
              {
                min: 6,
                message: "Minimum 6 characters!"
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(<Input.Password />)}
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="link" onClick={() => props.handleCancel()}>
            Cancel
          </Button>
          <Button type="primary" loading={state.loading} htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

const WrappedPasswordReset = Form.create({ name: "password-reset" })(
  PasswordReset
);

export default connect(null, { resetPassword })(WrappedPasswordReset);
