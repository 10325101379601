import {
  form1Submited,
  form2Submited,
  getAssociateExpertiseList,
  getIndustryExpertiseList,
  getSpecializationList,
  getVendorExpertiseList,
  showRegisterLoading,
  submitForm1,
  submitForm2,
} from "actions/commonActions";
import {
  createEmailVerification,
  deleteDocument,
  updateCompany,
  updateSelectedExpertisesV2,
} from "actions/companyActions";
import { getSubGroupList } from "actions/commonActions";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Slider,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FocusSlider from "../../company-edit/expertise/FocusSlider";
import panelIcon from "theme/assets/panelIcon.png";
import AsyncSelect from "react-select/async";
import {
  fetchAirlines,
  fetchAirports,
  fetchCountryAirports,
} from "actions/dataActions";
import {
  formatAirlineOption,
  formatAirportOption,
} from "components/helper/Format";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import { useListFetch } from "components/helper/Hooks";
import TwoOptionSlider from "components/ui-elements/TwoOptionSlider";
import Axios from "axios";
import {
  formatSelectedExpertisesV2,
  findExpertiseV3,
  findExpertiseV2,
} from "utils/functions";
import { api_v, base_url } from "utils/settings";
import moment from "moment";
import regex from "utils/regex";
import Dropzone from "components/ui-elements/Dropzone";
import { generateId } from "utils/functions";
import { uploadVendorDocument } from "actions/uploadActions";
import styled from "@emotion/styled-base";
import SubGroup from "../../company-edit/expertise/SubGroup";
import { debounce } from "lodash";
import { AlignLeftOutlined } from "@ant-design/icons";
import RegisterationExpertiseListing from "./RegisterationExpertiseListing";
import SubIndustriesExpertiese from "../../common/subIndustriesExpertiese1";
import QuestionsList from "components/company-edit/expertise/QuestionsList";
import ExpertiseListing from "components/company-edit/expertise/ExpertiseListing";
import Tradelane from "components/company-edit/expertise/Tradelane";
import ImportExportCountries from "components/company-edit/expertise/ImportExportCountries";
const MemoizedChildComponentA = React.memo(QuestionsList);
const formatAirportOption2 = ({ airport }, { context }) => {
  if (airport === undefined) {
    return null;
  }
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airport.airportName} ({airport.iata})
        </div>
        <span className="text-secondary">{airport.country}</span>
      </div>
    );
  }
  return <div>{airport.iata}</div>;
};

const fetchAirports2 = (input, callback) => {
  Axios.get(`${base_url}/${api_v}airports?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"].map((a) => ({ airport: a })));
    })
    .catch((err) => {
      return callback([]);
    });
};

const ExpertiseSelection = styled(Checkbox.Group)`
  min-height: 100px;
  display: block;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0.7em;
  margin-bottom: 24px;
`;

const ExpertiseInfo = (props) => {
  const dispatch = useDispatch();

  /* ---------- here, handle validation error status for when we click on "Next" Button ----------- */
  const [answerErrors, setAnswerErrors] = useState({})
  const [validationErrorMsg, setValidationErrorMsg] = useState({})
  const [validationErrorTradelane, setValidationErrorTradelane] = useState(false)
  const [validationErrorImportExport, setValidationErrorImportExport] = useState({ airImportCountry: false, airExportCountry: false })
  const [validationErrorAirCargo, setValidationErrorAirCargo] = useState({ expNacTopAirline: false, airImportAirport: false, expNacDestAirport: false, companyAirport: false })

  const [isValidInfo, setIsValidInfo] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isMembershipAccepted, setIsMembershipAccepted] = useState(false);

  /* -------------------------------------------------------------- */

  /* --------------------here, handle states of Question Module -------------*/
  const [no, setNo] = useState(false)
  const [inputFieldData, setInputFieldData] = useState({})
  const [allQuestionsData, setAllQuestionsData] = useState([])

  /* ------------------------------------------------------------------------- */

  /*  ---------------------------- SubIndustries and expertiese -------------------------*/
  const [subIndustries, setSubIndustries] = useState([]);
  const [checkedValObj, setCheckedValObj] = useState([]);
  const [checkedValueStatus, setCheckedValueStatus] = useState(false);
  const [selectedExpertieseIds, setSelectedExpertieseIds] = useState([]);
  const [allSelectedExpertiese, setAllSelectedExpertiese] = useState({});
  const [selectedSubIndustryIds, setSelectedSubIndustryIds] = useState([]);
  const [allSelectedSubIndustries, setAllSelectedSubIndustries] = useState({});
  const [selectedExpertieseIdsArray, setSelectedExpertieseIdsArray] = useState([]);
  /* ------------------------------------------------------------------------------------ */

  const [specializations, setSpecializations] = useState([]);
  const [industryExpertise, setIndustryExpertise] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorPanel, setErrorPanel] = useState(0);
  const [classification, setClassification] = useState("");
  // const [focus, setFocus] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [companydataId, setCompanyDataId] = useState(0);
  const [comapanyCountryId, setComapanyCountryId] = useState(0);
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [exportCountries, setExportCountries] = useState([]);
  const [importCountries, setImportCountries] = useState([]);
  const [companyIndustryExpertise, setCompanyIndustryExpertise] = useState([]);
  const [airlineRevenue, setAirlineRevenue] = useState("");
  const [otherCarrierCode, setOtherCarrierCode] = useState("");
  const [specialProduct, setSpecialProduct] = useState("");
  const [originAirports, setOriginAirports] = useState([]);
  const [airCarrier, setAirCarrier] = useState([]);
  const [carterCount, setCarterCount] = useState("");
  const [specialTradeName, setSpecialTradeName] = useState("");
  const [otherCoolChainService, setOtherCoolChainService] = useState("");
  const [coolChainService, setCoolChainService] = useState([]);
  const [selectedTCService, setSelectedTCService] = useState([]);
  const [selectedTCEService, setSelectedTCEService] = useState([]);
  const [is24, setIs24] = useState(false);
  // const [deskId, setDeskId] = useState(0);
  const [companySettingId, setCompanySettingId] = useState(0);
  /*  const [deskPersonName, setDeskPersonName] = useState("");
   const [deskTelNum, setDeskTelNum] = useState("");
   const [deskEmailId, setDeskEmailId] = useState(""); */
  const [seaAirDesc, setSeaAirDesc] = useState("");
  const [rfcDesc, setRFCDesc] = useState("");
  const [otherECommeceOp, setOtherECommeceOp] = useState("");
  const [selectedTerminalAgent, setSelectedTerminalAgent] = useState([]);
  const [otherTerminalAgent, setOtherTerminalAgent] = useState("");
  const [annualAIRevenue, setAnnualAIRevenue] = useState("");
  const [selectedExpressOps, setSelectedExpressOps] = useState([]);
  const [expressCourierCountries, setExpressCourierCountries] = useState([
    { countryId: "", bill: "" },
  ]);
  const [expressVendors, setExpressVendors] = useState([
    { vendor: "", bill: "" },
  ]);
  const [isAirImportExpressBusiness, setIsAirImportExpressBusiness] =
    useState(false);
  const [expressImportCountry, setExpressImportCountry] = useState([]);
  const [dgrServices, setDGRServices] = useState([]);
  const [dgrPackaging, setDGRPackaging] = useState([]);
  const [airfreightRevenue, setAirfreightRevenue] = useState("");
  const [airport, setAirport] = useState([]);
  const [selectedCargoAgent, setSelectedCargoAgent] = useState([]);
  const [isOperatingExclusively, setIsOperatingExclusively] = useState(false);
  const [isConductingBsaCpaContracts, setIsConductingBsaCpaContracts] =
    useState(false);
  const [isOperatingRegularlyScheduled, setIsOperatingRegularlyScheduled] =
    useState(false);
  const [isAgentDealingDirectly, setIsAgentDealingDirectly] = useState(false);
  const [consoleService, setConsoleService] = useState([]);
  const [isSeparateDivision, setIsSeparateDivision] = useState(false);
  const [divisionOrCompanyName, setDivisionOrCompanyName] = useState("");
  const [retail, setRetail] = useState(85);
  const [wholesale, setWholesale] = useState(15);
  const [expNacDestAirport, setExpNacDestAirport] = useState([
    { airportId: "", tonnes: "" },
  ]);
  const [topImportAirport, setTopImportAirport] = useState([]);
  const [expNacTopAirline, setExpNacTopAirline] = useState([
    { airline: "", tonnes: "" },
  ]);
  const [expNacAgreementAirline, setExpNacAgreementAirline] = useState([
    { airline: "", tonnes: "" },
  ]);

  const [vendorDocuments, setVendorDocuments] = useState([]);

  const {
    focus,
    countryList,
    companyData,
    submitForm2,
    showRegisterLoadingAction,
    updateCompanyAction,
    form2SubmittedAction,
    registerLoading,
    submitForm2Action,
    form1SubmittedAction,
    submitForm1Action,
    uploadVendorDocumentAction,
    sendEmailVerification,
  } = props;

  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;

  /*   const expTceServicesList = useListFetch("expTceServices");
    const expTceIndusServicesList = useListFetch("expTceIndusServices");
    const expTheServicesList = useListFetch("expTheServices");
    const expressOperatorsList = useListFetch("expressOperators");
    const expDgrServicesList = useListFetch("expDgrServices");
    const expDgrPackagingList = useListFetch("expDgrPackaging");
    const airCargoSalesAgentList = useListFetch("airCargoSalesAgent");
    const neutralAirConsolidatorsList = useListFetch("neutralAirConsolidators"); */
  const userEmail = localStorage.getItem("userEmail");

  const validateExpertiseInfo = () => {

    if ((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32))) {

      // inputFieldData contains all data (In key value pairs of ids and answers) in which field has answer filled or remove(empty) answers after filled once time.
      let keysOfinputFieldData = Object.keys(inputFieldData) //In keysOfinputFieldData filtered all answers keys(ids)

      /* -------Here, validataion for empty questions------- */
      //allQuestionsData contains all qustions data
      const filterAllQuestionsData = allQuestionsData.filter( // here, filter all question which have no answers fiiled (fresh question) at once
        e => !keysOfinputFieldData.includes(String(e.id))
      )

      const filterAllQuestionsDataWithRequired = filterAllQuestionsData.filter((value) => value.mandatory) // here, filter all question which is required (mandatory) and no answers filled at once

      /* --------here, handle validataion for required questions------ */
      let cpyFilterAllQuestionsDataWithRequired = []
      filterAllQuestionsDataWithRequired.map((value) => {
        if (value.QuestionType === "/v1/question_types/9" && value.linkedQuestion !== null) { // here, handle if linking of radio button (Child) with only Multi Select(Parent) 
          let linkedData = inputFieldData[value.linkedQuestion]
          if (linkedData?.length !== 0) {
            if (linkedData?.includes("Pharma") || linkedData?.includes("Medical Science")) {
              cpyFilterAllQuestionsDataWithRequired.push({ ...value })
            }
          }
        } else if ((value.QuestionType === "/v1/question_types/1" || value.QuestionType === "/v1/question_types/10" || value.QuestionType === "/v1/question_types/13") && value.linkedQuestion !== null) {
          /*  here, handle if 
              1:-> linking of TEXTBOX (Child "/v1/question_types/1") with Radio Button (Parent)
              2:-> linking of SINGLE_SELECT (Child "/v1/question_types/10") with Radio Button (Parent)
              3:-> linking of MULTI_SELECT_SEARCH (Child "/v1/question_types/13") with Radio Button (Parent) */

          let linkedData = inputFieldData[value.linkedQuestion] // get checked radio option id
          const filterLinkedQuestion = allQuestionsData.filter((item) => item.id === value.linkedQuestion) // here, filter parent(radio) question which have linking with textbox  
          const data = filterLinkedQuestion[0]?.questionOptions?.filter(e => e.id === Number(linkedData)) //here, filter radio button's answer
          if (data?.length !== 0) {
            let labelValue = data?.[0]?.label;
            let labelData = labelValue?.trim()?.toLowerCase(); // here, get  radio button's answer into lowercase
            if (labelData === 'yes') {
              cpyFilterAllQuestionsDataWithRequired.push({ ...value })
            }
          }
        } else {
          cpyFilterAllQuestionsDataWithRequired.push({ ...value })
        }
      })

      //---------here, handle validations for not required question----------//
      const filterAllQuestionsData2 = allQuestionsData.filter( // here, filter questions which is not required  and not fresh question
        e => keysOfinputFieldData.includes((e.mandatory === false && String(e.id)))
      )

      const filterEmptyFieldId = keysOfinputFieldData.filter((value) => //filter empty filed ids (in which have no answer or remove answer after filled once time)
        (inputFieldData[Number(value)] === '' || inputFieldData[Number(value)]?.length === 0)
      )

      const idsAry = filterAllQuestionsData2.map((value) => value.id)  // get Ids of that question is not required 
      const filterEmptyFieldIdWithRequired = filterEmptyFieldId.filter( //filter empty field ids which field is required
        e => !(idsAry.includes(Number(e)))
      )

      const d = allQuestionsData.filter((val) => { //get question data which have  empty field id and is required.
        let id = val.id

        return filterEmptyFieldIdWithRequired.includes(id.toString())
      })

      let filterEmptyFieldIdWithRequiredWithoutLinked = []
      let a = []
      d.map((item) => {
        if (item.linkedQuestion === null) {
          filterEmptyFieldIdWithRequiredWithoutLinked.push(item.id)
          a.push([])
        } else {
          if (!no) {         // handle, if radio button value is false (NO) and question is linked
            a.push(item.id)
          } /* else {
          a.push(item.id)
        } */
        }
      })

      if (cpyFilterAllQuestionsDataWithRequired.length !== 0 || filterEmptyFieldIdWithRequiredWithoutLinked.length !== 0 || a.length !== 0) {
        cpyFilterAllQuestionsDataWithRequired.map((el) => // handle , if question is required and empty
          setValidationErrorMsg((prevValue) => {
            return {
              ...prevValue,
              [el.id]: {
                'error': 'Required',
                'id': el.id
              }
            }
          })
        )
        filterEmptyFieldIdWithRequiredWithoutLinked.map((el) => //handle if field is empty and required and have no linking
          setValidationErrorMsg((prevValue) => {
            return {
              ...prevValue,
              [el]: {
                'error': 'Required',
                'id': el
              }
            }
          })

        )
        a.map((el) =>
          setValidationErrorMsg((prevValue) => {
            return {
              ...prevValue,
              [el]: {
                'error': 'Required',
                'id': el
              }
            }
          })

        )
        return {
          message: "Please Filled required field",
          isValidated: false,
        };
      }

      // Here, Validation for min/max error if exist for any anwer
      const errorMessageData = Object.values(answerErrors)
      const answerIsValid = (element) => element.error !== '' && keysOfinputFieldData.includes(element.id.toString()) && !filterEmptyFieldIdWithRequired.includes(element.id.toString())
      /* ------------  Qustion Answer Validation Status----------- */
      if (errorMessageData?.length !== 0) {
        const isValidAnswer = errorMessageData.some(answerIsValid);

        if (isValidAnswer) { // handle if answer not filled correct with min/max value
          return {
            message: "Fill answer in correct way",
            isValidated: false,
          };
        }
      }
    }
    /* -------------------------------------------------------- */
    // -----------Tradelane Validation Status--------------
    if (validationErrorTradelane) {
      return {
        message: "Tradelane is required",
        isValidated: false,
      }
    }
    if ((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32))) {
      /* -------Import Export Country Validation Status-------- */
      if (validationErrorImportExport.airExportCountry) {
        return {
          message: "Please select at least 1 export country",
          isValidated: false,
        }
      }
      if (validationErrorImportExport.airImportCountry) {
        return {
          message: "Please select at least 1 import country",
          isValidated: false,
        }
      }
      /* ----------------------------------------------------- */

      /* ------ Air Cargo Question Validation Status------ */
      if (validationErrorAirCargo.expNacTopAirline) {
        return {
          message: "Airline is required",
          isValidated: false,
        }
      }
      if (validationErrorAirCargo.airImportAirport) {
        return {
          message: "Airport is required",
          isValidated: false,
        }
      }
      if (validationErrorAirCargo.expNacDestAirport) {
        return {
          message: "Airport is required",
          isValidated: false,
        }
      }

      // if (validationErrorAirCargo.nacOriginAirports) {
      if (validationErrorAirCargo.companyAirport) {
        return {
          // message: "Airport Branch is required",
          message: "Airport is required",
          isValidated: false,
        }
      }
      if (validationErrorAirCargo.nacAirlineRevenue) {
        return {
          message: "Anuual gross air freight is required",
          isValidated: false,
        }
      }
    }
    /* -------------------------------------------------------- */

    if (!isTermsAccepted) {
      return {
        message: "Terms and conditions not accepted",
        isValidated: false,
      };
    }
    if (!isMembershipAccepted) {
      return {
        message: "Membership agreement not accepted",
        isValidated: false,
      };
    }
    if (!isValidInfo) {
      return {
        message: "Agreement to valid into not accepted",
        isValidated: false,
      };
    }

    // if (classification !== "Vendor") {
    //   if (selectedSpecialization.length === 0) {
    //     return {
    //       message: "Choose atleast one trade lane specialization",
    //       isValidated: false,
    //       id: "selectedSpecialization",
    //     };
    //   }
    //   if (exportCountries.length === 0) {
    //     return {
    //       message: "Choose atleast one country you export to",
    //       isValidated: false,
    //       id: "exportCountries",
    //     };
    //   }
    //   if (importCountries.length === 0) {
    //     return {
    //       message: "Choose atleast one country you import from",
    //       isValidated: false,
    //       id: "importCountries",
    //     };
    //   }
    //   if (focus.length > 0) {
    //     let total = 0;
    //     focus.map((element) => {
    //       total = element.value + total;
    //     });

    //     if (total > 100) {
    //       return {
    //         message: "Total sales focus can't be greater than 100%",
    //         isValidated: false,
    //         id: "SliderSelected",
    //       };
    //     }
    //   }
    // }
    // if (classification === "Air Partner") {
    //   if (
    //     companyIndustryExpertise.indexOf(1) > -1 ||
    //     companyIndustryExpertise.indexOf(2) > -1
    //   ) {
    //     if (
    //       !airfreightRevenue ||
    //       !airfreightRevenue.match(regex.DECIMALNUMBER)
    //     ) {
    //       setErrorPanel(1);
    //       return {
    //         message: "Please enter valid annual gross airfreight revenue",
    //         isValidated: false,
    //         id: "airfreightRevenue",
    //       };
    //     }

    //     if (airport.length === 0) {
    //       setErrorPanel(1);
    //       return {
    //         message: "Please select atleast one airport branch",
    //         isValidated: false,
    //         id: "airport",
    //       };
    //     }

    //     if (companyIndustryExpertise.indexOf(1) > -1) {
    //       /*  if (selectedCargoAgent.length === 0) {
    //          setErrorPanel(1);
    //          return {
    //            message:
    //              "Please select atleast one air cargo sales agent services",
    //            isValidated: false,
    //            id: "selectedCargoAgent",
    //          };
    //        } */
    //     }

    //     if (companyIndustryExpertise.indexOf(2) > -1) {
    //       /* if (consoleService.length === 0) {
    //         setErrorPanel(1);
    //         return {
    //           message:
    //             "Please select atleast one neutral air consolidator services",
    //           isValidated: false,
    //           id: "consoleService",
    //         };
    //       } */
    //     }


    //     //not exist in this component
    //     let isexpNacDestAirportValid = true;
    //     let expNacDestAirportId = "";
    //     for (let i = 0; i < expNacDestAirport?.length; i++) {
    //       let obj = expNacDestAirport[i];
    //       if (!obj.airportId || !obj.airportId.id) {
    //         isexpNacDestAirportValid = false;
    //         expNacDestAirportId = "dAirport_" + i;
    //         break;
    //       }
    //       if (!obj.tonnes || !obj.tonnes.match(regex.ONLYNUMERIC)) {
    //         isexpNacDestAirportValid = false;
    //         expNacDestAirportId = "dAirportTonnes_" + i;
    //         break;
    //       }
    //     }
    //     if (!isexpNacDestAirportValid) {
    //       setErrorPanel(1);
    //       return {
    //         message:
    //           "Please select valid destinations with valid tones per year you export to",
    //         isValidated: false,
    //         id: expNacDestAirportId,
    //       };
    //     }

    //     let isexpNacTopAirlineValid = true;
    //     let expNacTopAirlineId = "";
    //     for (let i = 0; i < expNacTopAirline.length; i++) {
    //       let obj = expNacTopAirline[i];
    //       if (!obj.airline || !obj.airline.id) {
    //         isexpNacTopAirlineValid = false;
    //         expNacTopAirlineId = "expAirline_" + i;
    //         break;
    //       }

    //       if (!obj.tonnes || !obj.tonnes.match(regex.ONLYNUMERIC)) {
    //         isexpNacTopAirlineValid = false;
    //         expNacTopAirlineId = "expAirlineTonnes_" + i;
    //         break;
    //       }
    //     }
    //     if (!isexpNacTopAirlineValid) {
    //       setErrorPanel(1);
    //       return {
    //         message:
    //           "Please select valid airlines with valid tones per year you export with",
    //         isValidated: false,
    //         id: expNacTopAirlineId,
    //       };
    //     }

    //     if (expNacAgreementAirline.length > 0) {
    //       let isexpNacAgreementAirlineValid = true;
    //       let expNacAgreementAirlineId = "";
    //       for (let i = 0; i < expNacAgreementAirline.length; i++) {
    //         let obj = expNacAgreementAirline[i];
    //         if (!obj.airline || !obj.airline.id) {
    //           isexpNacAgreementAirlineValid = false;
    //           expNacAgreementAirlineId = "bsaAirline_" + i;
    //           break;
    //         }
    //         if (!obj.tonnes || !obj.tonnes.match(regex.ONLYNUMERIC)) {
    //           isexpNacAgreementAirlineValid = false;
    //           expNacAgreementAirlineId = "bsaAirlineTonnes_" + i;
    //           break;
    //         }
    //       }
    //       if (!isexpNacAgreementAirlineValid) {
    //         setErrorPanel(1);
    //         return {
    //           message:
    //             "Please select valid airlines with valid tones per year where you hold a BSA or CPA",
    //           isValidated: false,
    //           id: expNacAgreementAirlineId,
    //         };
    //       }
    //     }

    //     if (topImportAirport.length === 0) {
    //       setErrorPanel(1);
    //       return {
    //         message: "Please select alteast one airport you import from",
    //         isValidated: false,
    //         id: "topImportAirport",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(3) > -1) {
    //     if (!airlineRevenue || !airlineRevenue.match(regex.ONLYNUMERIC)) {
    //       setErrorPanel(3);
    //       return {
    //         message: "Please enter valid annual gross airline revenue",
    //         isValidated: false,
    //         id: "airlineRevenue",
    //       };
    //     }

    //     if (originAirports.length === 0) {
    //       setErrorPanel(3);
    //       return {
    //         message:
    //           "Please select atleast one origin airports where you operate as a carrier",
    //         isValidated: false,
    //         id: "originAirports",
    //       };
    //     }

    //     if (
    //       !specialProduct ||
    //       !specialProduct.match(regex.ALPHANUMERICWITHSPACES)
    //     ) {
    //       setErrorPanel(3);
    //       return {
    //         message:
    //           "Please select atleast one origin airports where you operate as a carrier",
    //         isValidated: false,
    //         id: "specialProduct",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(5) > -1) {
    //     if (!carterCount) {
    //       setErrorPanel(5);
    //       return {
    //         message:
    //           "Please select number of cargo charters you operate per year",
    //         isValidated: false,
    //         id: "carterCount",
    //       };
    //     }

    //     if (
    //       !specialTradeName ||
    //       !specialTradeName.match(regex.ALPHANUMERICWITHSPACES)
    //     ) {
    //       setErrorPanel(5);
    //       return {
    //         message:
    //           "Please select valid cargo commodities name or trade lanes you are specialized at",
    //         isValidated: false,
    //         id: "specialTradeName",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(6) > -1) {
    //     if (coolChainService.length === 0) {
    //       setErrorPanel(6);
    //       return {
    //         message:
    //           "Please select one or more cool chain services your company is specialized",
    //         isValidated: false,
    //         id: "coolChainService",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(7) > -1) {
    //     if (dgrServices.length === 0) {
    //       setErrorPanel(7);
    //       return {
    //         message:
    //           "Please select one or more of the following DGR and HAZMAT class services your company is specialized",
    //         isValidated: false,
    //         id: "dgrServices",
    //       };
    //     }

    //     if (dgrPackaging.length === 0) {
    //       setErrorPanel(7);
    //       return {
    //         message:
    //           "Please select one or more of following packing groups if your company operates HAZMAT packaging and transport services",
    //         isValidated: false,
    //         id: "dgrPackaging",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(8) > -1) {
    //     if (!annualAIRevenue || !annualAIRevenue.match(regex.ONLYNUMERIC)) {
    //       setErrorPanel(8);
    //       return {
    //         message:
    //           "Please enter valid annual gross airlines & integrators revenue",
    //         isValidated: false,
    //         id: "annualAIRevenue",
    //       };
    //     }

    //     if (selectedExpressOps.length === 0) {
    //       setErrorPanel(8);
    //       return {
    //         message:
    //           "Please select atleast one express operator services your company is specialized",
    //         isValidated: false,
    //         id: "selectedExpressOps",
    //       };
    //     }

    //     let isexpressCourierCountriesValid = true;
    //     let expressCourierCountriesId = "";
    //     for (let i = 0; i < expressCourierCountries.length; i++) {
    //       let obj = expressCourierCountries[i];
    //       if (!obj.countryId) {
    //         isexpressCourierCountriesValid = false;
    //         expressCourierCountriesId = "expCourierDest_" + i;
    //         break;
    //       }
    //       if (!obj.bill || !obj.bill.match(regex.ONLYNUMERIC)) {
    //         isexpressCourierCountriesValid = false;
    //         expressCourierCountriesId = "expCourierDestBill_" + i;
    //         break;
    //       }
    //     }

    //     if (!isexpressCourierCountriesValid) {
    //       setErrorPanel(8);
    //       return {
    //         message:
    //           "Please valid information about your express and courier destination countries",
    //         isValidated: false,
    //         id: expressCourierCountriesId,
    //       };
    //     }

    //     let isexpressVendorsValid = true;
    //     let expressVendorsId = "";
    //     for (let i = 0; i < expressVendors.length; i++) {
    //       let obj = expressVendors[i];
    //       if (!obj.vendor || !obj.vendor.match(regex.ALPHANUMERICWITHSPACES)) {
    //         isexpressVendorsValid = false;
    //         expressVendorsId = "vendorName_" + i;
    //         break;
    //       }
    //       if (!obj.bill || !obj.bill.match(regex.ONLYNUMERIC)) {
    //         isexpressVendorsValid = false;
    //         expressVendorsId = "vendorBill_" + i;
    //         break;
    //       }
    //     }

    //     if (!isexpressVendorsValid) {
    //       setErrorPanel(8);
    //       return {
    //         message:
    //           "Please valid information about your vendors (Express Carriers - Integrators)",
    //         isValidated: false,
    //         id: expressVendorsId,
    //       };
    //     }

    //     if (isAirImportExpressBusiness) {
    //       if (expressImportCountry.length === 0) {
    //         setErrorPanel(8);
    //         return {
    //           message:
    //             "Please select atleast one country where you are involved in air import express business",
    //           isValidated: false,
    //           id: "expressImportCountry",
    //         };
    //       }
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(9) > -1) {
    //     if (selectedTCService.length === 0) {
    //       setErrorPanel(9);
    //       return {
    //         message:
    //           "Please select one or more of the following time-critical services your company is specialized",
    //         isValidated: false,
    //         id: "selectedTCService",
    //       };
    //     }
    //     if (selectedTCEService.length === 0) {
    //       setErrorPanel(9);
    //       return {
    //         message:
    //           "Please select one or more of the following industries your company is specialized",
    //         isValidated: false,
    //         id: "selectedTCEService",
    //       };
    //     }

    //     if (is24) {
    //       if (
    //         !deskPersonName ||
    //         !deskPersonName.match(regex.ALPHANUMERICWITHSPACES)
    //       ) {
    //         setErrorPanel(9);
    //         return {
    //           message: "Please enter valid name for desk person",
    //           isValidated: false,
    //           id: "deskPersonName",
    //         };
    //       }
    //       if (
    //         !deskTelNum ||
    //         !deskTelNum.match(regex.PHONE_WITH_SPECIALSYMBOLS)
    //       ) {
    //         setErrorPanel(9);
    //         return {
    //           message: "Please enter valid desk telephone number",
    //           isValidated: false,
    //           id: "deskTelNum",
    //         };
    //       }
    //       if (!deskEmailId || !deskEmailId.match(regex.EMAIL)) {
    //         setErrorPanel(9);
    //         return {
    //           message: "Please enter valid desk email id",
    //           isValidated: false,
    //           id: "deskEmailId",
    //         };
    //       }
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(10) > -1) {
    //     if (selectedTerminalAgent.length === 0) {
    //       setErrorPanel(10);
    //       return {
    //         message:
    //           "Please select atleast one terminal handling agent service",
    //         isValidated: false,
    //         id: "selectedTerminalAgent",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(11) > -1) {
    //     if (selectedECommeceOp.length === 0) {
    //       setErrorPanel(11);
    //       return {
    //         message: "Please select atleast one e-commerce operator",
    //         isValidated: false,
    //         id: "selectedECommeceOp",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(12) > -1) {
    //     if (!seaAirDesc || !seaAirDesc.match(regex.DESCRIPTION)) {
    //       setErrorPanel(12);
    //       return {
    //         message: "Please enter valid description for Sea Air service",
    //         isValidated: false,
    //         id: "seaAirDesc",
    //       };
    //     }
    //   }

    //   if (companyIndustryExpertise.indexOf(13) > -1) {
    //     if (!rfcDesc || !rfcDesc.match(regex.DESCRIPTION)) {
    //       setErrorPanel(13);
    //       return {
    //         message: "Please enter valid description for RFS service",
    //         isValidated: false,
    //         id: "rfcDesc",
    //       };
    //     }
    //   }
    // }
    return { message: "", isValidated: true };
  };

  const submitForm = async () => {
    let validationRes = { message: "", isValidated: true };
    if (localStorage.getItem("Valid") === "notvalid" || localStorage.getItem("Valid") === undefined || localStorage.getItem("Valid") === null) {
      validationRes = validateExpertiseInfo();
    }

    if (!validationRes?.isValidated) {
      localStorage.setItem("Valid", "notvalid")
      message.error(validationRes?.message);
      submitForm2Action(false);
      if (validationRes.id) {
        let eContext = document
          .getElementById(validationRes.id)
          ?.getElementsByClassName("ant-select-selection")[0];
        if (!eContext) {
          eContext = document.getElementById(validationRes.id);
        }
        if (eContext) {
          eContext.style = "border-color: red";
        }
      }
      return;
    } else {
      localStorage.setItem("Valid", "valid")
    }
    showRegisterLoadingAction(true); //here, handle loader of 'Next' Button onClick

    /*     let _airExportCountry = [];
        exportCountries.map((obj) =>
          _airExportCountry.push({
            country: `/v1/countries/${obj}`,
            subject: "airExportCountry",
          })
        );
        let _airImportCountry = [];
        importCountries.map((obj) =>
          _airImportCountry.push({
            country: `/v1/countries/${obj}`,
            subject: "airImportCountry",
          })
        );
        let _expNacDestAirport = [];
        if (expNacDestAirport?.length > 0) {
          expNacDestAirport.map((obj) =>
            _expNacDestAirport.push({
              airport: `/v1/airport/${obj.airportId.id}`,
              destinationAirportTonsPerYear: `${obj.tonnes}`,
            })
          );
        }
        let _expNacTopAirline = [];
        if (expNacTopAirline?.length > 0)
          expNacTopAirline.map((obj) =>
            obj.id
              ? _expNacTopAirline.push({
                id: `/v1/exp_nac_top_airlines/${obj.id}`,
                airline: { id: `/v1/airline/${obj.airline.id}` },
                topAirlineTonsPerYear: `${obj.tonnes}`,
              })
              : _expNacTopAirline.push({
                airline: { id: `/v1/airline/${obj.airline.id}` },
                topAirlineTonsPerYear: `${obj.tonnes}`,
              })
          );
        let _expNacAgreementAirline = [];
        if (expNacAgreementAirline?.length)
          expNacAgreementAirline.map((obj) =>
            obj.id
              ? _expNacAgreementAirline.push({
                id: `/v1/exp_nac_agreement_airlines/${obj.id}`,
                airline: { id: `/v1/airline/${obj.airline.id}` },
                agreementTonsPerYear: `${obj.tonnes}`,
              })
              : _expNacAgreementAirline.push({
                airline: { id: `/v1/airline/${obj.airline.id}` },
                agreementTonsPerYear: `${obj.tonnes}`,
              })
          );
        let _topImportAirport = [];
        if (topImportAirport?.length)
          topImportAirport.map((obj) =>
            _topImportAirport.push({
              airport: `/v1/airport/${obj.airport.id}`,
              subject: "airImportAirport",
            })
          );
        let _airCarrier = [];
        if (airCarrier?.length)
          airCarrier.map((obj) => _airCarrier.push(`/v1/airline/${obj.id}`));
        let _originAirports = [];
        originAirports.map((obj) => _originAirports.push(`/v1/airport/${obj.id}`));
        let _coolChainService = [];
        coolChainService.map((obj) =>
          _coolChainService.push(`/v1/exp_che_services/${obj}`)
        );
        let _dgrServices = [];
        dgrServices.map((obj) => _dgrServices.push(`/v1/exp_dgr_services/${obj}`));
        let _dgrPackaging = [];
        dgrPackaging.map((obj) =>
          _dgrPackaging.push(`/v1/exp_dgr_packagings/${obj}`)
        );
        let _selectedTerminalAgent = [];
        selectedTerminalAgent.map((obj) =>
          _selectedTerminalAgent.push(`/v1/exp_the_services/${obj}`)
        );
        let _selectedECommeceOp = [];
        selectedECommeceOp.map((obj) =>
          _selectedECommeceOp.push(`/v1/ecommerce_operators/${obj}`)
        );
        let _selectedTCService = [];
        selectedTCService.map((obj) =>
          _selectedTCService.push(`/v1/exp_tce_indus_services/${obj}`)
        );
        let _selectedTCEService = [];
        selectedTCEService.map((obj) =>
          _selectedTCEService.push(`/v1/exp_tce_services/${obj}`)
        );
        let _expressImportCountry = [];
        expressImportCountry.map((obj) =>
          _expressImportCountry.push({
            country: `/v1/countries/${obj}`,
            subject: "express_import_country",
          })
        );
        let _expressOperators = [];
        selectedExpressOps.map((obj) =>
          _expressOperators.push(`/v1/express_operators/${obj}`)
        );
    
        let _expressCourierCountries = [];
        expressCourierCountries.map(
          (obj) =>
            obj.countryId &&
            _expressCourierCountries.push({
              country: `/v1/countries/${obj.countryId}`,
              airwaybillsPerYear: obj.bill,
            })
        );
        let _expressVendors = [];
        expressVendors.map((obj) =>
          obj.id
            ? _expressVendors.push({
              id: `/v1/exp_ecw_top_vendors/${obj.id}`,
              vendor: obj.vendor,
              airwaybillsPerYear: obj.bill,
            })
            : _expressVendors.push({
              vendor: obj.vendor,
              airwaybillsPerYear: obj.bill,
            })
        );
    
        let obj = {
          companySettings: [
            {
              id: `/v1/company_settings/${companySettingId}`,
              status: "Pending",
              profileReadiness: 70,
              appFinished: true,
            },
          ],
          companyData: [],
        };
    
        if (classification !== "Vendor") {
          obj["specializations"] = selectedSpecialization.map(
            (obj) => "/v1/specializations/" + obj
          );
          obj["airExportCountry"] = _airExportCountry;
          obj["airImportCountry"] = _airImportCountry;
          obj["companyData"][0] = {
            id: `v1/company_datas/${companydataId}`,
            focusExports: focus?.filter((f) => f.id === "focusExports")[0]?.value,
            focusImports: focus?.filter((f) => f.id === "focusImports")[0]?.value,
            focusCrossTrade: focus?.filter((f) => f.id === "focusCrossTrade")[0]
              ?.value,
            focusEcommerce: focus?.filter((f) => f.id === "focusEcommerce")[0]?.value,
          };
          if (classification === "Air Partner") {
            obj["companyData"][0]["nacAirlineRevenue"] = airfreightRevenue;
            obj["companyData"][0]["isOperatingExclusively"] =
              isOperatingExclusively;
            obj["companyData"][0]["isConductingBsaCpaContracts"] =
              isConductingBsaCpaContracts;
            obj["companyData"][0]["isOperatingRegularlyScheduled"] =
              isOperatingRegularlyScheduled;
            obj["companyData"][0]["isAgentDealingDirectly"] =
              isAgentDealingDirectly;
            obj["companyData"][0]["isSeparateDivision"] = isSeparateDivision;
            obj["companyData"][0]["divisionOrCompanyName"] = divisionOrCompanyName;
            obj["companyData"][0]["retail"] = retail + "";
            obj["companyData"][0]["wholesale"] = wholesale + "";
            obj["companyData"][0]["gsaAirlineRevenue"] = airlineRevenue;
            obj["companyData"][0]["gsaIata"] = otherCarrierCode;
            obj["companyData"][0]["gsaSpecialServices"] = specialProduct;
            obj["companyData"][0]["acbTradelane"] = specialTradeName;
            obj["companyData"][0]["cheOthers"] = otherCoolChainService;
            obj["companyData"][0]["theOther"] = otherTerminalAgent;          
            obj["companyData"][0]["seaAirServices"] = seaAirDesc;
            obj["companyData"][0]["rfsServices"] = rfcDesc;
            obj["companyData"][0]["serviceDesk"] = is24;
            obj["companyData"][0]["ecwAirlineRevenue"] = annualAIRevenue;
            obj["companyData"][0]["airImportExpress"] = isAirImportExpressBusiness;
            if (carterCount) {
              obj["companyData"][0]["acbCargoCharters"] = carterCount;
            }
          }
        }
    
        if (classification === "Air Partner") {
          obj["subIndustry"] = allSelectedSubIndustries.subIndustry;
          obj["expertiese"] = allSelectedExpertiese.expertiese;
          obj["nacOriginAirports"] = airport.map((a) => `/v1/airport/${a.id}`);
          obj["airCargoSalesAgent"] = selectedCargoAgent.map(
            (a) => `/v1/air_cargo_sales_agents/${a}`
          );
          obj["neutralAirConsolidators"] = neutralAirConsolidatorsList.filter(
            (nac) => consoleService.indexOf(nac.id) > -1
          );
          if (_expNacDestAirport[0].airportId) {
            obj["expNacDestAirport"] = _expNacDestAirport;
          }
          if (_expNacTopAirline[0]?.airportId)
            obj["expNacTopAirline"] = _expNacTopAirline;
          if (_expNacAgreementAirline[0]?.airportId)
            obj["expNacAgreementAirline"] = _expNacAgreementAirline;
          obj["airImportAirport"] = _topImportAirport;
          obj["gsaAgentAirCarriers"] = _airCarrier;
          obj["gsaOriginAirports"] = _originAirports;
          obj["expCheServices"] = _coolChainService;
          obj["expDgrServices"] = _dgrServices;
          obj["expDgrPackaging"] = _dgrPackaging;
          obj["expTheServices"] = _selectedTerminalAgent;        
          
          obj["expTceServices"] = _selectedTCEService;
          obj["expressImportCountry"] = _expressImportCountry;
          obj["expressOperators"] = _expressOperators;
          obj["expEcwDestinationAirport"] = _expressCourierCountries;
          obj["expEcwTopVendors"] = _expressVendors;
    
          let _deskService = [
            {
              deskName: deskPersonName,
              deskTel: deskTelNum,
              deskEmail: deskEmailId,
            },
          ];
          if (deskId) {
            _deskService[0]["id"] = `/v1/desk_services/${deskId}`;
          }
         
        }
    
        if (vendorDocuments.length > 0) {
          const _docs = vendorDocuments.map((d) => `/v1/documents/${d.id}`);
          obj["document"] = _docs;
        } */

    let obj = {
      companySettings: [
        {
          id: `/v1/company_settings/${companySettingId}`,
          status: "Pending",
          profileReadiness: 70,
          appFinished: true,
        },
      ],
      // companyData: [],
    };

    if (vendorDocuments.length > 0) {
      const _docs = vendorDocuments.map((d) => `/v1/documents/${d.id}`);
      obj["document"] = _docs;
    }
    let updateResponse
    if (companyId === 0) {

    } else {
      updateResponse = await updateCompanyAction(obj, companyId);
      // true;
    }

    if (updateResponse) {
      const params = {
        email: userEmail,
      };

      const response = await sendEmailVerification(params);
      form2SubmittedAction(false);
      submitForm2Action(false);
      form1SubmittedAction(false);
      submitForm1Action(false);
      showRegisterLoadingAction(false);
      localStorage.setItem("status", "Pending")
      // localStorage.removeItem("status");
      props.history.push("/network");
    }
  };

  // Here, get all expertiese list
  let expertiseListArray = [
    ...companyData.subIndustry
      ?.map((a) => {
        return a.expertieses;
      })
      .flat(),
  ];

  // create expertise list with lable and value
  let expertiseList = expertiseListArray?.map((a) => ({
    label: a?.name,
    value: a?.id,
    // disabled:false
  }));

  useEffect(() => {
    initialFunction();
    setCompanyId(companyData?.id)
    getSpecializations();
  }, []);

  /*   useEffect(() => {
    
    }, []); */

  // get sub-industries list
  const initialFunction = async () => {
    const response = await dispatch(getSubGroupList());
    setSubIndustries(response);
  };

  // get sub-industries and expertiese from redux and set in state array
  useEffect(() => {
    if (companyData.subIndustry) {
      // Here, selectedSubIndustryIds conatain in an state array which comes from redux
      const subIndustryIdsArray = companyData.subIndustry.map((obj) => obj.id);
      setSelectedSubIndustryIds(subIndustryIdsArray);
    }
    if (companyData.expertiese) {
      // Here, set expertise ids in stateArray which comes from redux
      const expertieseIdsArray = companyData.expertiese.map((obj) => obj.id);
      setSelectedExpertieseIdsArray(expertieseIdsArray);
    }
    const expertisestoCheckedValues = findExpertiseV3(companyData);
    setCheckedValObj(expertisestoCheckedValues);
  }, [companyData]);

  // Create custom sub-industries array of object to send api on save
  useEffect(() => {
    if (selectedSubIndustryIds.length) {
      let params = {
        subIndustry: [],
      };
      params["subIndustry"] = selectedSubIndustryIds.map(
        (element) => "/v1/sub_industries/" + element
      );
      setAllSelectedSubIndustries(params);
    }
  }, [selectedSubIndustryIds]);

  // Here, all selectedExpertieseIds conatain in an state array
  useEffect(() => {
    if (checkedValObj) {
      let value = [];
      const expertiseCheckedIdsArray = Object.values(checkedValObj).flat();
      value.push(...expertiseCheckedIdsArray);
      setSelectedExpertieseIds(value);
    }
  }, [checkedValObj]);

  useEffect(() => {
    // Create custom expertiese array of object to send api on save
    if (checkedValueStatus) {
      let params = {
        expertiese: [],
      };
      params["expertiese"] = selectedExpertieseIds.map(
        (element) => "/v1/expertieses/" + element
      );
      setAllSelectedExpertiese(params);

      // Invoke api and Update expertiese when we select any one
      if (
        selectedExpertieseIds.length !== selectedExpertieseIdsArray.length &&
        checkedValueStatus
      ) {
        const addData = formatSelectedExpertisesV2(selectedExpertieseIds);
        const apiUpdate = debounce(() => {
          dispatch(
            updateSelectedExpertisesV2(addData, companyId, "expertiese")
          ).then(() => {
            message.success("Information has been updated.");
            setCheckedValueStatus(false);
          });
        }, 500);
        apiUpdate();
        // setCheckedValueStatus(false)
      }
    }
  }, [selectedExpertieseIds]);

  useEffect(() => {
    if (companyData) {
      const {
        // specializations,
        airExportCountry,
        airImportCountry,
        expressImportCountry,
        nacOriginAirports,
        neutralAirConsolidators,
        expNacDestAirport,
        airImportAirport,
        expNacTopAirline,
        expNacAgreementAirline,
        id,
        companySettings,
        countries,
        document,
      } = companyData;
      if (countries) {
        setComapanyCountryId(countries.id);
      }

      setCompanyId(id);
      if (specializations) {
        let _specialization = [];
        specializations.map((s) => {
          return _specialization.push(s.id);
        });
        setSelectedSpecialization(_specialization);
      }
      if (companyData.companyData) {
        const _companyData = companyData.companyData[0];
        const {
          focusExports,
          focusImports,
          focusCrossTrade,
          focusEcommerce,
          gsaAirlineRevenue,
          gsaIata,
          gsaSpecialServices,
          acbTradelane,
          acbCargoCharters,
          cheOthers,
          serviceDesk,
          seaAirServices,
          rfsServices,
          theOther,
          ecwAirlineRevenue,
          airImportExpress,
          nacAirlineRevenue,
          isOperatingExclusively,
          isConductingBsaCpaContracts,
          isOperatingRegularlyScheduled,
          isAgentDealingDirectly,
          isSeparateDivision,
          divisionOrCompanyName,
          retail,
          wholesale,
          eCommerceOperatorOther,
          id,
        } = _companyData;

        setCompanyDataId(id);
        const _focusObj = [
          {
            id: "focusExports",
            title: "Exports",
            value: focusExports ?? 25,
          },
          {
            id: "focusImports",
            title: "Imports",
            value: focusImports ?? 25,
          },
          {
            id: "focusCrossTrade",
            title: "Cross Trade",
            value: focusCrossTrade ?? 25,
          },
          {
            id: "focusEcommerce",
            title: "E-commerce",
            value: focusEcommerce ?? 25,
          },
        ];
        // setFocus(_focusObj);
        setAirlineRevenue(gsaAirlineRevenue);
        setOtherCarrierCode(gsaIata);
        setSpecialProduct(gsaSpecialServices);
        setSpecialTradeName(acbTradelane);
        setCarterCount(acbCargoCharters);
        setOtherCoolChainService(cheOthers);
        serviceDesk !== "" && setIs24(serviceDesk);
        setSeaAirDesc(seaAirServices);
        setRFCDesc(rfsServices);
        setOtherTerminalAgent(theOther);
        setAnnualAIRevenue(ecwAirlineRevenue);
        airImportExpress !== "" &&
          setIsAirImportExpressBusiness(airImportExpress);
        setAirfreightRevenue(nacAirlineRevenue);
        isOperatingExclusively !== "" &&
          setIsOperatingExclusively(isOperatingExclusively);
        isConductingBsaCpaContracts !== "" &&
          setIsConductingBsaCpaContracts(isConductingBsaCpaContracts);
        isOperatingRegularlyScheduled !== "" &&
          setIsOperatingRegularlyScheduled(isOperatingRegularlyScheduled);
        isAgentDealingDirectly !== "" &&
          setIsAgentDealingDirectly(isAgentDealingDirectly);
        isSeparateDivision !== "" && setIsSeparateDivision(isSeparateDivision);
        setDivisionOrCompanyName(divisionOrCompanyName);
        retail && setRetail(parseInt(retail));
        wholesale && setWholesale(parseInt(wholesale));
        setOtherECommeceOp(eCommerceOperatorOther);
      }

      if (airExportCountry) {
        let _exportCountries = [];
        airExportCountry.map((s) => {
          return _exportCountries.push(s.country.id);
        });
        setExportCountries(_exportCountries);
      }
      if (airImportCountry) {
        let _importCountries = [];
        airImportCountry.map((s) => {
          return _importCountries.push(s.country.id);
        });
        setImportCountries(_importCountries);
      }

      if (expressImportCountry) {
        let _expressImportCountry = [];
        /*   expressImportCountry.map((eic) =>
            _expressImportCountry.push(eic.country.id)
          ); */
        setExpressImportCountry(_expressImportCountry);
      }

      if (nacOriginAirports) {
        setAirport(nacOriginAirports);
      }
      if (neutralAirConsolidators) {
        let _neutralAirConsolidators = [];
        /*   neutralAirConsolidators.map((obj) =>
            _neutralAirConsolidators.push(obj.id)
          ); */
        setConsoleService(_neutralAirConsolidators);
      }
      if (expNacDestAirport?.length > 0) {
        let _expNacDestAirport = [];
        expNacDestAirport.map((obj) =>
          _expNacDestAirport.push({
            airportId: obj.airport,
            tonnes: obj.destinationAirportTonsPerYear,
            id: obj.id,
          })
        );
        setExpNacDestAirport(_expNacDestAirport);
      }

      if (expNacTopAirline?.length > 0) {
        let _expNacTopAirline = [];
        expNacTopAirline.map((obj) =>
          _expNacTopAirline.push({
            airline: obj.airline,
            tonnes: obj.topAirlineTonsPerYear,
            id: obj.id,
          })
        );
        setExpNacTopAirline(_expNacTopAirline);
      }

      if (expNacAgreementAirline?.length > 0) {
        let _expNacAgreementAirline = [];
        /* expNacAgreementAirline.map((obj) =>
          _expNacAgreementAirline.push({
            airline: obj.airline,
            tonnes: obj.agreementTonsPerYear,
            id: obj.id,
          })
        ); */
        setExpNacAgreementAirline(_expNacAgreementAirline);
      }

      if (airImportAirport) {
        setTopImportAirport(airImportAirport);
      }

      if (companySettings) {
        const _companySettings = companySettings[0];
        const { id, classification } = _companySettings;
        setCompanySettingId(id);
        setClassification(classification);
        if (classification === "Associate") {
          getAssociateExpertise();
        } else if (/*  classification === "Vendor" */   companyData?.subIndustry?.filter(item => item.id === 7)?.length !== 0) {
          getVendorExpertise();
        } else {
          getIndustryExpertise();
        }
      }

      if (document?.length > 0) {
        setVendorDocuments(document);
      }

      setLoading(false);
    }
  }, [companyData]);

  useEffect(() => {
    if (submitForm2 && !registerLoading) {
      submitForm();
    }
  }, [submitForm2]);

  // Here, Integrate api for Update subIndustries data
  const handleSubIndustry = async (id) => {
    setSelectedSubIndustryIds(id);
    if (id) {
      let params = {
        subIndustry: [],
      };
      params["subIndustry"] = id.map(
        (element) => "/v1/sub_industries/" + element
      );
      await dispatch(updateCompany(params, companyId));
      message.success("The information has been updated");
    }
  };

  // Here, select expertiese
  const handleExpertiseSelection = (list, checkedValues) => {
    setCheckedValObj((prevState) => ({
      ...prevState,
      [list]: checkedValues,
    }));
    setCheckedValueStatus(true);
  };

  const panelHeader = (text) => {
    return (
      <div className={`panelTitle`}>
        <img src={panelIcon} className="panelIcon" />
        <span>{text}</span>
      </div>
    );
  };

  const getSpecializations = async () => {
    const response = await getSpecializationList();
    if (response) {
      setSpecializations(response["hydra:member"]);
    }
  };

  const getIndustryExpertise = async () => {
    const response = await getIndustryExpertiseList();
    if (response) {
      setIndustryExpertise(response["hydra:member"]);
    }
  };

  const getAssociateExpertise = async () => {
    const response = await getAssociateExpertiseList();
    if (response) {
      setIndustryExpertise(response["hydra:member"]);
    }
  };

  const getVendorExpertise = async () => {
    const response = await getVendorExpertiseList();
    if (response) {
      setIndustryExpertise(response["hydra:member"]);
    }
  };

  const changeExpressCourierCountry = (value, index) => {
    let list = [...expressCourierCountries];
    list[index]["countryId"] = value;
    setExpressCourierCountries(list);
  };
  const changeAirwayBills = (value, index) => {
    let list = [...expressCourierCountries];
    list[index]["bill"] = value;
    setExpressCourierCountries(list);
  };
  const addExpressCourierCountries = () => {
    let list = [...expressCourierCountries, { countryId: "", bill: "" }];
    setExpressCourierCountries(list);
  };

  const changeExpressVendor = (value, index) => {
    let list = [...expressVendors];
    list[index]["vendor"] = value;
    setExpressVendors(list);
  };
  const changeVendorBills = (value, index) => {
    let list = [...expressVendors];
    list[index]["bill"] = value;
    setExpressVendors(list);
  };
  const addExpressVendor = () => {
    let list = [...expressVendors, { vendor: "", bill: "" }];
    setExpressVendors(list);
  };

  const setRetailWholesale = (value) => {
    const { one, two } = value;
    setRetail(one);
    setWholesale(two);
  };

  const addExpNacDestAirport = () => {
    let list = [...expNacDestAirport, { airportId: "", tonnes: "" }];
    setExpNacDestAirport(list);
  };

  const changeAirport = (value, index) => {
    let list = [...expNacDestAirport];
    list[index]["airportId"] = value;
    setExpNacDestAirport(list);
  };

  const changeTons = (value, index) => {
    let list = [...expNacDestAirport];
    list[index]["tonnes"] = value;
    setExpNacDestAirport(list);
  };

  const changeAirline = (value, index) => {
    let list = [...expNacTopAirline];
    list[index]["airline"] = value;
    setExpNacTopAirline(list);
  };

  const changeAirlineTons = (value, index) => {
    let list = [...expNacTopAirline];
    list[index]["tonnes"] = value;
    setExpNacTopAirline(list);
  };

  const addExpNacTopAirline = () => {
    let list = [...expNacTopAirline, { airline: "", tonnes: "" }];
    setExpNacTopAirline(list);
  };

  const changeAgreementAirline = (value, index) => {
    let list = [...expNacAgreementAirline];
    list[index]["airline"] = value;
    setExpNacAgreementAirline(list);
  };

  const changeAgreementTons = (value, index) => {
    let list = [...expNacAgreementAirline];
    list[index]["tonnes"] = value;
    setExpNacAgreementAirline(list);
  };

  const addExpNacAgreementAirline = () => {
    let list = [...expNacAgreementAirline, { airline: "", tonnes: "" }];
    setExpNacAgreementAirline(list);
  };

  const deleteExpNacDestAirport = (index) => {
    let list = [...expNacDestAirport];
    list.splice(index, 1);
    setExpNacDestAirport(list);
  };

  const deleteExpNacTopAirline = (index) => {
    let list = [...expNacTopAirline];
    list.splice(index, 1);
    setExpNacTopAirline(list);
  };

  const deleteExpNacAgreementAirline = (index) => {
    let list = [...expNacAgreementAirline];
    list.splice(index, 1);
    setExpNacAgreementAirline(list);
  };

  const deleteExpressCourierCountries = (index) => {
    let list = [...expressCourierCountries];
    list.splice(index, 1);
    setExpressCourierCountries(list);
  };

  const deleteExpressVendor = (index) => {
    let list = [...expressVendors];
    list.splice(index, 1);
    setExpressVendors(list);
  };

  const handleFocusSlider = (targetIndex, values) => {
    let value = values;
    let diff = focus[targetIndex].value - value;
    const nextIndex = targetIndex === focus?.length - 1 ? 0 : targetIndex + 1;
    const newValues = [...focus];

    // Set new value
    newValues[targetIndex].value = value;

    // Move from last to first
    // const moveToNext = (i) => {
    //   if (i === newValues.length - 1) {
    //     return updateNext(0);
    //   }
    //   return updateNext(i + 1);
    // };

    // Recrusive call
    // const updateNext = (i) => {
    //   // End reached
    //   if (diff === 0 || targetIndex === i) {
    //     return;
    //   }

    //   // Substract from next --------------------------
    //   if (diff < 0) {
    //     if (newValues[i].value === 0) {
    //       return moveToNext(i);
    //     }
    //     // Can subsctract all
    //     if (newValues[i].value >= Math.abs(diff)) {
    //       return (newValues[i].value = newValues[i].value - Math.abs(diff));
    //     } else {
    //       //  calc remaining diff, Set to 0 and move to next
    //       diff = diff + newValues[i].value;

    //       newValues[i].value = 0;
    //       return moveToNext(i);
    //     }
    //   }

    //   // If deacresing, add to next ----------------------------------
    //   else {
    //     return (newValues[i].value = newValues[i].value + diff);
    //   }
    // };
    // updateNext(nextIndex);
    // setFocus(newValues);
  };

  // const updateData = () => {
  //   let data = {};
  //   if (focus.length > 0) {
  //     let total = 0;
  //     focus.map((element) => {
  //       total = element.value + total;
  //     });
  //     if (total > 100) {
  //       message.error("Total sales focus can't be greater than 100%");
  //     } else {
  //       focus.forEach((a) => {
  //         data[a.id] = a.value;
  //       });
  //     }
  //   }
  // };
  const updateData = async () => {
    if (focus?.length > 0) {
      let total = 0;
      let fdata = focus?.map((element) => {
        total = element.value + total;
      });
      if (total > 100) {
        message.error("Total sales focus can't be greater than 100%");
      } else {
        let obj = {
          companySettings: [
            {
              id: `/v1/company_settings/${companySettingId}`,
              status: "Pending",
              profileReadiness: 70,
              appFinished: true,
            },
          ],
          companyData: [],
        };
        obj["companyData"][0] = {
          id: `v1/company_datas/${companydataId}`,
          focusExports: focus.filter((f) => f.id === "focusExports")[0]?.value,
          focusImports: focus.filter((f) => f.id === "focusImports")[0].value,
          focusCrossTrade: focus.filter((f) => f.id === "focusCrossTrade")[0]?.value,
          focusEcommerce: focus.filter((f) => f.id === "focusEcommerce")[0]?.value,
        };
        await updateCompanyAction(obj, companyId)
      }

    }

  }
  const acbCargoCharterList = [
    { label: "0-10", value: "0-10" },
    { label: "10-30", value: "10-30" },
    { label: "30-50", value: "30-50" },
    { label: "50-100", value: "50-100" },
    { label: "over 100", value: "over 100" },
  ];

  const updateTradeLaneSpec = (value) => {
    if (value && value.length > 3) {
      message.error("you can select only 3 specializations");
      return;
    }
    setSelectedSpecialization(value);
  };

  const updateTopImportAirport = (value) => {
    if (value && value.length > 5) {
      message.error("you can select only 5 airports");
      return;
    }
    setTopImportAirport(value);
  };

  const updateExportCountries = (value) => {
    if (value && value.length > 5) {
      message.error("you can select only 5 countries");
      return;
    }
    setExportCountries(value);
  };

  const saveExportCountries = async () => {
    let _airExportCountry = [];
    let obj = {
      companySettings: [
        {
          id: `/v1/company_settings/${companySettingId}`,
          status: "Pending",
          profileReadiness: 70,
          appFinished: true,
        },
      ],
      companyData: [],
    };
    const data = exportCountries?.map((item) =>
      _airExportCountry.push({
        country: `/v1/countries/${item}`,
        subject: "airExportCountry",
      }),
    );
    obj["airExportCountry"] = _airExportCountry;
    await updateCompanyAction(obj, companyId)
  };

  const saveTradeLaneSpec = async (event, special) => {

    // const areEqual = selectedSpecialization.length === event.length && selectedSpecialization.every((value, index) => value === event[index]);   
    // if(!areEqual){
    if (selectedSpecialization && selectedSpecialization?.length > 3) {
      message.error("you can select only 3 specializations");
      return;
    }
    let obj = {
      companySettings: [
        {
          id: `/v1/company_settings/${companySettingId}`,
          status: "Pending",
          profileReadiness: 70,
          appFinished: true,
        },
      ],
      companyData: [],
    };
    obj["specializations"] = selectedSpecialization.map(
      (obj) => "/v1/specializations/" + obj
    );
    await updateCompanyAction(obj, companyId)
    // }

  }
  const saveImportCountries = async () => {
    let _airImportCountry = [];

    let obj = {
      companySettings: [
        {
          id: `/v1/company_settings/${companySettingId}`,
          status: "Pending",
          profileReadiness: 70,
          appFinished: true,
        },
      ],
      companyData: [],
    };

    const data = importCountries?.map((obj) =>
      _airImportCountry.push({
        country: `/v1/countries/${obj}`,
        subject: "airImportCountry",
      })
    );
    obj["airImportCountry"] = _airImportCountry;
    await updateCompanyAction(obj, companyId)
  };
  // const updateFocus = async () =>{
  //   if (focus.length > 0) {
  //     let total = 0;
  //     focus?.map((element) => {
  //       total = element.value + total;
  //     });
  //     if (total > 100) {
  //       message.error("Total sales focus can't be greater than 100%");
  //     } else {
  //       let obj = {
  //         companySettings: [
  //           {
  //             id: `/v1/company_settings/${companySettingId}`,
  //             status: "Pending",
  //             profileReadiness: 70,
  //             appFinished: true,
  //           },
  //         ],
  //         companyData: [],
  //       };
  //       obj["companyData"][0] = {
  //         id: `v1/company_datas/${companydataId}`,
  //         focusExports: focus.filter((f) => f.id === "focusExports")[0].value,
  //         focusImports: focus.filter((f) => f.id === "focusImports")[0].value,
  //         focusCrossTrade: focus.filter((f) => f.id === "focusCrossTrade")[0]
  //           .value,
  //         focusEcommerce: focus.filter((f) => f.id === "focusEcommerce")[0].value,
  //       };
  //       await updateCompanyAction(obj, companyId)
  //     }

  //   }

  // }

  const updateImportCountries = (value) => {
    if (value.length > 5) {
      message.error("you can select only 5 countries");
      return;
    }
    setImportCountries(value);
  };

  const uploadFiles = (files) => {
    let data;
    files.forEach((file) => {
      // Data for Upload
      data = new FormData();
      data.append("file", file);
      data.append("source", "directory");

      // Data for Preview and progress
      const previewData = {
        id: generateId(),
        progress: 0,
        size: file.size,
        text: file.name.replace(/\.[^/.]+$/, ""),
        type: file.type,
      };
      // API request
      uploadDocument(data, previewData);
    });
  };

  const uploadDocument = async (data, previewData) => {
    const res = await uploadVendorDocumentAction(data, previewData);
    if (res) {
      setVendorDocuments((currentArray) => [...currentArray, res]);
    }
  };

  const deleteDocument = async (index) => {
    let list = [...vendorDocuments];
    list.splice(index, 1);
    setVendorDocuments(list);
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <div style={{ display: "flex" }}>
          <div className="internalFormSection expertDetailForm secondExpertise">
            {
              !(selectedSubIndustryIds?.includes(7))
              // selectedSubIndustryIds[0] !== 7
              &&
              // classification !== "Vendor" &&
              (
                <>

                  {/* <Row>
                  <Col>
                    <div className="forHeading">Old</div>
                  </Col>
                </Row> */}
                  {/* ----------right side */}
                  <div className="expertDetailFormWithSidebar">
                    <div className="internalFormSection expertDetailForm expertDetailFormLeft">
                      {/* <Row>
                      <Col> */}
                      {/*   <div className="forHeading">Industry expertise</div> */}
                      <div className="forHeading subindustriesHeading">
                        Networks
                      </div>
                      {/*  </Col>
                    </Row> */}
                      {/*  <Row>
                      <Col sm={12} lg={12} xs={12}>
                        <Row> */}
                      <SubIndustriesExpertiese
                        // subIndustries={subIndustries}
                        handleExpertiseSelection={handleExpertiseSelection}
                        handleSubIndustry={handleSubIndustry}
                        selectedSubIndustryIds={selectedSubIndustryIds}
                        companyData={companyData}
                        selectedExpertieseIds={selectedExpertieseIds}
                        checkedValObj={checkedValObj}
                      />
                      {/*  COMMENT REASON 102 */}
                      {/*  {industryExpertise?.map((expertise, index) => {
                            return (
                              <Col lg={8} sm={8} xs={24} key={`expertise_${index}`}>
                                <Checkbox value={expertise.id}>
                                  {expertise.industryExpertise}
                                </Checkbox>
                              </Col>
                            );
                          })} */}

                      {/*   </Row> */}

                      {/*  COMMENT REASON 102 */}
                      {/*   {classification === "Air Partner" && (
                        <Row>
                          <Col lg={24} sm={24} xs={24}>
                            <Collapse
                              defaultActiveKey={["1"]}
                              expandIconPosition={"right"}
                              className="extraWidth"
                            >
                              {(companyIndustryExpertise.indexOf(1) > -1 ||
                                companyIndustryExpertise.indexOf(2) > -1) && (
                                  <Panel
                                    header={panelHeader(
                                      (industryExpertise?.filter((c) => c.id === 1)[0]
                                        ? "Air cargo sales agent"
                                        : "") +
                                      (industryExpertise?.filter(
                                        (c) => c.id === 1 || c.id === 2
                                      ).length === 2
                                        ? " // "
                                        : "") +
                                      (industryExpertise?.filter((c) => c.id === 2)[0]
                                        ? "Neutral Air Consolidators"
                                        : "")
                                    )}
                                    key="1"
                                    className={`${1 === errorPanel ? "errorPanel" : ""}`}
                                  >
                                    <Row>
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="requiredLbl">
                                            Annual gross airfreight revenue (US$)
                                          </label>
                                          <Input
                                            size="large"
                                            placeholder="US$"
                                            value={airfreightRevenue}
                                            id="airfreightRevenue"
                                            onChange={(e) =>
                                              setAirfreightRevenue(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="requiredLbl">
                                            Name your airport branches
                                          </label>
                                          <AsyncSelect
                                            async={true}
                                            cacheOptions
                                            loadOptions={(input, callback) =>
                                              fetchCountryAirports(
                                                input,
                                                comapanyCountryId,
                                                callback
                                              )
                                            }
                                            formatOptionLabel={formatAirportOption}
                                            isClearable={false}
                                            noOptionsMessage={(input) =>
                                              "Please enter 2 or more characters"
                                            }
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            value={airport}
                                            id="airport"
                                            defaultValue={airport}
                                            onChange={(value) => setAirport(value)}
                                          />
                                        </div>
                                      </Col>
                                      {companyIndustryExpertise.indexOf(1) > -1 && (
                                        <Col lg={24} sm={24} xs={24}>
                                          <div className="itemField extraWidth">
                                            <label className="requiredLbl lbl1">
                                              Air cargo sales agent services
                                            </label>
                                            <Row>
                                              <Checkbox.Group
                                                value={selectedCargoAgent}
                                                id="selectedCargoAgent"
                                                onChange={(values) =>
                                                  setSelectedCargoAgent(values)
                                                }
                                              >
                                                {airCargoSalesAgentList?.map(
                                                  (expertise, index) => {
                                                    return (
                                                      <Col
                                                        lg={8}
                                                        sm={8}
                                                        xs={24}
                                                        key={`airCargoSalesAgentList_${index}`}
                                                      >
                                                        <Checkbox value={expertise.id}>
                                                          {expertise.airCargoSalesAgent}
                                                        </Checkbox>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </Checkbox.Group>
                                            </Row>
                                          </div>
                                        </Col>
                                      )}
                                      {companyIndustryExpertise.indexOf(2) > -1 && (
                                        <>
                                          <Col lg={24} sm={24} xs={24}>
                                            <div className="itemField extraWidth">
                                              <label className="requiredLbl lbl1">
                                                Neutral air consolidator services your
                                                company is specialized (minimum one)
                                              </label>
                                              <Row>
                                                <Checkbox.Group
                                                  value={consoleService}
                                                  id="consoleService"
                                                  onChange={(values) =>
                                                    setConsoleService(values)
                                                  }
                                                >
                                                  {neutralAirConsolidatorsList?.map(
                                                    (obj, index) => {
                                                      return (
                                                        <Col
                                                          lg={8}
                                                          sm={8}
                                                          xs={24}
                                                          key={`neutralAirConsolidatorsList_${index}`}
                                                        >
                                                          <Checkbox value={obj.id}>
                                                            {obj.neutralAirConsolidators}
                                                          </Checkbox>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Checkbox.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="yesNoOption"
                                          >
                                            <div className="itemField extraWidth">
                                              <label className="lbl">
                                                Is your company operating exclusively as a
                                                neutral freight wholesaler to the local
                                                freight forwarding market?
                                              </label>
                                              <Row>
                                                <Radio.Group
                                                  buttonStyle="solid"
                                                  size="small"
                                                  value={isOperatingExclusively}
                                                  onChange={(e) =>
                                                    setIsOperatingExclusively(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <Radio.Button value={true}>
                                                    Yes
                                                  </Radio.Button>
                                                  <Radio.Button value={false}>
                                                    No
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="yesNoOption"
                                          >
                                            <div className="itemField extraWidth">
                                              <label className="lbl">
                                                Is your company conducting BSA CPA
                                                contracts with carriers?
                                              </label>
                                              <Row>
                                                <Radio.Group
                                                  buttonStyle="solid"
                                                  size="small"
                                                  value={isConductingBsaCpaContracts}
                                                  onChange={(e) =>
                                                    setIsConductingBsaCpaContracts(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <Radio.Button value={true}>
                                                    Yes
                                                  </Radio.Button>
                                                  <Radio.Button value={false}>
                                                    No
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="yesNoOption"
                                          >
                                            <div className="itemField extraWidth">
                                              <label className="lbl">
                                                Is your company operating regularly
                                                scheduled consolidations?
                                              </label>
                                              <Row>
                                                <Radio.Group
                                                  buttonStyle="solid"
                                                  size="small"
                                                  value={isOperatingRegularlyScheduled}
                                                  onChange={(e) =>
                                                    setIsOperatingRegularlyScheduled(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <Radio.Button value={true}>
                                                    Yes
                                                  </Radio.Button>
                                                  <Radio.Button value={false}>
                                                    No
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="yesNoOption"
                                          >
                                            <div className="itemField extraWidth">
                                              <label className="lbl">
                                                Is your company a commercial retail
                                                freight agent dealing directly with
                                                shippers or consignees?
                                              </label>
                                              <Row>
                                                <Radio.Group
                                                  buttonStyle="solid"
                                                  size="small"
                                                  value={isAgentDealingDirectly}
                                                  onChange={(e) =>
                                                    setIsAgentDealingDirectly(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <Radio.Button value={true}>
                                                    Yes
                                                  </Radio.Button>
                                                  <Radio.Button value={false}>
                                                    No
                                                  </Radio.Button>
                                                </Radio.Group>
                                              </Row>
                                            </div>
                                          </Col>
                                          {isAgentDealingDirectly && (
                                            <>
                                              <Col
                                                lg={24}
                                                sm={24}
                                                xs={24}
                                                className="yesNoOption"
                                              >
                                                <div className="itemField extraWidth">
                                                  <label className="lbl">
                                                    Do you have a separate division or a
                                                    company of your group handling
                                                    exclusively neutral wholesale freight
                                                    business?
                                                  </label>
                                                  <Row>
                                                    <Radio.Group
                                                      buttonStyle="solid"
                                                      size="small"
                                                      value={isSeparateDivision}
                                                      onChange={(e) =>
                                                        setIsSeparateDivision(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <Radio.Button value={true}>
                                                        Yes
                                                      </Radio.Button>
                                                      <Radio.Button value={false}>
                                                        No
                                                      </Radio.Button>
                                                    </Radio.Group>
                                                  </Row>
                                                </div>
                                              </Col>
                                              {isSeparateDivision && (
                                                <Col
                                                  lg={24}
                                                  sm={24}
                                                  xs={24}
                                                  className="yesNoOption"
                                                >
                                                  <div className="itemField extraWidth">
                                                    <label className="lbl">
                                                      Please name your division or company
                                                      name of the group
                                                    </label>
                                                    <Input
                                                      size="large"
                                                      placeholder="division or company name"
                                                      value={divisionOrCompanyName}
                                                      id="divisionOrCompanyName"
                                                      onChange={(e) =>
                                                        setDivisionOrCompanyName(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              )}
                                            </>
                                          )}
                                          <Col lg={24} sm={24} xs={24}>
                                            <div className="itemField extraWidth">
                                              <label className="lbl">
                                                What is the percentage of your commercial
                                                retail business and your neutral master
                                                loading wholesale business?
                                              </label>
                                              <TwoOptionSlider
                                                defaultValue={retail}
                                                onChange={setRetailWholesale}
                                              ></TwoOptionSlider>
                                            </div>
                                          </Col>
                                        </>
                                      )}
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="requiredLbl lbl1">
                                            Name your top destinations you export to (Min
                                            1 - Max 5)
                                          </label>
                                          {expNacDestAirport.map((obj, index) => {
                                            return (
                                              <Row
                                                key={`expNacDestAirport_${index}`}
                                                className="addRemoveRow"
                                              >
                                                <Col lg={22} sm={22} xs={22}>
                                                  <Row>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Destination Airport
                                                        </label>
                                                        <AsyncSelect
                                                          placeholder={
                                                            "Destination Airport"
                                                          }
                                                          noOptionsMessage={(input) =>
                                                            "Please enter 2 or more characters"
                                                          }
                                                          loadOptions={(
                                                            input,
                                                            callback
                                                          ) =>
                                                            fetchAirports(input, callback)
                                                          }
                                                          formatOptionLabel={
                                                            formatAirportOption
                                                          }
                                                          // getOptionLabel={option => option.iata}
                                                          getOptionValue={(option) =>
                                                            option.id
                                                          }
                                                          isClearable={false}
                                                          value={obj.airportId}
                                                          id={`dAirport_${index}`}
                                                          defaultValue={obj.airportId}
                                                          onChange={(value) =>
                                                            changeAirport(value, index)
                                                          }
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Tonnes per year
                                                        </label>
                                                        <Input
                                                          size="large"
                                                          placeholder="Tonnes per year"
                                                          value={obj.tonnes}
                                                          id={`dAirportTonnes_${index}`}
                                                          onChange={(e) =>
                                                            changeTons(
                                                              e.target.value,
                                                              index
                                                            )
                                                          }
                                                          minLength={2}
                                                          maxLength={5}
                                                        />
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col lg={2} sm={2} xs={2}>
                                                  <label className="lbl">&nbsp;</label>
                                                  {expNacDestAirport?.length > 1 && (
                                                    <Button
                                                      className="deleteIcon"
                                                      onClick={() =>
                                                        deleteExpNacDestAirport(index)
                                                      }
                                                    >
                                                      <Icon type="delete" />
                                                    </Button>
                                                  )}
                                                </Col>
                                              </Row>
                                            );
                                          })}
                                          <Row>
                                            <Col
                                              lg={24}
                                              sm={24}
                                              xs={24}
                                              className="addMoreBtn"
                                            >
                                              {expNacDestAirport?.length < 5 && (
                                                <Button
                                                  type="dashed"
                                                  onClick={() => addExpNacDestAirport()}
                                                >
                                                  <Icon type="plus" /> Add More
                                                </Button>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="lbl1">
                                            Name your top airlines you export with (Min 1
                                            - Max 5)
                                          </label>
                                          {expNacTopAirline.map((obj, index) => {
                                            return (
                                              <Row
                                                key={`expNacTopAirline_${index}`}
                                                className="addRemoveRow"
                                              >
                                                <Col lg={22} sm={22} xs={22}>
                                                  <Row>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Airline
                                                        </label>
                                                        <AsyncSelect
                                                          placeholder={"Airline"}
                                                          noOptionsMessage={(input) =>
                                                            "Please enter 2 or more characters"
                                                          }
                                                          loadOptions={(
                                                            input,
                                                            callback
                                                          ) =>
                                                            fetchAirlines(input, callback)
                                                          }
                                                          formatOptionLabel={
                                                            formatAirlineOption
                                                          }
                                                          // getOptionLabel={option => option.iata}
                                                          getOptionValue={(option) =>
                                                            option.id
                                                          }
                                                          isClearable={false}
                                                          value={obj.airline}
                                                          id={`expAirline_${index}`}
                                                          defaultValue={obj.airline}
                                                          onChange={(value) =>
                                                            changeAirline(value, index)
                                                          }
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Tonnes per year
                                                        </label>
                                                        <Input
                                                          size="large"
                                                          placeholder="Tonnes per year"
                                                          value={obj.tonnes}
                                                          id={`expAirlineTonnes_${index}`}
                                                          onChange={(e) =>
                                                            changeAirlineTons(
                                                              e.target.value,
                                                              index
                                                            )
                                                          }
                                                          minLength={2}
                                                          maxLength={5}
                                                        />
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col lg={2} sm={2} xs={2}>
                                                  <label className="lbl">&nbsp;</label>
                                                  {expNacTopAirline?.length > 1 && (
                                                    <Button
                                                      className="deleteIcon"
                                                      onClick={() =>
                                                        deleteExpNacTopAirline(index)
                                                      }
                                                    >
                                                      <Icon type="delete" />
                                                    </Button>
                                                  )}
                                                </Col>
                                              </Row>
                                            );
                                          })}
                                          <Row>
                                            <Col
                                              lg={24}
                                              sm={24}
                                              xs={24}
                                              className="addMoreBtn"
                                            >
                                              {expNacTopAirline?.length < 5 && (
                                                <Button
                                                  type="dashed"
                                                  onClick={() => addExpNacTopAirline()}
                                                >
                                                  <Icon type="plus" /> Add More
                                                </Button>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="lbl1">
                                            Name your top 3 airlines where you hold a BSA
                                            (Blocked space) or CPA (Capacity purchase)
                                            agreement (Min 1 - Max 3)
                                          </label>
                                          {expNacAgreementAirline.map((obj, index) => {
                                            return (
                                              <Row
                                                key={`expNacAgreementAirline_${index}`}
                                                className="addRemoveRow"
                                              >
                                                <Col lg={22} sm={22} xs={22}>
                                                  <Row>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Airline
                                                        </label>
                                                        <AsyncSelect
                                                          placeholder={"Airline"}
                                                          noOptionsMessage={(input) =>
                                                            "Please enter 2 or more characters"
                                                          }
                                                          loadOptions={(
                                                            input,
                                                            callback
                                                          ) =>
                                                            fetchAirlines(input, callback)
                                                          }
                                                          formatOptionLabel={
                                                            formatAirlineOption
                                                          }
                                                          // getOptionLabel={option => option.iata}
                                                          getOptionValue={(option) =>
                                                            option.id
                                                          }
                                                          isClearable={false}
                                                          value={obj.airline}
                                                          id={`bsaAirline_${index}`}
                                                          defaultValue={obj.airline}
                                                          onChange={(value) =>
                                                            changeAgreementAirline(
                                                              value,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col lg={12} sm={12} xs={24}>
                                                      <div className="itemField">
                                                        <label className="requiredLbl">
                                                          Tonnes per year
                                                        </label>
                                                        <Input
                                                          size="large"
                                                          placeholder="Tonnes per year"
                                                          value={obj.tonnes}
                                                          id={`bsaAirlineTonnes_${index}`}
                                                          onChange={(e) =>
                                                            changeAgreementTons(
                                                              e.target.value,
                                                              index
                                                            )
                                                          }
                                                          minLength={2}
                                                          maxLength={5}
                                                        />
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col lg={2} sm={2} xs={2}>
                                                  <label className="lbl">&nbsp;</label>
                                                  {expNacAgreementAirline?.length > 1 && (
                                                    <Button
                                                      className="deleteIcon"
                                                      onClick={() =>
                                                        deleteExpNacAgreementAirline(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <Icon type="delete" />
                                                    </Button>
                                                  )}
                                                </Col>
                                              </Row>
                                            );
                                          })}
                                          <Row>
                                            <Col
                                              lg={24}
                                              sm={24}
                                              xs={24}
                                              className="addMoreBtn"
                                            >
                                              {expNacAgreementAirline?.length < 3 && (
                                                <Button
                                                  type="dashed"
                                                  onClick={() =>
                                                    addExpNacAgreementAirline()
                                                  }
                                                >
                                                  <Icon type="plus" /> Add More
                                                </Button>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField extraWidth">
                                          <label className="requiredLbl">
                                            Name your top 5 airports you import from
                                          </label>
                                          <AsyncSelect
                                            isMulti
                                            placeholder={"Destination Airport"}
                                            noOptionsMessage={(input) =>
                                              "Please enter 2 or more characters"
                                            }
                                            loadOptions={fetchAirports2}
                                            formatOptionLabel={formatAirportOption2}
                                            getOptionLabel={(option) => option.iata}
                                            getOptionValue={(option) => option.airport.id}
                                            isClearable={false}
                                            value={topImportAirport}
                                            id="topImportAirport"
                                            defaultValue={topImportAirport}
                                            onChange={(value) =>
                                              updateTopImportAirport(value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Panel>
                                )}
                              {companyIndustryExpertise.indexOf(3) > -1 && (
                                <Panel
                                  header={panelHeader(
                                    industryExpertise?.filter((c) => c.id === 3)[0]
                                      ?.industryExpertise
                                  )}
                                  key="3"
                                  className={`${3 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Annual gross airline revenue (US$)
                                        </label>
                                        <Input
                                          size="large"
                                          placeholder="US$"
                                          value={airlineRevenue}
                                          id="airlineRevenue"
                                          onChange={(e) =>
                                            setAirlineRevenue(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl">
                                          Name the air carriers you represent as GSA
                                          agent
                                        </label>
                                        <AsyncSelect
                                          isMulti
                                          placeholder={"Select airlines"}
                                          noOptionsMessage={(input) => "Type something"}
                                          loadOptions={fetchAirlines}
                                          formatOptionLabel={formatAirlineOption}
                                          getOptionLabel={(option) => option.iata}
                                          getOptionValue={(option) => option.id}
                                          isClearable={false}
                                          value={airCarrier}
                                          id="airCarrier"
                                          defaultValue={airCarrier}
                                          onChange={(value) => setAirCarrier(value)}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl">
                                          Other air carrier (IATA code)
                                        </label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={otherCarrierCode}
                                          id="otherCarrierCode"
                                          maxLength={2}
                                          onChange={(e) =>
                                            setOtherCarrierCode(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Name the origin airports where you operate as
                                          a carrier
                                        </label>
                                        <AsyncSelect
                                          isMulti
                                          placeholder={"Select airlines"}
                                          noOptionsMessage={(input) => "Type something"}
                                          loadOptions={fetchAirports}
                                          formatOptionLabel={formatAirportOption}
                                          getOptionLabel={(option) => option.iata}
                                          getOptionValue={(option) => option.id}
                                          isClearable={false}
                                          value={originAirports}
                                          id="originAirports"
                                          defaultValue={originAirports}
                                          onChange={(value) => setOriginAirports(value)}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Are you offering any special products and
                                          value-added services to the trade our members
                                          need to know
                                        </label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={specialProduct}
                                          id="specialProduct"
                                          onChange={(e) =>
                                            setSpecialProduct(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(5) > -1 && (
                                <Panel
                                  header={panelHeader(
                                    industryExpertise?.filter((c) => c.id === 5)[0]
                                      ?.industryExpertise
                                  )}
                                  key="5"
                                  className={`${5 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please name how many cargo charters you
                                          operate per year
                                        </label>
                                        <Radio.Group
                                          buttonStyle="solid"
                                          value={carterCount}
                                          id="carterCount"
                                          onChange={(e) =>
                                            setCarterCount(e.target.value)
                                          }
                                        >
                                          {acbCargoCharterList.map((a) => (
                                            <Radio.Button
                                              key={`acbCargoCharterList_${a.value}`}
                                              value={a.value}
                                            >
                                              {a.label}
                                            </Radio.Button>
                                          ))}
                                        </Radio.Group>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Name commodities or trade lanes you are specialized at
                                        </label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={specialTradeName}
                                          id="specialTradeName"
                                          onChange={(e) =>
                                            setSpecialTradeName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(6) > -1 && (
                                <Panel
                                  header={panelHeader("Cool chain expert")}
                                  key="6"
                                  className={`${6 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more cool chain services
                                          your company is specialized:
                                        </label>
                                        <Select
                                          mode="multiple"
                                          value={coolChainService}
                                          id="coolChainService"
                                          defaultValue={coolChainService}
                                          onChange={setCoolChainService}
                                        >
                                          <Option value={1}>Pharma</Option>
                                          <Option value={2}>Fresh</Option>
                                          <Option value={3}>
                                            Temperature Control facilities
                                          </Option>
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl">Others:</label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={otherCoolChainService}
                                          id="otherCoolChainService"
                                          onChange={(e) =>
                                            setOtherCoolChainService(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(7) > -1 && (
                                <Panel
                                  header={panelHeader("Dangerous goods expert")}
                                  key="7"
                                  className={`${7 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more of the following DGR
                                          and HAZMAT class services your company is
                                          specialized
                                        </label>
                                        <Select
                                          mode="multiple"
                                          showSearch
                                          // placeholder="Country"
                                          optionFilterProp="children"
                                          value={dgrServices}
                                          id="dgrServices"
                                          defaultValue={dgrServices}
                                          onChange={setDGRServices}
                                        >
                                          {expDgrServicesList?.map((obj, index) => {
                                            return (
                                              <Option
                                                value={obj.id}
                                                key={`expDgrServicesList_${index}`}
                                              >
                                                {obj.expDgrServices}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more of following packing
                                          groups if your company operates HAZMAT
                                          packaging and transport services
                                        </label>
                                        <Select
                                          mode="multiple"
                                          showSearch
                                          // placeholder="Country"
                                          optionFilterProp="children"
                                          value={dgrPackaging}
                                          id="dgrPackaging"
                                          defaultValue={dgrPackaging}
                                          onChange={setDGRPackaging}
                                        >
                                          {expDgrPackagingList?.map((obj, index) => {
                                            return (
                                              <Option
                                                value={obj.id}
                                                key={`expDgrPackagingList_${index}`}
                                              >
                                                {obj.expDgrPackaging}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(8) > -1 && (
                                <Panel
                                  header={panelHeader("Express operator")}
                                  key="8"
                                  className={`${8 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Annual gross airlines & integrators revenue
                                        </label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={annualAIRevenue}
                                          id="annualAIRevenue"
                                          onChange={(e) =>
                                            setAnnualAIRevenue(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl lbl1">
                                          Express operator services your company is
                                          specialized
                                        </label>
                                        <Row>
                                          <Checkbox.Group
                                            value={selectedExpressOps}
                                            id="selectedExpressOps"
                                            onChange={(values) =>
                                              setSelectedExpressOps(values)
                                            }
                                          >
                                            {expressOperatorsList?.map(
                                              (express, index) => {
                                                return (
                                                  <Col
                                                    lg={8}
                                                    sm={8}
                                                    xs={12}
                                                    key={`expressOperatorsList_${index}`}
                                                  >
                                                    <Checkbox value={express.id}>
                                                      {express.expressOperators}
                                                    </Checkbox>
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Checkbox.Group>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl lbl1">
                                          Name your Top 5 express and courier
                                          destination countries
                                        </label>
                                        {expressCourierCountries.map((obj, index) => {
                                          return (
                                            <Row
                                              key={`expressCourierCountries_${index}`}
                                              className="addRemoveRow"
                                            >
                                              <Col lg={22} sm={22} xs={22}>
                                                <Row>
                                                  <Col lg={12} sm={12} xs={24}>
                                                    <div className="itemField">
                                                      <label className="requiredLbl">
                                                        Country
                                                      </label>
                                                      <Select
                                                        showSearch
                                                        placeholder="Country"
                                                        optionFilterProp="children"
                                                        onChange={(value) =>
                                                          changeExpressCourierCountry(
                                                            value,
                                                            index
                                                          )
                                                        }
                                                        value={obj.countryId}
                                                        id={`expCourierDest_${index}`}
                                                        defaultValue={obj.countryId}
                                                      >
                                                        {countryList?.map(
                                                          (country, index) => {
                                                            return (
                                                              <Option
                                                                value={country.id}
                                                                key={`express_courier_countries_${index}`}
                                                              >
                                                                {country.countryname}
                                                              </Option>
                                                            );
                                                          }
                                                        )}
                                                      </Select>
                                                    </div>
                                                  </Col>
                                                  <Col lg={12} sm={12} xs={24}>
                                                    <div className="itemField">
                                                      <label className="requiredLbl">
                                                        Airwaybills per year
                                                      </label>
                                                      <Input
                                                        size="large"
                                                        placeholder="Airwaybills per year"
                                                        value={obj.bill}
                                                        id={`expCourierDestBill_${index}`}
                                                        onChange={(e) =>
                                                          changeAirwayBills(
                                                            e.target.value,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col lg={2} sm={2} xs={2}>
                                                <label className="lbl">&nbsp;</label>
                                                <Button
                                                  className="deleteIcon"
                                                  onClick={() =>
                                                    deleteExpressCourierCountries(index)
                                                  }
                                                >
                                                  <Icon type="delete" />
                                                </Button>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                        <Row>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="addMoreBtn"
                                          >
                                            <Button
                                              type="dashed"
                                              onClick={() =>
                                                addExpressCourierCountries()
                                              }
                                            >
                                              <Icon type="plus" /> Add More
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl lbl1">
                                          Name your Top 5 Vendors (Express carriers -
                                          Integrators)
                                        </label>
                                        {expressVendors.map((obj, index) => {
                                          return (
                                            <Row
                                              key={`expressVendors_${index}`}
                                              className="addRemoveRow"
                                            >
                                              <Col lg={22} sm={22} xs={22}>
                                                <Row>
                                                  <Col lg={12} sm={12} xs={24}>
                                                    <div className="itemField">
                                                      <label className="requiredLbl">
                                                        Vendor Name
                                                      </label>
                                                      <Input
                                                        size="large"
                                                        placeholder="Vendor Name"
                                                        value={obj.vendor}
                                                        id={`vendorName_${index}`}
                                                        onChange={(e) =>
                                                          changeExpressVendor(
                                                            e.target.value,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col lg={12} sm={12} xs={24}>
                                                    <div className="itemField">
                                                      <label className="requiredLbl">
                                                        Airwaybills per year
                                                      </label>
                                                      <Input
                                                        size="large"
                                                        placeholder="Airwaybills per year"
                                                        value={obj.bill}
                                                        id={`vendorBill_${index}`}
                                                        onChange={(e) =>
                                                          changeVendorBills(
                                                            e.target.value,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col lg={2} sm={2} xs={2}>
                                                <label className="lbl">&nbsp;</label>
                                                <Button
                                                  className="deleteIcon"
                                                  onClick={() =>
                                                    deleteExpressVendor(index)
                                                  }
                                                >
                                                  <Icon type="delete" />
                                                </Button>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                        <Row>
                                          <Col
                                            lg={24}
                                            sm={24}
                                            xs={24}
                                            className="addMoreBtn"
                                          >
                                            <Button
                                              type="dashed"
                                              onClick={() => addExpressVendor()}
                                            >
                                              <Icon type="plus" /> Add More
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl1">
                                          Are you involved in air import express
                                          business
                                        </label>
                                        <Checkbox
                                          checked={isAirImportExpressBusiness}
                                          onChange={(e) =>
                                            setIsAirImportExpressBusiness(
                                              e.target.checked
                                            )
                                          }
                                        >
                                          Yes
                                        </Checkbox>
                                      </div>
                                    </Col>
                                    {isAirImportExpressBusiness && (
                                      <Col lg={24} sm={24} xs={24}>
                                        <div className="itemField">
                                          <label className="requiredLbl">Country</label>
                                          <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="Country"
                                            optionFilterProp="children"
                                            onChange={setExpressImportCountry}
                                            value={expressImportCountry}
                                            id="expressImportCountry"
                                            defaultValue={expressImportCountry}
                                          >
                                            {countryList?.map((country, index) => {
                                              return (
                                                <Option
                                                  value={country.id}
                                                  key={`isAirImportExpressBusiness_countryList_${index}`}
                                                >
                                                  {country.countryname}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(9) > -1 && (
                                <Panel
                                  header={panelHeader("Time critical expert")}
                                  key="9"
                                  className={`${9 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more of the following
                                          time-critical services your company is
                                          specialized
                                        </label>
                                        <Select
                                          mode="multiple"
                                          value={selectedTCService}
                                          id="selectedTCService"
                                          defaultValue={selectedTCService}
                                          onChange={(value) =>
                                            setSelectedTCService(value)
                                          }
                                        >
                                          {expTceIndusServicesList?.map(
                                            (obj, index) => {
                                              return (
                                                <Option
                                                  value={obj.id}
                                                  key={`expTceIndusServicesList_${index}`}
                                                >
                                                  {obj.expTceIndusServices}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more of the following
                                          industries your company is specialized
                                        </label>
                                        <Select
                                          mode="multiple"
                                          value={selectedTCEService}
                                          id="selectedTCEService"
                                          defaultValue={selectedTCEService}
                                          onChange={(value) =>
                                            setSelectedTCEService(value)
                                          }
                                        >
                                          {expTceServicesList?.map((obj, index) => {
                                            return (
                                              <Option
                                                value={obj.id}
                                                key={`expTceServicesList_${index}`}
                                              >
                                                {obj.expTceServices}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Is your company operate 24/7 365 Service desk
                                        </label>
                                        <Checkbox
                                          checked={is24}
                                          onChange={(e) => setIs24(e.target.checked)}
                                        >
                                          Yes
                                        </Checkbox>
                                      </div>
                                    </Col>
                                    {is24 && (
                                      <>
                                        <Col lg={24} sm={24} xs={24}>
                                          <div className="itemField extraWidth">
                                            <label className="requiredLbl">
                                              Service desk Name and Lastname
                                            </label>
                                            <Input
                                              size="large"
                                              placeholder=""
                                              value={deskPersonName}
                                              id="deskPersonName"
                                              onChange={(e) =>
                                                setDeskPersonName(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={24} sm={24} xs={24}>
                                          <div className="itemField extraWidth">
                                            <label className="requiredLbl">
                                              Service desk telephone
                                            </label>
                                            <Input
                                              size="large"
                                              placeholder=""
                                              value={deskTelNum}
                                              id="deskTelNum"
                                              onChange={(e) =>
                                                setDeskTelNum(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={24} sm={24} xs={24}>
                                          <div className="itemField extraWidth">
                                            <label className="requiredLbl">
                                              Service desk email
                                            </label>
                                            <Input
                                              size="large"
                                              placeholder=""
                                              value={deskEmailId}
                                              id="deskEmailId"
                                              onChange={(e) =>
                                                setDeskEmailId(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(10) > -1 && (
                                <Panel
                                  header={panelHeader("Terminal handling agent")}
                                  key="10"
                                  className={`${10 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please select one or more of the following
                                          services your company operates
                                        </label>
                                        <Select
                                          mode="multiple"
                                          value={selectedTerminalAgent}
                                          id="selectedTerminalAgent"
                                          defaultValue={selectedTerminalAgent}
                                          onChange={(value) =>
                                            setSelectedTerminalAgent(value)
                                          }
                                        >
                                          {expTheServicesList?.map((obj, index) => {
                                            return (
                                              <Option
                                                value={obj.id}
                                                key={`expTheServicesList_${index}`}
                                              >
                                                {obj.expTheServices}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl">Others</label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={otherTerminalAgent}
                                          id="otherTerminalAgent"
                                          onChange={(e) =>
                                            setOtherTerminalAgent(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(11) > -1 && (
                                <Panel
                                  header={panelHeader("E Commerce Operator")}
                                  key="11"
                                  className={`${11 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Please choose
                                        </label>
                                        <Select
                                          mode="multiple"
                                          value={selectedECommeceOp}
                                          id="selectedECommeceOp"
                                          defaultValue={selectedECommeceOp}
                                          onChange={(value) =>
                                            setSelectedECommeceOp(value)
                                          }
                                        >
                                         
                                        </Select>
                                      </div>
                                    </Col>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="lbl">Others</label>
                                        <Input
                                          size="large"
                                          placeholder=""
                                          value={otherECommeceOp}
                                          id="otherECommeceOp"
                                          onChange={(e) =>
                                            setOtherECommeceOp(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(12) > -1 && (
                                <Panel
                                  header={panelHeader("Sea air services")}
                                  key="12"
                                  className={`${12 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Describe your services
                                        </label>
                                        <TextArea
                                          rows={4}
                                          value={seaAirDesc}
                                          id="seaAirDesc"
                                          onChange={(e) =>
                                            setSeaAirDesc(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                              {companyIndustryExpertise.indexOf(13) > -1 && (
                                <Panel
                                  header={panelHeader("RFS services")}
                                  key="13"
                                  className={`${13 === errorPanel ? "errorPanel" : ""}`}
                                >
                                  <Row>
                                    <Col lg={24} sm={24} xs={24}>
                                      <div className="itemField extraWidth">
                                        <label className="requiredLbl">
                                          Describe your services
                                        </label>
                                        <TextArea
                                          rows={4}
                                          value={rfcDesc}
                                          id="rfcDesc"
                                          onChange={(e) => setRFCDesc(e.target.value)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              )}
                            </Collapse>
                          </Col>
                        </Row>
                      )} */}
                      {/*  </Col> */}
                      {/* <Col sm={8} lg={8} xs={8}>
                      <RegisterationExpertiseListing
                        expertiseList={expertiseList}
                        expertises={selectedExpertieseIds}
                        //   classification={classification}
                        companyId={companyId} />
                    </Col> */}
                      {/*  COMMENT REASON 102 */}
                      {/*  <Col sm={8} lg={8} xs={8}>
                      <div className="instructionSection">
                        <label
                          htmlFor="normal_Register_username"
                          className="ant-form-item"
                          title="Company name"
                        >
                          {classification}
                        </label>
                        <div className="validationInst">
                          {industryExpertise?.map((expertise, index) => {
                            return (
                              <span key={`classification_exprt_${index}`}>
                                {expertise.industryExpertise}
                                <br />
                              </span>
                            );
                          })}
                        Air Cargo Sales Agents <br />Neutral Air Consolidators <br />GSA & Aviation Specialists <br />Charter brokers<br /> Time Critical Experts<br /> Express Operators <br />eCommerce specialists<br />  Cool Chain Specialists <br />Dangerous Goods Experts <br />Sea Air operator <br />Terminal handling Agents<br /> RFS operators 
                        </div>
                      </div>
                    </Col> */}
                      {/*      </Row> */}
                    </div>
                    <div className="rightArea">
                      {/*  <Row>
                      <Col>
                        <div className="forHeading subindustriesHeading">
                          Trade lane specialization
                        </div>
                      </Col>
                    </Row> */}

                      <>
                        <Tradelane expertise setValidationErrorTradelane={setValidationErrorTradelane}></Tradelane>
                      </>
                      {/* <Row>
                      <Col sm={16} lg={24} xs={24}>
                        <Row>
                          <Col lg={24} sm={24} xs={24}>
                            <div className="itemField extraWidth">
                              <label className="requiredLbl">Trade lane specialization</label>
                              <Select
                                mode="multiple"
                                placeholder="Specializations"
                                value={selectedSpecialization}
                                id="selectedSpecialization"
                                defaultValue={selectedSpecialization}
                                onChange={(value) => updateTradeLaneSpec(value)}
                                onBlur={(event) => { saveTradeLaneSpec(event, selectedSpecialization) }}
                              >
                                {specializations?.map((specialization, index) => {
                                  return (
                                    <Option
                                      value={specialization.id}
                                      key={`specialization_${specialization.id}`}
                                    >
                                      {specialization.specialization}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div className="instructionSection">
                                {selectedSpecialization?.length === 0 && <div style={{ color: 'red' }}>Required</div>}
                                <div className="validationInst">
                                  Chose maximum 3 trade lanes by priority
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row> */}
                      <Row>
                        {/*   <Col lg={24} sm={24} xs={24}>
                        <label className="requiredLbl">Sales Focus</label>
                      </Col> */}
                      </Row>
                      <Row>
                        <Col sm={24} lg={24} xs={24}>
                          <Row>

                            <>
                              <FocusSlider
                                // fileName="expertiseInfo"
                                expertise
                                focus={focus}
                                companyDataId={focus.id}
                                companyId={companyId}
                              ></FocusSlider>
                            </>
                            {/* {focus.map((item, i) => (
                            <Col lg={6} sm={6} xs={12} key={`focus_${item.id}`}>
                              <div className="salesCategory">
                                <div className="count">{item.value}%</div>
                                <div className="categoryTitle">{item.title}</div>
                                <Slider
                                  value={item.value}
                                  step={5}
                                  min={0}
                                  max={100}
                                  onMouseUp={updateData}
                                  onChange={(value) => handleFocusSlider(i, value)}
                                  onAfterChange={() => updateData()}
                                // onBlur={()=>{updateFocus()}}
                                ></Slider>
                              </div>
                            </Col>
                          ))} */}
                          </Row>
                        </Col>
                        <Col sm={8} lg={8} xs={8}></Col>
                      </Row>
                      {/*   <Row>
                      <Col>
                        <div className="forHeading">Export / Import Countries</div>
                      </Col>
                    </Row> */}

                      <Row>
                        <Col sm={24} lg={24} xs={24}>
                          <Row>
                            <ImportExportCountries setValidationErrorImportExport={setValidationErrorImportExport} fileName="expertiseInfo" selectedExpertieseIds={selectedExpertieseIds}></ImportExportCountries >
                            {/*  <Col lg={24} sm={24} xs={24}>
                            <div className="itemField extraWidth">
                              <label className="requiredLbl">
                                Name your top 5 countries you export to
                              </label>
                              <Select
                                placeholder="Select top 5 countries you export to"
                                mode="multiple"
                                value={exportCountries}
                                id="exportCountries"
                                defaultValue={exportCountries}
                                onChange={(value) => updateExportCountries(value)}
                                onBlur={saveExportCountries}
                              // validate={["required"]}                                
                              >
                                {countryList?.map((country, index) => {
                                  return (
                                    <Option
                                      value={country.id}
                                      key={`exportCountry_${index}`}
                                    >
                                      {country.countryname}
                                    </Option>
                                  );
                                })}
                              </Select>
                              {exportCountries?.length === 0 && <div style={{ color: 'red' }}>Required</div>}
                            </div>
                          </Col> */}
                            {/*    <Col lg={24} sm={24} xs={24}>
                            <div className="itemField extraWidth">
                              <label className="requiredLbl">
                                Name your top 5 countries you import from
                              </label>
                              <Select
                                showSearch
                                placeholder="Select top 5 countries you import from"
                                optionFilterProp="children"
                                mode="multiple"
                                value={importCountries}
                                id="importCountries"
                                defaultValue={importCountries}
                                onChange={(value) => updateImportCountries(value)}
                                onBlur={saveImportCountries}
                              >
                                {countryList?.map((country, index) => {
                                  return (
                                    <Option
                                      value={country.id}
                                      key={`importCountry_${index}`}
                                    >
                                      {country.countryname}
                                    </Option>
                                  );
                                })}
                              </Select>
                              {importCountries?.length === 0 && <div style={{ color: 'red' }}>Required</div>}
                            </div>
                          </Col> */}
                          </Row>
                        </Col>
                        {/* <Col sm={8} lg={8} xs={8}></Col> */}
                      </Row>

                      <Row>
                        <Col
                          sm={24}
                          lg={24}
                          xs={24}
                          className="customSecionCardRegister"
                        >
                          <div className="customSecionCard">
                            <>
                              <ExpertiseListing
                                selectedExpertieseIds={selectedExpertieseIds}
                                fileName="expertiseInfo"
                                fileStatus="registration"
                                expertiseList={expertiseList}
                                expertises={[1]}
                                classification={classification}
                                companyId={companyId}
                                setValidationErrorAirCargo={setValidationErrorAirCargo}
                              ></ExpertiseListing>
                            </>
                          </div>
                        </Col>
                      </Row>
                      {/*  <Row>
                      <Col>
                        <div className="forHeading">Questions</div>
                      </Col>
                    </Row> */}
                      <Row>
                        <Col
                          sm={24}
                          lg={24}
                          xs={24}
                          className="customSecionCardRegister"
                        >
                          {!selectedSubIndustryIds?.includes(7) ?
                            <div className="customSecionCard">
                              <MemoizedChildComponentA
                                // expertiseList={expertiseList}
                                companyData={companyData}
                                companyId={companyId}
                                subIndustry={selectedSubIndustryIds}
                                expertises={selectedExpertieseIds}
                                setAnswerErrors={setAnswerErrors}
                                validationErrorMsg={validationErrorMsg}
                                setValidationErrorMsg={setValidationErrorMsg}
                                inputFieldData={inputFieldData}
                                setInputFieldData={setInputFieldData}
                                allQuestionsData={allQuestionsData}
                                setAllQuestionsData={setAllQuestionsData}
                                setNo={setNo}
                              // classification={classification}
                              />
                            </div> : ""}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              )}
            {
              (selectedSubIndustryIds?.includes(7))
              /* selectedSubIndustryIds[0] === 7 */
              // classification === "Vendor"
              && (
                <div className="expertDetailFormWithSidebar">
                  <div className="internalFormSection expertDetailForm expertDetailFormLeft">
                    <div className="forHeading subindustriesHeading">
                      Networks
                    </div>
                    <SubIndustriesExpertiese
                      // subIndustries={subIndustries}
                      handleExpertiseSelection={handleExpertiseSelection}
                      handleSubIndustry={handleSubIndustry}
                      selectedSubIndustryIds={selectedSubIndustryIds}
                      companyData={companyData}
                      selectedExpertieseIds={selectedExpertieseIds}
                      checkedValObj={checkedValObj}
                    />
                  </div>
                  <div className="rightArea">
                    <Row>
                      <Col>
                        <div className="forHeading">Documents</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={16} lg={16} xs={16}>
                        {vendorDocuments.map((doc, index) => {
                          return (
                            <div
                              key={`vendorDocument_${index}`}
                              className="document"
                            >
                              <Icon
                                type="file"
                                style={{ fontSize: 20, marginRight: 5 }}
                              // theme="twoTone"
                              />
                              <span>{doc.originalName}</span>
                              <Icon
                                type="delete"
                                onClick={() => deleteDocument(index)}
                              />
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={16} lg={16} xs={16}>
                        <Dropzone handleDrop={uploadFiles} />
                      </Col>
                    </Row>
                  </div>
                </div>

              )}
            {/* ----------down */}
            <Row>
              <Col xs={24} lg={24}>
                <div className="noteSection">
                  <p>
                    NOTE: Once you application is approved, you will need your
                    user name and password to edit your profile with all
                    requested details (branches, contacts, destination notes,
                    bank details, general rates, etc). Your membership will not
                    be active unless your profile is completed 100%.
                  </p>
                  <p>
                    I hereby confirm that all above information is true and
                    correct and documents proving the above will be provided
                    within 30 days on request from Executive Networks Limited a
                    company incorporated in Hong Kong.
                  </p>
                  <div className="itemField extraWidth">
                    <Checkbox
                      checked={isTermsAccepted}
                      onChange={(e) => {
                        setIsTermsAccepted(e.target.checked)

                      }}
                    >
                      I agree{" "}
                      <a className="add-branch-btn" target="blank" href='https://neutralairpartner.com/terms-and-conditions' >
                        terms and conditions
                      </a>{" "}
                      and{" "}
                      <a
                        className="add-branch-btn"
                        target="blank"
                        href='https://neutralairpartner.com/benefits/nap-financial-security-guarantee-plan/'
                      >
                        NAP SAFE payment protection program
                      </a>
                    </Checkbox>
                  </div>
                  <div className="itemField extraWidth">
                    <Checkbox
                      checked={isMembershipAccepted}
                      onChange={(e) =>
                        setIsMembershipAccepted(e.target.checked)
                      }
                    >
                      I agree to the{" "}
                      <a
                        className="add-branch-btn"
                        target="blank"
                        href='https://neutralairpartner.com//download/Membership-Agreement-Code-of-Ethics-and-Practice-NAP.pdf'
                      >
                        Membership Agreement and the Code of Ethics and Practice
                        between Members
                      </a>
                    </Checkbox>
                  </div>
                  <div className="itemField extraWidth">
                    <Checkbox
                      checked={isValidInfo}
                      onChange={(e) => setIsValidInfo(e.target.checked)}
                    >
                      I confirm that all entered information is valid and true
                    </Checkbox>
                  </div>
                </div>
              </Col>
            </Row>
            {/* ----------down */}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  focus: state.company.data.companyData[0],
  auth: state.auth,
  companyData: state.company.data,
  submitForm2: state.common.submitForm2,
  countryList: state.common.countries,
  registerLoading: state.common.registerLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    form2SubmittedAction: (value) => form2Submited(dispatch, value),
    form1SubmittedAction: (value) => form1Submited(dispatch, value),
    updateCompanyAction: (data, id) => dispatch(updateCompany(data, id)),
    sendEmailVerification: (id) => dispatch(createEmailVerification(id)),
    showRegisterLoadingAction: (value) => showRegisterLoading(dispatch, value),
    submitForm2Action: (value) => submitForm2(dispatch, value),
    submitForm1Action: (value) => submitForm1(dispatch, value),
    uploadVendorDocumentAction: (data, prevData) =>
      dispatch(uploadVendorDocument(data, prevData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertiseInfo);
