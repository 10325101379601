import { createReducer } from "redux-starter-kit";

const initialState = {
    data:[]
}

const questionReducer = createReducer(initialState, {
    ADD_NEW_QUESTION: (state, action) => { 
        state.data = action.payload;
    },
    GET_CITIES_LIST:(state,action)=>{
        state.citiesData = action.payload
    },
    // SAVE_ANSWER:(state,action)=>{
    //     state.expQuizAnswers = action.payload.expQuizAnswers
    // }
})

export default questionReducer