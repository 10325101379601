import React, { useState, useEffect } from "react";
import { Switch, Input, Skeleton } from "antd";
import {
  updateCompany,
  addEventCompany,
} from "actions/companyActions";
import axios from "axios";
import { base_url, api_v } from "utils/settings";
import { useSelector, useDispatch } from "react-redux";
import Delegates from "./Delegates";

const Event = (props) => {
  const [loading, setLaoding] = useState(true)
  const [response, setResponse] = useState(null)
  const [enabled, setEnabled] = useState(false);
  const [eventLink, setEventLink] = useState("");
  const [ftfLink, setftfLink] = useState("");
  const [awardsLink, setAwardsLink] = useState("");
  const [awardImageLink, setAwardImageLink] = useState("");

  // const companyEvent = useSelector((state) => state.company.data);

  const dispatch = useDispatch();

  const initialFunction = () => {
    axios
      .get(`${base_url}/${api_v}company_events/${JSON.parse(localStorage.getItem('loggedInUserCompanyId'))}`)
      .then((res) => {
        setResponse(res.data)
        setLaoding(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    initialFunction();
  }, []);

  useEffect(() => {
    if (response) {
      const { isEnabled, eventLink, ftfLink, awardImageLink, awardLink } =
        response;
      setEnabled(isEnabled);
      setEventLink(eventLink);
      setftfLink(ftfLink);
      setAwardsLink(awardLink);
      setAwardImageLink(awardImageLink);
    }
  }, [response])

  const onChangeValue = async (e) => {
    const { id } = response;
    let params = {
      id: `v1/companies/${JSON.parse(localStorage.getItem('loggedInUserCompanyId'))}`,
      companyEvents: {
        company: `v1/companies/${JSON.parse(localStorage.getItem('loggedInUserCompanyId'))}`,
        eventLink: eventLink,
        ftfLink: ftfLink,
        isEnabled: enabled,
        awardLink: awardsLink,
        awardImageLink: awardImageLink,
      },
    };

    if (typeof e === "boolean") {
      setEnabled(e);
      params.companyEvents["isEnabled"] = e;
    } else if (e.target.name === "eventLink") {
      setEventLink(e.target.value);
      params.companyEvents["eventLink"] = e.target.value;
    } else if (e.target.name === "f2fLink") {
      setftfLink(e.target.value);
      params.companyEvents["ftfLink"] = e.target.value;
    }
    else if (e.target.name === "awardsLink") {
      setAwardsLink(e.target.value);
      params.companyEvents["awardLink"] = e.target.value;
    }
    else {
      setAwardImageLink(e.target.value);
      params.companyEvents["awardImageLink"] = e.target.value;
    }
    if (!response) {
      return await dispatch(addEventCompany(params.companyEvents));
    } else {
      params.companyEvents["id"] = `v1/company_events/${id}`;
      return dispatch(updateCompany(params, JSON.parse(localStorage.getItem('loggedInUserCompanyId'))));
    }
  };

  return (
    <>
      {loading ?
        <Skeleton className="portalsetting-skelton"/>
        :
        <>
          <div className="event-container app-event-container">
            {/* <h4>Event Links</h4>
            <hr /> */}
            <div className="additional-link-setting-swithcer">
              <label>Switch additional links in app</label>
              <Switch
                disabled={JSON.parse(localStorage.getItem('loggedInUserCompanyId')) !== 1}
                checkedChildren="on"
                unCheckedChildren="off"
                checked={enabled}
                onChange={(e) => onChangeValue(e)}
              ></Switch>
            </div>
            <div className="event-links">
              <div>
                <label className="requiredLbl">Event Link</label>
                <Input
                  disabled={JSON.parse(localStorage.getItem('loggedInUserCompanyId')) !== 1}
                  style={{ width: "20rem" }}
                  name="eventLink"
                  placeholder="url"
                  onChange={(e) => onChangeValue(e)}
                  value={eventLink}
                />
              </div>
              <div className="f2f">
                <label className="requiredLbl">F2F Link</label>
                <Input
                  disabled={JSON.parse(localStorage.getItem('loggedInUserCompanyId')) !== 1}
                  style={{ width: "20rem" }}
                  name="f2fLink"
                  placeholder="url"
                  onChange={(e) => onChangeValue(e)}
                  value={ftfLink}
                />
              </div>
            </div>
            <div className="event-links">
              <div>
                <label className="requiredLbl">Awards link</label>
                <Input
                  disabled={JSON.parse(localStorage.getItem('loggedInUserCompanyId')) !== 1}
                  style={{ width: "20rem" }}
                  name="awardsLink"
                  placeholder="url"
                  onChange={(e) => onChangeValue(e)}
                  value={awardsLink}
                />
              </div>
              <div className="f2f">
                <label className="requiredLbl">Awards image</label>
                <Input
                  disabled={JSON.parse(localStorage.getItem('loggedInUserCompanyId')) !== 1}
                  style={{ width: "20rem" }}
                  name="awardImageLink"
                  placeholder="url"
                  onChange={(e) => onChangeValue(e)}
                  value={awardImageLink}
                />
              </div>
            </div>

          </div>
          <Delegates />
        </>
      }
    </>
  );
};

export default Event;
