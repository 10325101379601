import React from "react";
import { Field } from "react-final-form";
import { Form } from "antd";

const setValidateStatus = meta => {
  if (meta.invalid) {
    return "error";
  }
  return "";
};

const validation = (value, rules) => {
  if (!rules) {
    return;
  }
  let error = "";
  rules.forEach(rule => {
    switch (rule) {
      case "required":
        return (error = "Required");
    }
  });

  if (!value) {
    return error;
  }
};

export default function FormField(props) {
  const { name, validate, component, label, selectedExpertieseIds, fileName, ...rest } = props;
  // console.log("reswt", rest)
  const isRequired = () => {
    if (props.validate) {
      return true;
    }
    return false;
  };

  // Without this (empty values aren't submited) ***
  const identity = value => value;

  return (
    <Field
      name={name}
      parse={identity}
      {...rest}
      validate={value => validation(value, validate)}
    >
      {fileName === 'registration'
        ?
        ({ input, meta }) => (
          <Form.Item
            label={label}
            required={(!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? isRequired() : false}
            hasFeedback={meta.invalid}
            validateStatus={(!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? setValidateStatus(meta) : false}
            help={(!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? meta.error : ''}
          >
            {React.cloneElement(props.children, input)}
          </Form.Item>
        )
        :
        ({ input, meta }) => (
          <Form.Item
            label={label}
            required={isRequired()}
            hasFeedback={meta.invalid}
            validateStatus={setValidateStatus(meta)}
            help={meta.error}
          >
            {React.cloneElement(props.children, input)}
          </Form.Item>
        )
      }
    </Field>
  );
}
