import React from "react";

const SkeletonLoading = ({ itemsPerPage }) => {
  let content = "loading...";

  if (itemsPerPage) {
    content = [...Array(itemsPerPage)].map((e, i) => (
      <div className="card card__network card__skeleton" key={i}>
        <div className="skeleton-line skeleton-line--xl"></div>
        <div className="skeleton-line"></div>
      </div>
    ));
  }
  return <div className="skeleton-loading">{content}</div>;
};

export default SkeletonLoading;
