import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Icon, Row, Col, message } from "antd";
import { clearAuth, resetPasswordRequest } from "actions/authActions";
import logo from "theme/assets/logo.svg";
import footerIcon from 'theme/assets/footerIcon.png'
import MobileAlert from "components/ui-elements/MobileAlert";
import queryString from 'query-string';
import { DOMAIN } from "utils/enum";

class ResetPassword extends Component {

  state = { passwordError: "" };
  onSubmit = async e => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      this.setState({ passwordError: "" })
      if (err) {
        return;
      }
      const { password, cpassword } = values;
      if (password !== cpassword) {

        this.setState({ passwordError: "Password and confirm password does not match!" })
        return false;
      }
      values.token = queryString.parse(this.props.location.search).token;
      const resp = await this.props.resetPasswordRequest(values);
      if (resp.status === 200) {
        message.success("Password has been sucessfully updated. Please login");
        this.props.history.push("/login");
      }
    });
  };
  loginFun=(domain)=>{

    let link;
    if(domain==undefined){
      link = "https://neutralairpartner.com/webapprout/"
    }else{
      if(domain===DOMAIN.NEX){
        link = "https://nex-network.com/webrout1/"
      }else{
        link = "https://neutralairpartner.com/webapprout/"
      }
    }
    return window.location.href = link
    
 
}
  render() {
    const { loading, error } = this.props.auth;
    const { getFieldDecorator } = this.props.form;
    const { logo, coverImage, domain } = this?.props?.domain
    const date = new Date()
    if (!localStorage.continueDesktop || localStorage.continueDesktop === "1") {
      return (
        <div className="noPaddingTop">
          <Row>
            <Col className="leftSection" lg={15} md={15} xs={0} onClick={()=>this.loginFun(domain)} >
            </Col>
            <Col className="formSection" lg={9} md={9} xs={24}>
              <div className="centerContent">
                <div className="mainContent">
                  <img src={logo} alt="logo" className="loginLogo" />
                  <div className="centerForm">
                    <div className="pageTitle">
                      <span className="pageTitleTxt">
                        Reset Password
                      </span>
                    </div>
                    {error && (
                      <h4 className="text-error">
                        {error}
                      </h4>
                    )}
                    {this.state.passwordError && <h4 className="text-error">{this.state.passwordError}</h4>}
                    <Form
                      onSubmit={this.onSubmit}
                      autoComplete="off"
                    >
                      <Form.Item>
                        {getFieldDecorator("password", {
                          rules: [
                            { required: true, message: "Please input your Password!" }
                          ]
                        })(
                          <Input.Password
                            prefix={
                              <Icon type="lock" style={{ color: "#3565AE" }} />
                            }
                            size="large"
                            type="password"
                            placeholder="Password"

                          />
                        )}
                      </Form.Item>

                      <Form.Item>
                        {getFieldDecorator("cpassword", {
                          rules: [
                            { required: true, message: "Please input your Password!" }
                          ]
                        })(
                          <Input.Password
                            prefix={
                              <Icon type="lock" style={{ color: "#3565AE" }} />
                            }
                            size="large"
                            type="password"
                            placeholder="Confirm Password"
                          />
                        )}
                      </Form.Item>
                      <Button
                       className="submit-btn"
                        // type="primary"
                        size="large"
                        loading={loading}
                        htmlType="submit"
                        // style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                  <img src={footerIcon} alt="logo" className="footerIcon" />
                </div>
                <div className="footerContent">
                  Copyright ©{date.getFullYear()} Produced by Neutral Air  Partner
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    else {
      return (
        <>
          <MobileAlert />
        </>
      );
    }

  }
}

const WrappedResetPassword = Form.create({ name: "normal_resetpassword" })(ResetPassword);

const mapStateToProps = state => ({
  auth: state.auth,
  domain: state?.common?.domain
});

export default connect(mapStateToProps, { resetPasswordRequest, clearAuth })(WrappedResetPassword);
