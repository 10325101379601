import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Drawer, Progress, Tooltip } from "antd";
import CompanyLogo from "components/ui-elements/CompanyLogo";
import CreateBanch from "./CreateBranch";

const CompanyHeader = ({
  name,
  logo,
  city,
  country,
  profileReadiness,
  classification,
  companyId,
  auth,
  toggleEdit
}) => {
  const [newBranch, toggleNewBranch] = useState(false);
  const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

  const companyInfo = useSelector((state) => {
    if (isAdmin) {
      return state.company;
      // return state.data;
    } else {
      return state.company;
    }
  });

  return (
    <div className="company-header container-fluid f-s-b">
      <div className="f">
        <div className="company-logo-box f-c-c">
          <CompanyLogo logo={logo}></CompanyLogo>
        </div>
        <div>
          <div id="autosave"></div>
          <h1 className="company-title">{name}</h1>
          <span className="text-secondary">
            {/* {classification} |*/} {city}, {country.countryname}
          </span>
        </div>
      </div>

      {
        isAdmin ?
          <>
            <div className="f-s-b" style={{ marginLeft: 20 }}>
              <Button
                type="link"
                icon="plus"
                className="add-branch-btn"
                onClick={() => toggleNewBranch(prevToggle => !prevToggle)}

              >
                Add branch
              </Button>
              {/* <Divider type="vertical"></Divider> */}
              {/*  <Button type="link"
                icon="close"
                className="close-btn"
                onClick={() => toggleEdit()} >
                CLOSE</Button> */}
              <Tooltip
                title={`Profile readiness ${profileReadiness}%`}
                placement="topRight"
              >
                <Progress
                  className="progress-circle-logo"
                  type="circle"
                  percent={profileReadiness}
                  strokeLinecap="square"
                  strokeWidth={18}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#4cb6b6"
                  }}
                  showInfo={false}
                  width={36}
                />
              </Tooltip>
              {/* <Divider type="vertical"></Divider> */}
              <Button type="link"
                icon="close"
                className="close-btn"
                onClick={() => toggleEdit()} >
                CLOSE</Button>
            </div>
            {/*  <div className="f-s-b" style={{ marginLeft: 20 }}> */}

            {/*    </div>   */}
          </>
          :
          <div className="f-s-b" style={{ marginLeft: 20 }}>
            <Button type="link"
              icon="close"
              className="close-btn"
              onClick={() => toggleEdit()} >
              CLOSE</Button>
          </div>
      }
      <Drawer
        width={700}
        closable={false}
        onClose={() => toggleNewBranch(prevToggle => !prevToggle)}
        visible={newBranch}
      >
        <CreateBanch
          isAdmin={isAdmin}
          companyInfo={companyInfo}
          companyId={companyId}
          countryId={country.id}
          onClose={() => toggleNewBranch(prevToggle => !prevToggle)}
        ></CreateBanch>
      </Drawer>
    </div >
  );
};

export default CompanyHeader;
