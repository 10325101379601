import React, { useState } from "react";
import { Form, Input } from "antd";
import { connect } from "react-redux";
import { useDebouncedCallback } from "components/helper/Hooks";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

const ChangePassword = (props) => {

    const [confirmDirty, setConfirmDirty] = useState(false)
    const [passwordNotMatch, setPasswordNotMatch] = useState(false)    
    const [confirmPassValue, setConfirmPassValue] = useState('')
    const { getFieldDecorator, getFieldError } = props.form;
    const { password } = props?.user?.user;

    const [debouncedUpdate] = useDebouncedCallback(props.saveUserInfo, 1000);

    const handlePassword = (e) => {
        const { value } = e.target;         
        setConfirmDirty(confirmDirty || !!value);
        const passwordErrors = getFieldError('password');
        const confirmPasswordErrors = getFieldError('confirm');      
        if ((!passwordErrors && value && !passwordNotMatch && !confirmPasswordErrors) || (passwordErrors?.length === 0 && confirmPasswordErrors?.length === 0)) {           
            debouncedUpdate(value)
        }
    }

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmPassValue(value)
        setConfirmDirty(confirmDirty || !!value);
        const confirmPasswordErrors = getFieldError('confirm');
        if ((!confirmPasswordErrors && value && !passwordNotMatch) || (confirmPasswordErrors?.length === 0)) {
            debouncedUpdate(value)
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;
        if ((value !== confirmPassValue) || (value && confirmDirty)) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        console.log("confirmDirty", confirmDirty)
        if (value && value !== form.getFieldValue("password")) {
            setPasswordNotMatch(true)
            callback("Passwords do not match!");
        } else {
            callback();
            setPasswordNotMatch(false)
        }
    };

    return (
        <Form {...formItemLayout} style={{ maxWidth: 600 }}  >
            <Form.Item label="Password" name="password" hasFeedback>
                {getFieldDecorator("password", {
                    initialValue: password && "******",
                    rules: [
                        {
                            required: true,
                            message: "Please input password"
                        },
                        {
                            min: 6,
                            message: "Minimum 6 characters"
                        },
                        {
                            validator: validateToNextPassword
                        }
                    ]
                })(<Input.Password onKeyUpCapture={handlePassword} />)}
            </Form.Item>

            <Form.Item label="Confirm Password" name="confirm" hasFeedback>
                {getFieldDecorator("confirm", {
                    initialValue: password && "******",
                    rules: [
                        {
                            required: true,
                            message: "Please confirm password"
                        },
                        {
                            validator: compareToFirstPassword
                        }
                    ]
                })(<Input.Password /* onBlur={handleConfirmBlur} */ onKeyUp={handleConfirmBlur} />)}
            </Form.Item>
        </Form>
    )
}

const WrappedNewMessage = Form.create({ name: "changePassword" })(ChangePassword);
const mapStateToProps = (state) => ({
    auth: state.auth,
    domain: state?.common?.domain,
});
export default connect(mapStateToProps, {  })(WrappedNewMessage);