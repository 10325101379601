import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CustomSelect from "components/ui-elements/Select";
import {
  getCompany2,
  createSpecialization,
  deleteSpecialization
} from "actions/companyActions";

import axios from "axios";
import { findSpecUrl, cleanArrayOfObjects } from "utils/functions";
import { base_url, api_v } from "utils/settings";
import { message } from "antd";

// Fetch countries (for react-select)
const fetchCountries = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}countries?countryname=${input}`)
    .then(res => {
      return callback(res.data["hydra:member"].map(a => ({ country: a })));
    })
    .catch(() => {
      return callback([]);
    });
};

// COMPONENT --------------------------------------------------------------------------------------------
const ExpressOperator = props => {
  const { companyId, airImportCountry, airExportCountry, setValidationErrorImportExport, fileName, selectedExpertieseIds } = props;
  const dispatch = useDispatch()
  const [loading, setLoading] = useState({
    airImportCountry: false,
    airExportCountry: false
  });

  //Here, handle validation Error status if any field is empty
  useEffect(() => {
    if ((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32))) {
      if (airExportCountry?.length === 0 && airImportCountry?.length === 0) {
        typeof setValidationErrorImportExport === "function" && setValidationErrorImportExport((prevValue) => {
          return {
            ...prevValue,
            airExportCountry: true,
            airImportCountry: true
          }
        })
      } else if (airExportCountry?.length === 0) {
        typeof setValidationErrorImportExport === "function" && setValidationErrorImportExport((prevValue) => {
          return {
            ...prevValue,
            airExportCountry: true,
            airImportCountry: false
          }
        })
      } else if (airImportCountry?.length === 0) {
        typeof setValidationErrorImportExport === "function" && setValidationErrorImportExport((prevValue) => {
          return {
            ...prevValue,
            airExportCountry: false,
            airImportCountry: true
          }
        })
      } else {
        typeof setValidationErrorImportExport === "function" && setValidationErrorImportExport((prevValue) => {
          return {
            ...prevValue,
            airExportCountry: false,
            airImportCountry: false
          }
        })
      }
    } else {
      typeof setValidationErrorImportExport === "function" && setValidationErrorImportExport((prevValue) => {
        return {
          ...prevValue,
          airExportCountry: false,
          airImportCountry: false
        }
      })
    }
  }, [airExportCountry, airImportCountry])


  const updateCountries = (e, name) => {
    const targetUrl = findSpecUrl(name);
    setLoading({ ...loading, [name]: true });
    if (e.action !== 'remove-value' && name === "airExportCountry" && airExportCountry.length >= 5) {
      message.error("you can select only 5 Country");
      setLoading({ ...loading, [name]: false });
      return;
    }
    if (e.action !== 'remove-value' && name === "airImportCountry" && airImportCountry.length >= 5) {
      message.error("you can select only 5 Country");
      setLoading({ ...loading, [name]: false });
      return;
    }
    switch (e.action) {
      case "select-option":
        const data = {
          company: `/${api_v}company/${companyId}/profile`,
          country: `/${api_v}countries/${e.option.country.id}`,
          subject: name
        };
        return props.createSpecialization(data, name, targetUrl, fileName).then(() => {
          setLoading({ ...loading, [name]: false });
          message.success("Information has been updated.");
        });
      case "remove-value":
        return props
          .deleteSpecialization(e.removedValue.id, targetUrl, name, fileName, companyId)
          .then(() => {
            setLoading({ ...loading, [name]: false });
            message.success("Information has been updated.");
          });
    }
  };

  // Render
  return (
    <div>
      {/* <h3>Export / Import Countries</h3> */}
      <CustomSelect
        async={true}
        className="customSelectWithChips"
        isDisabled={loading.airExportCountry}
        label={"Name your Top 5 countries you export to"}
        required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
        fileStatus="editProfile"
        selectedExpertieseIds={selectedExpertieseIds}
        //   label={<><span style={{ color: "red" }}>*</span> Name your Top 5 countries you export to</>}
        cacheOptions
        // validate={["required"]} 
        value={airExportCountry}
        loadOptions={fetchCountries}
        isClearable={false}
        noOptionsMessage={input => "Please type"}
        isMulti
        getOptionLabel={option => option.country.countryname}
        getOptionValue={option => option.country.id}
        onChange={(_, e) => updateCountries(e, "airExportCountry")}
      ></CustomSelect>

      <CustomSelect
        className="customSelectWithChips"
        async={true}
        isDisabled={loading.airImportCountry}
        required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
        fileStatus="editProfile"
        selectedExpertieseIds={selectedExpertieseIds}
        label={"Name your Top 5 countries you import from"}
        //    label={<><span style={{ color: "red" }}>*</span> Name your Top 5 countries you import from</>}
        value={airImportCountry}
        cacheOptions
        loadOptions={fetchCountries}
        isClearable={false}
        noOptionsMessage={input => "Please type"}
        isMulti
        getOptionLabel={option => option.country.countryname}
        getOptionValue={option => option.country.id}
        onChange={(_, e) => updateCountries(e, "airImportCountry")}
      ></CustomSelect>
    </div>
  );
};

const mapStateToProps = state => ({
  // ID for actions
  companyId: state.company.data.id,

  // Name your Top 5 countries you import from * --------------------------------------------------------------
  airImportCountry: cleanArrayOfObjects(state.company.data.airImportCountry),

  // Name your Top 5 countries you export to * --------------------------------------------------------------
  airExportCountry: cleanArrayOfObjects(state.company.data.airExportCountry)
});

export default connect(mapStateToProps, {
  createSpecialization,
  deleteSpecialization
})(ExpressOperator);
