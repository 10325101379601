import React, { Component } from "react";
import { Skeleton, Popover, Icon } from "antd";
import ExpertiseItem from "components/network/company-card/Expertise/ExpertiseItem";
import AirCargoNeutral from "./AirCargoNeutral";
import { getCompany } from "actions/companyActions";
import { connect } from "react-redux";

// Loading for target Company
class ShouldLoad extends Component {
  componentDidMount() {
    // Load Company Details if not present or not matching correct company, or allrready loading (onHover triggers this)
    if (
      !this.props.loadedCompanyId ||
      this.props.loadedCompanyId !== this.props.companyId
    ) {
      if (this.props.loading) {
        return;
      }
      this.props.getCompany(this.props.companyId);
    }
  }
  render() {
    const { data, containerWidth, loading } = this.props;

    let content;

    if (loading || data === null) {
      content = <Skeleton active={true} />;
    } else {
      content = this.props.children;
    }

    return (
      <div
        className="expertise-popup"
        style={{ width: (containerWidth * 3) / 4 - 48 }}
      >
        {content}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loadedCompanyId: state.company.data === null ? null : state.company.data.id,
  data: state.company.data,
  loading: state.company.loading
});

const ExpertiseLoading = connect(mapStateToProps, { getCompany })(ShouldLoad);

// AirCargo and Neutral Air Consolidators are treated differently than other expertises
const ExpertiseHoverItem = ({
  item,
  companyId,
  title,
  expertiseId,
  air,
  containerWidth,
  neutral
}) => {
  const expertiseDetails = (
    <>
      {air || neutral ? (
        <ExpertiseLoading
          companyId={companyId}
          title={title}
          containerWidth={containerWidth}
        >
          <AirCargoNeutral air={air} neutral={neutral}></AirCargoNeutral>
        </ExpertiseLoading>
      ) : (
        <ExpertiseLoading
          companyId={companyId}
          title={title}
          containerWidth={containerWidth}
        >
          <ExpertiseItem
            expertiseId={expertiseId}
            title={title}
            companyId={companyId}
          ></ExpertiseItem>
        </ExpertiseLoading>
      )}
    </>
  );

  return (
    <Popover
      content={expertiseDetails}
      title={title}
      placement="right"
      destroyTooltipOnHide
    >
      <a
        className="ant-dropdown-link"
        href="#"
        onClick={e => e.preventDefault()}
        style={{ display: "block", lineHeight: 1.15, paddingBottom: 6 }}
      >
        {title}
      </a>
    </Popover>
  );
};

export default ExpertiseHoverItem;
