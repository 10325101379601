import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { Icon } from "antd";
import { variables } from "utils/settings";

const UploadWidget = styled("div")`
  position: fixed;
  background: white;
  padding: 24px;
  bottom: 30px;
  right: 30px;
  z-index: 99999;
  width: 360px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: ${variables.borderRadius.regular};
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
`;

const Item = styled("div")`
  border-bottom: 1px solid ${variables.colors.border};
  padding: 8px 0;
  &:last-child {
    border-bottom: none;
  }
`;
class UploadPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.files.length !== this.props.files.length) {
      this.setState({
        isOpen: this.props.files.length === 0 ? false : true
      });
    }
  }

  render() {
    const { files } = this.props;

    if (files.length > 0) {
      let filesContent;
      filesContent = files.map(item => (
        <Item className="f-s-b" key={item.id}>
          <Icon
            type="file"
            style={{
              marginRight: 10,
              fontSize: "16px",
              color: "rgba(0,0,0,.4)"
            }}
          ></Icon>
          <div style={{ flex: 2 }}>
            <span style={{ maxWidth: "90%", overflow: "hidden" }}>
              {item.text}
            </span>
            <span style={{ fontSize: 10, float: "right" }}>
              {item.size / 1000} kb
            </span>
            <div style={{ fontSize: 10 }}>
              {item.progress !== 100 ? (
                <span>Uploading {item.progress}%</span>
              ) : (
                <span className="text-success">Completed</span>
              )}
            </div>

            {item.progress !== undefined ? (
              <div className="upload-progress">
                <div className="line" style={{ width: item.progress + "%" }} />
              </div>
            ) : (
              ""
            )}
          </div>
        </Item>
      ));
      return <UploadWidget>{filesContent}</UploadWidget>;
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  files: state.upload.files
});

export default connect(mapStateToProps)(UploadPreview);
