import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const OurTools = () => {
    const logoContent = useSelector((state) => state.auth.logoData);
    const [logoArray, setLogoArray] = useState([]);

    useEffect(() => {
        if (logoContent?.length > 0) {
            const array = [];
            //   console.log(logoContent,"logoContent")
            const element = logoContent.forEach((element) =>
                element?.status === "1" ? array.push(element) : []
            );
            setLogoArray(array);
        }
    }, [logoContent]);
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <span className="accrediation-icon">
                    {/* <img src={tradelaneIcon2} className="tradelane-icon" /> */}

                    <svg className="tradelane-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V4C11 3.73478 10.8946 3.48043 10.7071 3.29289C10.5196 3.10536 10.2652 3 10 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V10C3 10.2652 3.10536 10.5196 3.29289 10.7071C3.48043 10.8946 3.73478 11 4 11ZM5 5H9V9H5V5ZM20 3H14C13.7348 3 13.4804 3.10536 13.2929 3.29289C13.1054 3.48043 13 3.73478 13 4V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H20C20.2652 11 20.5196 10.8946 20.7071 10.7071C20.8946 10.5196 21 10.2652 21 10V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3ZM19 9H15V5H19V9ZM10 21C10.2652 21 10.5196 20.8946 10.7071 20.7071C10.8946 20.5196 11 20.2652 11 20V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13H4C3.73478 13 3.48043 13.1054 3.29289 13.2929C3.10536 13.4804 3 13.7348 3 14V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H10ZM5 15H9V19H5V15ZM18 14H16V16H14V18H16V20H18V18H20V16H18V14Z" fill="black" />
                    </svg>


                </span>
                <div>
                    <h3 className="accrediation-title">Our Tools</h3>
                    <div className="AccrediatationsImagesWrap">
                        {/* {
                            data.map((licence) => (
                                <Tooltip
                                    key={licence.id}
                                    title={licence.acreditation + "REF No." + licence.refNo}
                                >
                                    <a
                                        className="mx-1"
                                        style={{
                                            width: "60px",
                                            height: "auto",
                                            display: "inline-block",
                                        }}
                                    >
                                        <img src={licence.imageUrl} style={{ width: "100%" }} />
                                    </a>
                                </Tooltip>
                            ))
                        } */}
                        {logoArray?.map((element) => {
                            const { partnerLink, partnerLogo, partnerName, status } =
                                element;
                            return status === "1" ? (
                                    <NavLink
                                        className="paddingTools"
                                        to={{
                                            pathname: partnerLink,
                                        }}
                                        target="_blank"
                                    >
                                        <img
                                            src={partnerLogo}
                                            alt={partnerName}
                                            className="partnerIcon"
                                        />
                                    </NavLink>
                            ) : (
                                ""
                            );
                        })}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default OurTools;