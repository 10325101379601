
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button } from 'antd'
const ConnectionCard = (props) => {
    const { title, data } = props;
    return (
        <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
            <Col className="gutter-row" >{title}</Col>
            <Col className="gutter-row" >{data}</Col>
        </Row>
    )
}
export default ConnectionCard