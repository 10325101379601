import React from "react";
import styled from "@emotion/styled";
import { Icon } from "antd";
import { variables } from "utils/settings";
import { useSelector } from "react-redux";
import { DOMAIN } from "utils/enum";

const FileBox = styled("div")`
  width: 150px;
  padding: 20px 10px;
  position: relative;
  color: ${variables.colors.text};
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-right: 10px;
  border-radius: ${variables.borderRadius.regular};
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: nowrap;
  transition: border 0.24s ease-in-out;

  a {
    color: ${variables.colors.textSecondary};
  }
  &:hover {
    background: ${variables.colors.light};

    .delete-icon {
      visibility: visible;
    }
  }
`;

const DeleteIcon = styled(Icon)`
  position: absolute;
  visibility: hidden;
  padding: 8px;
  top: 0;
  right: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export default function FilePreview({ name, link, id, onDelete }) {
  const { domain } = useSelector((state) => state.common)

  const deletefile = () => {
    onDelete(id).then(res => console.log(res));
  };
  return (
    <FileBox style={{ position: "relative", display: "inline-block" }}>
      <a href={link} style={{ display: "inline-flex", alignItems: "center" }}>
        {domain?.domain === DOMAIN.NEX ?
          <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="file" width="1em" height="1em" fill="#BB2648" aria-hidden="true"><path fill="#e6f7ff" d="M534 352V136H232v752h560V394H576a42 42 0 0 1-42-42z"></path><path fill="red" d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path></svg>
          :
          <Icon
            type="file"
            style={{ fontSize: 20, marginRight: 5 }}
            theme="twoTone"
            className="add-branch-btn"
          />
        }


        <span className="text-small">{name}</span>
      </a>
      {onDelete && (
        <DeleteIcon
          className="delete-icon"
          onClick={deletefile}
          type="delete"
        ></DeleteIcon>
      )}
    </FileBox>
  );
}
