import React, { useEffect, useState } from "react";
import { connect,useSelector } from "react-redux";
import { getCountries, fetchCities, getCity } from "actions/dataActions";
import { updateCompanyInfo, updateBank } from "actions/companyActions";
import CustomSelect from "components/ui-elements/Select";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { TitleForm } from "components/ui-elements/Typography";
import { Input, Form, message,Select } from "antd";
import { api_v } from "utils/settings";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const Legal = props => {
  const [bankCity, setBankCity] = useState({});
  
  const countryList = useSelector(state => state.data.countries)
  const companyId= useSelector(state=>state.company.data)

  // Auto Save Inputs - Debounced
  const save = async values => {
    const promises = [];

    Object.keys(values).forEach(function(key, index) {
      const data = {
        [key]: values[key]
      };

      if (key === "legalRepresentative" || key === "legalRepresentativeEmail") {
        return promises.push(
          Promise.resolve(props.updateCompanyInfo(data, props.companyInfo?.id))
        );
      }

      promises.push(Promise.resolve(props.updateBank(data, props.bankReference?.id)));
    });

    return await Promise.all(promises);
  };

  const saveBank = async values => {
    return await props.handleBankSave(values);
  };

  // Country Selection
  const handleCountrySelect = selected => {
    props
      .updateBank(
        { bankCountry: `/${api_v}countries/${selected.id}` },
        props.companyId
      )
      .then(() => {
        message.success("Information has been updated");
      });
    setBankCity(false);
  };

  // City selection
  const handleCitySelect = selected => {
    setBankCity(selected);
    props
      .updateBank({ bankCity: `${selected.id}` }, props.companyId)
      .then(() => {
        message.success("Information has been updated");
      });
  };

  // ComponentDidMount
  useEffect(() => {
    props.getCountries();
    if (props.bankReference.bankCity) {
      getCity(props.bankReference.bankCity).then(city => {
        if (city) {
          setBankCity(city.data);
        }
      });
    }
  }, []);

  const { legalRepresentativeEmail, legalRepresentative, countries} = props;

  const {
    bankName,
    bankCountry,
    bankEmail,
    bankPerson,
    bankTel,
    dialCode
  } = props.bankReference;

  let telCountryCodeSelect = (
    <FormField
      name="dialCode"
    >
      <Select showSearch style={{ width: 90 }}
        value={dialCode}
      // onChange={handleCountryCodeSelect} size="large"
      >
        {countryList.map((item, index) => {
          return (
            <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
      </Select>
    </FormField>
  );

  return (
    <div className="card">
      <div className="card-header">
        <h2>Representatives and references</h2>
      </div>

      <div className="card-body">
        <AutoSaveWrapper
          onSubmit={save}
          initialValues={{
            dialCode: dialCode ? dialCode : companyId.countries?.dialCode,
            legalRepresentativeEmail,
            legalRepresentative,
            bankName,
            bankEmail,
            bankPerson,
            bankTel
          }}
          subscription={{}}
        >
          {() => (
            <>
              <AutoSave
                debounce={1000}
                save={save}
                debounced={[
                  "dialCode",
                  "legalRepresentativeEmail",
                  "legalRepresentative",
                  "bankName",
                  "bankEmail",
                  "bankPerson",
                  "bankTel"
                ]}
              />
              <TitleForm variant="subtitle">Legal representative</TitleForm>
              <Form {...formItemLayout} style={{ maxWidth: "600px" }}>
                <FormField
                  name="legalRepresentative"
                  label="Full name"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField>
                <FormField
                  name="legalRepresentativeEmail"
                  label="Legal representative email"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField>
              </Form>
              <TitleForm variant="subtitle">Bank Information</TitleForm>
              <Form {...formItemLayout} style={{ maxWidth: "600px" }}>
                <FormField
                  name="bankName"
                  label="Bank Name"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField>

                <Form.Item label="Country" required={true}>
                  <CustomSelect
                    name="bankCountry"
                    placeholder={"Select country"}
                    defaultValue={bankCountry}
                    options={countries}
                    getOptionLabel={option => option.countryname}
                    getOptionValue={option => option.id}
                    isClearable={false}
                    onChange={handleCountrySelect}
                  />
                </Form.Item>

                <Form.Item label="City" required={true}>
                  <CustomSelect
                    async={true}
                    placeholder={"Select City"}
                    key={bankCity}
                    value={bankCity}
                    cacheOptions
                    loadOptions={(input, callback) =>
                      fetchCities(input, bankCountry.id, callback)
                    }
                    noOptionsMessage={input => "Type to search"}
                    getOptionLabel={option => option.cityAsciiname}
                    getOptionValue={option => option.id}
                    onChange={handleCitySelect}
                  ></CustomSelect>
                </Form.Item>

                <FormField
                  name="bankPerson"
                  label="Contact person"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField>

                <FormField name="bankEmail" label="E-mail">
                  <Input></Input>
                </FormField>

                <FormField name="bankTel" label="Telephone">
                {/*   <Input></Input> */}
                <Input addonBefore={telCountryCodeSelect}></Input>
                </FormField>
              </Form>
            </>
          )}
        </AutoSaveWrapper>

        <AutoSaveWrapper
          onSubmit={saveBank}
          initialValues={{
            bankName: props.bankReference.bankName,
            bankTel: props.bankReference.bankTel,
            referenceBankAddress: props.bankReference.referenceBankAddress,
            referenceBankAccount: props.bankReference.referenceBankAccount,
            referenceBankSwift: props.bankReference.referenceBankSwift,
            referenceBankFax: props.bankReference.referenceBankFax,
            // referenceBankAccnumber: props.bankReference.referenceBankAccnumber
          }}
          subscription={{}}
        >
          {() => (
            <>
              <AutoSave
                debounce={1500}
                save={saveBank}
                debounced={[
                  "bankName",
                  "bankTel",
                  "referenceBankAddress",
                  "referenceBankAccount",
                  "referenceBankSwift",
                  "referenceBankFax",
                  // "referenceBankAccnumber"
                ]}
              />

              {/* <TitleForm variant="subtitle">Bank Information</TitleForm> */}

              <Form {...formItemLayout} style={{ maxWidth: "600px" }}>
                {/* <FormField
                  name="bankName"
                  label="Bank Name"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField> */}

{/*                 <FormField
                  name="referenceBankAccount"
                  label="Beneficary name"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField> */}

                {/* <FormField
                  name="referenceBankAccnumber"
                  label="Account number"
                  validate={["required"]}
                >
                  <Input addonBefore="IBAN"></Input>
                </FormField> */}
{/*                 <FormField
                  name="referenceBankSwift"
                  label="Swift code"
                  validate={["required"]}
                >
                  <Input></Input>
                </FormField> */}

                <FormField
                  name="referenceBankAddress"
                  label="Address, city, country"
                  validate={["required"]}
                >
                  <Input.TextArea autoSize={{ minRows: 2 }}></Input.TextArea>
                </FormField>

                {/* <FormField
                  name="bankTel"
                  label="Telephone"
                  validate={["required"]}
                >
                  <Input style={{ maxWidth: 240 }}></Input>
                </FormField> */}

                {/* <FormField name="referenceBankFax" label="Fax">
                  <Input style={{ maxWidth: 240 }}></Input>
                </FormField> */}
              </Form>
            </>
          )}
        </AutoSaveWrapper>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  bankReference: state.company.data.companyBankInfo[0],
  legalRepresentative: state.company.data.companyInfo[0].legalRepresentative,
  legalRepresentativeEmail:
    state.company.data.companyInfo[0].legalRepresentativeEmail,
  countries: state.data.countries,
  cities: state.data.cities,
  companyId: state.company.data.id,
  companyInfo: state.company.data.companyInfo[0]
});
export default connect(mapStateToProps, {
  getCountries,
  updateBank,
  updateCompanyInfo
})(Legal);
