import React from "react";
import { Row } from "antd";
import Stats from "./Stats";

const TradeStats = ({ companyData }) => {
	return (
		<Row>
			<Stats percentage={companyData[0].focusExports} classification="Export" color="#67caca" />
			<Stats percentage={companyData[0].focusImports } classification="Import" color="#ffa500" />
			<Stats percentage={companyData[0].focusCrossTrade} classification="Cross-Trade" color="#e9cd6b" />
			<Stats percentage={companyData[0].focusEcommerce} classification="E-Commerce" color="#a680f6" />
		</Row>
	);
};

export default TradeStats;
