import React, { useState, useEffect } from "react";
import UserInformation from "./UserInformation";
import { connect, useSelector } from "react-redux";
import { updateUser, deleteUser, updateKeyPersonel } from "actions/userActions";
import { findAvatar } from "utils/functions";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { useDebouncedCallback } from "components/helper/Hooks";
import ImageUpload from "components/ui-elements/ImageUpload";
import PasswordReset from "./PasswordReset";
import jwt_decode from "jwt-decode";
import {
  Input,
  Row,
  Col,
  Button,
  Form,
  Popconfirm,
  Switch,
  Icon,
  Tooltip,
  Divider,
  message,
  Checkbox
} from "antd";
import { TitleForm } from "components/ui-elements/Typography";
import { api_v } from "utils/settings";
import styled from "@emotion/styled";
import { DOMAIN } from "utils/enum";
import ChangePassword from "./ChangePassword";

const SwitchForm = styled(Form)`
  alignitems: center;
  display: flex;
  justify-content: space-between;

  label {
    line-height: 1;
  }
`;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const UserDetails = (props) => {
  const [editPassword, toggleEditPassword] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false)
  const { getFieldDecorator } = props.form;
  console.log("props.form...", props.form)
  // Props
  const userId = props.user.user.id;

  const { branchId, auth, subIndustries } = props;

  const decoded = jwt_decode(localStorage.jwtToken);
  const loggedInUserId = decoded.id;
  const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

  /* START:- Get company's info */
  const companyInfo = JSON.parse(localStorage.companyInfo);
  /* END:- Get company's info */

  const togglePassword = () => {
    toggleEditPassword((editPassword) => !editPassword);
  };

  const handleAvatarUpload = (avatar) => {
    const data = {
      userAvatar: [`/${api_v}user_avatars/${avatar.response.id}`],
    };

    return props.updateUser(data, userId, branchId);
  };

  const saveUser = async (values) => {
    console.log("save user,.....", values)
    values.username = values.email;
    return await props.updateUser(values, userId, branchId);
  };

  const saveKeyPersonel = async (values) => {
    console.log("save key perso...", values)
    return await props.updateKeyPersonel(values, props.user.id, branchId);
  };

  const saveSubIndustries = async (values) => {
    if (values) {
      let params = {
        "subIndustry": []
      }
      params["subIndustry"] = values.map((element) => "/v1/sub_industries/" + element);
      // return await props.updateUser(params,  userId, branchId);
      props.updateUser(params, userId, branchId).then(value => {
        if (value) {

          return message.success("The information has been updated");
        }
      });
    }
  }

  // Render ----------------------------------------------------------------
  const { firstName, lastName, email, password } = props.user.user;

  // const { getFieldDecorator } = props.form;
  const { schedulerKey } = props.user
  const subIndustry = props.user.user.subIndustry.map((element) => element.id);

  const { editProfile, editBranch, useTools, keyPersonel, companyAdmin, nexHub } =
    props.user;
  const userInfo = props.user.user.userInfo;
  const { domain } = props?.domain
  const avatar = findAvatar(props.user.user.userAvatar);

  const data = useSelector((state) => state.data)



  // useEffect(() => {
  //   if (data) {

  //       const { companies } = data
  //     const companyData = companies.map((item) => {
  //       return item
  //     })
  //     setCompanyData(companyData)
  //   }
  // }, [data])

  // useEffect(() => {
  //   if (companyData.length) {
  //     const subIndustries = companyData.filter((item) => item.id === props.companyId)

  //     setSubIndustries(subIndustries[0]?.subIndustry)
  //   }
  // }, [companyData])

  /*  const handleConfirmBlur = e => {
     const { value } = e.target;
     this.setState({ confirmDirty: this.state.confirmDirty || !!value });
   }; */

  const saveUserInfo = (value) => {
    props.updateUser({ "password": value }, userId, branchId).then(value => {
      if (value) {
        return message.success("The information has been updated");
      }
    });
  }


   return (
    <div className="user-edit container-fluid">
      <Row gutter={30}>
        <Col span={14}>
          {/*    <AutoSaveWrapper
            onSubmit={saveKeyPersonel}
            initialValues={{
              password,
            }}
            subscription={{}}
          >
            {() => (
              <>
                <AutoSave
                  debounce={1000}
                  save={saveUser}
                  debounced={["password", "confirmPassword"]}
                /> */}
          <ChangePassword user={props?.user} saveUserInfo={saveUserInfo}/>
          {/* </> */}
          {/*   )}
          </AutoSaveWrapper> */}
        </Col>
      </Row>
      {/* <AutoSaveWrapper
        // onSubmit={props.saveSubIndustries(userId)}
        onSubmit={saveSubIndustries}
        initialValues={{
          subIndustry
        }}
        subscription={{}}
      >
        {() => ( */}
      {
        subIndustries?.length > 0 ? <>
          {/* <AutoSave
              debounce={1000}
              //  save={props.saveSubIndustries(userId)}
              save={saveSubIndustries}
              debounced={["subIndustry"]}
            /> */}
          <TitleForm variant="subtitle">Network</TitleForm>
          {/* <FormField
              name="subIndustry"
              label="subIndustries"
            // validate={["required"]}
            > */}
          <div className="imagesAndCheckboxes">
            <Checkbox.Group
              value={subIndustry}
              onChange={saveSubIndustries}
              className="expertise-box-group"
            >
              {subIndustries?.map((element, index) => {
                return (
                  <Checkbox value={element.id} key={`subGroup${index}`} className="expertise-box-head">
                    {element.industryLogo ?
                      <div className="logoAndBadge">
                        <img
                          src={
                            element.industryLogo
                          }
                          className="logo-img"
                        />
                      </div>
                      : "None"}
                  </Checkbox>
                )
              })
              }
            </Checkbox.Group>
          </div>
          {/* </FormField> */}
        </> : ""
      }
      {/* )}
      </AutoSaveWrapper> */}

      <TitleForm variant="subtitle">Key person details</TitleForm>

      <Row gutter={30}>
        <Col span={14}>
          <AutoSaveWrapper
            onSubmit={saveKeyPersonel}
            initialValues={{
              firstName,
              lastName,
              email
            }}
            subscription={{}}
          >
            {() => (
              <>
                <AutoSave
                  debounce={1000}
                  save={saveUser}
                  debounced={["firstName", "lastName", "email"]}
                />
                <Form {...formItemLayout} style={{ maxWidth: 600 }}>
                  <FormField
                    name="firstName"
                    label="First name"
                    validate={["required"]}
                  >
                    <Input></Input>
                  </FormField>
                  <FormField
                    name="lastName"
                    label="Last name"
                    validate={["required"]}
                  >
                    <Input></Input>
                  </FormField>

                  <FormField name="email" label="Email" validate={["required"]}>
                    <Input></Input>
                  </FormField>
                </Form>
              </>
            )}
          </AutoSaveWrapper>
        </Col>
        <Col span={10}>
          <label>User Photo</label>
          <ImageUpload
            img={avatar ? avatar.path : ""}
            target="user_avatars"
            onUpload={handleAvatarUpload}
          ></ImageUpload>
        </Col>
      </Row>

      <AutoSaveWrapper
        onSubmit={saveKeyPersonel}
        initialValues={{
          keyPersonel,
          editProfile,
          editBranch,
          useTools,
          companyAdmin,
          schedulerKey,
          nexHub
        }}
        subscription={{}}
      >
        {() => (
          <>
            <AutoSave
              debounce={1000}
              save={saveKeyPersonel}
              debounced={[
                "keyPersonel",
                "editProfile",
                "editBranch",
                "useTools",
                "companyAdmin",
                "schedulerKey",
                "nexHub"
              ]}
            />

            <TitleForm variant="subtitle">Settings</TitleForm>
            {/*  <Form {...{
              labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
                md:{ span: 8 },
                lg:{ span: 8 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
                md:{ span: 16 },
                lg:{ span: 16 },
              },
              labelAlign:'left'
            }} style={{ maxWidth: 600 }} >
              <FormField name="schedulerKey" label="Scheduler key" validate={["required"]}>
                <Input></Input>
              </FormField>
            </Form> */}
            <SwitchForm >
              {/* <FormField
                name="keyPersonel"
                label={
                  <span>
                    Key person&nbsp;
                    <Tooltip title="List as key person on the company profile">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                type="checkbox"
              >
                <Switch></Switch>
              </FormField> */}

              <FormField
                name="editProfile"
                label="Edit profile"
                type="checkbox"
              >
                <Switch ></Switch>
              </FormField>

              <FormField name="editBranch" label="Edit branch" type="checkbox" >
                <Switch></Switch>
              </FormField>

              <FormField name="useTools" label="Use tools" type="checkbox" >
                <Switch></Switch>
              </FormField>
              {isAdmin && (
                <>
                  <FormField
                    name="companyAdmin"
                    label="Company admin"
                    type="checkbox"
                  >
                    <Switch></Switch>
                  </FormField>
                  {domain === DOMAIN.NEX
                    &&
                    <FormField
                      name="nexHub"
                      label="NeXHUB"
                      type="checkbox"
                    >
                      <Switch></Switch>
                    </FormField>
                  }
                </>
              )}
            </SwitchForm>
          </>
        )}
      </AutoSaveWrapper>

      <Divider></Divider>
      <TitleForm variant="subtitle">Contact Information</TitleForm>
      <UserInformation
        userInfo={userInfo}
        userId={userId}
        branchId={branchId}
      ></UserInformation>

      {
        userId === loggedInUserId && (
          <>
            <Divider></Divider>
            <Button
              onClick={togglePassword}
              style={{ paddingLeft: 0 }}
              type="link"
              className="add-branch-btn"
            >
              Change password
            </Button>
          </>
        )
      }

      {
        editPassword ? (
          <PasswordReset
            handleCancel={togglePassword}
            userId={userId}
          ></PasswordReset>
        ) : null
      }
      <Divider></Divider>
      {/* START:- Allow Super admin and company admin to delete user's profile */}
      {
        (isAdmin || companyInfo?.companyAdmin) && (
          <Popconfirm
            title="Sure to delete?"
            className="pop-confirm-btn"
            onConfirm={() =>
              props.deleteUser(props.user.user.id, branchId).then((success) => {
                if (success) {
                  message.info("User deleted successfully");
                }
              })
            }
          >
            <Button icon="delete" className="add-new-btn">Delete profile - Cannot be undone!</Button>
          </Popconfirm>
        )
      }
      {/* END:- Allow Super admin and company admin to delete user's profile */}
    </div >
  );
};
const WrappedNewMessage = Form.create({ name: "userDetails" })(UserDetails);
const mapStateToProps = (state) => ({
  auth: state.auth,
  domain: state?.common?.domain,
}); 
export default connect(mapStateToProps, {
  updateUser,
  updateKeyPersonel,
  deleteUser,
})(WrappedNewMessage);




