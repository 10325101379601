import axios from "axios";
import { base_url } from "utils/settings";
import { UpdateTrigger } from "actions/companyActions";
import { logoData } from "actions/dataActions";
import { Input, notification, Switch, message } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";

const CompanyLogoData = (props) => {
  const { logoContent, companyId } = props;
  const [CompanyLogo, setCompanyLogo] = useState([]);
  // const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  const roles = localStorage.getItem("rolesUser");
  const dispatch = useDispatch();

  useEffect(() => {
    if (logoContent) {
      setCompanyLogo(logoContent);
    }
  }, [logoContent]);


  const onChangeValue = async (
    e,
    companyPartnerId,
    id,
    partnerLink,
    status
  ) => {
    if (companyId) {
      if (typeof e === "boolean") {
        const props = {
          companyPartners: [
            {
              id: companyPartnerId,
              company: companyId,
              napPartners: id,
              customPartnerLink: partnerLink,
              status: e === true ? "1" : "0",
            },
          ],
        };
        await dispatch(UpdateTrigger(props));
        // if (CompanyLogo === undefined) {
        if (JSON.parse(localStorage.getItem('loggedInUserCompanyId')) === companyId) {
          await dispatch(logoData(companyId));
          message.success("Updated Successfully")
        } else {
          try {
            const res = await axios.get(`${base_url}/v1/company/partners/${companyId}`);
            setCompanyLogo(res.data)
            message.success("Updated Successfully")
            return true;
          } catch (err) {
            /* dispatch({
              type: GLOBAL_ERROR,
              payload: err
            }); */
            return false;
          }
        }
        // }
        /*  notification["success"]({
           message: "Updated Successfully",
         }); */

        // if
        // (roles === "ROLE_ADMIN") {
        //   dispatch(logoData("nap_partners"));
        // } else {
        //   await dispatch(logoData(CompanyId));
        //   notification["success"]({
        //     message: "Updated Successfully",
        //   });
        // }
      }
    }
    // }
  };

  const save = async (
    values,
    partnerLogo,
    partnerName,
    id,
    status,
    companyPartnerId
  ) => {
    if (values[partnerName] || values[partnerName] === "") {
      const props = {
        companyPartners: [
          {
            id: companyPartnerId, // If Id found in response.Then I will update
            company: companyId,
            napPartners: id,
            // status:status,
            customPartnerLink: values[partnerName],
          },
        ],
      };
      await dispatch(UpdateTrigger(props));
      if (CompanyLogo === undefined) {
        await dispatch(logoData(companyId));
      }
      notification["success"]({
        message: "Updated Successfully",
      });

    }
  };
  console.log("CompanyLogo....................", CompanyLogo)
  return (
    CompanyLogo?.length > 0 ? (
      <div className="company-partner-container">
        {<h4>Our Tools</h4>}
        <hr />
        <br />
        {CompanyLogo?.map((element, index, arr) => {
          const {
            partnerLink,
            partnerLogo,
            partnerName,
            id,
            status,
            showInTool,
            companyPartnerId,
          } = element;
          const partnerarr = arr.map((element) => element.partnerName);
          const initialValues = arr.reduce(
            (o, key) => ({ ...o, [key.partnerName]: key.partnerLink }),
            {}
          );

          return (
            <AutoSaveWrapper
              onSubmit={(value) =>
                save(
                  value,
                  partnerLogo,
                  partnerName,
                  id,
                  status,
                  companyPartnerId
                )
              }
              initialValues={initialValues}
              subscription={{}}
            >
              {() => (
                showInTool &&
                <div className="card">
                  <AutoSave
                    debounce={1000}
                    save={(value) =>
                      save(
                        value,
                        partnerLogo,
                        partnerName,
                        id,
                        status,
                        companyPartnerId
                      )
                    }
                    debounced={partnerarr}
                  />
                  <nav className="nav main__nav">
                    <NavLink
                      className="nav-item"
                      to={{
                        pathname: partnerLink,
                      }}
                      target="_blank"
                    >
                      <img
                        src={partnerLogo}
                        alt={partnerName}
                        className="partnerIcon"
                      />
                    </NavLink>
                    <Switch
                      checkedChildren="on"
                      unCheckedChildren="off"
                      checked={status === "1" ? true : false}
                      onChange={(e) =>
                        onChangeValue(
                          e,
                          companyPartnerId,
                          id,
                          partnerLink,
                          status
                        )
                      }
                    ></Switch>
                    <FormField name={partnerName} label="">
                      <Input></Input>
                    </FormField>
                  </nav>
                </div>
              )}
            </AutoSaveWrapper>
          );
        })}
      </div>
    ) : (
      ""
    )
  )
};

export default CompanyLogoData;
