/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  message,
  Col,
  Row,
  Button,
  Upload,
} from "antd";
import {
  getUsers,
  getUsersByInfo,
  updateSchedulerKey,
  importKeys,
} from "actions/dataActions";
import { useDispatch } from "react-redux";
import {
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { SkeltonLoadingPromoTab } from "components/company-profile/content/right-pane/members/SkeltonLoadingPromoTab";
import FilePreview from "components/ui-elements/FilePreview";
import Dropzone from "components/ui-elements/Dropzone";
import Axios from "axios";
import { api_v, base_url } from "utils/settings";

// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i.toString(),
//     Id: `Edrward ${i}`,
//     SchedulerKey: `London Park no. ${i}`,
//   });
// }

const EditableContext = React.createContext();

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  form,
}) => {
  const getInput = () => {
    return <Input />;
  };

  return (
    <td>
      {editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ],
            initialValue: record[dataIndex],
          })(getInput())}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({ form }) => {
  const data = [];
  // const dispatch = useDispatch();
  const [dataState, setDataState] = useState(data);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingKey, setEditingKey] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [visibleFile, setVisibleFile] = useState(true);

  useEffect(() => {
    initialFunction();
  }, [currentPage, inputValue]);

  const initialFunction = async () => {
    setLoading(true);
    const response = await getUsers(inputValue, currentPage);
    if (
      response["hydra:member"]?.length == 0 &&
      response["hydra:totalItems"] > 0
    ) {
      setCurrentPage(1);
    }
    const filteredData = response["hydra:member"].map((item) => ({
      key: `${item.userSettings[0].id}`,
      name: `${item.firstName} ${item.lastName}`,
      schedulerKey:
        `${item.userSettings[0].schedulerKey}` !== "null"
          ? `${item.userSettings[0].schedulerKey}`
          : "",
      companyName: `${item.userSettings[0].company_name}`,
    }));
    setDataState(filteredData);
    setTotalItems(response["hydra:totalItems"]);
    setLoading(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "35%",
      editable: false,
      render: (text, record) => {
        return (
          <Row>
            <p style={{ fontWeight: "800" }}>
              {record.name}
              <span style={{ display: "block", fontWeight: "400" }}>
                {record.companyName}
              </span>
            </p>
          </Row>
        );
      },
    },
    {
      title: "Scheduler Key",
      dataIndex: "schedulerKey",
      width: "50%",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              className="edit-btn"
              onClick={() => save(form, record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </a>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.key)}
            >
              <a className="edit-btn">Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a
            disabled={editingKey !== ""}
            onClick={() => edit(record.key)}
            className="edit-btn"
          >
            {/*  <EditOutlined /> */} Edit
          </a>
        );
      },
    },
  ];
  const resetFilter = () => {
    setInputValue("");
    setCurrentPage(1);
  };
  const isEditing = (record) => record.key === editingKey;

  const handlePageChange = (value) => {
    setEditingKey("");
    setCurrentPage(value);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = (form, key) => {
    form.validateFields(async (error, row) => {
      if (error) {
        return;
      }
      const newData = [...dataState];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataState(newData);
        const res = await updateSchedulerKey(row, key);
        if (!res) {
          message.error("something went wrong.");
        }
        setEditingKey("");
      } /*  else {
        console.log("elseee")
        newData.push(row);
        setDataState(newData);
        setEditingKey('');
      } */
    });
  };

  const edit = (key) => {
    setEditingKey(key);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const mappedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        form,
      }),
    };
  });

  /* START:- Import csv file */
  const props = {
    listType: "csv",
    showUploadList: false,
    onRemove: (file) => {
      const index = csvFile.indexOf(file);
      const newFileList = csvFile.slice();
      newFileList.splice(index, 1);
      setCsvFile(newFileList);
    },
    beforeUpload: (file) => {
      setCsvFile([...csvFile, file]);
      return false;
    },
    // csvFile,
  };

  const fileHandler = (event) => {
    const formData = new FormData();
    csvFile.forEach((file) => {
      formData.append("csv_file", file);
    });
    setUploading(true);
    Axios({
      method: "post",
      url: `${base_url}/${api_v}usersKeysImport`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.status === 200) {
          message.success(response.data.message);
          setCsvFile([]);
          setUploading(false);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
  const manuallyCreatedUploadDesign = <div class="ant-upload-list ant-upload-list-csv">
    <span>
    <div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-csv">
      <div class="ant-upload-list-item-info">
        <span>
          <i
            aria-label="icon: paper-clip"
            class="anticon anticon-paper-clip"
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              class=""
              data-icon="paper-clip"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
            </svg>
          </i>
          <span class="ant-upload-list-item-name ant-upload-list-item-name-icon-count-1">
            {csvFile[0]?.name}
          </span>
          <span class="ant-upload-list-item-card-actions ">

              <i
                aria-label="icon: delete"
                title="Remove file"
                tabindex="-1"
                class="anticon anticon-delete"
                onClick={() => {
                  props.onRemove(csvFile[0]);
                } }
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  class=""
                  data-icon="delete"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                </svg>
              </i>
          
          </span>
        </span>
      </div>
    </div>
    </span>
  </div>;
  /* END:- Import csv file */

  return (
    <>
      <div className="event-container app-event-container">
        <h4>Delegates</h4>
        <hr />
      </div>
      <div className="searchArea">
        {/* <label className="requiredLbl">Search</label> */}
        <Row>
          <Col xs={13} sm={13} lg={13}>
            <div className="search">
              <SearchOutlined />
              <Input
                size="large"
                value={inputValue}
                placeholder="Enter name | company name | key"
                onChange={(e) => setInputValue(e.target.value)}
              ></Input>
            </div>
          </Col>
          <Col xs={2} sm={2} lg={2}>
            <Button className="searchBtn" onClick={resetFilter}>
              <CloseOutlined />
            </Button>
          </Col>
          <Col
            xs={9}
            sm={9}
            lg={9}
            style={{ display: "inline-flex" }}
            className="import-csv-parent"
          >
            <Upload {...props}>
              {csvFile.length ? (
                manuallyCreatedUploadDesign
              ) : (
                <Button className="invite-user-cancel-btn">
                  <UploadOutlined />
                  Import File
                </Button>
              )}
            </Upload>
            <Button
              className="add-member-btn"
              type="primary"
              onClick={fileHandler}
              disabled={csvFile.length === 0}
              loading={uploading}
              style={{ marginLeft: 8 }}
            >
              {uploading ? "Uploading" : "Upload File"}
            </Button>
            {/* <div> */}
            {/* <label for="csvFileInput" className="btn choose-file-btn">Import Keys</label>
              {csvFile?.name && <p>{csvFile?.name} </p>}</div> */}
            {/* <input type="file" id="csvFileInput" accept={".csv"} onChange={(e) => fileHandler(e)}  style={{ "visibility": "hidden" }} /> */}
          </Col>
        </Row>
      </div>

      <EditableContext.Provider value={form}>
        {loading ? (
          <SkeltonLoadingPromoTab itemsPerPage={3} />
        ) : (
          <Table
            components={components}
            dataSource={dataState}
            columns={mappedColumns}
            className="application-connection-table"
            rowClassName="editable-row"
            pagination={{
              className: "paginationColor edit-btn",
              pageSize: 10,
              total: totalItems,
              current: currentPage,
              onChange: handlePageChange,
              showSizeChanger: false,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSizeOptions: [10],
              // defaultCurrent: 1,
            }}
          />
        )}
      </EditableContext.Provider>
    </>
  );
};

const Delegates = Form.create()(EditableTable);

export default Delegates;
