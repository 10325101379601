import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const customStyles = {
  option: (provided, state) => (
    {
      ...provided,
      borderBottom: "1px dotted #e0e0e0",
      padding: "14px 20px"
    }),
  container: provided => ({
    ...provided,
    lineHeight: "1.2",
    minWidth: 180
  }),
  menu: provided => ({
    ...provided,
    zIndex: 99
  }),
  control: provided => ({
    ...provided,
    borderColor: "#d9d9d9"
  }),
  indicatorSeparator: () => ({
    border: "none"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};


const customStyles2 = {
  control: provided => ({
    ...provided,
    borderColor: "red",
    outline: 'none',
    boxShadow: "none",
  }),

};

const customTheme = theme => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: "#D2EDED",
    primary50: "#A5DADA",
    primary75: "#79C8C8",
    primary: "#4cb6b6"
  }
});

class CustomSelect extends Component {
  render() {
    let selectData;

    const { options, value, maxOptions, className } = this.props;

    if (this.props.async) {
      selectData = (
        <AsyncSelect
          styles={(className === "airCargoSelect1" || className === "airCargoSelect2" || className === "airCargoSelect3") ? customStyles2 : customStyles}
          theme={theme => customTheme(theme)}
          {...this.props}
        />
      );
    } else {
      selectData = (
        <Select
          styles={customStyles}
          theme={theme => customTheme(theme)}
          {...this.props}
        />
      );
    }

    if (this.props.label) {
      return (
        <>
          {this.props.fileStatus === "editProfile"
            ?
            <div className="input-label">
              <label>
                {this.props.required && <span className="text-error"> *</span>}{" "}
                {this.props.label}
              </label>
              {selectData}
              {(!this.props.selectedExpertieseIds?.includes(24) && !this.props.selectedExpertieseIds?.includes(31) && !this.props.selectedExpertieseIds?.includes(32)) &&
                (this.props.value?.length === 0 || this.props.defaultValue?.length === 0) && <div style={{ color: 'red' }}>Required</div>}
            </div>
            :
            <div className="input-label">
              <label>
                {this.props.label}{" "}
                {this.props.required && <span className="text-error"> *</span>}
              </label>
              {selectData}
            </div>
          }
        </>

      );
    }
    return selectData;
  }
}

export default CustomSelect;
