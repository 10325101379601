import { createReducer } from "redux-starter-kit";

const initialState = {
    countries: [],
    question: [],
    submitForm1: false,
    form1Submited: false,
    submitForm2: false,
    form2Submited: false,
    registerLoading: false,
    allSubindustry: [],
    domain: []
}

const commonReducer = createReducer(initialState, {
    SET_COUNTRY_LIST: (state, action) => {
        state.countries = action.payload["hydra:member"];
    },
    SUBMIT_FORM1: (state, action) => {
        state.submitForm1 = action.payload;
    },
    FORM1_SUBMITED: (state, action) => {
        state.form1Submited = action.payload;
    },
    SUBMIT_FORM2: (state, action) => {
        state.submitForm2 = action.payload;
    },
    FORM2_SUBMITED: (state, action) => {
        state.form2Submited = action.payload;
    },
    REGISTER_LOADING: (state, action) => {
        state.registerLoading = action.payload;
    },
    SET_SUBINDUSTRY: (state, action) => {
        state.allSubindustry = action.payload;
    },
    SET_DOMAIN: (state, action) => {
        state.domain = action.payload;
    },
    SET_QUESTION: (state, action) => {
        state.question = action.payload;
    },
    NEX_HUB_LADING: (state, action) => {
        state.nexHubLoading = action.payload;
    },
    CLEAR_COMMON_QUESTION: (state, action) => {
        state.question = []
    },
    SET_USER_SETTINGS: (state, action) => {
        state.userSettingsData = action.payload
    },
})

export default commonReducer