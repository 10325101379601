/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
    Table,
    Input,
    Popconfirm,
    Form,
    message,
    Col,
    Row,
    Button,
} from "antd";
import {
    getCompanyPaidInfo,
    storeParentCompanyId,
} from "actions/dataActions";
import {
    CloseOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { SkeltonLoadingPromoTab } from "components/company-profile/content/right-pane/members/SkeltonLoadingPromoTab";

const EditableContext = React.createContext();

const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    children,
    form,
}) => {
    const getInput = () => {
        return <Input />;
    };

    return (
        <td>
            {editing ? (
                <Form.Item style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ],
                        initialValue: record[dataIndex],
                    })(getInput())}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTable = ({ form }) => {
    const data = [];
    const [dataState, setDataState] = useState(data);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingKey, setEditingKey] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initialFunction();
    }, [currentPage, inputValue]);

    const initialFunction = async () => {
        setLoading(true);
        const response = await getCompanyPaidInfo(inputValue, currentPage);
        if (
            response["results"]?.length === 0 &&
            response["totalPages"] > 0
        ) {
            setCurrentPage(1);
        }

        const filteredData = response["results"].map((item) => ({
            key: item?.companyId,
            companyId: item?.companyId,
            paidBy: item?.paidDate !== null
                ? moment(item?.paidDate?.date).format("DD/MM/YYYY")
                : "Not Available",
            companyName: item?.companyName,
            parentCompany: item?.parentCompanyId
        }));
        setDataState(filteredData);
        setTotalItems(response["totalItems"]);
        setLoading(false);
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            width: "35%",
            editable: false,
            render: (text, record) => {
                return (
                    <Row>
                        <p style={{ fontWeight: "800" }}>
                            {`ID: ${record.companyId}`}
                            <span style={{ display: "block", fontWeight: "400" }}>
                                {record.companyName}
                            </span>
                        </p>
                    </Row>
                );
            },
        },
        {
            title: "Paid by",
            dataIndex: "paidBy",
            width: "50%",
            editable: false,
        },
        {
            title: "",
            dataIndex: "parentCompany",
            width: "50%",
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            className="edit-btn"
                            onClick={() => save(form, record.key)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </a>
                        <Popconfirm
                            title="Sure to cancel?"
                            onConfirm={() => cancel(record.key)}
                        >
                            <a className="edit-btn">Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <a
                        disabled={editingKey !== ""}
                        onClick={() => edit(record.key)}
                        className="edit-btn"
                    >
                        Edit
                    </a>
                );
            },
        },
    ];
    const resetFilter = () => {
        setInputValue("");
        setCurrentPage(1);
    };
    const isEditing = (record) => record.key === editingKey;

    const handlePageChange = (value) => {
        setEditingKey("");
        setCurrentPage(value);
    };
    const cancel = () => {
        setEditingKey("");
    };

    const save = (form, key) => {
        form.validateFields(async (error, row) => {
            if (error) {
                return;
            }
            const newData = [...dataState];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setDataState(newData);
                const res = await storeParentCompanyId(row, key);               
                if (!res) {
                    message.error("something went wrong.");
                } else if (res.title === "An error occurred") {
                    message.error("Item not found.");
                }
                else {
                    message.success("Save data successfully.");
                }
                setEditingKey("");
            }
        });
    };

    const edit = (key) => {
        setEditingKey(key);
    };

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const mappedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                form,
            }),
        };
    });

    return (
        <>
            <div className="event-container">
                {/* <h4>ParentCompanyRelation</h4> */}
                <hr />
            </div>
            <div className="searchArea">
                <Row>
                    <Col xs={16} sm={16} lg={16}>
                        <div className="search">
                            <SearchOutlined />
                            <Input
                                size="large"
                                value={inputValue}
                                placeholder="Search..."
                                onChange={(e) => setInputValue(e.target.value)}
                            ></Input>
                        </div>
                    </Col>
                    <Col xs={2} sm={2} lg={2}>
                        <Button className="searchBtn" onClick={resetFilter}>
                            <CloseOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>

            <EditableContext.Provider value={form}>
                {loading ? (
                    <SkeltonLoadingPromoTab itemsPerPage={3} />
                ) : (
                    <Table
                        components={components}
                        dataSource={dataState}
                        className="application-connection-table"
                        columns={mappedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            className: "paginationColor edit-btn",
                            pageSize: 20,
                            total: totalItems,
                            current: currentPage,
                            onChange: handlePageChange,
                            showSizeChanger: false,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                            pageSizeOptions: [10],
                            // defaultCurrent: 1,
                        }}
                    />
                )}
            </EditableContext.Provider>
        </>
    );
};

const ParentCompanyRelation = Form.create()(EditableTable);

export default ParentCompanyRelation;
