import React, { useState } from "react";
import { Row, Col, Divider, Tooltip } from "antd";
import { cleanObjectWithId } from 'utils/functions';

const Offices = ({ company }) => {
	const [visibleBranch, setVisibleBranch] = useState('HQ');

	const toggleBranch = (branch, e) => {
		e.preventDefault();
		setVisibleBranch(branch);
	}
	cleanObjectWithId(company?.coordinator)
	cleanObjectWithId(company?.branch)
	return (
		<>
			<Row gutter={16} className="mt-8">
				<Col>
					<h3>OFFICES</h3>
				</Col>
				<Col span={8}>
					{company?.city ? <><a onClick={(e) => toggleBranch('HQ', e)} className={`${visibleBranch === 'HQ' ? null : 'text-black'} office-hq`}>{company.city} HQ</a>
						<Divider style={{ margin: '0.5rem 0' }} /></> : ""}
					{company?.branch?.length !== 0 ? company?.branch?.map(branch => (
						<React.Fragment key={branch.id}>
							{branch?.branchCity ? <><a onClick={(e) => toggleBranch(branch.branchName, e)} className={`${visibleBranch === branch.branchName ? null : 'text-black'} office-branch-name`}>{branch.branchCity}</a>
								<Divider style={{ margin: '0.5rem 0' }} /></> : ""}
						</React.Fragment>
					)) : null}
				</Col>
				<Col span={8}>
					{(company.coordinator && visibleBranch === 'HQ') ? <>
						<h3>NAP CO-ORDINATOR</h3>
						<span>{company.coordinator.name}</span>
						<br />
						{company.coordinator.email ? <>
							<Tooltip
								title={company.coordinator.email}
							// placement="topRight"
							>
								<span className="companyEmailParent"><strong>E.</strong><span className="companyEmail">{company.coordinator.email}</span></span>
								{/* <br /> */}
							</Tooltip>
						</> : ''}
						{company.coordinator.telephone ? <span><strong>T.</strong> {company.coordinator.telephone}</span> : ''}
					</> : ''}
					{(company?.companyInfo?.length && visibleBranch === 'HQ') ? <>
						<h3 className={visibleBranch === 'HQ' ? 'mt-3' : null}>HQ CONTACTS</h3>
						{company.companyInfo.map((info) => {
							return (
								<React.Fragment key={info.id}>
									{info.companyTel ? <><span><strong>T.</strong> {`${(info.telDialCode !== null && info.telDialCode !== "") ? info.telDialCode : ""} ${info.companyTel}`}</span><br /></> : ''}
									{info.companyFax ? <><span><strong>F.</strong> {info.companyFax}</span><br /></> : ''}
									{info.companyEmail ? <><Tooltip title={info.companyEmail}><span className="companyEmailParent"><strong>E.</strong> <span className="companyEmail">{info.companyEmail}</span></span>
									</Tooltip></> : ''}
									{info.companyWebsite ? <><Tooltip title={info.companyWebsite}><span className="companyEmailParent"><strong>W.</strong> <span className="companyEmail">{info.companyWebsite}</span></span><br />
									</Tooltip></> : ''}
									{info.companySkype ? <span><strong>S.</strong> {info.companySkype}</span> : ''}
								</React.Fragment>
							)
						}
						)}
					</> : ''}
					{company?.branch?.length ? company.branch.map(branch => (
						visibleBranch === branch.branchName ?
							<React.Fragment key={branch.id}>
								<h3 style={{ textTransform: 'uppercase' }}>{branch.branchName ? branch.branchName : branch.branchCity} CONTACTS</h3>
								{branch.branchInfo.map((info) =>
									<React.Fragment key={info.id}>
										{info.branchTel ? <><span><strong>T.</strong> {info.branchTel}</span><br /></> : ''}
										{info.branchFax ? <><span><strong>F.</strong> {info.branchFax}</span><br /></> : ''}
										{info.branchEmail ? <><span><strong>E.</strong> {info.branchEmail}</span><br /></> : ''}
										{info.branchWeb ? <><span><strong>W.</strong> {info.branchWeb}</span><br /></> : ''}
										{info.branchSkype ? <span><strong>S.</strong> {info.branchSkype}</span> : ''}
									</React.Fragment>
								)}
							</React.Fragment> : ''
					)) : ''}
				</Col>
				{company?.companyInfo?.length ?
					<Col span={8}>
						<h3>ADDRESS</h3>
						{company?.branch?.length
							?
							company?.branch.map(branch => (
								visibleBranch === branch?.branchName ?
									<React.Fragment key={branch.id}>
										{branch?.subIndustry.map((info) =>
											<><h3><img src={info.industryLogo} /><br /></h3></>
										)}

									</React.Fragment> : ''
							)) : ""}
						{visibleBranch === 'HQ'
							?
							<React.Fragment>
								{company?.companyName ? <span><h4 className="companyName">{company.companyName}</h4></span> : ""}
								{company.companyInfo?.length ?
									company.companyInfo.map((info) =>
										<span key={info.id}>
											{info.address1 ? <><span>{info.address1}</span><br /></> : ''}
											{info.address2 ? <><span>{info.address2}</span><br /></> : ''}
											{company.city}, {company.countries.countryname}
										</span>
									)
									:
									''
								}
							</React.Fragment> : ''}
						{company?.branch?.length ?
							company?.branch?.map(branch => (
								visibleBranch === branch?.branchName ?
									<React.Fragment key={branch.id}>
										<h4>{branch.branchName ? branch.branchName : branch.branchCity}</h4>
										{branch.branchInfo.map((info) =>
											<span key={info.id}>
												{info.branchAddress1 ? <><span>{info.branchAddress1}</span><br /></> : ''}
												{info.branchAddress2 ? <><span>{info.branchAddress2}</span><br /></> : ''}
												{branch?.branchCity ? branch?.branchCity : ''}, {branch?.country?.countryname ? branch?.country?.countryname : ''}
											</span>
										)}
									</React.Fragment> : ''
							))
							:
							''}
					</Col> : ''
				}
			</Row>
			<Divider />
		</>
	);
};

export default Offices;
