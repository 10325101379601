import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Radio, message } from "antd";
import { updateCompany } from "actions/companyActions";
import { getExpertiseList } from "actions/dataActions";
import { api_v } from "utils/settings";

class Classification extends Component {
  constructor(props) {
    super(props);
  }

  handleClassificationSelect = (e) => {
    const { getExpertiseList, companyId, updateCompany } = this.props;
    const data = {
      id: `/${api_v}company/${companyId}/profile`,
      companySettings: [
        {
          id:`/v1/company_settings/${this.props.companySettings[0].id}`,
          classification: "Air Partner",
        },
      ],
    };
    Promise.resolve(
      updateCompany(data, companyId).then(() => {
        getExpertiseList(e.target.value);
        message.success("Information has been updated");
      })
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="expertise-classification">
        <div className="card-header">
          <h3>Classification</h3>
        </div>
        <div className="card-body">
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator("classification", {
              initialValue: this.props.companySettings[0].classification,
              rules: [
                {
                  required: true,
                  message: "Please choose company classification",
                },
              ],
            })(
              <Radio.Group onChange={this.handleClassificationSelect}>
                <Radio.Button value="Air Partner">Air Partner</Radio.Button>
                <Radio.Button value="Associate">Associate</Radio.Button>
                <Radio.Button value="Vendor">Vendor</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

const WrappedClassification = Form.create({ name: "classification" })(
  Classification
);

const mapStateToProps = (state) => ({
  companySettings: state.company.data.companySettings,
});

export default connect(mapStateToProps, { updateCompany, getExpertiseList })(
  WrappedClassification
);
