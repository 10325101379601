import React, { useState } from "react";
import { connect } from "react-redux";
import { Slider, message } from "antd";
import { updateCompanyData } from "actions/companyActions";
import styled from "@emotion/styled";

const min = 0,
  max = 100,
  step = 5;

const Item = styled("li")`
  width: 25%;
  text-align: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const FocusSlider = props => {
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState([
    {
      id: "focusExports",
      title: "Exports",
      value: props.focus.focusExports
    },
    {
      id: "focusImports",
      title: "Imports",
      value: props.focus.focusImports
    },
    {
      id: "focusCrossTrade",
      title: "Cross Trade",
      value: props.focus.focusCrossTrade
    },
    {
      id: "focusEcommerce",
      title: "E-commerce",
      value: props.focus.focusEcommerce
    }
  ]);

  const handleFocusSlider = (targetIndex, values) => {
    let value = values
    
    let diff = focus[targetIndex].value - value;
    
    const nextIndex = targetIndex === focus.length - 1 ? 0 : targetIndex + 1;
  
    const newValues = [...focus];
    
    // Set new value
    newValues[targetIndex].value = value;

    // Move from last to first
    // const moveToNext = (i) => {
    //   if (i === newValues.length - 1) {
    //     return updateNext(0);
    //   }
    //   return updateNext(i + 1);
    // };

    // Recrusive call
    // const updateNext = (i) => {
    //   // End reached
    //   if (diff === 0 || targetIndex === i) {
    //     return;
    //   }

    //   // Substract from next --------------------------
    //   if (diff < 0) {
    //     if (newValues[i].value === 0) {
    //       return moveToNext(i);
    //     }
    //     // Can subsctract all
    //     if (newValues[i].value >= Math.abs(diff)) {
    //       return (newValues[i].value = newValues[i].value - Math.abs(diff));
    //     } else {
    //       //  calc remaining diff, Set to 0 and move to next
    //       diff = diff + newValues[i].value;

    //       newValues[i].value = 0;
    //       return moveToNext(i);
    //     }
    //   }

    //   // If deacresing, add to next ----------------------------------
    //   else {
    //     return (newValues[i].value = newValues[i].value + diff);
    //   }
    // };
    // updateNext(nextIndex);
   
    setFocus(newValues);
  };

  // API request
  const updateData = () => {
    setLoading(true)
  
    let data = {};
    if (focus.length > 0) {
      let total = 0;
      focus.map((element) => {
        total = element.value + total;
      })

    
      if (total > 100) {
        message.error("Total sales focus can't be greater than 100%");
        setLoading(false)
      } else if(total < 100){
        message.error("Total sales focus can't be less than 100%");
        setLoading(false)
      }
       else {
        focus.forEach(a => {
          data[a.id] = a.value ? a.value : 0;
        });


        props.updateCompanyData(data, props.companyDataId,props.fileName).then(() => {
          message.success("Information has been updated");
          setLoading(false)
        });
      }

    }

  };

  return (
    <>
      {props.expertise
        ?
        <>
          <div> <label className="requiredLbl">Sales Focus</label></div>
          <ul className="list-blank f SalesFocusList">
            {focus.map((item, i) => (
              <Item key={item.id}>
                <span style={{ fontSize: "2rem" }}>{item.value}%</span>
                <br />
                <span>{item.title}</span>

                <Slider
                  disabled={loading}
                  value={item.value}
                  step={step}
                  min={min}
                  max={max}
                  onChange={value => handleFocusSlider(i, value)}
                  onAfterChange={() => updateData()}
                ></Slider>
              </Item>
            ))}
          </ul>
        </>
        :
        <div className="card">
          <div className="card-header">
            <h3>Sales focus</h3>
          </div>
          <div className="card-body">
            <ul className="list-blank f">
              {focus.map((item, i) => (
                <Item key={item.id}>
                  <span style={{ fontSize: "2rem" }}>{item.value}%</span>
                  <br />
                  <span>{item.title}</span>

                  <Slider
                    value={item.value}
                    step={step}
                    min={min}
                    max={max}
                    onChange={value => handleFocusSlider(i, value)}
                    onAfterChange={() => updateData()}
                  ></Slider>
                </Item>
              ))}
            </ul>
          </div>
        </div>
      }
    </>
  );
};

export default connect(
  null,
  { updateCompanyData }
)(FocusSlider);
