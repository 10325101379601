import axios from "axios";
import { base_url, api_v } from "../utils/settings";

import {
  ADD_DOCUMENT,
  SET_FILE_UPLOAD,
  UPDATE_FILE_UPLOAD,
  CLEAR_FILE_UPLOAD,
  GLOBAL_ERROR
} from "./types";

// Upload Document (Generate preview and upload progress)
export const uploadDocument = (
  data,
  previewData,
  prevDocuments,
  companyId
) => dispatch => {
  const uploadConfig = {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: progress => {
      dispatch({
        type: UPDATE_FILE_UPLOAD,
        payload: {
          progress: Math.floor((progress.loaded * 100) / progress.total),
          id: previewData.id
        }
      });
    }
  };

  dispatch(setFileUpload(previewData));
  axios
    .post(`${base_url}/${api_v}documents`, data, uploadConfig)
    .then(res => {
      axios
        .put(`${base_url}/${api_v}companies/${companyId}`, {
          document: [`/${api_v}documents/${res.data.id}`, ...prevDocuments]
        })
        .then(() => {
          dispatch({
            type: ADD_DOCUMENT,
            payload: res.data
          });
        });

      dispatch({
        type: CLEAR_FILE_UPLOAD,
        payload: previewData.id
      });
    })
    .catch(err => {
      dispatch({ type: GLOBAL_ERROR, payload: err });
      dispatch({
        type: CLEAR_FILE_UPLOAD,
        payload: previewData.id
      });
    });
};

// Set File Upload
export const setFileUpload = preview => {
  return {
    type: SET_FILE_UPLOAD,
    payload: preview
  };
};


export const uploadVendorDocument = (data, previewData) => async dispatch => {
  const uploadConfig = {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: progress => {
      dispatch({
        type: UPDATE_FILE_UPLOAD,
        payload: {
          progress: Math.floor((progress.loaded * 100) / progress.total),
          id: previewData.id
        }
      });
    }
  };

  dispatch(setFileUpload(previewData));
  return await axios
    .post(`${base_url}/${api_v}documents`, data, uploadConfig)
    .then(res => {
      dispatch({
        type: CLEAR_FILE_UPLOAD,
        payload: previewData.id
      });
      return res.data;
    })

}