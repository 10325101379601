import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Icon, Row, Col, message, Skeleton } from "antd";
import { clearAuth, forgetPasswordRequest } from "actions/authActions";
import logo from "theme/assets/logo.svg";
import footerIcon from 'theme/assets/footerIcon.png'
import MobileAlert from "components/ui-elements/MobileAlert";
import { DOMAIN } from "utils/enum";
class ForgotPassword extends Component {

  onSubmit = async e => {

    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const resp = await this.props.forgetPasswordRequest(values);

      if (resp.status === 200) {
        message.success("Reset password link has been sent on your email.Please check your email.");
        this.props.history.push("/login");
      }
    });
  };
  loginFun = (domain) => {

    let link;
    if (domain == undefined) {
      link = "https://neutralairpartner.com/webapprout/"
    } else {
      if (domain === DOMAIN.NEX) {
        link = "https://nex-network.com/webrout1/"
      } else {
        link = "https://neutralairpartner.com/webapprout/"
      }
    }
    window.open(link, "_blank");
    // return window.location.href = link


  }
  render() {
    const { loading, error } = this.props.auth;
    const { getFieldDecorator } = this.props.form;
    const { logo, coverImage, domain } = this?.props?.domain
    const date = new Date();
    if (!localStorage.continueDesktop || localStorage.continueDesktop === "1") {
      return (
        <div className="noPaddingTop">
          <Row>
            <Col className="leftSection" lg={15} md={15} xs={0} onClick={() => this.loginFun(domain)} >
              {/* <img src={coverImage} className="coverImageLogin" /> */}
            </Col>
            <Col className="formSection" lg={9} md={9} xs={24}>
              <div className="centerContent">
                <div className="mainContent">
                  {logo === undefined
                    ?
                    <Skeleton className="logo-skeleton" size="default" active paragraph={{ rows: 0 }} />
                    :
                    <img src={logo ? logo : ''} alt="logo" className="loginLogo" />
                  }
                  <div className="centerForm">
                    <div className="pageTitle">
                      <span className="pageTitleTxt">
                        Forgot Password
                      </span>
                    </div>
                    {error && (
                      <h4 className="text-error">
                        {error}
                      </h4>
                    )}
                    <Form
                      onSubmit={this.onSubmit}
                      autoComplete="off"
                    >
                      <Form.Item>
                        {getFieldDecorator("email", {
                          rules: [
                            { required: true, message: "Please input your username!" }
                          ]
                        })(
                          <Input
                            type="email"
                            prefix={
                              <Icon type="email" style={{ color: "#3565AE" }} />
                            }
                            size="large"
                            placeholder="Username email"
                          />
                        )}
                      </Form.Item>

                      <Button
                        className="submit-btn"
                        // type="primary"
                        size="large"
                        loading={loading}
                        htmlType="submit"
                      // style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                  <img src={footerIcon} alt="logo" className="footerIcon" />
                </div>
                <div className="footerContent">
                  Copyright ©{date.getFullYear()} Produced by Neutral Air  Partner
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    else {
      return (
        <>
          <MobileAlert />
        </>
      );
    }

  }
}

const WrappedForgotPassword = Form.create({ name: "normal_forgotpassword" })(ForgotPassword);

const mapStateToProps = state => ({
  auth: state.auth,
  domain: state?.common?.domain
});

export default connect(mapStateToProps, { forgetPasswordRequest, clearAuth })(WrappedForgotPassword);
