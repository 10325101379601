import axios from "axios";
import { base_url, api_v } from "utils/settings";

import {
  CREATE_BRANCH,
  UPDATE_BRANCH_DETAILS,
  UPDATE_BRANCH_DATA,
  UPDATE_BRANCH_INFO,
  DELETE_BRANCH,
  ADD_BRANCH_FEE,
  UPDATE_BRANCH_FEE,
  DELETE_BRANCH_FEE,
  UPDATE_BRANCH_BANK,
  GLOBAL_ERROR
} from "./types";

export const createBranch = data => async dispatch => {
  try {
    const res = await axios.post(`${base_url}/${api_v}branches`, data);

    dispatch({
      type: CREATE_BRANCH,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

export const updateBranch = (data, id) => async dispatch => {
  try {
    const res = await axios.put(`${base_url}/${api_v}branches/${id}`, data);
    dispatch({
      type: UPDATE_BRANCH_DETAILS,
      branchId: res.data.id,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

export const updateBranchData = (data, id) => async dispatch => {
  try {
    const res = await axios.put(`${base_url}/${api_v}branch_datas/${id}`, data);
    dispatch({
      type: UPDATE_BRANCH_DATA,
      branchId: id,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

export const updateBranchInfo = (data, branchId, infoId) => async dispatch => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}branch_infos/${infoId}`,
      data
    );
    dispatch({
      type: UPDATE_BRANCH_INFO,
      branchId,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

// ---------- Fees --------------------------------------------------------------------------------------

// CREATE a new Fee(importhandover || fob || dap || rates)
export const createBranchFee = (
  data,
  targetUrl,
  branchId,
  key
) => async dispatch => {
  try {
    const res = await axios.post(`${base_url}/${api_v}${targetUrl}`, data);
    dispatch({
      type: ADD_BRANCH_FEE,
      branchId,
      key: key,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
  }
};

// UPDATE Fee(importhandover || fob || dap || rates)
export const updateBranchFee = (
  data,
  targetUrl,
  id,
  branchId,
  key
) => async dispatch => {
  try {
    const res = await axios.put(`${base_url}/${api_v}${targetUrl}/${id}`, data);
    dispatch({
      type: UPDATE_BRANCH_FEE,
      key: key,
      branchId,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
  }
};

// DELETE Fee(importhandover || fob || dap || rates)
export const deleteBranchFee = (
  targetUrl,
  id,
  branchId,
  key
) => async dispatch => {
  try {
    const res = await axios.delete(`${base_url}/${api_v}${targetUrl}/${id}`);
    dispatch({
      type: DELETE_BRANCH_FEE,
      key: key,
      branchId,
      payload: { id: id }
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
  }
};

// Update Branch Bank Info
export const updateBranchBank = (data, id) => async dispatch => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}branch_bank_infos/${id}`,
      data
    );
    dispatch({
      type: UPDATE_BRANCH_BANK,
      branchId: id,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

export const deleteBranch = id => async dispatch => {
  try {
    const res = await axios.delete(`${base_url}/${api_v}branches/${id}`);
    dispatch({
      type: DELETE_BRANCH,
      payload: id
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};
