import { object } from "@amcharts/amcharts4/core";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { api_v } from "./settings";

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

// Update Query String
export const generateApiUrl = (param, paramVal, url) => {

  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] != param) {
        if (
          (tempArray[i] === 'isExpired=2' && param === 'companyName') ||
          (tempArray[i] === 'isExpired=2' && param === 'fos_email') ||
          (tempArray[i] === 'isExpired=2' && param === 'country') ||
          (tempArray[i] === 'isExpired=2' && param === 'page') ||
          // (tempArray[i] === 'isExpired=2' && param === 'city') ||
          (tempArray[i] === 'isExpired=1' && param === 'companyName') ||
          (tempArray[i] === 'isExpired=1' && param === 'fos_email') ||
          (tempArray[i] === 'isExpired=1' && param === 'country') ||
          (tempArray[i] === 'isExpired=1' && param === 'page') ||
          // (tempArray[i] === 'isExpired=1' && param === 'city') ||
          (tempArray[i] !== 'isExpired=1' && tempArray[i] !== 'isExpired=2' && param === 'status') || //here, remove expired tab
          (param === 'city') ||
          (param === 'subIndustryRels') ||
          (param === 'expertiseRels') ||
          (param === 'specializations') ||
          (param === 'airline') ||
          (param === 'destinationAirport') ||
          (param === 'originAirport') ||
          (param === 'sorting') ||
          // here, add these param 
          ((param === 'page' || param === 'companyName' || param === 'fos_email' || param === 'country' || param === 'status' || param === 'subIndustryRels' || param === 'specializations' || param === 'airline' || param === 'destinationAirport' || param === 'originAirport' || param === 'sorting')
            &&
            (tempArray[i] !== 'isExpired=1' && tempArray[i] !== 'isExpired=2')
          )
          /* ((param === 'page' || param === 'companyName' || param === 'country' || param === 'city')
            && (tempArray[i] === 'status=Approved' || tempArray[i] === 'status=Not%20Finished' || tempArray[i] === 'status=Pending' || tempArray[i] === 'status=Resigned' || tempArray[i] === 'status=InReview' || tempArray[i] === 'status=Suspended' || tempArray[i] === 'status=Blocked' || (tempArray[i] !== 'isExpired=1' && tempArray[i] !== 'isExpired=2')))  */
        ) {
          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }
    }
  }
  var rows_txt = temp + "" + param + "=" + paramVal;
  var final = baseURL + "?" + newAdditionalURL + rows_txt;
  return final;
};

// Add Query String (Multiselect)
export const ApiUrlAdd = (param, paramVal, url) => {
  var queryString = `&${param}=${paramVal}`,
    result = url;

  return (result += queryString);
};

// Remove Query String (Multiselect)
export const ApiUrlRemove = (key, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      } else if (key !== 'classification' && key !== 'airIndustryExpertise') {
        if (param === 'isExpired' && key !== 'classification') {
          params_arr.splice(i, 1);
        } else if (key === "allSubIndustryRels") {
          params_arr.splice(i, 1);
        }
      }
      /* else if (param === 'isExpired' && key !== 'classification') {
         params_arr.splice(i, 1);
       } else if (param === 'page' && typeof key !== 'object') {
          params_arr.splice(i, 1);
        } */
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

export const ApiUrlExpired = (key, value, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  var queryString1 = `${queryString}&${key}=${value}`
  if (queryString1 !== "") {
    params_arr = queryString1.split("&");
    let uniqueParams_arr = params_arr.filter((c, index) => {
      return params_arr.indexOf(c) === index;
    });
    for (var i = uniqueParams_arr.length - 1; i >= 0; i -= 1) {
      param = uniqueParams_arr[i].split("=")[0];
      if (param === 'status') {
        uniqueParams_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + uniqueParams_arr.join("&");
  }
  return rtn;
};

export const ApiUrlExpired2 = (key, value, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  var queryString1 = `${queryString}&${key}=${value}`
  if (queryString1 !== "") {
    params_arr = queryString1.split("&");
    let uniqueParams_arr = params_arr.filter((c, index) => {
      return params_arr.indexOf(c) === index;
    });
    for (var i = uniqueParams_arr.length - 1; i >= 0; i -= 1) {
      param = uniqueParams_arr[i].split("=")[0];
      if (param === 'status') {
        uniqueParams_arr.splice(i, 1);
      } else if (value === 2) {
        for (let i = 0; i < uniqueParams_arr.length; i++) {
          if (uniqueParams_arr[i] == "isExpired=1") {
            // whenever we run splice, the array gets reindexed
            // so we will skip over an index
            uniqueParams_arr.splice(i, 1);
          }
        }
      } else if (value === 1) {
        for (let i = 0; i < uniqueParams_arr.length; i++) {
          if (uniqueParams_arr[i] == "isExpired=2") {
            // whenever we run splice, the array gets reindexed
            // so we will skip over an index
            uniqueParams_arr.splice(i, 1);
          }
        }
      }
    }
    rtn = rtn + "?" + uniqueParams_arr.join("&");
  }
  return rtn;
};
// Update for React Router optional param for "/network/:search?" ( NOT USED PROLLY *******)
export const generateRouterParam = (param, paramVal, url) => {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  var final = "network/" + newAdditionalURL + rows_txt;

  return final;
};

// Find expertise proprety based on classification
// Not used this
export const findExpertise = item => {
  let category = item?.companySettings[0]?.classification;

  switch (category) {
    case "Air Partner":
      return item.airIndustryExpertise;
    case "Associate":
      return item.associateIndustryExpertise;
    case "Vendor":
      return item.vendorIndustryExpertise;
    default:
      return null;
  }
};

export const findExpertiseV2 = item => {
  return item.expertiese;
};

export const findExpertiseV3 = item => {

  const expertise = item.expertiese;

  const result = expertise?.reduce((acc, curr) => {
    if (!acc[curr.subIndustry.name]) {
      acc[curr.subIndustry.name] = [curr.id];
    } else {
      acc[curr.subIndustry.name].push(curr.id);
    }
    return acc;
  }, {});
  return result;
};

export const findSubIndustry = item => {
  return item?.subIndustry
  // let category = item?.companySettings[0]?.classification;
  // switch (category) {
  //   case "Air Partner":
  //     return item.subIndustry;
  //   case "Associate":
  //     return item.associateIndustryExpertise;
  //   case "Vendor":
  //     // return item.vendorIndustryExpertise; 
  //     /* -----------In case of vendor old condition modify to new------------ */
  //     return item.subIndustry;
  //   default:
  //     return null;
  // }
};


// Format selected expertises for API request
// Not used this
export const formatSelectedExpertises = (values, classification) => {
  switch (classification) {
    case "Air Partner":
      const airIndustryExpertise = values.map(
        id => `${api_v}air_industry_expertises/${id}`
      );
      return { airIndustryExpertise };

    case "Associate":
      const associateIndustryExpertise = values.map(
        id => `${api_v}associate_industry_expertises/${id}`
      );
      return { associateIndustryExpertise };
    case "Vendor":
      const vendorIndustryExpertise = values.map(
        id => `${api_v}vendor_industry_expertises/${id}`
      );
      return { vendorIndustryExpertise };
    default:
      return null;
  }
};

// covert any array [1,,2,3] to     
/*  "expertiese": [
        "/v1/expertieses/1",
        "/v1/expertieses/2",
        "/v1/expertieses/3",
        "/v1/expertieses/10",
        "/v1/expertieses/11"
    ] */
export const formatSelectedExpertisesV2 = (values) => {
  const expertiese = values.map(
    id => `${api_v}expertieses/${id}`
  );
  return { expertiese };

};

// Find Avatar Img url, buggy Database currently
export const findAvatar = arr => {
  if (Array.isArray(arr)) {
    if (arr.length === 0) {
      return false;
    }
    return arr[arr.length - 1];
  }
  return false;
};

// Fob, dap, importhandover, rates URL generate
export const findRateUrl = subject => {
  switch (subject) {
    case "importhandover":
      return "import_handovers";

    case "fob":
      return "fobs";

    case "dap":
      return "daps";

    case "rates":
      return "rates";
  }
};

// Fob, dap, importhandover, rates URL generate for BRANCH
export const findBranchRateUrl = subject => {
  switch (subject) {
    case "importhandover":
      return "import_handoverbranches";

    case "fob":
      return "fobbranches";

    case "dap":
      return "dapbranches";

    case "rates":
      return "ratesbranches";
  }
};

// Expertise Mapping
export const expertiseMaping = (company, expertiseId) => {
  // Air Cargo Sales Agent and Neutral Air Consolidator
  const mainSection = () => [
    {
      label: "Annual gross airfreight revenue (US$)",
      data: company["companyData"][0]["nacAirlineRevenue"]
    },
    {
      label: "Airport branches",
      data: company["nacOriginAirports"].map(item => ({
        location: item
      }))
    },
    {
      label: "Name your top destinations you export to (Min 1 - Max 5)",
      data: company["expNacDestAirport"].map(item => ({
        tonsPerYear: item.destinationAirportTonsPerYear,
        location: item.airport
      }))
    },
    {
      label: "Name your top 5 airports you import from",
      data: company["airImportAirport"].map(item => ({
        location: item.airport
      }))
    },
    {
      label: "Name your Top 5 countries you export to",
      data: company["airExportCountry"].map(item => ({
        location: item.country
      }))
    },
    {
      label: "Name your Top 5 countries you import from",
      data: company["airImportCountry"].map(item => ({
        location: item.country
      }))
    }
  ];
};

export const findSpecUrl = name => {
  switch (name) {
    // one to many
    case "expNacTopAirline":
      return "exp_nac_top_airlines";
    case "expNacDestAirline":
      return "exp_nac_dest_airlines";
    case "expNacDestAirport":
      return "exp_nac_dest_airports";
    case "airImportCountry":
      return "air_import_countries";
    case "airExportCountry":
      return "air_export_countries";
    case "airImportAirport":
      return "air_import_airports";
    // Airport, Airline, Countries etc...   
    case "nacOriginAirports":
      return "airport";
    default:
      return false;
  }
};

// Helper Functions -------------------------------------------------------------------------------------

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function cleanObject(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
}

export function cleanArrayOfObjects(arr) {
  let newArr = [];
  let isClean;

  arr.forEach(e => {
    isClean = true;
    for (var propName in e) {
      if (e[propName] === null || e[propName] === undefined) {
        isClean = false;
      }
    }

    if (isClean) {
      newArr.push(e);
    }
  });

  return newArr;
}

export function decamelize(str, separator) {
  separator = typeof separator === "undefined" ? " " : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .replace(/^./, str => str.toUpperCase());
}

export function skeleton(source, isArray) {
  var o = Array.isArray(source) ? [] : {};
  for (var key in source) {
    if (source.hasOwnProperty(key)) {
      var t = typeof source[key];
      o[key] =
        t == "object"
          ? skeleton(source[key])
          : { string: "", number: 0, boolean: false }[t];
    }
  }
  return o;
}

export function generateId(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateId);
}

export function removeFalseAndTransformToArray(obj) {
  let array = [];
  for (var key in obj) {
    if (obj[key] === false) {
      delete obj[key];
    } else {
      array.push(parseInt(key));
    }
  }
  return array;
}

export function ObjectToArray(data) {
  let dataArray = [];
  for (let key in data) {
    if (data[key]?.hasOwnProperty('elementType')) {
      dataArray.push({
        id: key,
        config: data[key]
      });
    }
  }

  return dataArray;
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {

  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    if (Array.isArray(value)) {
      isValid = value.length > 0 ? true : false;
    } else if (value == null) {
      isValid = false;
    } else {
      isValid = value.toString().trim() !== '' && isValid;
    }
  }

  if (rules.minLength && value != '') {
    isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength && value != '') {
    isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail && value != '') {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid
  }

  if (rules.isAlphanumeric && value != '') {
    const pattern = /^[A-Za-z0-9_-]*$/;
    isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric && value != '') {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid
  }
  if (rules.maxValue && value != '') {
    isValid = value <= rules.maxValue && isValid
  }
  if (rules.minValue && value != '') {
    isValid = value >= rules.minValue && isValid
  }
  if (rules.isMobile && value != '') {
    var pattern = /^[0]?[6789]\d{9}$/
    if (pattern.test(value)) {
      isValid = true;
    } else {
      if (value.split(value.slice(-10))[0] == '91' || value.split(value.slice(-10))[0] == '+91') {
        isValid = true;
      } else {
        isValid = false;
      }

    }

  }
  if (rules.isPositive && value != '') {
    isValid = value > 0
  }
  return isValid;
}

export const checkValidityType = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return '';
  }

  if (rules.required) {
    if (Array.isArray(value)) {
      isValid = value.length > 0 ? true : false;
    } else if (value == null) {
      isValid = false;
    } else {
      isValid = value.toString().trim() !== '' && isValid;
    }
    if (!isValid) {
      return 'required';
    }
  }

  if (rules.minLength && value != '') {
    isValid = value.length >= rules.minLength && isValid
    if (!isValid) {
      return 'minLength';
    }
  }

  if (rules.maxLength && value != '') {
    isValid = value.length <= rules.maxLength && isValid
    if (!isValid) {
      return 'maxLength';
    }
  }

  if (rules.isEmail && value != '') {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid
    if (!isValid) {
      return 'isEmail';
    }
  }

  if (rules.isAlphanumeric && value != '') {
    const pattern = /^[A-Za-z0-9_-]*$/;
    isValid = pattern.test(value) && isValid
    if (!isValid) {
      return 'isAlphanumeric';
    }
  }

  if (rules.isNumeric && value != '') {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid
    if (!isValid) {
      return 'isNumeric';
    }
  }
  if (rules.maxValue && value != '') {
    isValid = value <= rules.maxValue && isValid
    if (!isValid) {
      return 'maxValue';
    }
  }
  if (rules.minValue && value != '') {
    isValid = value >= rules.minValue && isValid
    if (!isValid) {
      return 'minValue';
    }
  }

  if (rules.isMobile && value != '') {
    var pattern = /^[0]?[6789]\d{9}$/
    if (pattern.test(value)) {
      isValid = true;
    } else {

      if (value.split(value.slice(-10))[0] == '91' || value.split(value.slice(-10))[0] == '+91') {
        isValid = true;
      } else {
        isValid = false;
      }
      if (!isValid) {
        return 'isMobile';
      }
    }
  }
  if (rules.isPositive && value != '') {
    isValid = value > 0
    if (!isValid) {
      return 'isPositive';
    }
  }

  return isValid;
}

export function cleanObjectWithId(obj, ary) {
  for (var propName in obj) {
    /*   if (ary?.includes(propName)) { */
    if (propName !== 'id' || propName !== 'legalRepresentative' || propName !== 'legalRepresentativeEmail') {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      } else if (typeof obj[propName] === "object" && Object.keys(obj[propName]).length === 0) {
        delete obj[propName];
      }
      else if (typeof obj[propName] === "string" && obj[propName].trim() === "") {
        delete obj[propName];
      } else if (obj[propName] == "") {
        delete obj[propName];
      }
    }
  }
  // }
}

/* Created for checking if all keys in focus sales is zero | empty | undefined | null*/
export const CheckFocusKeys = item => {
  let count = 0;
  const totalkeys = Object?.keys(item)?.length;
  if (typeof item == "object") {
    let keys = Object?.keys(item)
    keys.map((i) => {
      if (item[i] === null || item[i] === undefined || item[i] === "") {
        count += 1
      }
    })
    if (totalkeys == count) {
      return true
    } else {
      return false
    }
  }
  console.log("unexpected type coming")
};
