import React from "react";

export const formatAirportOption = (
  { airportName, iata, city, country },
  { context }
) => {
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airportName} ({iata})
        </div>
        <span className="text-secondary">{country}</span>
      </div>
    );
  }
  return <div>{iata}</div>;
};

export const formatSubGroupList = (
 {name}
) => {
  // if (context === "menu") {
  //   return (
  //     <div className="border-bottom">
  //       <div>
  //         {airportName} ({iata})
  //       </div>
  //       <span className="text-secondary">{country}</span>
  //     </div>
  //   );
  // }
  return <div>{name}</div>;
};


export const formatAirlineOption = (
  { airlineName, iata, country },
  { context }
) => {

  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airlineName} ({iata})
        </div>
        <span className="text-secondary">{country}</span>
      </div>
    );
  }
  return <div>{iata}</div>;
};

export const printCityName = city => {
  if (!city) {
    return "";
  }
  if (typeof city === "string" || city instanceof String) {
    return city;
  }
  return city.cityName;
};
