import React from "react";
import axios from "axios";
import { base_url, api_v } from "utils/settings";
import { Upload, message, Button, Icon } from "antd";

const ImageUpload = ({ img, target, onUpload }) => {
  const uploadSettings = {
    name: "file",
    listType: "picture",

    // API url from props ('target')
    action: `${base_url}/${api_v}${target}`,

    // Options
    customRequest: options => {
      const data = new FormData();
      data.append("file", options.file);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        },
        onUploadProgress: e => {
          options.onProgress({ percent: (e.loaded / e.total) * 100 });
        }
      };
      axios
        .post(options.action, data, config)
        .then(res => {
          options.onSuccess(res.data, options.file);
        })
        .catch(err => {
          console.log(err);
        });
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
    
        info.fileList = [];
        return onUpload(info.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  return (
    <div className="image-edit">
      <div className="image-display">
        <img src={img} />
      </div>
      <Upload {...uploadSettings}>
        <Button className="add-new-btn">
          <Icon type="upload" /> Click to Upload
        </Button>
      </Upload>
    </div>
  );
};

export default ImageUpload;
