import React, { Suspense } from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import DynamicCSS from "./DynamicCSS";  // Add this line
import { Provider } from "react-redux";
import store from "store";
/* import { Skeleton } from 'antd';
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
const DynamicCSS = React.lazy(() => import("./DynamicCSS"));
const App = React.lazy(() => import("./App")); */


ReactDOM.render(
    <React.Fragment>
        {/*   <Suspense fallback={<div><Skeleton /></div>}> */}
        <Provider store={store}>
            <DynamicCSS>
            <App />
            </ DynamicCSS>
        </Provider>
        
        {/*  </Suspense> */}
    </React.Fragment>

    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
