import React from 'react';
import { Icon, Button } from 'antd';
import { useSelector } from 'react-redux'
import { Link, useLocation } from "react-router-dom";
import { SettingOutlined } from '@ant-design/icons';

const PageHeader = ({ className, icon, title, canAdd, toggleAddMember }) => {
    let location = useLocation();
    const auth = useSelector(state => state.auth)

    return (
        <div className="network-header f-s-b">
            <h1 className="page-title">
                <img src={icon} className={className ? className : 'pageIcon'} />
                {title}
            </h1>
            {(canAdd && auth.user.roles.includes("ROLE_ADMIN") && location.pathname !== '/accounting')
                &&
                <Button
                    // type="primary"
                    className="add-member-btn"
                    onClick={toggleAddMember}
                >
                    Add member
                    <Icon type="plus-circle" />
                </Button>
            }
            {location.pathname === '/accounting' &&
                <Link
                    to="/application"
                    className="application-settings-btn"
                >
                    SETTINGS
                    <SettingOutlined />
                </Link>
            }
        </div>
    )
}

export default PageHeader;