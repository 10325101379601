import React from 'react'

export const SkeltonLoadingPromoTab = ({ itemsPerPage }) => {
    let content = "loading...";
  
    if (itemsPerPage) {
      content = [...Array(itemsPerPage)].map((e, i) => (
        <div className="cardpromoright card__network card__skeleton_promoright" key={i}>
          <div className="skeleton-line skeleton-line--xl"></div>
          <div className="skeleton-line"></div>
        </div>
      ));
    }
    return <div className="skeleton-loading">{content}</div>;
  };
