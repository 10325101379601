import React from "react";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { Input, Form } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const Bank = props => {
  const save = async values => {
    return await props.handleBankSave(values);
  };

  return (
    <AutoSaveWrapper
      onSubmit={save}
      initialValues={{
        bankName: props.bankReference.bankName,
        bankTel: props.bankReference.bankTel,
        referenceBankAddress: props.bankReference.referenceBankAddress,
        referenceBankAccount: props.bankReference.referenceBankAccount,
        referenceBankSwift: props.bankReference.referenceBankSwift,
        referenceBankFax: props.bankReference.referenceBankFax,
        // referenceBankAccnumber: props.bankReference.referenceBankAccnumber
      }}
      subscription={{}}
    >
      {() => (
        <div className="card">
          <AutoSave
            debounce={1000}
            save={save}
            debounced={[
              "bankName",
              "bankTel",
              "referenceBankAddress",
              "referenceBankAccount",
              "referenceBankSwift",
              "referenceBankFax",
              // "referenceBankAccnumber"
            ]}
          />

          <div className="card-header">
            <h2>Bank information</h2>
          </div>

          <div className="card-body">
            <Form {...formItemLayout} style={{ maxWidth: "600px" }}>
              <FormField
                name="bankName"
                label="Bank Name"
                validate={["required"]}
              >
                <Input></Input>
              </FormField>

              <FormField
                name="referenceBankAccount"
                label="Beneficary name"
                validate={["required"]}
              >
                <Input></Input>
              </FormField>

              {/* <FormField
                name="referenceBankAccnumber"
                label="Account number"
                validate={["required"]}
              >
                <Input addonBefore="IBAN"></Input>
              </FormField> */}
              <FormField
                name="referenceBankSwift"
                label="Swift code"
                validate={["required"]}
              >
                <Input></Input>
              </FormField>

              <FormField
                name="referenceBankAddress"
                label="Address, city, country"
                validate={["required"]}
              >
                <Input.TextArea autoSize={{ minRows: 2 }}></Input.TextArea>
              </FormField>

              <FormField
                name="bankTel"
                label="Telephone"
                validate={["required"]}
              >
                <Input style={{ maxWidth: 240 }}></Input>
              </FormField>

              <FormField name="referenceBankFax" label="Fax">
                <Input style={{ maxWidth: 240 }}></Input>
              </FormField>
            </Form>
          </div>
        </div>
      )}
    </AutoSaveWrapper>
  );
};

export default Bank;
