import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import ExpertiseItem from "./ExpertiseItem";
import CustomSelect from "components/ui-elements/Select";
import {
  updateCompany,
  updateCompanyData,
  updateCompanyInfo,
  createSpecialization,
  updateSpecialization,
  deleteSpecialization
} from "actions/companyActions";
import { fetchAirlines, fetchAirports, fetchCountryAirports } from "actions/dataActions";
import Table from "./Table";
import { Input, Form, Checkbox, Radio, message } from "antd";
import AutoSave from "components/helper/AutoSave";
import { Form as AutoSaveWrapper } from "react-final-form";
import FormField from "components/ui-elements/FormField";
import TwoOptionSlider from "components/ui-elements/TwoOptionSlider";
import axios from "axios";
import { base_url, api_v } from "utils/settings";
import { findSpecUrl, cleanArrayOfObjects } from "utils/functions";
import NumberFormat from "react-number-format";
// import { useListFetch } from "components/helper/Hooks";
import styled from "@emotion/styled";
import { formatAirlineOption } from "components/helper/Format";

const HorizontalForm = styled("div")`
  margin-bottom: 20px;

  .ant-row {
    display: flex;
    padding: 12px 0;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
  }

  .ant-form-item-label {
    flex: 2;
  }

  .ant-form-item-control-wrapper {
    padding-left: 30px;
    flex: 1;

    .ant-form-item-children {
      float: right;
    }
  }
`;

// Re-render only controlled components
const stopRender = (prevProps, nextProps) => {
  if (
    prevProps.expNacDestAirport !== nextProps.expNacDestAirport ||
    prevProps.expNacTopAirline !== nextProps.expNacTopAirline
  ) {
    return false;
  }

  return true;
};

const formatAirportOption2 = ({ airport }, { context }) => {

  if (airport === undefined) {
    return null;
  }
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airport?.airportName} ({airport?.iata})
        </div>
        <span className="text-secondary">{airport?.country}</span>
      </div>
    );
  }
  return <div>{airport?.iata}</div>;
};

const formatAirlineOption2 = ({ airline }, { context }) => {
  if (airline === undefined) {
    return null;
  }
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airline?.airlineName} ({airline?.iata})
        </div>
        <span className="text-secondary">{airline?.country}</span>
      </div>
    );
  }
  return <div>{airline?.iata}</div>;
};


const formatAirportOption = ({ airportName, iata, country }, { context }) => {
  if (context === "menu") {
    return (
      <div className="border-bottom">
        <div>
          {airportName} ({iata})
        </div>
        <span className="text-secondary">{country}</span>
      </div>
    );
  }
  return <div>{iata}</div>;
};

// Fetch airports (for react-select)
const fetchAirports2 = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airports?search=${input}`)
    .then(res => {
      return callback(res.data["hydra:member"].map(a => ({ airport: a })));
    })
    .catch(err => {
      return callback([]);
    });
};

const fetchAirlines2 = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airlines?search=${input}`)
    .then(res => {
      return callback(res.data["hydra:member"].map(a => ({ airline: a })));
    })
    .catch(err => {
      return callback([]);
    });
};

// COMPONENT --------------------------------------------------------------------------------------------

const AirCargoSalesAgent = /* React.memo( */(props) => {

  const nacOriginAirportsModify = useSelector((state) => state.company.data.nacOriginAirports)

  const [data, setData] = useState({ // handle , input field highlight in red color if value > 5
    importAirport: [],
    exportAirport: [],
    airline: []
  })

  const [valueBlank, setValueBlank] = useState({ // handle Required validation
    importAirport: false,
    exportAirport: false,
    airline: false,
    nacOriginAirports: false
  })
  const [loading, setLoading] = useState({
    airImportAirport: false,
    expNacTopAirline: false,
    expNacDestAirport: false,
    nacOriginAirports: false

  });
  // ExpNacDestAirport --------------------------------------------------------
  const createExpNacDestAirport = async (row, id, name) => {
    const { expNacDestAirport } = props;

    const ExpNacDesarray = [
      {
        airport: `/${api_v}airport/${row[`${id}airport`].id}`,
        destinationAirportTonsPerYear:
          row[`${id}destinationAirportTonsPerYear`]
      }
    ]
    if (expNacDestAirport.length) {
      expNacDestAirport.map((value) => {
        let obj = {
          airport: `/${api_v}airport/${value.airport.id}`,
          destinationAirportTonsPerYear: value.destinationAirportTonsPerYear
        }
        ExpNacDesarray.unshift(obj)
      })
    }
    const data = {
      [name]: ExpNacDesarray
    }
    return await props.updateCompany(data, props.companyId, name);
  };

  const updateExpNacDestAirport = async (row, id, record, name) => {
    const data = {
      airport: `/${api_v}airport/${row[`${id}airport`].id}`,
      destinationAirportTonsPerYear: row[`${id}destinationAirportTonsPerYear`]
    };
    const targetUrl = findSpecUrl(name);

    await props.updateSpecialization(data, id, name, targetUrl);
  };

  // ExpNacTopAirline  -------------------------------------------
  /*  const createExpNacTopAirline = async (row, id, name) => {
     const data = {
       company: `/${api_v}company/${props.companyId}/profile`,
       airline: `/${api_v}airline/${row[`${id}airline`].id}`,
       topAirlineTonsPerYear: row[`${id}topAirlineTonsPerYear`],
       subject: ""
     };
 
     const targetUrl = findSpecUrl(name);
 
     return await props.createSpecialization(data, name, targetUrl,props.fileName);
   }; */

  /*  const updateExpNacTopAirline = async (row, id, record, name) => {
     const data = {
       airline: `/${api_v}airline/${row[`${id}airline`].id}`,
       topAirlineTonsPerYear: row[`${id}topAirlineTonsPerYear`]
     };
     const targetUrl = findSpecUrl(name);
 
     await props.updateSpecialization(data, id, name, targetUrl);
   }; */

  // ExpNacAgreementAirline ------------------------------------------
  /*  const createExpNacAgreementAirline = async (row, id, name) => {
     const data = {
       company: `/${api_v}company/${props.companyId}/profile`,
       airline: `/${api_v}airline/${row[`${id}airline`].id}`,
       agreementTonsPerYear: row[`${id}agreementTonsPerYear`]
     };
 
     const targetUrl = findSpecUrl(name);
 
     return await props.createSpecialization(data, name, targetUrl);
   }; */

  /* const updateExpNacAgreementAirline = async (row, id, record, name) => {
    const data = {
      airline: `/${api_v}airline/${row[`${id}airline`].id}`,
      agreementTonsPerYear: row[`${id}agreementTonsPerYear`]
    };
    const targetUrl = findSpecUrl(name);

    await props.updateSpecialization(data, id, name, targetUrl);
  }; */

  // Delete Specialization item
  /*  const deleteItem = (id, name) => {
     const targetUrl = findSpecUrl(name);
     return props.deleteSpecialization(id, targetUrl, name);
   }; */

  const updateRetail = values => {
    const data = {
      retail: values.one.toString(),
      wholesale: values.two.toString()
    };

    props.updateCompanyData(data, props.companyDataId).then(() => {
      message.success("Information has been updated");
    });
  };

  // Debounce autosave ------------------------------------------------------
  const save = async values => {
    const promises = [];

    Object.keys(values).forEach(function (key, index) {
      // Toggle additional questions
      if (key === "isSeparateDivision") {
        toggleIsSeparateDivisionForm(values[key]);
      }

      if (key === "isAgentDealingDirectly") {
        toggleIsAgentDealingDirectlyForm(values[key]);
      }

      // API requests
      let data;
      if (
        key === "nacAirlineRevenue" ||
        key === "isOperatingExclusively" ||
        key === "isConductingBsaCpaContracts" ||
        key === "isOperatingRegularlyScheduled" ||
        key === "isAgentDealingDirectly" ||
        key === "isSeparateDivision" ||
        key === "divisionOrCompanyName"
      ) {
        data = {
          [key]: values[key]
        };

        return promises.push(
          Promise.resolve(props.updateCompanyData(data, props.companyDataId, props.fileName))
        );
      }

      if (key === "nacOriginAirports") {
        setLoading({ ...loading, [key]: true });
        data = {
          [key]: values[key]?.map(a => `/${api_v}${findSpecUrl(key)}/${a.id}`)
        };

        return promises.push(
          Promise.resolve(props.updateCompany(data, props.companyId/* , key */))
        );
      }
      if (key === "airCargoSalesAgent") {
        data = {
          [key]: values[key].map(a => `/${api_v}${findSpecUrl(key)}/${a}`)
        };
        return promises.push(
          Promise.resolve(props.updateCompany(data, props.companyId/* , key */))
        );
      }
    });
    setLoading({ ...loading, ["nacOriginAirports"]: false });
    return await Promise.all(promises);
  };

  const handleMultiSelect = (values, meta) => {
    const { action, name, option, removedValue } = meta;

    let data;
    let promise;
    const targetUrl = findSpecUrl(name);
    setLoading({ ...loading, [name]: true });
    if (action === "select-option") {
      if (values?.length > 5) {
        if (name === "airImportAirport") {
          // setData((prevValue) => {
          //   return {
          //     ...prevValue,
          //     importAirport: values
          //   }
          // })
          message.error("you can select only 5 airports");
          setLoading({ ...loading, [name]: false });
          return;
        } else if (name === "expNacTopAirline") {
          // setData((prevValue) => {
          //   return {
          //     ...prevValue,
          //     airline: values
          //   }
          // })

          message.error("you can select only 5 airlines");
        } else {
          // setData((prevValue) => {
          //   return {
          //     ...prevValue,
          //     exportAirport: values
          //   }
          // })

          message.error("you can select only 5 airports");
        }
        setLoading({ ...loading, [name]: false });
      } else {
        if (name === "airImportAirport") {
          data = {
            airport: `/${api_v}airport/${option.airport.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
          // setValueBlank((prevValue) => {
          //   return {
          //     ...prevValue,
          //     importAirport: false
          //   }
          // })
        } else if (name === "expNacTopAirline") {
          data = {
            airline: `/${api_v}airline/${option.airline?.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
          // setValueBlank((prevValue) => {
          //   return {
          //     ...prevValue,
          //     airline: false
          //   }
          // })
        } else if (name === "expNacDestAirport") {
          data = {
            // country: `/${api_v}countries/${option.airport.id}`,
            airport: `/${api_v}airport/${option.airport.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
          // setValueBlank((prevValue) => {
          //   return {
          //     ...prevValue,
          //     exportAirport: false
          //   }
          // })
        }
        else {
          data = {
            country: `/${api_v}countries/${option.country.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            subject: name,
            tonsPerYear: ""
          };
        }
        promise = props.createSpecialization(data, name, targetUrl, props.fileName);
        // setLoading({ ...loading, [name]: true });
      }
    } else {
      //  setData([])
      /* if (action === "select-option") {
        if (name === "airImportAirport") {
          data = {
            airport: `/${api_v}airport/${option.airport.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
        
        } else if (name === "expNacTopAirline") {
          data = {
            airline: `/${api_v}airline/${option.airline.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
        } else if (name === "expNacDestAirport") {

          data = {
            // country: `/${api_v}countries/${option.airport.id}`,
            airport: `/${api_v}airport/${option.airport.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            tonsPerYear: "",
            subject: name
          };
        }
        else {
          data = {
            country: `/${api_v}countries/${option.country.id}`,
            company: `/${api_v}company/${props.companyId}/profile`,
            subject: name,
            tonsPerYear: ""
          };
        }
        promise = props.createSpecialization(data, name, targetUrl);
      } else { */
      if (values?.length <= 5 || values === null) {

        // if (name === "airImportAirport") {
        //   setData((prevValue) => {
        //     return {
        //       ...prevValue,
        //       importAirport: null
        //     }
        //   })
        //   setValueBlank((prevValue) => {
        //     return {
        //       ...prevValue,
        //       importAirport: false
        //     }
        //   })
        // } else if (name === "expNacTopAirline") {        
        //   setData((prevValue) => {
        //     return {
        //       ...prevValue,
        //       airline: null
        //     }
        //   })
        //   setValueBlank((prevValue) => {
        //     return {
        //       ...prevValue,
        //       airline: false
        //     }
        //   })
        // } else {
        //   setData((prevValue) => {
        //     return {
        //       ...prevValue,
        //       exportAirport: null
        //     }
        //   })
        //   setValueBlank((prevValue) => {
        //     return {
        //       ...prevValue,
        //       exportAirport: false
        //     }
        //   })
        // }
        promise = props.deleteSpecialization(removedValue.id, targetUrl, name, props.fileName, props.companyId);

        // } else { // Required validation      
        //   if (name === "airImportAirport") {
        //     setValueBlank((prevValue) => {
        //       return {
        //         ...prevValue,
        //         importAirport: true
        //       }
        //     })
        //   } else if (name === "expNacTopAirline") {

        //     setValueBlank((prevValue) => {
        //       return {
        //         ...prevValue,
        //         airline: true
        //       }
        //     })
        //   } else {
        //     setValueBlank((prevValue) => {
        //       return {
        //         ...prevValue,
        //         exportAirport: true
        //       }
        //     })
        //   }
        // setLoading({ ...loading, [name]: false });
      }
      // }
    }

    if (promise !== undefined) {
      promise.then(res => {
        setLoading({ ...loading, [name]: false });
        message.success("Airports/Airlines Updated successfully")
      }).catch(err => {
        setLoading({ ...loading, [name]: false });
        message.error("Something went wrong")
      });
    }
  };

  const handleAirportBranches = (values, meta) => {
    let promise;
    const { action, name, option, removedValue } = meta;
    setLoading({ ...loading, [name]: true });
    /* NAp Changes v2-262 */
    /*     if (values?.length > 5) {
          if (name === "nacOriginAirports") {
            message.error("you can select only 5 airports");
            setLoading({ ...loading, [name]: false });
            return;
          }
        } else  */
    if (values === null) {
      let data = {
        [name]: []
      };
      // promise = props.updateCompany(data, props.companyId,/*  name */ )
      promise = props.updateCompanyInfo(
        {
          "companyAirport": `/v1/airport/${values.id}`
        },
        props.companyId
      );
      message.error("you should select atleast 1 airport")
    }
    else {
      /*   let data = {
          [name]: values?.map(a => `/${api_v}${findSpecUrl(name)}/${a.id}`)
        };
        promise = props.updateCompany(data, props.companyId) */
      setAirport(values)
      promise = props.updateCompanyInfo(
        {
          "companyAirport": `/v1/airport/${values.id}`
        },
        props.companyId
      );
    }
    if (promise !== undefined) {
      promise.then(res => {
        setLoading({ ...loading, [name]: false });
        message.success("Airports Updated successfully")
      }).catch(err => {
        setLoading({ ...loading, [name]: false });
        message.error("Something went wrong")
      });
    }
  }

  /*  const airCargoSalesAgentList = useListFetch("airCargoSalesAgent").map(a => ({
     label: a.airCargoSalesAgent,
     value: a.id
   }));
 
   const neutralAirConsolidatorsList = useListFetch(
     "neutralAirConsolidators"
   ).map(a => ({
     label: a.neutralAirConsolidators,
     value: a.id
   })); */

  const {
    air,
    neutral,
    // Air
    nacAirlineRevenue,
    nacOriginAirports,
    airCargoSalesAgent,
    expNacDestAirport,
    expNacTopAirline,
    airImportAirport,
    // Neutral
    isOperatingExclusively,
    isConductingBsaCpaContracts,
    isOperatingRegularlyScheduled,
    isAgentDealingDirectly,
    isSeparateDivision,
    divisionOrCompanyName,
    retail,
    wholesale,
    comapanyCountryId,
    setValidationErrorAirCargo,
    selectedExpertieseIds,
    companyAirport
  } = props;

  console.log("companyAirport...", companyAirport)

  const [airport, setAirport] = useState(companyAirport)
  const [
    isAgentDealingDirectlyForm,
    toggleIsAgentDealingDirectlyForm
  ] = useState(isAgentDealingDirectly);

  const [isSeparateDivisionForm, toggleIsSeparateDivisionForm] = useState(
    isSeparateDivision
  );

  useEffect(() => {
    if (expNacTopAirline?.length === 0 && airImportAirport?.length === 0 && expNacDestAirport?.length === 0 && companyAirport === null && nacAirlineRevenue?.length === 0) {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          expNacTopAirline: true,
          airImportAirport: true,
          expNacDestAirport: true,
          companyAirport: true,
          nacAirlineRevenue: true
        }
      })
    } else if (expNacTopAirline?.length === 0
    ) {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          expNacTopAirline: true,
        }
      })
    }
    else if (airImportAirport?.length === 0
    ) {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          airImportAirport: true,
        }
      })
    } else if (expNacDestAirport.length === 0
    ) {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          expNacDestAirport: true,
        }
      })
    }
    else if (companyAirport === null /* companyAirport?.length === 0 */
    ) {
      console.log("companyAirport..inside block",companyAirport)
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          companyAirport: true,
        }
      })
    }
    else if (nacAirlineRevenue?.length === 0
    ) {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          nacAirlineRevenue: true,
        }
      })
    }
    else {
      typeof setValidationErrorAirCargo === "function" && setValidationErrorAirCargo((prevValue) => {
        return {
          ...prevValue,
          expNacTopAirline: false,
          airImportAirport: false,
          expNacDestAirport: false,
          companyAirport: false,
          nacAirlineRevenue: false
        }
      })
    }

  }, [expNacTopAirline, airImportAirport, expNacDestAirport, companyAirport, nacAirlineRevenue])

  return (
    <>
      {
        props.fileStatus === "registration"
          ?
          <>
            <CustomSelect
              // className={`${data?.airline?.length > 5 && "airCargoSelect2"}`}
              async={true}
              isDisabled={loading.expNacTopAirline}
              className="customSelectWithChips"
              required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
              fileStatus="editProfile"
              selectedExpertieseIds={selectedExpertieseIds}
              label="Select your top 5 airlines you export with"
              name="expNacTopAirline"
              cacheOptions
              value={valueBlank.airline ? [] : expNacTopAirline}
              loadOptions={(input, callback) => fetchAirlines2(input, callback)}
              formatOptionLabel={formatAirlineOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => {
                return option?.airline?.id
              }}
              onChange={handleMultiSelect}
            ></CustomSelect>

            <CustomSelect
              // className={`${data?.importAirport?.length > 5 && "airCargoSelect1"}`}
              async={true}
              isDisabled={loading.airImportAirport}
              className="customSelectWithChips"
              required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
              fileStatus="editProfile"
              selectedExpertieseIds={selectedExpertieseIds}
              label="Select your top 5 airports you import from"
              name="airImportAirport"
              value={valueBlank.importAirport ? [] : airImportAirport}
              // value={data?.length == 6 ? airImportAirport:[]}
              cacheOptions
              // loadOptions={fetchAirports2}
              loadOptions={(input, callback) => fetchAirports2(input, callback)}
              formatOptionLabel={formatAirportOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => option.airport.id}
              onChange={handleMultiSelect}
            ></CustomSelect>

            <CustomSelect
              // className={`${data?.exportAirport?.length > 5 && "airCargoSelect3"}`}
              async={true}
              isDisabled={loading.expNacDestAirport}
              className="customSelectWithChips"
              required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
              fileStatus="editProfile"
              selectedExpertieseIds={selectedExpertieseIds}
              label="Select your top 5 airports you export to"
              name="expNacDestAirport"
              cacheOptions
              value={valueBlank.exportAirport ? [] : expNacDestAirport}
              loadOptions={(input, callback) => fetchAirports2(input, callback)}
              formatOptionLabel={formatAirportOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => option?.airport?.id}
              onChange={handleMultiSelect}
            ></CustomSelect>
            {props.fileName !== "expertise"
              &&
              <CustomSelect
                async={true}
                name="companyAirport"
                label="Airport we operate at"
                isClearable={false}
                // isDisabled={loading.nacOriginAirports}
                required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
                selectedExpertieseIds={selectedExpertieseIds}
                fileStatus="editProfile"
                value={airport ? airport : []}
                noOptionsMessage={(input) => "Type to search"}
                loadOptions={(input, callback) => fetchCountryAirports(input, comapanyCountryId, callback)}
                formatOptionLabel={formatAirportOption}
                getOptionLabel={option => option.iata}
                getOptionValue={option => option.id}
                onChange={handleAirportBranches /* handleAirportSelect */}

              ></CustomSelect>
            }
            {/* <CustomSelect
                async={true}
                className="customSelectWithChips"
                isDisabled={loading.nacOriginAirports}
                required={((!selectedExpertieseIds?.includes(24) && !selectedExpertieseIds?.includes(31) && !selectedExpertieseIds?.includes(32)) ? true : false)}
                fileStatus="editProfile"
                selectedExpertieseIds={selectedExpertieseIds}
                label="Airports we operate at"
                value={nacOriginAirportsModify}
                name="nacOriginAirports"
                cacheOptions
                loadOptions={(input, callback) => fetchCountryAirports(input, comapanyCountryId, callback)}
                formatOptionLabel={formatAirportOption}
                isClearable={false}
                noOptionsMessage={input =>
                  "Please enter 2 or more characters"
                }
                isMulti
                getOptionValue={option => option.id}
                onChange={handleAirportBranches}
              ></CustomSelect> */}


            <AutoSaveWrapper
              onSubmit={save}
              initialValues={{
                nacAirlineRevenue,
                // nacOriginAirports,
                /*               airCargoSalesAgent,
               */
                isOperatingExclusively,
                isConductingBsaCpaContracts,
                isOperatingRegularlyScheduled,
                isAgentDealingDirectly,
                isSeparateDivision,
                retail,
                wholesale,

                divisionOrCompanyName
              }}
              subscription={{}}
            >
              {() => (
                <>
                  <AutoSave
                    debounce={1000}
                    save={save}
                    debounced={[
                      "nacAirlineRevenue",
                      // "nacOriginAirports",
                      /*   "airCargoSalesAgent",
                        */
                      "isOperatingExclusively",
                      "isConductingBsaCpaContracts",
                      "isOperatingRegularlyScheduled",
                      "divisionOrCompanyName",
                      "retail",
                      "wholesale"
                    ]}
                  />
                  <Form style={{ maxWidth: "800px" }} className="nacAirlineRevenue">
                    {/*                     <FormField
                      name="nacOriginAirports"
                      label="Airport branches"
                      validate={["required"]}
                    >
                      <CustomSelect
                        async={true}
                        className="customSelectWithChips"
                        isDisabled={loading.nacOriginAirports}
                        cacheOptions
                        loadOptions={(input, callback) => fetchCountryAirports(input, comapanyCountryId, callback)}
                        formatOptionLabel={formatAirportOption}
                        isClearable={false}
                        noOptionsMessage={input =>
                          "Please enter 2 or more characters"
                        }
                        isMulti
                        getOptionValue={option => option.id}
                      ></CustomSelect>
                    </FormField> */}
                    <FormField
                      name="nacAirlineRevenue"
                      label="Annual gross airfreight revenue (US$)"
                      validate={["required"]}
                      selectedExpertieseIds={selectedExpertieseIds}
                      fileName="registration"
                    >
                      <NumberFormat
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={"$"}
                        style={{ maxWidth: 300 }}
                      ></NumberFormat>
                    </FormField>
                  </Form>
                </>
              )}
            </AutoSaveWrapper>

          </>
          :
          <ExpertiseItem  // this component never render 
            title={
              (air ? "Air cargo sales agent" : "") +
              (air && neutral ? " / " : "") +
              (neutral ? "Neutral Air Consolidators" : "")
            }
          >

            {/* NEUTRAL AIR CONSOLIDATOR */}
            {/* {neutral ? (
        <Form.Item
          label="What is the percentage of your commercial retail business and your neutral master loading wholesale business?"
        >
          <TwoOptionSlider
            defaultValue={retail}
            onChange={updateRetail}
          ></TwoOptionSlider>
        </Form.Item>
      ) : null} */}

            {/* <section className="section-border">
        <Table
          items={expNacDestAirport}
          name="expNacDestAirport"
          title="Name your top destinations you export to (Min 1 - Max 5)"
          min={1}
          max={5}
          skeleton={{
            destinationAirportTonsPerYear: ""
          }}
          columns={[
            {
              title: "Destinations",
              dataIndex: "airport",
              width: "35%",
              editable: true,
              type: "async-select-airports",
              render: airport => {
                if (!airport) {
                  return "";
                }
                return (
                  <span>
                    {airport.airportName} ({airport.iata})
                  </span>
                );
              }
            },
            {
              title: "Tons per year",
              dataIndex: "destinationAirportTonsPerYear",
              width: "35%",
              sorter: (a, b) =>
                a.destinationAirportTonsPerYear.localeCompare(
                  b.destinationAirportTonsPerYear
                ),
              editable: true,
              type: "input-number"
            }
          ]}
          createItem={createExpNacDestAirport}
          updateItem={updateExpNacDestAirport}
          deleteItem={deleteItem}
        ></Table>
      </section> */}

            {/* <section className="section-border">
        <Table
          items={expNacTopAirline}
          name="expNacTopAirline"
          title="Name your top airlines you export with (Min 1 - Max 5)*"
          min={1}
          max={5}
          skeleton={{
            topAirlineTonsPerYear: ""
          }}
          columns={[
            {
              title: "Destinations",
              dataIndex: "airline",
              width: "35%",
              editable: true,
              type: "async-select",
              render: airline => {
                if (!airline) {
                  return "";
                }
                return (
                  <span>
                    {airline.airlineName} ({airline.iata})
                  </span>
                );
              }
            },
            {
              title: "Tons per year",
              dataIndex: "topAirlineTonsPerYear",
              width: "35%",
              sorter: (a, b) =>
                a.topAirlineTonsPerYear.localeCompare(b.topAirlineTonsPerYear),
              editable: true,
              type: "input-number"
            }
          ]}
          createItem={createExpNacTopAirline}
          updateItem={updateExpNacTopAirline}
          deleteItem={deleteItem}
        ></Table>
      </section> */}

            {/* <section style={{ marginBottom: 20 }}>
        <Table
          items={expNacAgreementAirline}
          name="expNacAgreementAirline"
          title="Name your top 3 airlines where you hold a BSA (Blocked space) or CPA (Capacity purchase) agreement"
          min={1}
          max={3}
          skeleton={{
            agreementTonsPerYear: ""
          }}
          columns={[
            {
              title: "Destinations",
              dataIndex: "airline",
              width: "35%",
              editable: true,
              type: "async-select",
              render: airline => {
                if (!airline) {
                  return "";
                }
                return (
                  <span>
                    {airline.airlineName} ({airline.iata})
                  </span>
                );
              }
            },
            {
              title: "Tons per year",
              dataIndex: "agreementTonsPerYear",
              width: "35%",
              sorter: (a, b) =>
                a.agreementTonsPerYear.localeCompare(b.agreementTonsPerYear),
              editable: true,
              type: "input-number"
            }
          ]}
          createItem={createExpNacAgreementAirline}
          updateItem={updateExpNacAgreementAirline}
          deleteItem={deleteItem}
        ></Table>
      </section> */}
            <CustomSelect
              // className={data?.airline?.length > 5 && "airCargoSelect2"}
              async={true}
              className="customSelectWithChips"
              label="Select your top 5 airlines you export with"
              name="expNacTopAirline"
              cacheOptions
              value={expNacTopAirline}
              loadOptions={(input, callback) => fetchAirlines2(input, callback)}
              formatOptionLabel={formatAirlineOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => option.airline.id}
              onChange={handleMultiSelect}
            ></CustomSelect>

            <CustomSelect
              // className={data?.importAirport?.length > 5 && "airCargoSelect1"}
              async={true}
              className="customSelectWithChips"
              label="Select your top 5 airports you import from"
              name="airImportAirport"
              value={airImportAirport}
              // value={data?.length == 6 ? airImportAirport:[]}
              cacheOptions
              // loadOptions={fetchAirports2}
              loadOptions={(input, callback) => fetchAirports2(input, callback)}
              formatOptionLabel={formatAirportOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => option.airport.id}
              onChange={handleMultiSelect}
            ></CustomSelect>

            <CustomSelect
              // className={data?.exportAirport?.length > 5 && "airCargoSelect3"}
              async={true}
              className="customSelectWithChips"
              label="Select your top 5 airports you export to"
              name="expNacDestAirport"
              cacheOptions
              value={expNacDestAirport}
              loadOptions={(input, callback) => fetchAirports2(input, callback)}
              formatOptionLabel={formatAirportOption2}
              isClearable={false}
              noOptionsMessage={input => "Please enter 2 or more characters"}
              isMulti
              getOptionValue={option => option.airport.id}
              onChange={handleMultiSelect}
            ></CustomSelect>
            <CustomSelect
              async={true}
              className="customSelectWithChips"
              isDisabled={loading.nacOriginAirports}
              label="Airport branch"
              value={nacOriginAirports}
              name="nacOriginAirports"
              cacheOptions
              loadOptions={(input, callback) => fetchCountryAirports(input, comapanyCountryId, callback)}
              formatOptionLabel={formatAirportOption}
              isClearable={false}
              noOptionsMessage={input =>
                "Please enter 2 or more characters"
              }
              isMulti
              getOptionValue={option => option.id}
              onChange={handleAirportBranches}
            ></CustomSelect>

            <AutoSaveWrapper
              onSubmit={save}
              initialValues={{
                nacAirlineRevenue,
                // nacOriginAirports,
                /*                 airCargoSalesAgent,
                                */
                isOperatingExclusively,
                isConductingBsaCpaContracts,
                isOperatingRegularlyScheduled,
                isAgentDealingDirectly,
                isSeparateDivision,
                retail,
                wholesale,

                divisionOrCompanyName
              }}
              subscription={{}}
            >
              {() => (
                <>
                  <AutoSave
                    debounce={1000}
                    save={save}
                    debounced={[
                      "nacAirlineRevenue",
                      // "nacOriginAirports",
                      /* "airCargoSalesAgent",
                      */
                      "isOperatingExclusively",
                      "isConductingBsaCpaContracts",
                      "isOperatingRegularlyScheduled",
                      "divisionOrCompanyName",
                      "retail",
                      "wholesale"
                    ]}
                  />
                  <Form style={{ maxWidth: "800px" }}>
                    {/* <FormField
                      name="nacOriginAirports"
                      label="Airport branches"
                      validate={["required"]}
                    >
                      <CustomSelect
                        async={true}
                        isDisabled={loading.nacOriginAirports}
                        className="customSelectWithChips"
                        cacheOptions
                        loadOptions={(input, callback) => fetchCountryAirports(input, comapanyCountryId, callback)}
                        formatOptionLabel={formatAirportOption}
                        isClearable={false}
                        noOptionsMessage={input =>
                          "Please enter 2 or more characters"
                        }
                        isMulti
                        getOptionValue={option => option.id}
                      ></CustomSelect>
                    </FormField> */}
                    <FormField
                      name="nacAirlineRevenue"
                      label="Annual gross airfreight revenue (US$)"
                      validate={["required"]}
                    >
                      <NumberFormat
                        customInput={Input}
                        thousandSeparator={true}
                        prefix={"$"}
                        style={{ maxWidth: 300 }}
                      ></NumberFormat>
                    </FormField>

                    {/* {air && (
                <FormField
                  name="airCargoSalesAgent"
                  label="Air cargo sales agent services"
                  validate={["required"]}
                >
                  <Checkbox.Group options={airCargoSalesAgentList} />
                </FormField>
              )} */}
                    {/* NEUTRAL AIR CONSOLIDATOR */}
                    {/* {neutral ? (
                <>
                  <FormField
                    name="neutralAirConsolidators"
                    label="Neutral air consolidator services your company is specialized (minimum one)"
                  >
                    <Checkbox.Group options={neutralAirConsolidatorsList} />
                  </FormField>
                  <HorizontalForm>
                    <FormField
                      name="isOperatingExclusively"
                      label="Is your company operating exclusively as a neutral freight wholesaler to the local freight forwarding market?"
                    >
                      <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value={true}>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormField>

                    <FormField
                      name="isConductingBsaCpaContracts"
                      label="Is your company conducting BSA CPA contracts with carriers?"
                    >
                      <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value={true}>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormField>

                    <FormField
                      name="isOperatingRegularlyScheduled"
                      label="Is your company operating regularly scheduled consolidations?"
                    >
                      <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value={true}>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormField>

                    <FormField
                      name="isAgentDealingDirectly"
                      label="Is your company a commercial retail freight agent dealing directly with shippers or consignees?"
                    >
                      <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value={true}>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormField>

                    {isAgentDealingDirectlyForm && (
                      <>
                        <FormField
                          name="isSeparateDivision"
                          label="Do you have a separate division or a company of your group handling exclusively neutral wholesale freight business?"
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            size="small"
                            onChange={() => {
                            
                            }}
                          >
                            <Radio.Button value={true}>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </FormField>
                        {isSeparateDivisionForm && (
                          <FormField
                            name="divisionOrCompanyName"
                            label="Please name your division or company name of the group"
                          >
                            <Input></Input>
                          </FormField>
                        )}
                      </>
                    )}
                  </HorizontalForm>
                </>
              ) : null} */}
                  </Form>
                </>
              )}
            </AutoSaveWrapper>

            {/* <CustomSelect
        async={true}
        label="Name your Top 5 countries you export to"
        name="airExportCountry"
        defaultValue={airExportCountry}
        cacheOptions
        loadOptions={fetchCountries}
        isClearable={false}
        noOptionsMessage={input => "Please enter 2 or more characters"}
        isMulti
        getOptionLabel={option => option.country.countryname}
        getOptionValue={option => option.country.id}
        onChange={handleMultiSelect}
      ></CustomSelect>

      <CustomSelect
        async={true}
        label="Name your Top 5 countries you import from "
        name="airImportCountry"
        defaultValue={airImportCountry}
        cacheOptions
        loadOptions={fetchCountries}
        isClearable={false}
        noOptionsMessage={input => "Please enter 2 or more characters"}
        isMulti
        getOptionLabel={option => option.country.countryname}
        getOptionValue={option => option.country.id}
        onChange={handleMultiSelect}
      ></CustomSelect> */}
          </ExpertiseItem >
      }
    </>
  );
}/* , stopRender); */

const mapStateToProps = state => ({
  // ID for actions
  companyId: state.company.data.id,
  companyAirport: state.company.data.companyInfo[0].companyAirport,

  companyDataId: state.company.data.companyData[0].id,

  // Annual gross airfreight revenue (US$)* --------------------------------------------------------------
  nacAirlineRevenue: state.company.data.companyData[0].nacAirlineRevenue,

  // Name your airport branches*
  nacOriginAirports: state.company.data.nacOriginAirports,

  // Please select one or more of the following air cargo sales agent services your company is specialized*
  airCargoSalesAgent: state.company.data.airCargoSalesAgent?.map(a => a.id),

  // Name your top destinations you export to (Min 1 - Max 5)* ]
  expNacDestAirport: state.company.data.expNacDestAirport,

  // Name your top airlines you export with (Min 1 - Max 5)* ]
  expNacTopAirline: state.company.data.expNacTopAirline,

  // Name your top 5 airports you import from*
  airImportAirport: state.company.data.airImportAirport,

  // Name your Top 5 countries you export to *s
  airExportCountry: cleanArrayOfObjects(state.company.data.airExportCountry),

  // Name your Top 5 countries you import from *
  airImportCountry: cleanArrayOfObjects(state.company.data.airImportCountry),

  // Neutral Air Consolidator -------------------------------------------

  // Please select one or more of the following neutral air consolidator services your company is specialized*
  neutralAirConsolidators: state.company.data.neutralAirConsolidators?.map(
    a => a.id
  ),

  // What is the percentage of your commercial retail business and your neutral master loading wholesale business?
  retail: Number(state.company.data.companyData[0].retail),
  wholesale: Number(state.company.data.companyData[0].wholesale),

  // Is your company operating exclusively as a neutral freight wholesaler to the local freight forwarding market?
  isOperatingExclusively:
    state.company.data.companyData[0].isOperatingExclusively,

  // Is your company conducting BSA CPA contracts with carriers?
  isConductingBsaCpaContracts:
    state.company.data.companyData[0].isConductingBsaCpaContracts,

  // Is your company operating regularly scheduled consolidations?
  isOperatingRegularlyScheduled:
    state.company.data.companyData[0].isOperatingRegularlyScheduled,

  // Is your company a commercial retail freight agent dealing directly with shippers or consignees?
  isAgentDealingDirectly:
    state.company.data.companyData[0].isAgentDealingDirectly,

  // do you have a separate division or a company of your group handling exclusively neutral wholesale freight business?
  isSeparateDivision: state.company.data.companyData[0].isSeparateDivision,

  //

  divisionOrCompanyName: state.company.data.companyData[0].divisionOrCompanyName,

  // please name your division or company name of the group (MISSING!)

  //Company country id
  comapanyCountryId: state.company.data.countries.id
});
export default connect(mapStateToProps, {
  updateCompany,
  updateCompanyData,
  updateCompanyInfo,
  createSpecialization,
  updateSpecialization,
  deleteSpecialization
})(AirCargoSalesAgent);
