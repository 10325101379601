import React from "react";
import { Form as AutoSaveWrapper } from "react-final-form";
import { Input, Form } from "antd";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
// props.updateCompanyData(values, props.companyId)
const Mawb = props => {
  const save = async values => {
    return await props.handleSave(values);
  };

  const { mawbAddress, notifyAddress, destinationNotes } = props.data;

  return (
    <AutoSaveWrapper
      onSubmit={save}
      initialValues={{
        mawbAddress,
        notifyAddress,
        destinationNotes
      }}
      subscription={{}}
    >
      {() => (
        <div className="card">
          <AutoSave
            debounce={1000}
            save={save}
            debounced={["mawbAddress", "notifyAddress", "destinationNotes"]}
          />
          <div className="card-header">
            <h2>MAWB/CNEE Information and Destination notes</h2>
          </div>

          <div className="card-body">
            <Form
              {...formItemLayout}
              style={{ maxWidth: "800px", marginTop: 10 }}
            >
              <FormField
                name="mawbAddress"
                label="MAWB/CNEE address"
                validate={["required"]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 10 }}
                ></Input.TextArea>
              </FormField>

              <FormField
                name="notifyAddress"
                label="Notify address"
                validate={["required"]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 10 }}
                ></Input.TextArea>
              </FormField>

              <FormField
                label="Destination notes"
                name="destinationNotes"
                validate={["required"]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 10 }}
                ></Input.TextArea>
              </FormField>
            </Form>
          </div>
        </div>
      )}
    </AutoSaveWrapper>
  );
};

export default Mawb;
