import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateCompany,
  updateSelectedExpertises,
  updateSelectedExpertisesV2
} from "actions/companyActions";
// import { getExpertiseList } from "actions/dataActions"; // comment reason 101
import { findExpertise, findExpertiseV2, findExpertiseV3, formatSelectedExpertisesV2 } from "utils/functions";
import ExpertiseListing from "./ExpertiseListing";
import QuestionsList from "./QuestionsList";
import { message, Checkbox, Divider } from "antd";
import ImportExportCountries from "./ImportExportCountries";
import styled from "@emotion/styled";
import FocusSlider from "./FocusSlider";
import Classification from "./Classification";
import Tradelane from "./Tradelane";
import SubGroup from './SubGroup'
import { debounce } from "lodash";
const MemoizedChildComponentA = React.memo(QuestionsList);
// import SubIndustriesExpertiese from "../../common/subIndustriesExpertiese"

const ExpertiseSelection = styled(Checkbox.Group)`
  min-height: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0.7em;
  margin-bottom: 24px;
`;

class Expertise extends Component {
  constructor(props) {

    super(props);
    console.log(this.props.expertises, "this.props.expertises")
    this.state = {
      resultChk: [],
      selectedExpertieseIds: [],
      selectedSubIndustry: this.props.subIndustry,
      checkedValObj: this.props.expertisestoCheckedValues,
      selectedExpertiseList: this.props.expertises,
      maxExpertises: /* this.props.classification  === "Vendor" */  (this.props?.subIndustry?.filter(item => item.id === 7)?.length) !== 0 ? 3 : 4
    };
  }


  componentDidMount() {
    //  this.props.getExpertiseList(this.props.classification);  // comment reason 101
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expertises !== this.props.expertises) {
      // Update the state when expertises prop changes
      this.setState({
        selectedExpertiseList: this.props.expertises,
      });
    }
    if (prevProps.subIndustry !== this.props.subIndustry) {
      // Update the state when expertises prop changes
      this.setState({
        selectedSubIndustry: this.props.subIndustry,
      });
    }

    if (this.state.checkedValObj !== prevState.checkedValObj) {
      const expertiseArray = Object.values(this.state.checkedValObj).flat();

      this.setState(
        {
          resultChk: expertiseArray
        }
      );
      /*  let value = []
       const expertiseCheckedIdsArray = Object.values(this.state.checkedValObj).flat();
       value.push(...expertiseCheckedIdsArray)
       this.setState({
       selectedExpertieseIds: value
       }) */

    }
  }

  // Checkbox selection
  onChange = (list, checkedValues) => {
    const { classification, companyId, expertiseList } = this.props;

    // this.setState({
    //   selectedExpertiseList: checkedValues
    // });
    this.setState(prevState => ({
      checkedValObj: {
        ...prevState.checkedValObj,
        [list]: checkedValues,
      }
    }));

    const apiUpdate = debounce(() => {
      // const payload = [];
      // payload.push(this.state.resultChk);

      const addData = formatSelectedExpertisesV2(this.state.resultChk);
      this.props
        .updateSelectedExpertisesV2(
          addData,
          companyId,
          "expertiese"
        )
        .then(() => {
          message.success("Information has been updated.");
        });
    }, 500);

    apiUpdate();
  };

  render() {
    console.log(this.state, "-------ss-")
    const { maxExpertises, selectedExpertiseList, selectedSubIndustry } = this.state;
    console.log("selectedExpertiseList,,,,,,", selectedExpertiseList)
    const { classification, focus, companyId, auth, companyData } = this.props;

    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");


    // const distinctSubIndustries = [...new Set(this.props?.subIndustry.map(item => item.name))];


    let expertiseListArray = [...this.props?.subIndustry?.map(a => {
      return a.expertieses;
    }).flat()]

    // List formated for CheckBox.Group comment reason 101
    let expertiseList = expertiseListArray?.map(a => ({
      label: a?.name,
      value: a?.id
    }));


    // Disable not selected, and limit for maxExpertise
    // if (selectedExpertiseList.length >= maxExpertises) {
    //   expertiseListArray.forEach((item, i) => {
    //     if (!selectedExpertiseList.includes(item.id)) {
    //       expertiseListArray[i].disabled = true;
    //     }
    //   });
    // }
    // console.log("woking","")
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h3>Networks</h3>
          </div>
          <div className="card-body">
            <div className="expertise-classificationPanel imagesAndCheckboxes">
              {
                /*    isAdmin && */
                <>
                  {/* <Classification
                companyDataId={focus.id}
                companyId={companyId}></Classification> */}
                  {/*  <SubGroup /> */}
                </>
              }

            </div>
            <p style={{ marginBottom: "20px" }}>
              Chose maximum {maxExpertises} of below industry expertise that
              your company is specialized at
            </p>
            <div className="customSecionCard">
              <SubGroup isAdmin={isAdmin} />
              {/* {103} */}
              {/* {classification === "Air Partner" ? (
                <>
                  <ExpertiseListing
                    expertiseList={expertiseList}
                    expertises={selectedExpertiseList}
                    classification={classification}
                    companyId={companyId}
                  ></ExpertiseListing>
                </>
              ) : null} */}
              {/* {103} */}
            </div>


            {/* here is iteration for all sunindustry in order to showing all checkboxes in groupes*/}
            {/*   <SubIndustriesExpertiese subIndustries={this.props?.subIndustry} companyData={this.props?.companyData} handleExpertiseSelection={this.onChange} selectedExpertieseIds={this.props.selectedExpertieseIds}/> */}
            {/*  {this.props?.subIndustry?.map((sub) => {
              let expertiseListnew = sub.expertieses?.map(a => ({
                label: a.name,
                value: a.id,
                key: sub.name           
              }));
              return (
                <>
                  <div className="ant-checkbox-group expertise-box-group expertise-box-group-custom">
                    <div className="expertise-box">
                      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: "20px" }} className="expertise-box-head">
                        <div className="logoAndBadge">
                          <img src={sub.industryLogo} className="logo-img" />
                          <span style={{ marginLeft: "20px" }} className="expertise" > Expertise </span>
                        </div>
                      </div>

                      < ExpertiseSelection
                        className="userExpertise"
                        options={expertiseListnew}
                        defaultValue={selectedExpertiseList}
                        onChange={(check) => { this.onChange(sub.name, check) }}
                      // disabled={!isAdmin}
                      ></ExpertiseSelection>
                    </div>
                  </div>
                </>
                )
            })}
 */}

          </div>
        </div>
        {selectedSubIndustry.filter(item => item.id === 7).length === 0 ?
          <div className="card">
            <div className="card-body">
              {/*           {classification !== "Vendor" ? (
            <>
              <Tradelane expertise ></Tradelane>
            </>
          ) : null}
          {classification !== "Vendor" ? (
            <>
              <FocusSlider
                expertise
                focus={focus}
                companyDataId={focus.id}
                companyId={companyId}
              ></FocusSlider>
            </>
          ) : null}       */}

              {selectedSubIndustry.filter(item => item.id === 7).length === 0 ? (
                <>
                  <Tradelane expertise ></Tradelane>
                </>
              ) : null}
              {selectedSubIndustry.filter(item => item.id === 7).length === 0 ? (
                <>
                  <FocusSlider
                    fileName="expertise"
                    expertise
                    focus={focus}
                    companyDataId={focus.id}
                    companyId={companyId}
                  ></FocusSlider>
                </>
              ) : null}
              {/* <div className="card"> */}
              {/*  <div className="card-header">
            <h3>Industry expertise</h3>
          </div> */}
              {/* <div className="card-body"> */}
              {/* {classification !== "Vendor" ? (
            <> */}
              {selectedSubIndustry.filter(item => item.id === 7).length === 0 ? <ImportExportCountries fileName="expertise" selectedExpertieseIds={selectedExpertiseList} /> : ""}
              {/* <Divider></Divider> */}
              {/* </>
          ) : null} */}
              {/* </div>
        </div> */}

              {/* <div className="card"> */}
              {/* <div className="card-header">
            <h3>Questions</h3>
          </div> */}
              {/* <div className="card-body"> */}
              {/*  <div className="expertise-classificationPanel imagesAndCheckboxes">
            </div> */}
              {selectedSubIndustry.filter(item => item.id === 7).length === 0 ?
                <div className="customSecionCard">
                  <ExpertiseListing
                    fileName="expertise"
                    fileStatus="registration"
                    expertiseList={expertiseList}
                    expertises={[1]}
                    classification={classification}
                    companyId={companyId}
                    selectedExpertieseIds={selectedExpertiseList}
                  ></ExpertiseListing>
                </div> : ""}
              {console.log("-->", companyData)}

              {selectedSubIndustry.filter(item => item.id === 7).length === 0 ?
                <div className="customSecionCard">
                  <MemoizedChildComponentA
                    // expertiseList={expertiseList}
                    companyData={companyData}
                    userStatus='admin'
                    subIndustry={selectedSubIndustry}
                    expertises={selectedExpertiseList}
                    companyId={companyId}
                    setInputFieldData={() => { }}
                    setAllQuestionsData={() => { }}
                    setValidationErrorMsg={() => { }}
                    setAnswerErrors={() => { }}
                    fileStatus="expertise"
                    setNo={() => { }}
                  // classification={classification}
                  />
                </div> : ""}

              {/* {selectedSubIndustry.filter(item => item.id === 7).length === 0 ?
                <div className="customSecionCard">
                  <QuestionsList
                    companyData={companyData}
                    userStatus='admin'
                    subIndustry={selectedSubIndustry}
                    expertises={selectedExpertiseList}
                    companyId={companyId}
                    setInputFieldData={() => { }}
                    setAllQuestionsData={() => { }}
                    setValidationErrorMsg={() => { }}
                    setAnswerErrors={() => { }}
                    fileStatus="expertise"
                    setNo={() => { }}
                  >
                  </ QuestionsList>
                </div> : ""} */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div> : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  companyId: state.company.data.id,
  focus: state.company.data.companyData[0],
  classification: state.company.data?.companySettings[0]?.classification,
  expertises: findExpertiseV2(state.company.data).map(a => a.id),
  expertisestoCheckedValues: findExpertiseV3(state.company.data),
  specializationList: state.data.specializationList,
  companyData: state.company.data,
  subIndustry: state.company.data.subIndustry,
  // expertiseList: state.data.expertiseList,    // comment reason 101
  auth: state.auth
});

export default connect(mapStateToProps, {
  // getExpertiseList,   // comment reason 101
  updateCompany,
  updateSelectedExpertises,
  updateSelectedExpertisesV2
})(Expertise);
