import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Skeleton} from "antd";
import { Link } from "react-router-dom";
import { getNetworkNewMembers } from "actions/dataActions";
import { SkeltonLoadingPromoTab } from "./SkeltonLoadingPromoTab";


const Members = (/* props */) => {
	// const { data } = props

	// replace this code from parent to child , because we want, network api loaded when promo is active tab
	const [data, setData] = useState([])

	useEffect(() => {
		
			getNetworkNewMembers().then(res => {
				let response = res["hydra:member"]
				response = response.slice(0, 5)
				setData(response)
			}).catch(err => console.log(err))
		
		
	}, [])
	// replace this code from parent to child , because we want, network api loaded when promo is active tab
	return (
		<div className="text-white memberRight">
			<h2 className="text-white mt-1" style={{ textAlign:"center",borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>New Members</h2>
			{data && data.length  ?
				data.map((value, index) => {
					const { city, companyName, countries, coordinator, id ,logo} = value
					return (
						<div key={index} className="members-data">
							<Row gutter={16} type="flex" align="top" className="mt-4 pb-3" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
								<Col span={4} className="mt-2">
									{
										logo.length > 0 && <Avatar src={logo[0].path} size={54} icon="user" />
									}
								</Col>
								<Col span={20} className="text-left">
									{companyName && <Link to={`/company-profile/${id}`}><h2 className="mb-0 text-white member-header">{companyName}</h2></Link>}
									{city && countries && <span>{city}, {countries.countryname}</span>}
									<br />
									{coordinator?.name &&
										<>
											<span><strong>NAP CO-ordinator:</strong></span>
											<br />
											<span>{coordinator.name}</span>
										</>
									}
								</Col>
							</Row>
						</div>
					)
				})
				: <SkeltonLoadingPromoTab itemsPerPage={3}/>}
		</div>
	);
};

export default Members;
