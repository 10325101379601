import React from "react";
import styled from "@emotion/styled";

const EditCompanySkeleton = styled("div")`
  .skeleton-line {
    width: 100%;
  }
`;

const Sidebar = styled("div")`
  width: 260px;
  padding: 20px 30px 0 0;
  margin-right: 30px;

  .skeleton-line {
    margin-bottom: 40px;
  }
`;

export default function SkeletonLoading() {
  return (
    <EditCompanySkeleton>
      <div className="company-header container-fluid f">
        <div className="company-logo-box" style={{ flexShrink: 0 }}>
          <div className="skeleton-img"></div>
        </div>
        <div className="skeleton-line" style={{ width: "30%" }}></div>
      </div>
      <div className="container-fluid">
        <div
          style={{
            height: "60px",
            marginBottom: "31px",
            borderBottom: "1px solid #eee",
            paddingTop: "30px"
          }}
        >
          <div className="skeleton-line" style={{ width: "30%" }}></div>
        </div>
        <div style={{ display: "flex" }}>
          <Sidebar>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </Sidebar>
          <div style={{ flexGrow: 2, height: "500px", padding: "20px" }}>
            <div className="skeleton-line"></div>
          </div>
        </div>
      </div>
    </EditCompanySkeleton>
  );
}
