import {
  form1Submited,
  getCountryCities,
  showRegisterLoading,
  submitForm1,
  uploadFileAsync,
} from "actions/commonActions";
import { updateCompany } from "actions/companyActions";
import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  message,
  Row,
  Select,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import regex from "utils/regex";
import NumberFormat from "react-number-format";

const dateFormat = "DD/MM/YYYY";

const employesNumberList = [
  {
    label: "1-10",
    value: "1-10",
  },
  {
    label: "11-25",
    value: "11-25",
  },
  {
    label: "26-50",
    value: "26-50",
  },
  {
    label: "50-100",
    value: "50-100",
  },
  {
    label: "100-200",
    value: "100-200",
  },
  {
    label: "200+",
    value: "200+",
  },
];

const CompanyInfo = (props) => {
  const [loading, setLoading] = useState(true);

  const [logo, setLogo] = useState({});
  const [logoUri, setLogoUri] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [cities, setCitites] = useState([]);
  const [bankCities, setBankCitites] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [addressLine1, setAddressLine1] = useState([]);
  const [addressLine2, setAddressLine2] = useState([]);
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [wno, setWNo] = useState("");
  const [qqDialCode, setQqDialCode] = useState("");
  // const [fno, setFNo] = useState("");
  const [employesCount, setEmployeeCount] = useState("");
  // const [establishedIn, setEstablishedIn] = useState(new Date());
  const [establishedIn, setEstablishedIn] = useState(null);
  const [registeredCapitalAmt, setRegisteredCapitalAmt] = useState("");
  const [turnOver, setTurnOver] = useState("");
  const [companyDesc, setCompanyDesc] = useState("");
  const [legalRName, setLegalRName] = useState("");
  const [legalEmail, setLegalEmail] = useState("");
  const [companyInfoId, setCompanyInfoId] = useState(0);
  const [companyBankInfoId, setCompanyBankInfoId] = useState(0);
  const [classification, setClassification] = useState("");

  console.log("establishedIn///////////////////////////", establishedIn)

  /** Bank Information */
  const [bankName, setBankName] = useState("");
  // const [accNum, setAccNum] = useState(0);
  const [bankCountry, setBankCountry] = useState(null);
  const [bankCity, setBankCity] = useState(null);
  const [contactPerson, setContactPerson] = useState("");
  const [bankEmail, setBankEmail] = useState("");
  const [bankTelephone, setBankTelephone] = useState("");
  const [bankDialCode, setBankDialCode] = useState("");

  /** Certificates */
  const [selectedCerts, setSelectedCerts] = useState([
    { acreditation: "", refNo: "" },
  ]);

  const { Option } = Select;
  const { TextArea } = Input;
  const {
    companyData,
    countryList,
    certificateList,
    submitForm1,
    form1SubmittedAction,
    updateCompanyAction,
    uploadFileAsyncAction,
    showRegisterLoadingAction,
    registerLoading,
    submitForm1Action,
  } = props;
  useEffect(() => {
    if (submitForm1 && !registerLoading) {
      submitForm();
    }
  }, [submitForm1]);

  const validateCompanyDetails = () => {
    /** validate company details */
    if (!companyName /* || !companyName.match(regex.GENERAL_REGER) */) {
      return {
        message: "Enter valid company name",
        isValidated: false,
        id: "companyName",
      };
    }
    if (!logoUri) {
      return {
        message: "Please upload logo",
        isValidated: false,
        id: "logoUri",
      };
    }
    if (!addressLine1 /* || !addressLine1.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL) */) {
      return {
        message: "Please enter valid address Line 1",
        isValidated: false,
        id: "addressLine1",
      };
    }
    /* if (addressLine2  && !addressLine2.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL) ) {
      return {
        message: "Please enter valid address Line 2",
        isValidated: false,
      };
    } */
    if (!website || !website.match(regex.WEBSITE_WITH_SPECIAL_SYMBOL)) {
      return {
        message: "Please enter valid website",
        isValidated: false,
        id: "website",
      };
    }
    if (!email || !email.match(regex.EMAIL_WITH_SPECIAL_SYMBOL)) {
      return {
        message: "Please enter valid email",
        isValidated: false,
        id: "email",
      };
    }
    if (!telephone || !telephone.match(regex.PHONE_WITH_SPECIALSYMBOLS)) {
      return {
        message: "Please enter valid telephone number",
        isValidated: false,
        id: "telephone",
      };
    }
    if (!employesCount) {
      return {
        message: "Please select employee count",
        isValidated: false,
        id: "employesCount",
      };
    }
    if (!establishedIn) {
      return {
        message: "Please select date of establishment",
        isValidated: false,
        id: "establishedIn",
      };
    }
    if (/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0) {
      if (
        !registeredCapitalAmt /* ||
        !registeredCapitalAmt.match(regex.NUMBER1) */
      ) {
        return {
          message: "Please enter valid registered capital amount",
          isValidated: false,
          id: "registeredCapitalAmt",
        };
      }
      if (!turnOver /* || !turnOver.match(regex.DECIMALNUMBER) */) {
        return {
          message: "Please enter valid turnover",
          isValidated: false,
          id: "turnOver",
        };
      }
    }
    if (!companyDesc) {
      return {
        message: "Please enter valid company description",
        isValidated: false,
        id: "companyDesc",
      };
    }
    /** validate bank information */
    if (/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0) {
      // if (!legalRName || !legalRName.match(regex.ALPHANUMERICWITHSPACES)) {
      if (!legalRName /* || !legalRName.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL) */) {
        return {
          message: "Please enter valid legal name",
          isValidated: false,
          id: "legalRName",
        };
      }
      if (!legalEmail || !legalEmail.match(regex.EMAIL_WITH_SPECIAL_SYMBOL)) {
        return {
          message: "Please enter valid legal email",
          isValidated: false,
          id: "legalEmail",
        };
      }
      if (!wno || !wno.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL)) {
        return {
          message: "Please enter valid whatsapp number",
          isValidated: false,
          id: "wno",
        };
      }
      if (!qqDialCode) {
        return {
          message: "Please enter valid whatsapp dial code",
          isValidated: false,
          id: "qqdialcode",
        };
      }
    }
    if (/* classification === "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length !== 0) {
      /*  if (!fno || !fno.match(regex.PHONE_WITH_SPECIALSYMBOLS)) {
         return {
           message: "Please enter valid Fax number",
           isValidated: false,
           id: "fno",
         };
       } */
      if (!wno || !wno.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL)) {
        return {
          message: "Please enter valid whatsapp number",
          isValidated: false,
          id: "wno",
        };
      }
      if (!qqDialCode) {
        return {
          message: "Please enter valid whatsapp dial code",
          isValidated: false,
          id: "qqdialcode",
        };
      }
    }


    /** validate selected certificates */
    if (selectedCerts.length === 0) {
      return { message: "Please add a certificate", isValidated: false };
    }
    let isValidCert = true;
    selectedCerts.map((cert, index) => {
      if (!cert.acreditation || !cert.refNo) {
        isValidCert = false;
        return;
      }
    });
    if (!isValidCert) {
      return {
        message: "Please enter valid certificate details",
        isValidated: false,
      };
    }

    /** validate bank details */
    if (/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0) {
      // if (!bankName || !bankName.match(regex.ALPHABETSONLYWITHSPACES)) {
      if (!bankName /* || !bankName.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL) */) {
        return {
          message: "Please enter valid bank name",
          isValidated: false,
          id: "bankName",
        };
      }
      if (!bankCountry) {
        return {
          message: "Please select bank country",
          isValidated: false,
          id: "bankCountry",
        };
      }
      if (!bankCity) {
        return {
          message: "Please select bank city",
          isValidated: false,
          id: "bankCity",
        };
      }
      if (
        !contactPerson /* ||
        !contactPerson.match(regex.ALPHABETSONLYWITHSPACES) */
      ) {
        return {
          message: "Please enter valid contact person",
          isValidated: false,
          id: "contactPerson",
        };
      }
      if (!bankEmail || !bankEmail.match(regex.EMAIL_WITH_SPECIAL_SYMBOL)) {
        return {
          message: "Please enter valid bank email",
          isValidated: false,
          id: "bankEmail",
        };
      }
      if (
        !bankTelephone ||
        !bankTelephone.match(regex.ALPHANUMERICWITHSPECIAL_SYMBOL)
      ) {
        return {
          message: "Please enter valid bank telephone",
          isValidated: false,
          id: "bankTelephone",
        };
      }
      // if (!accNum || !accNum.match(regex.ONLYNUMERIC)) {
      //   return {
      //     message: "Please enter valid account number",
      //     isValidated: false,
      //     id: "accNum",
      //   };
      // }
    }
    return { message: "", isValidated: true };
  };

  const submitForm = async () => {
    const validationRes = validateCompanyDetails();
    if (!validationRes.isValidated) {
      message.error(validationRes.message);
      submitForm1Action(false);
      if (validationRes.id) {
        document.getElementById(validationRes.id).style = "border-color: red";
      }

      return;
    }
    showRegisterLoadingAction(true);

    const _selectedCerts = selectedCerts.map((cert, index) => {
      if (cert.id) {
        return { ...cert, id: `v1/licences/${cert.id}` };
      } else {
        return { ...cert };
      }
    });

    let obj = {
      companyName: companyName,
      logo: [logoUri],

      companyInfo: [
        {
          id: `v1/company_infos/${companyInfoId}`,
          address1: addressLine1,
          address2: addressLine2,
          companyWebsite: website,
          companyEmail: email,
          companyTel: telephone,
          telDialCode: dialCode,
          employesNumber: employesCount,
          establishement: establishedIn,
          description: companyDesc,
        },
      ],
      licences: _selectedCerts,
    };

    if (/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0) {
      obj["companyInfo"][0]["registeredCapital"] = registeredCapitalAmt;
      obj["companyInfo"][0]["fiscalTurnover"] = turnOver;
      obj["companyInfo"][0]["legalRepresentative"] = legalRName;
      obj["companyInfo"][0]["legalRepresentativeEmail"] = legalEmail;
      obj["companyInfo"][0]["qq"] = wno;
      obj["companyInfo"][0]["qqDialCode"] = qqDialCode;

      obj["companyBankInfo"] = [
        {
          id: `v1/company_bank_infos/${companyBankInfoId}`,
          bankName: bankName,
          bankCountry: {
            id: `v1/countries/${bankCountry}`,
          },
          bankCity: bankCity + "",
          bankPerson: contactPerson,
          bankEmail: bankEmail,
          bankTel: bankTelephone,
          dialCode: bankDialCode,
          // referenceBankAccnumber: accNum,
        },
      ];
    } else {
      // obj["companyInfo"][0]["companyFax"] = fno;
      obj["companyInfo"][0]["qq"] = wno;
      obj["companyInfo"][0]["qqDialCode"] = qqDialCode;
    }
    const updateResponse = await updateCompanyAction(obj, companyId);
    if (updateResponse) {
      form1SubmittedAction(true);
      showRegisterLoadingAction(false);
    }
  };

  useEffect(() => {
    if (companyData) {
      const {
        companyName,
        logo,
        cityId,
        countries,
        branch,
        companyInfo,
        companyBankInfo,
        licences,
        id,
        companySettings,
      } = companyData;
      setCompanyId(id);
      setCompanyName(companyName);
      if (logo.length > 0) {
        setLogo(logo[0]);
        setLogoUri(`/v1/logos/${logo[0]?.id}`);
      }
      if (countries) {
        onChangeCountry(countries.id);
      }
      cityId && setCity(parseInt(cityId));
      if (branch) {
        setBranches(branch);
        setSelectedBranch(branch.map((b) => b.id));
      }

      if (companyInfo.length > 0) {
        const _companyInfo = companyInfo[0];
        const {
          address1,
          address2,
          companyWebsite,
          companyEmail,
          telDialCode,
          companyTel,
          companyFax,
          employesNumber,
          establishement,
          registeredCapital,
          fiscalTurnover,
          legalRepresentative,
          legalRepresentativeEmail,
          description,
          qq,
          id,
          qqDialCode
        } = _companyInfo;

        setAddressLine1(address1);
        setAddressLine2(address2);
        setWebsite(companyWebsite);
        setEmail(companyEmail);
        setDialCode(telDialCode);
        setTelephone(companyTel);
        setEmployeeCount(employesNumber);

        setEstablishedIn(establishement || '')
        setRegisteredCapitalAmt(registeredCapital);
        setTurnOver(fiscalTurnover);
        setCompanyDesc(description);
        setLegalRName(legalRepresentative);
        setLegalEmail(legalRepresentativeEmail);
        setWNo(qq);
        setQqDialCode(qqDialCode);
        setCompanyInfoId(id);
        // setFNo(companyFax);
      }

      if (companyBankInfo.length > 0) {
        const _companyBankInfo = companyBankInfo[0];
        const {
          bankName,
          bankCountry,
          bankCity,
          bankPerson,
          bankEmail,
          bankTel,
          // referenceBankAccnumber,
          id,
          dialCode,
        } = _companyBankInfo;
        setBankName(bankName);
        // setAccNum(referenceBankAccnumber);
        bankCity && setBankCity(parseInt(bankCity));
        setContactPerson(bankPerson);
        setBankEmail(bankEmail);
        setBankDialCode(dialCode);
        setBankTelephone(bankTel);
        setCompanyBankInfoId(id);
        if (bankCountry) {
          onChangeBankCountry(bankCountry.id);
        }
        if (dialCode) {

        }
      }

      if (companySettings) {
        const _companySettings = companySettings[0];
        const { id, classification } = _companySettings;
        setClassification(classification);
      }

      if (licences.length > 0) {
        setSelectedCerts(licences);
      }
      setLoading(false);
    }
  }, [companyData]);

  useEffect(() => {
    if (countryList) {
      setCountriesList(countryList);
      if (country) {
        const CountryObj = countryList.filter(item => item.id === country)
        handleCountryCodeSelect(CountryObj[0])
      }
    }
  }, [countryList, country]);

  useEffect(() => {
    if (bankCountry) {
      const BankCountryObj = countryList.filter(item => item.id == bankCountry)
      handleBankCountryCodeSelect(BankCountryObj[0])
    }
  }, [countryList, bankCountry])

  const onChangeCountry = async (value) => {
    setCountry(value);
    await getCities(value);
  };

  const getCities = async (value) => {
    const response = await getCountryCities(value);
    if (response) {
      setCitites(response["hydra:member"]);
    }
  };

  const onChangeCity = (value) => {
    setCity(value);
  };

  const onChangeBankCountry = async (value) => {
    const BankCountryObj = countryList.filter(item => item.id == bankCountry)
    handleBankCountryCodeSelect(BankCountryObj[0])
    setBankCountry(value);
    await getBankCities(value);
  };

  const getBankCities = async (value) => {
    const response = await getCountryCities(value);
    if (response) {
      setBankCitites(response["hydra:member"]);
    }
  };

  const onChangeBankCity = (value) => {
    setBankCity(value);
  };

  const selectFile = async (e) => {
    showRegisterLoadingAction(true);
    let file = e.currentTarget.files[0];
    // setState({
    //     imageName: file["name"]
    // })
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        let _logo = { ...logo };
        _logo["path"] = reader.result;
        setLogo(_logo);
        // setImageUrl(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
      const uploadResponse = await uploadFileAsyncAction(file, "logos");
      if (uploadResponse) {
        const { id } = uploadResponse;
        setLogoUri(`/v1/logos/${id}`);
        showRegisterLoadingAction(false);
      }
    }
  };

  const changeCertificate = (key, index) => {
    const list = [...selectedCerts];
    list[index]["acreditation"] = key;
    setSelectedCerts(list);
  };
  const changeCertRefNum = (value, index) => {
    const list = [...selectedCerts];
    list[index]["refNo"] = value;
    setSelectedCerts(list);
  };

  const addCertificate = () => {
    setSelectedCerts([...selectedCerts, { acreditation: "", refNo: "" }]);
  };
  const deleteCertificate = (index) => {
    let list = [...selectedCerts];
    list.splice(index, 1);
    setSelectedCerts(list);
  };

  const selectDate = (_, stringDate) => {
    setEstablishedIn(stringDate);
    // props.updateCompanyInfo({ establishement: stringDate }, props.companyId);
  };
  const isObject = (value) => {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    );
  }
  const handleCountryCodeSelect = (value) => {
    if (isObject(value)) {
      setDialCode(value?.dialCode);
    } else {
      setDialCode(value);
    }
  }
  const handleQqCountryCodeSelect = (value) => {
    if (isObject(value)) {
      setQqDialCode(value?.dialCode);
    } else {
      setQqDialCode(value);
    }
  }
  const handleBankCountryCodeSelect = (value) => {
    if (isObject(value)) {
      setBankDialCode(value.dialCode);
    } else {
      setBankDialCode(value);
    }
  }

  const BankCountryCodeSelect = (
    <Select showSearch style={{ width: 90 }}
      value={bankDialCode}
      onChange={handleBankCountryCodeSelect}
      size="large">
      {countriesList.map((item, index) => {
        return (
          <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
            {item.dialCode ? item.dialCode : `no code${item.id}`}
          </Option>
        );
      })}
    </Select>
  );

  const QqCountryCodeSelect = (
    <Select showSearch style={{ width: 90 }}
      value={qqDialCode}
      onChange={handleQqCountryCodeSelect}
      size="large">
      {countriesList.map((item, index) => {
        return (
          <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
            {item.dialCode ? item.dialCode : `no code${item.id}`}
          </Option>
        );
      })}
    </Select>
  );
  const CountryCodeSelect = (
    <Select showSearch style={{ width: 90 }}
      value={dialCode}
      onChange={handleCountryCodeSelect}
      size="large">
      {
        countriesList.map((item, index) => {
          return (
            <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
              {item.dialCode ? item.dialCode : `no code${item.id}`}
            </Option>
          );
        })}
    </Select>
  );

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="internalFormSection">
          <Row className="firstRow">
            <Col sm={16} lg={16} xs={16}>
              <Row className="formRow">
                <Col lg={16} sm={16} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Company name</label>
                    <Input
                      size="large"
                      placeholder="Company Name LTD"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                      id="companyName"
                    />
                  </div>
                </Col>
                <Col lg={8} sm={8} xs={24} className="fileUploaderContainer">
                  <div id="logoUri" className="fileUploader">
                    {logo?.path ? (
                      <img src={logo.path} className="selectedlogo" />
                    ) : (
                      <>
                        <Icon type="plus" />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </>
                    )}
                    <input
                      type="file"
                      className="fileSelector"
                      onChange={(e) => selectFile(e)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={8} lg={8} xs={8}>
              <div className="instructionSection">
                <label
                  htmlFor="normal_Register_username"
                  className="ant-form-item-required"
                  title="Company name"
                >
                  LOGO
                </label>
                <div className="validationInst">
                  Recomanded size
                  <br /> 250x250px less than 1MB
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="forHeading">Company Details</div>
            </Col>
          </Row>
          <Row>
            <Col sm={16} lg={16} xs={16}>
              <Row className="formRow">
                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Country</label>
                    <Select
                      showSearch
                      disabled
                      placeholder="Select a Country"
                      optionFilterProp="children"
                      onChange={onChangeCountry}
                      value={country}
                      id="country"
                      defaultValue={country}
                    >
                      {countriesList?.map((country, index) => {
                        return (
                          <Option value={country.id} key={`country_${index}`}>
                            {country.countryname}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">City</label>
                    <Select
                      showSearch
                      disabled
                      placeholder="Select a City"
                      optionFilterProp="children"
                      onChange={onChangeCity}
                      value={city}
                      id="city"
                      defaultValue={city}
                    >
                      {cities?.map((city, index) => {
                        return (
                          <Option value={city.id} key={`city_${index}`}>
                            {city.cityName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
                <Col lg={24} sm={24} xs={24}>
                  <div className="itemField extraWidth">
                    <label className="requiredLbl">Branches</label>
                    <Select
                      mode="multiple"
                      placeholder="Please select Branches"
                      disabled
                      value={selectedBranch}
                      defaultValue={selectedBranch}
                    >
                      {branches.map((branchInfo, index) => {
                        return (
                          <Option value={branchInfo.id} key={`branch_${index}`}>
                            {branchInfo.branchName || branchInfo.branchCity}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={8} lg={8} xs={8}>
              <div className="instructionSection">
                <label
                  htmlFor="normal_Register_username"
                  className="ant-form-item-required"
                  title="Company name"
                >
                  ADDRESS
                </label>
                <div className="validationInst">
                  Disabled information is edited only by admin, please contact
                  bdm@neutralairpartner.com to request change of this info
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={16} lg={16} xs={16}>
              <Row>
                <Col lg={24} sm={24} xs={24}>
                  <div className="itemField extraWidth">
                    <label className="requiredLbl">Address</label>
                    <Input
                      size="large"
                      placeholder="Line 1"
                      value={addressLine1}
                      id="addressLine1"
                      onChange={(e) => setAddressLine1(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={24} sm={24} xs={24}>
                  <div className="itemField extraWidth">
                    <label className="requiredLbl"></label>
                    <Input
                      size="large"
                      placeholder="Line 2"
                      value={addressLine2}
                      id="addressLine2"
                      onChange={(e) => setAddressLine2(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Website</label>
                    <Input
                      size="large"
                      placeholder="www.web.com"
                      value={website}
                      id="website"
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Email</label>
                    <Input
                      size="large"
                      placeholder="john@doe.com"
                      value={email}
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={8} lg={8} xs={8}>
              {/* <div className="instructionSection">
                        <label htmlFor="normal_Register_username" className="ant-form-item-required" title="Company name">LOGO</label>
                        <div className="validationInst">
                            Recomanded size<br /> 250x250px less than 1MB
                        </div>
                                    </div> */}
            </Col>
          </Row>
          <Row>
            <Col sm={16} lg={16} xs={16}>
              <Row>
                <Col lg={12} sm={12} xs={24}>
                  {/* <div className="itemField">
                    <label className="requiredLbl">Telephone</label>
                    <Input
                      size="large"
                      placeholder="Telephone"
                      value={telephone}
                      id="telephone"
                      id="telephone"
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div> */}

                  <div className="itemField">
                    <label className="requiredLbl">Telephone</label>
                    <Input
                      size="large"
                      value={telephone}
                      id="telephone"
                      onChange={(e) => setTelephone(e.target.value)}
                      addonBefore={CountryCodeSelect}
                      placeholder="Enter phone number" />
                    {/* <Input
                      size="large"
                      placeholder="Telephone"
                      value={telephone}
                      id="telephone"
                      onChange={(e) => setTelephone(e.target.value)}
                    /> */}
                  </div>

                </Col>

                {/* classification === "Vendor" */
                  /*   companyData?.subIndustry?.filter(item => item.id === 7)?.length !== 0 ? (
                     <Col lg={12} sm={12} xs={24}>
                       <div className="itemField">
                         <label className="requiredLbl">FAX</label>
                         <Input
                           size="large"
                           placeholder="Line 2"
                           value={fno}
                           id="fno"
                           onChange={(e) => setFNo(e.target.value)}
                         />
                       </div>
                     </Col>
                   ) : ( */
                  <Col lg={12} sm={12} xs={24}>
                    <div className="itemField">
                      <label className="requiredLbl">Whatsapp</label>
                      <Input
                        size="large"
                        placeholder="Line 2"
                        value={wno}
                        id="wno"
                        addonBefore={QqCountryCodeSelect}
                        onChange={(e) => setWNo(e.target.value)}
                      />
                    </div>
                  </Col>
                  // )
                }

                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Number of employees</label>
                    <Select
                      placeholder="Please select employees count"
                      value={employesCount}
                      id="employesCount"
                      defaultValue={employesCount}
                      onChange={(value) => setEmployeeCount(value)}
                    >
                      {employesNumberList.map((obj, index) => {
                        return (
                          <Option key={`empCount_${index}`} value={obj.value}>
                            {obj.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={24}>
                  <div className="itemField">
                    <label className="requiredLbl">Year of incorporation</label>
                    <DatePicker
                      onChange={selectDate}
                      allowClear={false}
                      defaultValue={establishedIn ? moment(establishedIn, "DD/MM/YYYY") : ''}
                      format={dateFormat}
                      id="establishedIn"
                    />
                  </div>
                </Col>
                {/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0 && (
                  <>
                    <Col lg={12} sm={12} xs={24}>
                      {/*  <div className="itemField">
                        <label className="requiredLbl">
                          Registered capital (US$)
                        </label>
                        <Input
                          size="large"
                          placeholder="000.000"
                          value={registeredCapitalAmt}
                          id="registeredCapitalAmt"
                          onChange={(e) =>
                            setRegisteredCapitalAmt(e.target.value)
                          }
                        />
                      </div> */}
                      <div className="itemField">
                        <label>
                          <span style={{ color: "red" }}>*</span> Registered capital (US$)
                        </label>
                        <div className="inputHolder">
                          <NumberFormat
                            size="large"
                            placeholder="000.000"
                            customInput={Input}
                            thousandSeparator={true}
                            prefix={"$"}
                            id="registeredCapitalAmt"
                            name="registeredCapitalAmt"
                            defaultValue={registeredCapitalAmt}
                            onChange={(e) =>
                              setRegisteredCapitalAmt(e.target.value)
                            }
                          ></NumberFormat>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      {/*  <div className="itemField">
                        <label className="requiredLbl">
                          Last fiscal year turnover (US$)
                        </label>
                        <Input
                          size="large"
                          placeholder="000.000"
                          value={turnOver}
                          id="turnOver"
                          onChange={(e) => setTurnOver(e.target.value)}
                        />
                      </div> */}
                      <div className="itemField">
                        <label>
                          <span style={{ color: "red" }}>*</span>  Last fiscal year turnover (US$)
                        </label>
                        <div className="inputHolder">
                          <NumberFormat
                            size="large"
                            placeholder="000.000"
                            customInput={Input}
                            thousandSeparator={true}
                            prefix={"$"}
                            id="turnOver"
                            name="turnOver"
                            defaultValue={turnOver}
                            onChange={(e) => setTurnOver(e.target.value)}
                          ></NumberFormat>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
                <Col lg={24} sm={24} xs={24}>
                  <div className="itemField extraWidth">
                    <label className="requiredLbl">
                      Company short description
                    </label>
                    <TextArea
                      rows={4}
                      value={companyDesc}
                      onChange={(e) => setCompanyDesc(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={8} lg={8} xs={8}>
              <div className="instructionSection">
                {/* <label htmlFor="normal_Register_username" className="ant-form-item-required" title="Company name">LOGO</label>
                                    <Icon type="message" theme="filled" className="messageIcon" />
                                    <div className="validationInst">
                                        Please provide your personal cell phone number. This number will be used in relevant groups, so you can receive updates and notifications from the network.
                                                </div> */}
              </div>
            </Col>
          </Row>
          {/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0 && (
            <>
              <Row>
                <Col>
                  <div className="forHeading">Legal Representative</div>
                </Col>
              </Row>
              <Row>
                <Col sm={16} lg={16} xs={16}>
                  <Row>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">
                          First and last name
                        </label>
                        <Input
                          size="large"
                          placeholder="John Doe"
                          value={legalRName}
                          id="legalRName"
                          onChange={(e) => setLegalRName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">
                          Legal representative email
                        </label>
                        <Input
                          size="large"
                          placeholder="john@doe.com"
                          value={legalEmail}
                          id="legalEmail"
                          onChange={(e) => setLegalEmail(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <div className="forHeading">Certificates</div>
            </Col>
          </Row>
          <Row>
            <Col sm={16} lg={16} xs={16}>
              {selectedCerts.map((cert, index) => {
                return (
                  <Row key={`cert_${index}`} className="addRemoveRow">
                    <Col lg={22} sm={22} xs={22}>
                      <Row>
                        <Col lg={12} sm={12} xs={24}>
                          <div className="itemField">
                            <label className="requiredLbl">
                              Certificate type
                            </label>
                            <Select
                              showSearch
                              placeholder="Certificate type"
                              optionFilterProp="children"
                              onChange={(value) =>
                                changeCertificate(value, index)
                              }
                              value={cert.acreditation}
                              id="acreditation"
                              defaultValue={cert.acreditation}
                            >
                              {certificateList?.map((certificate, _index) => {
                                return (
                                  <Option
                                    value={certificate.certificatesValue}
                                    key={`certificate_${_index}`}
                                  >
                                    {certificate.certificatesKey}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col lg={12} sm={12} xs={24}>
                          <div className="itemField">
                            <label className="requiredLbl">
                              Certificate Number
                            </label>
                            <Input
                              size="large"
                              placeholder="XXXXXX"
                              value={cert.refNo}
                              id="refNo"
                              onChange={(e) =>
                                changeCertRefNum(e.target.value, index)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={2} sm={2} xs={2}>
                      <label className="lbl">&nbsp;</label>
                      <Button
                        className="deleteIcon add-new-btn"
                        onClick={() => deleteCertificate(index)}
                      >
                        <Icon type="delete" />
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col lg={24} sm={24} xs={24} className="addMoreBtn">
                  <Button type="dashed" onClick={() => addCertificate()}>
                    <Icon type="plus" /> Add new
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={8} lg={8} xs={8}>
              <div className="instructionSection">
                <label
                  htmlFor="normal_Register_username"
                  className="ant-form-item"
                  title="Company name"
                ></label>
                <div className="validationInst">
                  Select one or more certificate with correponding reference
                  number
                </div>
              </div>
            </Col>
          </Row>
          {/* classification !== "Vendor" */ companyData?.subIndustry?.filter(item => item.id === 7)?.length === 0 && (
            <>
              <Row>
                <Col>
                  <div className="forHeading">Bank Information</div>
                </Col>
              </Row>
              <Row>
                <Col sm={16} lg={16} xs={16}>
                  <Row>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">Bank name</label>
                        <Input
                          size="large"
                          placeholder="Bank name"
                          value={bankName}
                          id="bankName"
                          onChange={(e) => setBankName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      {/* <div className="itemField">
                        <label className="requiredLbl">Account Number</label>
                        <Input
                          size="large"
                          placeholder="XXXXXX"
                          value={accNum}
                          id="accNum"
                          onChange={(e) => setAccNum(e.target.value)}
                        />
                      </div> */}
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">Country</label>
                        <Select
                          showSearch
                          placeholder="Select a Country"
                          optionFilterProp="children"
                          onChange={onChangeBankCountry}
                          value={bankCountry}
                          id="bankCountry"
                          defaultValue={bankCountry}
                        >
                          {countriesList?.map((country, index) => {
                            return (
                              <Option
                                value={country.id}
                                key={`country_${index}`}
                              >
                                {country.countryname}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">City</label>
                        <Select
                          showSearch
                          placeholder="Select a City"
                          optionFilterProp="children"
                          onChange={onChangeBankCity}
                          value={bankCity}
                          id="bankCity"
                          defaultValue={bankCity}
                        >
                          {bankCities?.map((city, index) => {
                            return (
                              <Option value={city.id} key={`city_${index}`}>
                                {city.cityName}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">Contact person</label>
                        <Input
                          size="large"
                          placeholder="Contact person"
                          value={contactPerson}
                          id="contactPerson"
                          onChange={(e) => setContactPerson(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      <div className="itemField">
                        <label className="requiredLbl">E-mail</label>
                        <Input
                          size="large"
                          placeholder="john@doe.com"
                          value={bankEmail}
                          id="bankEmail"
                          onChange={(e) => setBankEmail(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={24}>
                      {/* <div className="itemField">
                        <label className="requiredLbl">Telephone</label>
                        <Input
                          size="large"
                          placeholder="Telephone"
                          value={bankTelephone}
                          id="bankTelephone"
                          onChange={(e) => setBankTelephone(e.target.value)}
                        />
                      </div> */}
                      <div className="itemField">
                        <label className="requiredLbl">Telephone</label>
                        <Input
                          size="large"
                          placeholder="Telephone"
                          value={bankTelephone}
                          id="bankTelephone"
                          addonBefore={BankCountryCodeSelect}
                          onChange={(e) => setBankTelephone(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={8} lg={8} xs={8}></Col>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state.company.data,
    countryList: state.common.countries,
    certificateList: state.data.certificateList,
    submitForm1: state.common.submitForm1,
    registerLoading: state.common.registerLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    form1SubmittedAction: (value) => form1Submited(dispatch, value),
    updateCompanyAction: (data, id) => dispatch(updateCompany(data, id)),
    uploadFileAsyncAction: (file, target) => uploadFileAsync(file, target),
    showRegisterLoadingAction: (value) => showRegisterLoading(dispatch, value),
    submitForm1Action: (value) => submitForm1(dispatch, value),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
