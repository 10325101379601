import React from "react";
import { Collapse, Table } from "antd";

const { Panel } = Collapse;

const { Column } = Table;

function rates(rates, documents, renderDocuments) {
	const data = rates.map(rate => (
		{
			key: rate.id,
			id: '#',
			fee: rate.ratesFee,
			price: rate.ratesPrice
		}
	));

	return (
		<Collapse defaultActiveKey={['1']} bordered={false} expandIconPosition="right" className="rates-fees-collapse" style={{ marginTop: '2.5rem', backgroundColor: 'transparent' }}>
			<Panel header={<h2 className="mb-0">Rates/Tariffs</h2>} key="1">
				{data.length &&
				<Table dataSource={data} pagination={false}>
					<Column title="#" dataIndex="id" key="#" />
					<Column title="Fees" dataIndex="fee" key="fees" />
					<Column title="Price" dataIndex="price" key="price" />
				</Table>}
				{documents.length ? renderDocuments(documents) : null}
			</Panel>
		</Collapse>
	);
}

const Rates = ({ company, getSourceWiseDocument, renderDocuments }) => {
	const rateDocuments = getSourceWiseDocument(company.document, 'rates');

	return (company.rates.length || rateDocuments.length) ? rates(company.rates, rateDocuments, renderDocuments) : <></>;
};

export default Rates;
