import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorGlobalReducer from "./errorGlobalReducer";
import profileReducer from "./profileReducer";
import dataReducer from "./dataReducer";
import companyReducer from "./companyReducer";
import uploadReducer from "./uploadReducer";
import commonReducer from "./commonReducer";
import questionReducer from "./questionReducer.js";

export default combineReducers({
  auth: authReducer,
  errors: errorGlobalReducer,
  profile: profileReducer,
  data: dataReducer,
  company: companyReducer,
  upload: uploadReducer,
  common: commonReducer,
  question: questionReducer
});
