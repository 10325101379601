import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSpecializations } from "actions/dataActions";
import { updateCompany } from "actions/companyActions";
import CustomSelect from "components/ui-elements/Select";
import { api_v } from "utils/settings";
import { useDebouncedCallback } from "components/helper/Hooks";
import { message } from "antd";

const Tradelane = props => {
  const [valueBlank, setValueBlank] = useState(false)
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    props.getSpecializations();
  }, []);

  const updateTradelanes = values => {
    const data = {
      specializations: []
    };
    if (values?.length) {
      /*  eslint no-unused-expressions: off */
      values?.map(a => {
        data.specializations.push(`/${api_v}specializations/${a.id}`);
      });
    }
    props.updateCompany(data, props.companyId).then(() => {
      setLoading(false)
      message.success("Information has been updated");
    });
  };

  const [debouncedUpdate] = useDebouncedCallback(updateTradelanes, 800);

  const handleChange = values => {
    setLoading(true)
    if (values?.length !== 0 && values !== null) {
      if (values?.length > 3) {
        message.error("you can select only 3 specializations");
        setLoading(false)
        return;
      }
      debouncedUpdate(values);
      // setValueBlank(false)

    } else {
      console.log("hellog")
      debouncedUpdate(values);
      // setValueBlank(true)      
    }
  };

  const { tradelanes, specializationList, expertise, setValidationErrorTradelane } = props;

  useEffect(() => {
    if (valueBlank) {
      typeof setValidationErrorTradelane === "function" && setValidationErrorTradelane(true)
    } else {
      typeof setValidationErrorTradelane === "function" && setValidationErrorTradelane(false)
    }
  }, [valueBlank])


  useEffect(() => {
    if (tradelanes.length !== 0) {
      setValueBlank(false)
    } else {
      setValueBlank(true)
    }
  }, [tradelanes])

  return (
    <>
      {expertise ?
        <>
          {/* <div><label className="requiredLbl">Trade lane specialization</label></div> */}
          <CustomSelect
            maxOptions={3}
            isDisabled={loading}
            isClearable={false}
            className="customSelectWithChips"
            isMulti
            label={"Trade lane specialization"}
            required
            fileStatus="editProfile"
            onChange={handleChange}
            options={specializationList}
            getOptionLabel={option => option.specialization}
            getOptionValue={option => option.id}
            // defaultValue={valueBlank ? [] : tradelanes}
            value={tradelanes}
          />
          <p>Name maximum 3 of the trade lanes your company is specialized</p>
        </>
        :
        <div className="card">
          <div className="card-header">
            <h3>Tradelane specializations</h3>
          </div>
          <div className="card-body">
            <p>Name maximum 3 of the trade lanes your company is specialized</p>
            <CustomSelect
              className="customSelectWithChips"
              maxOptions={3}
              isClearable={false}
              isMulti
              onChange={handleChange}
              options={specializationList}
              getOptionLabel={option => option.specialization}
              getOptionValue={option => option.id}
              defaultValue={tradelanes}
            // value={tradelanes}
            />
          </div>
        </div>
      }
    </>
  );
};
const mapStateToProps = state => ({
  tradelanes: state.company.data.specializations,
  companyId: state.company.data.id,
  specializationList: state.data.specializationList
});

export default connect(mapStateToProps, { getSpecializations, updateCompany })(
  Tradelane
);
