import { Icon, Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

const FormWithNavigation = (props) => {

    const { activeIndex, tabs, onTabSwitch, onSubmit, onBackClick, registerLoading } = props;
   
    let displayComponent = () => {
        return (
            <div></div>
        )
    }
    return (
        <>
            <div className="formTopNavigation">
                {
                    tabs.map((tab, index) => {
                        const { name, tabIndex, component } = tab;

                        if (tabIndex === activeIndex) {
                            displayComponent = component;
                        }

                        const tabClass = tabIndex < activeIndex ? "topTab processedIndex" : "topTab";
                        return (
                            <React.Fragment key={`tab_${index}`}>
                                {
                                    tabIndex > 1 && <div className="spaceBar"></div>
                                }
                                <div className={tabClass} >
                                    {
                                        tabIndex < activeIndex
                                            ?
                                            <Icon type="check" />
                                            :
                                            tabIndex === activeIndex
                                                ?
                                                <div className="tabIndex filled">{tabIndex}</div>
                                                :
                                                <div className="tabIndex">{tabIndex}</div>
                                    }
                                    <div className="tabName">{name}</div>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </div>
            {
                displayComponent &&
                React.createElement(displayComponent, { ...props })
            }
            <div className="actionSection">
                {
                    activeIndex !== tabs[0].tabIndex
                        ?
                        <Button
                            type="default"
                            size="large"
                            loading={registerLoading}
                            htmlType="submit"
                            style={{ width: "30%" }}
                            onClick={() => onBackClick(activeIndex - 1)}
                        >
                            Previous
                        </Button>
                        :
                        <div></div>
                }
                {
                    activeIndex !== tabs.length &&
                    <Button
                        type="primary"
                        size="large"
                        className='create-btn'
                        loading={registerLoading}
                        htmlType="submit"
                        style={{ width: "30%" }}
                        onClick={() => onTabSwitch(activeIndex + 1)}
                    >
                        Next
                    </Button>
                }
                {
                    activeIndex === tabs.length &&
                    <Button
                        // className="submit-btn"
                        type="primary"
                        size="large"
                        // loading={loading}
                        htmlType="submit"
                        style={{ width: "30%" }}
                        onClick={() => onSubmit(activeIndex + 1)}
                    >
                        Submit
                    </Button>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        registerLoading: state.common.registerLoading
    }
}

export default connect(mapStateToProps)(FormWithNavigation);