import React, { useEffect, useState, useReducer } from 'react'
import { getCompanyPromos, getCompanySpecificPromos, updatePromoAction } from '../../../../../actions/dataActions'
import produce from "immer";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import PromoCard from "components/ui-elements/PromoCard";
import AddPromo from "components/promos/AddPromo"
import { Drawer } from "antd";
import activityPromos from "theme/assets/activity-promos.png";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const initialState = {
    promos: null,
    selectedRowKeys: [],
    loading: true,
    updating: false,
    contact: false,
    page: 1,
    total: 100
};

function reducer(state, action) {
    switch (action.type) {
        case "GET_DATA":
            return {
                ...state,
                promos: action.payload["hydra:member"],
                loading: false,
                total: action.payload["hydra:totalItems"],
                page: action.page
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: true
            };

        case "SET_UPDATING":
            return {
                ...state,
                updating: true
            };

        case "UPDATE_PROMO":
            return produce(state, draftState => {
                const promoIndex = state.promos.findIndex(
                    a => a.id === action.payload.id
                );

                draftState.promos[promoIndex].approved = action.payload.approved;
            });

        case "SET_SELECTED":
            return {
                ...state,
                selectedRowKeys: action.payload
            };

        case "CLEAR_SELECTED":
            return {
                ...state,
                selectedRowKeys: [],
                updating: false
            };
        default:
            throw new Error();
    }
}

const Activity = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { promos, page, total } = state;
    const [editData, setEditData] = useState({});
    const [isAdd, setIsAdd] = useState(false);


    useEffect(() => {
        fetchCompanyPromos(1)
    }, [])

    // Switch Pages
    const changePage = page => {
        window.scrollTo(0, 0);
        fetchCompanyPromos(page);
    };

    const fetchCompanyPromos = type => {
        dispatch({
            type: "SET_LOADING"
        });
        //  const date = moment().format("YYYY-MM-DD");
        const companyId = props.loadedCompanyId;
        getCompanySpecificPromos(companyId).then(data => {
            if (data) {
                dispatch({
                    type: "GET_DATA",
                    payload: data,
                    page
                });
            }
        });
    };

    const editPromo = (promo) => {
        setEditData(promo);
        setIsAdd(true);
    }

    const canclePromo = (promoId) => {
        const data = {
            status: "Archive",
            id: `/v1/my_promos/${promoId}`,
        };
        updatePromoAction(data, promoId).then(res => {
            fetchCompanyPromos(1);
            dispatch({ type: "UPDATE_PROMO", payload: res })
        })
    }

    const closeDrawer = () => {
        setEditData({});
        setIsAdd(false);
    }

    const onAddUpdate = () => {
        fetchCompanyPromos(1);
        setIsAdd(false);
    }
    // Render View -------------------------------------------------------------------------

    return (
        <div>
            <div className="container">
                {promos?.length ?
                    promos?.map((promo, index) => {
                        return (
                            <PromoCard
                                promoDetails={promo}
                                index={index}
                                activeTab={1}
                                editPromoCallback={(data) => editPromo(data)}
                                canclePromoCallback={(id) => canclePromo(id)}
                                displayActions={true}
                                key={`promoCard_${index}`} />
                        )
                    })
                    : <div className="activity-promo">
                        <img src={activityPromos}></img>
                        <div className="noActivity">
                            <h3>No available promotions</h3>
                            {/* <Link type="button" className="ant-btn ant-btn-primary" >
                                <i className="fa fa-pencil m-r-15" ></i>POST YOUR FIRST PROMO
                                </Link> */}
                        </div>
                    </div>
                }
                {
                    promos && promos?.length ?
                        <Pagination
                            onChange={changePage}
                            locale={localeInfo}
                            current={page}
                            defaultPageSize={10}
                            total={total}
                        ></Pagination> : null
                }
            </div>
            <Drawer
                width={800}
                destroyOnClose={true}
                closable={false}
                onClose={() => closeDrawer()}
                visible={isAdd} >
                <AddPromo onAddUpdateCallback={onAddUpdate} editableInfo={editData} />
            </Drawer>
        </div>
    )
}


const mapStateToProps = state => ({
    loadedCompanyId: state.company.data === null ? null : state.company.data.id,
  });

export default connect(mapStateToProps)(Activity)
