import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import CustomSelect from "components/ui-elements/Select";
import { Form, Input, Select, Button, Row, Col } from "antd";
import { getAllCurrenciesList, getCompany } from "actions/companyActions";

const { Option } = Select;

const TransportForm = (props) => {
  const dispatch = useDispatch();
  const [currencies, setCurrencies] = useState([]);
  const userData = useSelector((state) => state.auth.user);
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  useEffect(() => {
    getCurrencyList();
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch(getCompany(companyInfo.company));
    }
  }, [userData]);

  const getCurrencyList = async () => {
    const response = await getAllCurrenciesList();
    setCurrencies(response);
  };

  const { showImtBlock, setIsVisible } = props;
  // const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setIsVisible(false);
    setRequiredMarkType(requiredMarkValue);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        showImtBlock(values);
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <Form
      className="transportform"
      // form={form}
      layout="vertical"
      initialValues={{
        requiredMarkValue: requiredMark,
      }}
      onValuesChange={onRequiredTypeChange}
      requiredMark={requiredMark}
      onSubmit={onSubmit}
    >
      <Row className="i-m-box">
        <Col span={8} className="transportform-col">
          <Form.Item label="Your Company VAT">
            {getFieldDecorator("VatNo", {
              rules: [
                {
                  required: true,
                  message: "Please input Company VAT",
                },
              ],
            })(<Input placeholder="Your Company VAT" />)}
          </Form.Item>
        </Col>
        <Col span={8} className="transportform-col">
          <Form.Item label="Your Company No">
            {getFieldDecorator("CompanyNo", {
              rules: [
                {
                  required: true,
                  message: "Please input Company No",
                },
              ],
            })(<Input placeholder="Your Company No" />)}
          </Form.Item>
        </Col>
        <Col span={5} className="transportform-col">
          <Form.Item label="Currency">
            {getFieldDecorator("Currency", {
              rules: [
                {
                  required: true,
                  message: "Please choose Currency",
                },
              ],
            })(
              <CustomSelect
                placeholder={"Currency"}
                // value={country}
                // name="country"
                options={currencies}
                isClearable={true}
                getOptionLabel={(option) => option.code}
                getOptionValue={(option) => option.code}
                // onChange={this.handleSelect}
              ></CustomSelect>
            )}
            {/* <Select
            // value={value.currency}
            // onChange={this.handleCurrencyChange}
            >
              <Option S value="rmb">EUIR</Option>
              <Option value="dollar">Dollar</Option>
            </Select> */}
          </Form.Item>
        </Col>
        <Col span={3} className="transportform-col">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Start
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedTransportForm = Form.create({
  name: "transportform",
  onValuesChange(_, values) {
    _.setIsVisible(false);
  },
})(TransportForm);

export default connect(null, { TransportForm })(WrappedTransportForm);
