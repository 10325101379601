import React, { useEffect, useState } from "react";
import { Collapse, Row, Col } from "antd";
import OurTools from '../overview/OurTools'
import Licence from "./Licence";
import History from "./History";
import Offices from "./Offices";
import Destination from "./Destination";
import Bank from "./Bank";
import OtherDetails from "./OtherDetails";
import { base_url } from "utils/settings";
import UserQuestionAnswerList from "../overview/UserQuestionAnswerList";
import Network from "../overview/Network";
import { cleanObjectWithId } from 'utils/functions';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Accrediatations from "../overview/Accrediations";
// import defaultLogo from "theme/assets/defaultLogo.jpg";
import defaultProfileLogo from "theme/assets/img/profile-logo.png";
const { Panel } = Collapse;


const VendorCompany = ({ company, auth }) => {
	// const logoContent = useSelector((state) => state.auth.logoData);

	const expertiseWithIconNav = [1, 2, 3, 4, 11, 13];
	cleanObjectWithId(company?.companyBankInfo[0])
	cleanObjectWithId(company?.companyInfo[0])
	const logoContent = useSelector((state) => state.auth.logoData);
	const [logoArray, setLogoArray] = useState([]);

	useEffect(() => {
		if (logoContent?.length > 0) {
			const array = [];
			//   console.log(logoContent,"logoContent")
			const element = logoContent.forEach((element) =>
				element?.status === "1" ? array.push(element) : []
			);
			setLogoArray(array);
		}
	}, [logoContent]);

	const getIcon = (id) => {
		if (expertiseWithIconNav.includes(id)) {
			return 'nav.svg';
		}
		return 'nap.svg';
	};
	function getTitle(icon, title) {
		return (
			<div className="specializationBox">
				{/* <RightOutlined /> */}
				<span className="specializationSpan">
					{title}
					{/* <div style={{backgroundColor:'black'; }}> */}
					<img
						src={icon}
						// style={{ backgroundColor: 'black' }}
						// src={base_url + "/bundles/profile/images/" + icon}
						width="40"
						height="40"
						className="mr-2"
					/>
					{/* </div> */}

				</span>
			</div>
		);
	}
	function getTitleFor7(icon, title) {
		return (
			<div className="specializationBox">
				{/* <RightOutlined /> */}
				<span>
					<img
						src={icon}
						// style={{ backgroundColor: 'black' }}
						// src={base_url + "/bundles/profile/images/" + icon}
						width="40"
						height="40"
						className="mr-2"
					/>
					{title}
					{/* <div style={{backgroundColor:'black'; }}> */}

					{/* </div> */}

				</span>
			</div>
		);
	}
	console.log("company in vendor..", company)
	return (
		<div className="px-8">

			<Row>
				{/* ----------<<<<<<<<<<<<- start ->>>>>>>>>>---------------- */}
				{/* {company?.subIndustry?.filter(item => item.id === 7).length === 0 ?
					<>
						<Network companyData={company} />
					</>
					:
					<>	 */}
				{/* ---------THIS RENDER ONLY IN CASE OF "VENDOR"---------------- */}

				{/* {company.expertiese.length > 0 ?
							<>
								<h3>SPECIALIZATIONS</h3>
								<div className="Display">
									{company.expertiese.map((expertise) => {
										return (
											<Panel header={getTitleFor7(
												expertise?.subIndustry?.deactiveImg,
												expertise.name
											)}
												key={expertise.id}
											> */}
				{/* <UserQuestionAnswerList expertiseId={expertise.id} /> */}
				{/* </Row></Panel>
										)
									}

									)}
								</div>

							</> : ""}
					*/}
				<Col className="gutter-row" span={8}>
					<img src={company.logo[0]?.path ? company.logo[0]?.path : defaultProfileLogo} alt="logo" className={`${company.logo[0]?.path ? "company-logo-company-profile" : "default-company-profile-logo"}`} />
				</Col>
				{/* <h3>SPECIALIZATIONS</h3> */}
				<Col className="gutter-row" span={16}>
					<h3>Our Network/s</h3>
					<Network companyData={company} networkStatus="vendor" />
					{/* -------------<<<<<<<<<<<<<<<<<<<- start accredetion->>>>>>>>>>>>>>>>>>>>>>--------------- */}

					{company.licences?.length !== 0 && <Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
						className="paddingBottomExpertise">
						{/* -----------Accrediations----------------- */}
						<Col span={18}>
							{company.licences?.length !== 0 && (
								<Accrediatations data={company?.licences} />
							)}
						</Col>
					</Row>}
					{/* -------------<<<<<<<<<<<<<<<<<<<- end accredetion->>>>>>>>>>>>>>>>>>>>>>--------------- */}

					{/* -------------<<<<<<<<<<<<<<<<<<<- start partners->>>>>>>>>>>>>>>>>>>>>>--------------- */}
					{logoArray?.length !== 0 && <Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
						className="paddingBottomExpertise"
					>
						{/* -----------Partners----------------- */}

						<Col span={18}>
							{logoArray?.length !== 0 && (
								<OurTools />
							)}
						</Col>
					</Row>}
					{/* -------------<<<<<<<<<<<<<<<<<<<- end partners->>>>>>>>>>>>>>>>>>>>>>--------------- */}

				</Col>
				{/* -------------<<<<<<<<<<<<<<<<<<<- end ->>>>>>>>>>>>>>>>>>>>>>--------------- */}
				{/* </>} */}
				{/*   <Collapse
          style={{ backgroundColor: "#fff" }}
          activeKey={active}
          onChange={(e) => handleChange(e)}
        >
         
          {company.expertiese.length ? (
            <> */}
				{/* Air Cargo and Neutral Air Consolidator */}
				{/* {airNeutralExpertise(company.airIndustryExpertise)} */}
				{/* All other expertise */}
				{/*   {company.expertiese.map((expertise) => {
                return (
                  <Panel
                    header={getTitle(
                      // expertiseIcons[expertise?.subIndustry?.activeImg],
                      expertise?.subIndustry?.activeImg,
                      expertise.name
                    )}
                    showArrow={false}
                    key={expertise.id}
                    className="company-profile-expertise-panel"
                  >

                    <ExpertiseItem
                      expertiseId={expertise.id}
                      title={expertise.name}
                      companyId={company.id}
                    ></ExpertiseItem>
                  </Panel>
                );
              })} */}
				{/*    </>
          ) : null}
        </Collapse> */}
				{/* 				<ul
					className="list-blank"
					style={{ border: "1px solid #d9d9d9", cursor: "pointer" }}
				>
					{company.associateIndustryExpertise.length
						? company.associateIndustryExpertise.map((expertise) => (
							<li
								key={expertise.id}
								className="p-3"
								style={{
									borderBottom: "1px solid #d9d9d9",
									cursor: "pointer",
								}}
							>
								<img
									src={`${base_url}/bundles/profile/images/nap.svg`}
									width="30"
									height="30"
									className="mr-2"
								/>{" "}
								{expertise.industryExpertise}
							</li>
						))
						: null}
			
				</ul> */}
			</Row>

			{/* <Row>
				<h3>SPECIALIZATIONS</h3>
				<ul className="list-blank" style={{ border: '1px solid #d9d9d9', cursor: 'pointer' }}>
					{company.vendorIndustryExpertise.length 
					? company.vendorIndustryExpertise.map(expertise => (
						<li key={expertise.id} className="p-3" style={{ borderBottom: '1px solid #d9d9d9', cursor: 'pointer' }}><img src={base_url +'/bundles/profile/images/' + getIcon(expertise.id)} width="30" height="30" className="mr-2" /> {expertise.industryExpertise}</li>
					)) 
					: null}
				</ul>
			</Row> */}

			{/* <Licence licences={company.licences} /> */}

			{company?.companyInfo[0]?.description ? <History companyInfo={company.companyInfo} marginClass="mt-8" /> : ''}

			<Offices company={company} />

			<Destination companyData={company.companyData} />

			{/* {(company?.companyBankInfo?.length || company?.companyInfo?.length) ?  */}<Bank companyBankInfo={company.companyBankInfo} companyInfo={company.companyInfo} /> {/* :
				''}
 */}
			{auth.user.roles.includes("ROLE_ADMIN") && <OtherDetails companyInfo={company.companyInfo} companyBankInfo={company.companyBankInfo} />}
		</div>
	);
};

export default VendorCompany;
