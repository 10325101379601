import { createReducer } from "redux-starter-kit";

const initialState = {
  files: []
};

const uploadReducer = createReducer(initialState, {
  SET_FILE_UPLOAD: (state, action) => {
    state.files.push(action.payload);
  },

  UPDATE_FILE_UPLOAD: (state, action) => {
    let index = state.files.findIndex(file => file.id === action.payload.id);
    state.files[index].progress = action.payload.progress || 0;
  },

  CLEAR_FILE_UPLOAD: (state, action) => {
    state.files = state.files.filter(file => file.id !== action.payload);
  }
});

export default uploadReducer;
