import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import {connect, useSelector } from "react-redux";
import Header from "components/layout/Header";
import MobileAlert from "components/ui-elements/MobileAlert";
import { DOMAIN } from "utils/enum";

const PrivateRoute = ({ component: Component, auth, title, ...rest }) => {

  const { domain } = useSelector((state) => state.common)

  useEffect(() => {
        let logo;
    let tabTitle;
    if (domain?.domain === undefined) {
      logo = "loading.png";
      tabTitle = "loading..."
    } else {
      if (domain?.domain === DOMAIN.NEX) {     
        logo = "nex-icon.png"
        tabTitle = `${title}-Nex Portal`;
      } else {     
        logo = "nap-icon.png"
        tabTitle = `${title}-Nap Portal`;
      }
    }
    document.title = tabTitle;
    let iconLink = document.querySelector("link[rel='shortcut icon']")
    iconLink.href = logo
  }, [title, domain]);

  /*if (auth.isAuthenticated) {
    if (!auth.user.roles.includes("ROLE_ADMIN")) {
      return (
        <Result
          status="warning"
          title="You are not authorized to access this page"
        />
      );
    }
  }*/

  return (
    <Route
      {...rest}
      render={props =>
        !localStorage.continueDesktop || localStorage.continueDesktop === "1"
          ?
          auth.isAuthenticated === true
            ?
            (
              <>
                <Header />
                <Component {...props} />
              </>
            )
            :
            (
              <Redirect to="/login" />
            )
          :
          (
            <>
              <MobileAlert />
            </>
          )
      }
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
