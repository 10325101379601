import { CALL_FUNCTION } from "utils/enum";

export const createQuestion = {
    QuestionType: {
        elementType: 'select',
        label: 'Input Type',
        heading: '',
        error: '',
        elementConfig: {
            options: []
        },
        defaultValue: '',
        value: '',
        col: 4,
        fullRow: 0,
        validation: {
            required: true
        },
        errorMessage: {
            required: 'Question Type is required'
        },
        valid: false,
        touched: false
    },
    question: {
        elementType: 'input',
        label: 'Question',
        col: 8,
        fullRow: 0,
        error: '',
        divider: 0,
        heading: '',
        elementConfig: {
            type: 'text',
            placeholder: 'Type Question Here',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage: {
            required: 'Question field is required',
        },
        valid: false,
        touched: false
    },
    placeholder: {
        elementType: 'input',
        label: 'Answer',
        col: 8,
        fullRow: 0,
        error: '',
        divider: 0,
        heading: '',
        elementConfig: {
            type: 'text',
            placeholder: 'Please Enter Answer Text Here',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage: {
            required: 'Answer field is required',
        },
        valid: false,
        touched: false
    },
    mandatory: {
        elementType: 'radio',
        isradio: 1,
        label: 'Required',
        col: 4,
        fullRow: 0,
        heading: '',
        error: '',
        elementConfig: {
            options: [
                { value: true, displayValue: 'Required' },
                { value: false, displayValue: 'Optional' }
            ]
        },
        value: '',
        validation: {
            required: true
        },
        errorMessage: {
            required: 'This field is required',
        },
        valid: false,
        touched: false
    },
    active: {
        elementType: 'switch',
        label: 'Active',
        col: 4,
        fullRow: 0,
        error: '',
        divider: 0,
        heading: '',
        elementConfig: {
            type: "checkbox"
        },
        value: false,
        validation: {
            required: false,
        },
        errorMessage: {
            required: 'This field is required',
        },
        valid: true,
        touched: false
    },
    subIndustries: {
        elementType: 'multiSelect',
        label: 'Networks',
        heading: '',
        error: '',
        elementConfig: {
            options: []
        },
        defaultValue: '',
        value: '',
        col: 4,
        fullRow: 0,
        validation: {
            required: false
        },
        errorMessage: {
            required: 'Network is required'
        },
        valid: true,
        touched: false
    },
    expertiese: {
        elementType: 'multiSelect',
        label: 'Expertise',
        heading: '',
        error: '',
        elementConfig: {
            options: []
        },
        defaultValue: '',
        value: '',
        col: 4,
        fullRow: 0,
        validation: {
            required: false
        },
        errorMessage: {
            required: 'Expertiese is required'
        },
        valid: true,
        touched: false
    },
};

export const callFunctionField = {
    elementType: 'switch',
    label: 'Call Function',
    col: 4,
    fullRow: 0,
    error: '',
    divider: 0,
    heading: '',
    elementConfig: {
        type: "checkbox"
    },
    value: false,
    validation: {
        required: false,
    },
    errorMessage: {},
    valid: true,
    touched: false
}

export const callActionField = {
    elementType: 'select',
    label: 'Call Action',
    heading: '',
    error: '',
    elementConfig: {
        options: CALL_FUNCTION,
        placeholder: 'Call Stack'
    },
    value: '',
    col: 4,
    fullRow: 0,
    validation: {
        required: true
    },
    errorMessage: {
        required: 'Call Function is required'
    },
    valid: false,
    touched: false
}

export const fileTypeField = {
    elementType: 'multiple_autocomplete',
    label: 'Select File Types',
    heading: '',
    error: '',
    elementConfig: {
        options: [
            { id: "0", name: 'png' },
            { id: "1", name: 'jpeg' },
            { id: "2", name: 'jpg' },
            { id: "3", name: 'pdf' },
            { id: "4", name: 'audio' },
            { id: "5", name: 'video' }
        ],
        placeholder: 'Select File Types'
    },
    value: [],
    valueObj: [],
    col: 4,
    fullRow: 0,
    validation: {
        required: true
    },
    errorMessage: {
        required: 'File Type is required'
    },
    valid: false,
    touched: false
}

export const optionField = type => {
    switch (type) {
        case "RADIO":
        case "SINGLE_SELECT":
        case "CHECKBOX":
        case "MULTI_SELECT":
        case 'SINGLE_SELECT_SEARCH':
        case 'MULTI_SELECT_SEARCH':
            return (
                {
                    elementType: 'input',
                    label: '',
                    col: 8,
                    fullRow: 4,
                    error: '',
                    divider: 0,
                    heading: '',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Option',
                    },
                    value: '',
                    validation: {
                        required: true,
                    },
                    errorMessage: {
                        required: 'Option Field is required',
                    },
                    valid: false,
                    touched: false,
                    isModelBtn: false,
                    configureMeta: {}
                }
            );
        default:
            return '';
    }
};

export const MinMaxFields = {
    minLimit: {
        elementType: 'input',
        label: 'Min',
        col: 6,
        fullRow: 0,
        error: '',
        divider: 0,
        heading: '',
        fieldType: 'minField',
        elementConfig: {
            type: 'number',
            placeholder: 'Enter Min',
        },
        value: null,
        validation: {
            isNumeric: true,
            isPositive: true
        },
        errorMessage: {
            isNumeric: "Invaild number",
            isPositive: "Number Should be Greater than 0"
        },
        valid: true,
        touched: false
    },
    maxLimit: {
        elementType: 'input',
        label: 'Max',
        col: 6,
        fullRow: 0,
        error: '',
        divider: 0,
        heading: '',
        fieldType: 'maxField',
        elementConfig: {
            type: 'number',
            placeholder: 'Enter Max',
        },
        value: null,
        validation: {
            isNumeric: true,
            isPositive: true
        },
        errorMessage: {
            isNumeric: "Invaild number",
            isPositive: "Number Should be Greater than 0"
        },
        valid: true,
        touched: false
    }
}


export const linkingFunctionField = {
    elementType: 'switch',
    label: 'Linking Function',
    col: 4,
    fullRow: 0,
    error: '',
    divider: 0,
    heading: '',
    elementConfig: {
        type: "checkbox"
    },
    value: false,
    validation: {
        required: false,
    },
    errorMessage: {},
    valid: true,
    touched: false
}

export const linkingActionField = {
    elementType: 'select',
    label: 'Linking Action',
    heading: '',
    error: '',
    elementConfig: {
        options: CALL_FUNCTION,
        placeholder: 'Call Stack'
    },
    value: '',
    col: 4,
    fullRow: 0,
    validation: {
        required: true
    },
    errorMessage: {
        required: 'Linking Function is required'
    },
    valid: false,
    touched: false
}

