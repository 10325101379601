import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd"
import NexHubHeader from "./nexHubHeader";
import Header from "components/layout/Header";
import { getDomainList, loginNexHub } from "actions/commonActions";
import { Redirect } from "react-router-dom";

const NexHub = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.auth)
    const [error, setError] = useState(false)
    // const { nexHubLoading } = useSelector((state) => state.common) 

    const initialFunction = async () => {
            const response = await loginNexHub();
            if (response.nex_login_link) {
                window.location.href = response.nex_login_link;
            }else{
                setError(true)
            }
    };
    useEffect(() => {
        initialFunction()
    }, [])


    useEffect(()=>{

    },[error])

    return (
        <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }} className="loadingNexHubRow">
            <Col className="gutter-row loadingCol loadingTop" span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {/* <NexHubHeader data={data} /> */}
                <Header />
            </Col>
            <Col className="gutter-row loadingCol loadingArea" span={24}>
                <div className="loadingParent">
                    <img
                        src="https://neutralairpartner.com/download/spec_icons/nex-hub.svg"
                        alt="NeXHub"
                        className="partnerIcon"
                    />
                          {error ? <p className="loading" >Something went wrong, please try again after some time...</p> :<p className="loading" >connecting...</p>}
                </div>
            </Col>
        </Row>
    )
}
export default NexHub