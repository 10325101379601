import React from "react";

const TopAirportsAndAirportsBranches = (props) => {
    const { data, title } = props;
    console.log("data>>>>>",data)
    return (
        <div>
            <h3 className="accrediation-title">{title}</h3>
            <div className="airports">
                {data?.map((item) => (
                    <React.Fragment key={item.id}>
                        <div className="aiport-btn">
                            {title === "Top airports" ? item?.airport?.iata : item /* item.iata */}
                        </div>
                        <br />
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
export default TopAirportsAndAirportsBranches;