import React from "react";
import { connect } from "react-redux";
import {
  Table,
  Input,
  Popconfirm,
  Button,
  Icon,
  Form,
  Typography,
  Row,
  Col,
  message,
} from "antd";
import {
  createLicence,
  updateLicence,
  deleteLicence,
  updateCompanyData,
} from "actions/companyActions";
import { getCertificates } from "actions/dataActions";
import { generateId } from "utils/functions";
import CustomSelect from "components/ui-elements/Select";
import FormField from "components/ui-elements/FormField";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import { api_v } from "utils/settings";

const { Title } = Typography;
const EditableContext = React.createContext();

const formatCertificateOption = (
  { imageUrl, certificatesKey },
  { context }
) => {
  return (
    <div className="border-bottom f">
      <img style={{ width: "30px", marginRight: "20px" }} src={imageUrl}></img>
      {certificatesKey}
    </div>
  );
};

class EditableCell extends React.Component {
  renderCell = ({ form, certificateList }) => {
    const {
      editing,
      dataIndex,
      isNew,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <td {...restProps}>
        {editing || isNew ? (
          inputType === "select" ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ],
                type: "object",

                initialValue: record[dataIndex],
              })(
                <CustomSelect
                  name="certificate"
                  options={certificateList}
                  isSearchable={false}
                  getOptionValue={(option) => option.id}
                  isClearable={false}
                  formatOptionLabel={formatCertificateOption}
                ></CustomSelect>
              )}
            </Form.Item>
          ) : (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(record.id + dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ],

                initialValue: record[dataIndex],
              })(<Input placeholder={`Enter ${this.props.title}`} />)}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newItems: [], editingKey: "", loading: {}, deleting: {} };
    this.columns = [
      {
        title: "Certificate",
        dataIndex: "certificate",
        width: "35%",
        editable: true,
        render: (certificate, row) => {
          return (
            <span>
              <img
                style={{ width: "30px", marginRight: "20px" }}
                src={certificate?.imageUrl}
              ></img>
              {certificate?.certificatesKey}
            </span>
          );
        },
      },
      {
        title: "Reference number",
        dataIndex: "refNo",
        width: "35%",
        editable: true,
      },
      {
        title: "Operation",
        align: "right",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <>
              {record.isNew ? (
                <span>
                  <EditableContext.Consumer>
                    {(context) => (
                      <Button
                        type="primary"
                        className="create-btn"
                        loading={this.state.loading[`${record.id}`]}
                        onClick={() => this.createNew(context.form, record.id)}
                      >
                        Create
                      </Button>
                    )}
                  </EditableContext.Consumer>
                  <Button className="cancel-btn" type="link" onClick={() => this.removeNew(record.id)}>
                    Cancel
                  </Button>
                </span>
              ) : (
                <span>
                  {editable ? (
                    <span>
                      <Button
                        className="cancel-btn"
                        type="link"
                        onClick={() => this.cancel(record.id)}
                      >
                        Cancel
                      </Button>
                      <EditableContext.Consumer>
                        {(context) => (
                          <Button
                            className="create-btn"
                            type="primary"
                            loading={this.state.loading[`${record.id}`]}
                            onClick={() => this.save(context.form, record.id)}
                          >
                            Save
                          </Button>
                        )}
                      </EditableContext.Consumer>
                    </span>
                  ) : (
                    <span>
                      <Button
                        disabled={editingKey !== ""}
                        className="btn-edit"
                        type="link"
                        shape="circle"
                        onClick={() => this.edit(record.id)}
                      >
                        <Icon type="edit" theme="filled"></Icon>
                      </Button>

                      <Popconfirm
                        title="Sure to delete?"
                        placement="topRight"
                        onConfirm={() => this.delete(record.id)}
                      >
                        <Button
                          disabled={editingKey !== ""}
                          className="btn-edit"
                          loading={this.state.deleting[`${record.id}`]}
                          shape="circle"
                          type="link"
                        >
                          <Icon type="delete" theme="filled"></Icon>
                        </Button>
                      </Popconfirm>
                    </span>
                  )}
                </span>
              )}
            </>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.props.getCertificates();
  }

  isEditing = (record) => record.id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  add = () => {
    const id = generateId();

    this.setState({
      newItems: [
        ...this.state.newItems,
        { id, isNew: true, certificate: this.props.certificateList[0] },
      ],
    });
  };

  removeNew = (id) => {
    this.setState({
      newItems: this.state.newItems.filter((item) => item.id !== id),
    });
  };

  createNew = (form, id) => {
    form.validateFields(async (error, row) => {
      if (error) {
        return;
      }
      // Set loading for ID
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true },
      });

      const data = {
        company: `/${api_v}company/${this.props.companyId}/profile`,
        acreditation: `${row[`${id}certificate`].certificatesKey}`,
        subject: "licences",
        refNo: row[`${id}refNo`],
      };
      await this.props
        .createLicence(data)
        .then(() => {
          message.success("Licence created successfully");
          this.setState((prevState) => ({
            ...prevState,
            loading: {
              ...prevState.loading,
              [`${id}`]: false,
            },
            newItems: prevState.newItems.filter((newItem) => newItem.id !== id),
          }));
        })
        .catch(() => {
          message.error("Something went wrong");
        });
    });
  };

  delete = async (id) => {
    this.setState({
      deleting: { ...this.state.deleting, [`${id}`]: true },
    });

    const promise = this.props.deleteLicence(id);
    promise
      .then(() => {
        message.success("Licence deleted successfully");
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  save(form, id) {
    form.validateFields(async (error, row) => {
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true },
      });

      const data = {
        company: `/${api_v}company/${this.props.companyId}/profile`,
        acreditation: `${row[`${id}certificate`].certificatesKey}`,
        subject: "licences",
        refNo: row[`${id}refNo`],
      };

      const promise = this.props.updateLicence(data, id);
      promise
        .then(() => {
          message.success("Licence updated successfully");
        })
        .catch(() => {
          message.error("Something went wrong");
        });
      // Clean up after
      this.setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`${id}`]: false,
        },
        editingKey: "",
      }));
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  saveOther = async (values) => {
    return await this.props.updateCompanyData(values, this.props.companyId);
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          isNew: record.isNew,
          inputType: col.dataIndex === "certificate" ? "select" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { certificates, certificateList, otherAcreditation, otherRefNo } =
      this.props;
    return (
      <div className="card">
        <EditableContext.Provider
          value={{ form: this.props.form, certificateList }}
        >
          <div className="card-header">
            <Title level={3} style={{ margin: 0 }}>
              Certificates
            </Title>
          </div>

          <Table
            components={components}
            rowKey="id"
            dataSource={certificates.concat(this.state.newItems)}
            columns={columns}
            pagination={false}
            rowClassName="editable-row"
          />
          <div className="card-body">
            <Button type="dashed" className="add-new-btn" onClick={this.add} style={{ width: "40%" }}>
              <Icon type="plus" /> Add new
            </Button>
          </div>
        </EditableContext.Provider>
{/*         <div className="note">
          <h6>
            * Other Certificates below have been discarded and will be removed
            1st of Jan, please move all data to the OTHER input above.
          </h6>
        </div> */}
        {/* <div style={{ padding: 10 }}>
          <h4>Other Certificate</h4>
          <AutoSaveWrapper
            onSubmit={this.saveOther}
            initialValues={{
              otherAcreditation,
              otherRefNo,
            }}
            subscription={{}}
          >
            {() => (
              <>
                <AutoSave
                  debounce={1500}
                  save={this.saveOther}
                  debounced={["otherAcreditation", "otherRefNo"]}
                />
                <Row gutter={5}>
                  <Col span={9}>
                    <FormField name="otherAcreditation">
                      <Input placeholder="Name of other certificate"></Input>
                    </FormField>
                  </Col>
                  <Col span={10}>
                    <FormField name="otherRefNo">
                      <Input placeholder="Reference number"></Input>
                    </FormField>
                  </Col>
                </Row>
              </>
            )}
          </AutoSaveWrapper>
        </div> */}
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

const mapStateToProps = (state) => ({
  companyId: state.company.data.id,
  certificates: state.company.data.licences.map((a) => ({
    id: a.id,
    certificate: {
      imageUrl: a.imageUrl,
      certificatesKey: a.acreditation,
      certificatesValue: a.acreditation,
    },
    refNo: a.refNo,
  })),
  certificateList: state.data.certificateList,
  otherAcreditation: state.company.data.companyData[0].otherAcreditation,
  otherRefNo: state.company.data.companyData[0].otherRefNo,
});

export default connect(mapStateToProps, {
  createLicence,
  updateLicence,
  deleteLicence,
  getCertificates,
  updateCompanyData,
})(EditableFormTable);
