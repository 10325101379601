import React, { useEffect, useState } from "react";
import { Drawer, Button } from "antd";
import Title from "./Title";
import CompanyLogo from "components/ui-elements/CompanyLogo";
import CompanyEdit from "components/company-edit/CompanyEdit";
import { useSelector } from 'react-redux'
import NacImage from '../../../theme/assets/img/subIndustry/nac.svg';
import Nav from '../../../theme/assets/img/subIndustry/nav-aero.svg';
import NaxTime from '../../../theme/assets/img/subIndustry/nax-247.svg';
import NaxExpress from '../../../theme/assets/img/subIndustry/nax-express.svg';
import NaxFresh from '../../../theme/assets/img/subIndustry/nax-fresh.svg';

import { InfoCircleFilled } from "@ant-design/icons";

const ImagesEnum = [
	{ id: 1, image: NacImage },
	{ id: 2, image: Nav },
	{ id: 3, image: NaxExpress },
	{ id: 4, image: NaxTime },
	{ id: 5, image: NaxFresh }
]

const Header = (props) => {
	const [drawerWidth, setdrawerWidth] = useState(1100);
	const subIndustryRels = useSelector(state => state.company.data?.subIndustryRels)
	const { name, logo, city, country, companyId, classification, showEdit, canEdit, toggleEdit, companySettings } = props;
	// console.log("companySettings", companySettings)
	const imageUrl = ImagesEnum.filter((ele) => subIndustryRels?.subIndustry?.id === ele?.id);
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 1024) {
				setdrawerWidth('100%');
			} else {
				setdrawerWidth(1100); // Set the desired width for the Drawer
			}
		};

		handleResize(); // Call it initially to set the correct width

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (

		<div className="profile-header-wrapper">
			{/* {!companySettings[0].notificationFlag ?
				<div className="under_approval_box" >
					<InfoCircleFilled />
					<p>This account is under review.</p>
				</div>
				: ""
			} */}
			<div className="profile-leftpane-header">
				<div className={`profile-header py-2 px-5 ${canEdit ? 'f-c-c' : 'f'} partition`}>
					{/* 		<CompanyLogo logo={logo} width="100"></CompanyLogo> */}
					<Title
						name={name}
						country={country}
						city={city}
					// classification={classification}
					/>
					{companySettings[0]?.notificationFlag && companySettings[0]?.status ==="InReview" ?
						<div className="under_approval_box account-under-review" >
							<InfoCircleFilled />
							<p>This account is under review.</p>
						</div>
						: ""
					}
				</div>

				<div className="profile-rightpane">
					{canEdit && (
						<Button
							className="edit-profile-btn"
							// type="primary"
							// className="ml-auto"
							onClick={toggleEdit}
						>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M11.413 16.9964L7 17.0114V12.5814L16.634 3.03944C17.384 2.29044 18.703 2.28644 19.459 3.04244L21.045 4.62844C21.423 5.00644 21.631 5.50844 21.631 6.04244C21.631 6.57644 21.423 7.07844 21.045 7.45644L11.413 16.9964ZM19.634 6.03944L18.045 4.45644L16.451 6.03644L18.037 7.62144L19.634 6.03944ZM15.03 7.44244L9 13.4154V15.0044L10.587 14.9994L16.616 9.02844L15.03 7.44244ZM19 20.9985H5C3.897 20.9985 3 20.1015 3 18.9985V4.99854C3 3.89554 3.897 2.99854 5 2.99854H13.847L11.847 4.99854H5V18.9985H7.979C7.99515 18.999 8.01108 19.0013 8.02688 19.0036C8.04435 19.0061 8.06168 19.0085 8.079 19.0085C8.092 19.0085 8.10525 19.006 8.1185 19.0035C8.13175 19.001 8.145 18.9985 8.158 18.9985H19V12.3305L21 10.3305V18.9985C21 20.1015 20.103 20.9985 19 20.9985Z" fill="#333333" />
							</svg>	EDIT PROFILE
						</Button>
					)}
				</div>
				{subIndustryRels && subIndustryRels.subIndustry && (subIndustryRels.subIndustry.name).toLowerCase() !== 'none' &&
					< div className="profile-header-parttion">
						<img src={imageUrl.length ? imageUrl[0].image : ""} alt="company-logo" height="50" width="50"></img>
						<h3 title={subIndustryRels.subIndustry.name}>{subIndustryRels.subIndustry.name}</h3>
					</div>
				}
				<Drawer
					width={drawerWidth}
					destroyOnClose={true}
					closable={false}
					onClose={toggleEdit}
					visible={showEdit}
				>
					<CompanyEdit
						key={companyId}
						companyId={companyId}
						toggleEdit={toggleEdit}
					></CompanyEdit>
				</Drawer>
			</div>
		</div >
	);
}
export default Header;
