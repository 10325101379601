import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CompanyLogo from "components/ui-elements/CompanyLogo";
import { CheckFocusKeys, findSubIndustry } from "utils/functions";
import { Col, Row } from "antd";
import moment from "moment";
import NAPlogo from "theme/assets/logo.svg";
import CompanyStatus from "./CompanyStatus";

const CompanyHeaderAccounting = ({
  companySettings,
  name,
  logo,
  city,
  classification,
  country,
  focus,
  toggleEdit,
  status,
  approved,
  id,
  auth,
  userCompanyId,
  roles,
  licences,
  subIndustry,
  itemAccounting,
  selectCard
}) => {
  let isEditable = true;
  let history = useHistory();
  let location = useLocation();
  const [acreditationData, setAcreditationData] = useState([]);
  // Approved
  // Pending
  // Not Finished

  // isApproved
  if (
    localStorage.status !== "Approved" ||
    (status !== "Not Finished" && !approved)
  ) {
    isEditable = false;
  }

  const showEdit = (subIndustry) => {
    if (!isEditable) {
      return;
    }

    handleEdit();
  };
  useEffect(() => {
    if (licences?.length > 0) {
      const result = licences.filter(
        (item) =>
          item.acreditation === "IATA" &&
          item.subject === "licences" &&
          item.imageUrl !== ""
      );

      setAcreditationData(result);
    }
  }, [licences]);
  const handleEdit = () => {
    const isAdmin = auth.user.roles.includes("ROLE_ADMIN");

    if (isAdmin) {
      toggleEdit(id);
    } else {
      history.push(`/company-profile/${id}`);
    }
  };
  return (
    <div className="card-header accounting-payment-main">
      <div
        className={classnames("f accounting-payment-left", { "network-card-edit": isEditable })}
        onClick={() => showEdit(subIndustry)}
      >
        <div className="f-c-c inline-image">
          <CompanyLogo logo={logo}></CompanyLogo>
        </div>
        <div>
          <h3 className="network-card-title">{name}</h3>
          <span className="text-secondary">
            {/*  {classification} | */} {city}, {country}
          </span>
          {/* {roles === "ROLE_ADMIN" ? ( */}
          {userCompanyId ? (
            <div className="company-id">NAP Code: {userCompanyId}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Col className="accounting-industry">
        <Row className="industry-row">
          <Col className="industry-col">
            <div className="content">
              <p>Network</p>
            </div>
            <div className="content">
              <p>paid date</p>
            </div>
          </Col>
          <Col className="industry-col">
            <div className="content">
              <img src={NAPlogo ? NAPlogo : ""} alt="logo" />
            </div>
            <div className="content">
              {companySettings?.paidDate ? (
                <p>
                  {`${moment(companySettings?.paidDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </p>
              ) : itemAccounting?.companyInfo[0]?.parentCompany?.paidDate
                ?
                <p>
                  {`${moment(itemAccounting?.companyInfo[0]?.parentCompany?.paidDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </p>
                :
                (
                  <p>{`Not Available`}</p>
                )}
            </div>
          </Col>
          {subIndustry.length
            ? subIndustry.map((i) => (
              <Col className="industry-col">
                <div className="content">
                  <img src={i.industryLogo} />
                </div>
              </Col>
            ))
            : ""}
        </Row>
      </Col>

      <div className="end-review">
        {itemAccounting.companySettings[0] && (
          <CompanyStatus
            companySettings={itemAccounting.companySettings[0]}
            companyInfo={itemAccounting.companyInfo[0]}
            companyName={itemAccounting.companyName}
            companyId={itemAccounting.id}
            Companypartner={itemAccounting.companyPartners}
            auth={auth}
            selectCard={selectCard}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyHeaderAccounting;
