import React from "react";
import { Collapse, Table } from "antd";

const { Panel } = Collapse;

const { Column } = Table;

function fob(fob, documents, renderDocuments) {
	const data = fob.map(fob => (
		{
			key: fob.id,
			id: '#',
			fee: fob.fobFee,
			price: fob.fobPrice
		}
	));

	return (
		<Collapse defaultActiveKey={['1']} bordered={false} expandIconPosition="right" className="rates-fees-collapse" style={{ marginTop: '2.5rem', backgroundColor: 'transparent' }}>
			<Panel header={<h2 className="mb-0">FOB/EXW</h2>} key="1">
				{data.length &&
				<Table dataSource={data} pagination={false}>
					<Column title="#" dataIndex="id" key="#" />
					<Column title="Fees" dataIndex="fee" key="fees" />
					<Column title="Price" dataIndex="price" key="price" />
				</Table>}
				{documents.length ? renderDocuments(documents) : null}
			</Panel>
		</Collapse>
	);
}

const FOB = ({ company, getSourceWiseDocument, renderDocuments }) => {
	const fobDocuments = getSourceWiseDocument(company.document, 'fob');

	return (company.fob.length || fobDocuments.length) ? fob(company.fob, fobDocuments, renderDocuments) : <></>;
};

export default FOB;
