import React from 'react';
import { Button, Form, Input, Radio, Select, Switch, Skeleton } from 'antd';

const InputField = (props) => {
    let inputElement = null;

    switch (props.elementType) {
        case ('input'):
            inputElement =
                (props.label !== "Max" && props.label !== "Min")
                    ?
                    ((props.elementConfig.placeholder !== "Option" && (props.value === '' && props.edit && !props.touched)))
                        ?
                        <Skeleton active paragraph={{ rows: 0 }} className="inputField-skeleton" />
                        :
                        <Input onChange={props.changed} placeholder={props.elementConfig.placeholder} defaultValue={props.value} />
                    :
                    <Input onChange={props.changed} placeholder={props.elementConfig.placeholder} value={props.value} />
            break;
        case ('select'):
            inputElement =
                props.elementConfig.options?.length === 0
                    ?
                    < Skeleton active paragraph={{ rows: 0 }
                    } className="inputField-skeleton" />
                    :
                    <Select disabled={(props.label === 'Input Type' && props.edit !== '') ? true : false} onChange={props.changed} value={props.value} defaultValue={props.defaultValue} placeholder={props.elementConfig.placeholder}>
                        {props.elementConfig.options?.map((element, index) => {
                            return (
                                <Select.Option value={element.id} key={`select_${index}`}>{element.name}</Select.Option>)
                        })}
                    </Select>
            break;
        case ('multiple_autocomplete'):
            inputElement = <Select
                mode="multiple"
                style={{
                    width: '100%',
                }}
                value={props.value}
                placeholder={props.elementConfig.placeholder}
                defaultValue={props.valueObj}
                onChange={props.changed}
                optionLabelProp="label">
                {props.elementConfig.options.map((element, index) =>
                    <Select.Option value={element.id} label={element.name} key={`multi_auto_${index}`}>
                        {element.name}
                    </Select.Option>)}
            </Select>
            break;
        case ('radio'):
            inputElement = <Radio.Group onChange={props.changed} value={props?.value}>
                {props.elementConfig.options.map((element, index) => {
                    return <Radio.Button value={element.value} key={`radio_${index}`}>{element.displayValue}</Radio.Button>
                })}
            </Radio.Group>
            break;
        case ('switch'):
            inputElement = <Switch onChange={props.changed} checked={props.value}
            />
            break;

        default:
            inputElement = <></>;
    }
    console.log("props.error>>>>", props.error)
    return <>
        {props?.elementType !== 'multiSelect'
            ?
            <Form.Item label={props.label} key={props} required={props?.shouldValidate?.required}>{inputElement}
            </Form.Item>
            :
            ""
        }
        {props.error != '' && props.error != undefined && (<p className="error">{props.error}</p>)}
        {props.isCrossBtn == 1 && (
            <Button onClick={props.deleteMethod}>X</Button>
        )}
    </>;
};

export default InputField;