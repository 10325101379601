import React from "react";
import { Col } from "antd";

const Stats = (props) => {
	const { percentage, classification, color } = props;
	const style = { 
		background: 'rgba(255, 255, 255, 0.05)',
		borderRight: '1px solid rgb(55, 60, 83)',
		borderBottom: `2px solid ${color}`,
		borderLeft: '1px solid rgb(55, 60, 83)',
		display: 'flex'
	};

	return (
		<Col span={6} className="pt-4 f-c-c-col text-white" style={style}>
			<p className="mb-0 text-4xl">{percentage ?? '-'}%</p>
			<p>{classification}</p>
			<span style={{ backgroundColor: color, opacity: 0.3, height: percentage+'%', bottom: 0, position: 'absolute', width: '100%' }}></span>
		</Col>
	);
};

export default Stats;
