import React, { useState } from "react";
import { Slider } from "antd";
import styled from "@emotion/styled";

const Box = styled("div")`
  text-align: center;
  line-height: 1.5;
  padding: 0 14px;
  p {
    font-size: 1.35rem;
    margin: 0;
  }
`;

const Container = styled("div")`
  background-color: #fafafa;
  max-width: 600px;
  padding: 6px 10px;
  border-radius: 20px;
  margin-top: 10px;

  .ant-slider-handle {
    height: 24px;
    margin-top: -12px;
    background-color: #fff;
    border: solid 2px #c5dbd9;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.08);
  }
  .ant-slider-rail {
    background: rgb(63, 147, 139);
    background: linear-gradient(
      90deg,
      rgba(63, 147, 139, 1) 0%,
      rgba(224, 237, 94, 1) 100%
    );
  }

  .ant-slider-track {
    background-color: transparent;
  }
`;

const TwoOptionSlider = ({ defaultValue, onChange }) => {
  const [state, setState] = useState({
    one: defaultValue,
    two: 100 - defaultValue
  });
  const handleChange = value => {
    setState({ one: value, two: 100 - value });
  };

  return (
    <Container className="f-s-b">
      <Box>
        <p>{state.two}%</p>
        <span>Wholesale</span>
      </Box>

      <Slider
        style={{ flex: 2, margin: "0 10px" }}
        step={5}
        defaultValue={defaultValue}
        onChange={handleChange}
        tooltipVisible={false}
        onAfterChange={() => onChange(state)}
      ></Slider>

      <Box>
        <p>{state.one}%</p>
        <span>Retail</span>
      </Box>
    </Container>
  );
};

export default TwoOptionSlider;
