import React from "react";
import FeeGroup from "components/company-edit/fees/FeeGroup";

const Fees = props => {
  const {
    fob,
    dap,
    importhandover,
    rates,
    documents,
    onCreateFee,
    onUpdateFee,
    onDeleteFee,
    onUpload,
    onDocumentDelete
  } = props;

  const importhandoverDocuments = documents.filter(
    document => document.source === "importhandover"
  );

  const fobDocuments = documents.filter(document => document.source === "fob");

  const dapDocuments = documents.filter(document => document.source === "dap");

  const ratesDocuments = documents.filter(
    document => document.source === "rates"
  );

  return (
    <>
      {/* {importhandover.length && */}
        <FeeGroup
          fees={importhandover}
          documents={importhandoverDocuments}
          name="importhandover"
          title="Import Handover Fees"
          onCreateFee={onCreateFee}
          onUpdateFee={onUpdateFee}
          onDeleteFee={onDeleteFee}
          onUpload={onUpload}
          onDocumentDelete={onDocumentDelete}
        ></FeeGroup>
      {/* } */}
      {/* {fob.length && */}
        <FeeGroup
          fees={fob}
          documents={fobDocuments}
          name="fob"
          title="FOB/EXW Charges"
          onCreateFee={onCreateFee}
          onUpdateFee={onUpdateFee}
          onDeleteFee={onDeleteFee}
          onUpload={onUpload}
          onDocumentDelete={onDocumentDelete}
        ></FeeGroup>
      {/* } */}

      {/* {dap.length && */}
        <FeeGroup
          fees={dap}
          documents={dapDocuments}
          name="dap"
          title="DAP/DDP Charges"
          onCreateFee={onCreateFee}
          onUpdateFee={onUpdateFee}
          onDeleteFee={onDeleteFee}
          onUpload={onUpload}
          onDocumentDelete={onDocumentDelete}
        ></FeeGroup>
      {/* } */}

      {/* {rates.length && */}
        <FeeGroup
          fees={rates}
          documents={ratesDocuments}
          name="rates"
          title="Rates and Tariffs"
          onCreateFee={onCreateFee}
          onUpdateFee={onUpdateFee}
          onDeleteFee={onDeleteFee}
          onUpload={onUpload}
          onDocumentDelete={onDocumentDelete}
        ></FeeGroup>
      {/* } */}

    </>
  );
};

export default Fees;
