import React from "react";
import { Divider, Button } from "antd";
import ImportHandover from "./ImportHandover";
import FOB from "./FOB";
import DAP from "./DAP";
import activityPromos from "theme/assets/activity-promos.png";
import Rates from "./Rates";

function getSourceWiseDocument(documents, source) {
	return documents.filter(document => document.source === source);
}

function renderDocuments(documents) {
	return (
		<>
			<h3 className="mt-6">Fees</h3>
			<Divider style={{ margin: '1rem 0' }} />
			{documents.map(doc => (
				<React.Fragment key={doc.id}>
					<p className="mb-0">{doc.originalName}</p>
					<Button type="primary" icon="download" href={doc.path} target="_blank" block>
						Download
					</Button>
					<Divider style={{ margin: '0.5rem 0' }} />
				</React.Fragment>
			))}
		</>
	);
}

const RatesFees = ({ company }) => {
	const { importhandover, fob, dap, rates } = company;
	return (
		<div className="px-8">
			{
				importhandover.length === 0 && fob.length === 0 && dap.length === 0 && rates.length === 0
					?
					<div className="activity-promo">
						<img src={activityPromos}></img>
						<div className="noActivity">
							<h3>Rates & Fees are not available</h3>
							{/* <Link type="button" className="ant-btn ant-btn-primary" >
						<i className="fa fa-pencil m-r-15" ></i>POST YOUR FIRST PROMO
						</Link> */}
						</div>
					</div>
					:
					<>
						<ImportHandover
							company={company}
							getSourceWiseDocument={getSourceWiseDocument}
							renderDocuments={renderDocuments}
						/>
						<FOB
							company={company}
							getSourceWiseDocument={getSourceWiseDocument}
							renderDocuments={renderDocuments}
						/>
						<DAP
							company={company}
							getSourceWiseDocument={getSourceWiseDocument}
							renderDocuments={renderDocuments}
						/>
						<Rates
							company={company}
							getSourceWiseDocument={getSourceWiseDocument}
							renderDocuments={renderDocuments}
						/>
					</>
			}
		</div>
	);
};

export default RatesFees;
