import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Input, Button, Form } from 'antd'
import { saveNetworkBrevoCred } from '../../actions/questionAction';

const EditConnection = (props) => {
    const { data, closeDrawer, initialFunction } = props;
    const dispatch = useDispatch()
    const [inputField, setInputField] = useState({ apiKey: data?.apiKey, folderId: data?.folderId })

    const handleInputField = (e) => {
        e.persist()
        setInputField((prevValue) => {
            return {
                ...prevValue,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleSubmit = async(e) => {
        e.preventDefault();
        const editData = {
            apiKey: inputField.apiKey,
            folderId: Number(inputField.folderId)
        }
        await dispatch(saveNetworkBrevoCred(editData, data?.id))
        closeDrawer()
        initialFunction()
    }
    return (
        <Form onSubmit={handleSubmit} layout="vertical" style={{ padding: "0 64px" }} className="connectionForm">
            <Row gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }} >
                <Col className="gutter-row mt-5" span={24}><h4 style={{color: "#000", fontWeight: "600"}}>CONNECT</h4></Col>
                <Col className="gutter-row mt-5" span={24}>
                    <label>API KEY</label>
                    <Input size="default" name="apiKey" defaultValue={inputField?.apiKey} onChange={(e) => handleInputField(e)} />
                </Col>
                <Col className="gutter-row mt-5" span={24}>
                    <label>FOLDER ID</label>
                    <Input size="default" name="folderId" defaultValue={inputField?.folderId} onChange={(e) => handleInputField(e)} />
                </Col>
                <Col className="gutter-row mt-5" span={24}>
                   {/*  <Button
                        className="invite-user-cancel-btn connection-edit-btn"
                        type="secondary"

                    >
                        Delete
                    </Button> */}
                    <Button type="primary" className="create-btn" htmlType="submit" style={{marginLeft: "auto", display: "block"}}>Save</Button>
                </Col>
            </Row>
        </Form>
    )
}
export default EditConnection