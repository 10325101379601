import React, { useState, useEffect } from "react"
import { Row, Col, Icon, Divider } from "antd"
import QuestionAnswer from "./QuestionAnswer"

const Expertise = (props) => {
    const { subIndusryId, companyData, subindustryItem, networkStatus } = props;
    const [expertise, setExpertise] = useState([])

    useEffect(() => {
        const filterExpertiseBySubIndustryId = companyData?.expertiese.filter((item) => item.subIndustry.id === subIndusryId)
        setExpertise(filterExpertiseBySubIndustryId)
    }, [subIndusryId])

    return (
        <Row className="ExpertiseBoxRow">
            <Col lg={24} xl={10} className="ExpertiseBoxCol left">
                {expertise.map((value) =>
                   <>
                    <div className="leftList">
                        <div id='left-side-image'>

                        </div>
                        {/* <Icon type="check" className="expertise-check-icon" /> */}
                        <p>{value.name} </p>
                        
                    </div>
                    <Divider className="mb-0 mt-0"></Divider>
                    </>
                  
                )
                }
            </Col>
            {networkStatus !== "vendor" &&
                <Col lg={24} xl={14} className="ExpertiseBoxCol right">
                    <QuestionAnswer subIndusryId={subIndusryId} expertise={expertise} companyData={companyData} subindustryItem={subindustryItem} /></Col>
            }
        </Row>
    )
}
export default Expertise