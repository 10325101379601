import React from "react";

const TopAirlines = ({ data }) => {
    return (
        <div>
            <h3 className="accrediation-title">Top airlines</h3>
            <div className="top-airlnes">
                {data?.map((airline) => (
                    <React.Fragment key={airline.id}>
                        <span id="tagcustom">
                            {airline.airline.iata}
                        </span>
                        <br />
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
export default TopAirlines;