import axios from "axios";
import { base_url, api_v } from "utils/settings";

import {
  ADD_NEW_QUESTION, GLOBAL_ERROR, GET_CITIES_LIST, SAVE_ANSWER
} from "./types";

export const AddQuestion = (data, key) => async (dispatch) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}questions`, data);
    dispatch({
      type: ADD_NEW_QUESTION,
      key,
      payload: res,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

export const SeqPutQuestion = (data, key) => async (dispatch) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}questions_sequence`, data);
    dispatch({
      type: ADD_NEW_QUESTION,
      key,
      payload: res,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};



export const getQuestionList = () => async (dispatch) => {
  let url = `${base_url}/${api_v}questions`;
  return axios
    .get(url)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};

// Get Question type list
export const getQuestionTypesList = () => (dispatch) => {
  let url = `/${api_v}question_types`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      const filteredPeople = res.data["hydra:member"].filter((item) => !([7, 14, 15].includes(item.id)));
      return filteredPeople;
      // return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};

// Get Expertiese list
export const getExpertieseList = () => (dispatch) => {
  let url = `/${api_v}expertieses`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};



// Get Country list
export const getCountriesList = () => (dispatch) => {
  let url = `/${api_v}countries`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
};




// Get Cities
/* export const getCitiesList = () => (dispatch) => {
  let url = `/${api_v}cities`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      //  return res.data["hydra:member"];
      dispatch({
        type: GET_CITIES_LIST,
        payload: res.data["hydra:member"],
      })
    })
    .catch((err) => {
      return [];
    });
}; */

// Fetch cities (for react-select)
export const fetchCities = (search, countryId, callback) => {
  axios
    .get(
      `${base_url}/${api_v}cities?cityAsciiname=${search}&countryId=${countryId}`
    )
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

export const fetchCitiesByCname = (search, countryname, callback) => {
  console.log("ser",search, countryname, callback)
  let str;
  if (countryname === undefined) {
    str = ""
  } else {
    str = `&countryName=${countryname}`
  }

  axios
    .get(
      `${base_url}/${api_v}cities?cityAsciiname=${search}${str}`
    )
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Fetch airports (for react-select)
export const fetchAirports = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airports?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Fetch airlines(for react-select)
export const fetchAirlines = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}airlines?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => callback([]));
};


export const fetchAirlines2 = (input, callback) => {
  axios.get(`${base_url}/${api_v}airlines?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"].map((a) => ({ airline: a })));
    })
    .catch((err) => {
      return callback([]);
    });
};

export const fetchAirports2 = (input, callback) => {
  axios.get(`${base_url}/${api_v}airports?search=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"].map((a) => ({ airport: a })));
    })
    .catch((err) => {
      return callback([]);
    });
};
// Fetch countries (for react-select)
export const fetchCountries = (input, callback) => {
  axios
    .get(`${base_url}/${api_v}countries?countryname=${input}`)
    .then((res) => {
      return callback(res.data["hydra:member"]);
    })
    .catch((err) => {
      return callback([]);
    });
};

// Delet Question
export const deleteQuestion =
  (id) => async (dispatch) => {
    try {
      await axios.delete(`${base_url}/${api_v}questions/${id}`);
      return true
    } catch (err) {
      return false;
    }
  };
// Delet Option
export const deleteOption =
  (id) => async (dispatch) => {
    try {
      await axios.delete(`${base_url}/${api_v}question_options/${id}`);
      return true
    } catch (err) {
      return false;
    }
  };

// Save Questions
export const saveAnswers = (data, companyId) => async (dispatch) => {
  try {
    let d = `${base_url}/${api_v}companies/${companyId}`
    const res = await axios.put(d, data);

    dispatch({
      type: SAVE_ANSWER,
      payload: res.data,
    });
    return true;
  } catch (err) {
    return false
  }
};

// Get Country list
/* export const getCountriesById = (id) => (dispatch) => {
  let url = `/${api_v}countries/${id}`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
}; */


// Get country by id

/* export const getCountriesById = (id) => (dispatch) => {
  let url = `/${api_v}countries/${id}`;
  return axios
    .get(`${base_url}${url}`)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
}; */

export const getNetworkBrevoList = () => async (dispatch) => {
  let url = `${base_url}/${api_v}network_brevo_creds`;
  return axios
    .get(url)
    .then((res) => {
      return res.data["hydra:member"];
    })
    .catch((err) => {
      return [];
    });
}

export const saveNetworkBrevoCred = (data, id) => async (dispatch) => {
  try {
    let url = `${base_url}/${api_v}network_brevo_creds/${id}`
    await axios.put(url, data);
    return true;
  } catch (err) {
    return false
  }
};

export const deleteNetworkBrevoList =
  (id) => async (dispatch) => {
    try {
      await axios.delete(`${base_url}/${api_v}network_brevo_creds/${id}`);
      return true
    } catch (err) {
      return false;
    }
  };

export const getSyncContacts = (listName, id) => async (dispatch) => {
  let url = `${base_url}/${api_v}import/contacts/${listName}?network=${id}`;
  return axios
    .get(url)
    .then((res) => {     
      return res.data["hydra:member"];
    })
    .catch((err) => {        
      return false
    });
}
