import React from "react";
import { Collapse, Table } from "antd";

const { Panel } = Collapse;

const { Column } = Table;

function importHandover(importHandover, documents, renderDocuments) {
	const data = importHandover.map(handover => (
		{
			key: handover.id,
			id: '#',
			importhandoverFee: handover.importhandoverFee,
			importhandoverPrice: handover.importhandoverPrice
		}
	));

	return (
		<Collapse defaultActiveKey={['1']} bordered={false} expandIconPosition="right" className="rates-fees-collapse" style={{ marginTop: '2.5rem', backgroundColor: 'transparent' }}>
			{data.length &&
				<Panel header={<h2 className="mb-0">Import/Handover</h2>} key="1">
					{data.length &&
						<Table dataSource={data} pagination={false}>
							<Column title="#" dataIndex="id" key="#" />
							<Column title="Fees" dataIndex="importhandoverFee" key="fees" />
							<Column title="Price" dataIndex="importhandoverPrice" key="price" />
						</Table>}
					{documents.length ? renderDocuments(documents) : null}
				</Panel>
			}
		</Collapse>
	);
}

const ImportHandover = ({ company, getSourceWiseDocument, renderDocuments }) => {
	const handoverDocuments = getSourceWiseDocument(company.document, 'importhandover');

	return (company.importhandover.length || handoverDocuments.length) ? importHandover(company.importhandover, handoverDocuments, renderDocuments) : <></>;
};

export default ImportHandover;
