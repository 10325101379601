import axios from "axios";
import { base_url, api_v } from "utils/settings";

import {
  GET_COMPANY,
  GET_COMPANY_EVENT_LINKS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_DATA,
  UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_AVATAR,
  CLEAR_COMPANY,
  ADD_FEE,
  DELETE_FEE,
  UPDATE_FEE,
  UPDATE_BANK,
  CREATE_SPECIALIZATION,
  UPDATE_SPECIALIZATION,
  DELETE_SPECIALIZATION,
  UPDATE_DESK_SERVICE,
  UPDATE_COMPANY_EXPERTISE,
  ADD_LICENCE,
  UPDATE_LICENCE,
  DELETE_LICENCE,
  DELETE_DOCUMENT,
  COMPANY_UPDATING,
  COMPANY_LOADING,
  GLOBAL_ERROR,
  SEND_EMAIL,
  UPDATE_TRIGGER,
} from "./types";

// Get Company
export const getCompany = (id) => (dispatch) => {

  dispatch(setCompanyLoading());
  axios
    .get(`${base_url}/${api_v}company/${id}/profile`)
    .then((res) =>
      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      })
    );
};

// Get Company2 
export const getCompany2 = (id, fileName) => (dispatch) => {
  if (fileName === "expertiseInfo") {
    dispatch(setCompanyLoading());
  }
  axios
    .get(`${base_url}/${api_v}company/${id}/profile`)
    .then((res) =>
      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      })
    );
};

export const getCompanyInfo = async (id) => {
  var config = {
    method: "get",
    url: `${base_url}/${api_v}company/${id}/profile`,
    headers: {
      Authorization: `Bearer ${localStorage.jwtToken}`,
    },
  };

  return await axios(config)
    .then(function (response) {

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Update Company
export const updateCompany = (data, id, key) => async (dispatch) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}companies/${id}`, data);
    dispatch({
      type: UPDATE_COMPANY,
      key,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Update Company Info
export const updateCompanyInfo = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}company_infos/${id}`,
      data
    );
    dispatch({
      type: UPDATE_COMPANY_INFO,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Update Company Data
export const updateCompanyData = (data, id, fileName) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}company_datas/${id}`,
      data
    );

    dispatch({
      type: UPDATE_COMPANY_DATA,
      payload: res.data,
    });
    if (fileName === 'expertise') {
      await dispatch(getCompany2(id))
    } else if (fileName === 'noloading') {
      await dispatch(getCompany2(id))
    } else {
      await dispatch(getCompany(id))
    }
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });

    return false;
  }
};

// Create desk service
export const createDeskService = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}desk_services`, data);
    dispatch({
      type: UPDATE_DESK_SERVICE,
      payload: res.data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });

    return false;
  }
};

// Update desk service
export const updateDeskService = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}desk_services/${id}`,
      data
    );
    dispatch({
      type: UPDATE_DESK_SERVICE,
      payload: res.data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });

    return false;
  }
};

// Update Selected Expertises (strange API)

export const updateSelectedExpertises =
  (addData, toBeRemoved, targetExpertise, payload, companyId, key) =>
    async (dispatch) => {
      const promises = [];

      toBeRemoved.forEach((id) => {
        promises.push(
          Promise.resolve(
            axios.delete(
              `${base_url}/${api_v}company/${companyId}/${targetExpertise}/${id}`
            )
          )
        );
      });

      promises.push(
        Promise.resolve(
          axios.put(`${base_url}/${api_v}companies/${companyId}`, addData)
        )
      );

      await Promise.all(promises);

      dispatch({
        type: UPDATE_COMPANY_EXPERTISE,
        payload,
        key,
      });

      return true;
    };
// Set Expertises from admin 
export const updateSelectedExpertisesV2 =
  (addData, companyId, key) =>
    async (dispatch) => {
      const promises = [];

      promises.push(
        Promise.resolve(
          axios.put(`${base_url}/${api_v}companies/${companyId}`, addData)
        )
      );

      let data = await Promise.all(promises);
      const payload = data[0].data;

      dispatch({
        type: UPDATE_COMPANY_EXPERTISE,
        payload
      });

      return true;
    };
// Update Company avatar
export const updateCompanyAvatar = (data, logo, id) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_AVATAR,
    payload: logo,
  });

  try {
    const res = await axios.put(`${base_url}/${api_v}companies/${id}`, data);
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Update Company Bank Info
export const updateBank = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}company_bank_infos/${id}`,
      data
    );
    dispatch({
      type: UPDATE_BANK,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// ---------- Specializations --------------------------------------------------------------------------

// Create Specialization Item
export const createSpecialization =
  (data, key, targetUrl, fileName) => async (dispatch) => {

    let [, , , companyId,] = data.company.split("/");
    try {
      const res = await axios.post(`${base_url}/${api_v}${targetUrl}`, data);

      dispatch({
        type: CREATE_SPECIALIZATION,
        key,
        payload: res.data,
      });
      if (fileName !== undefined) {
        await dispatch(getCompany2(companyId, fileName))
      } else {
        await dispatch(getCompany(companyId))
      }
      return res.data;
    } catch (err) {
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      });
      return false;
    }
  };

// Update Specialization Item with PUT
export const updateSpecialization =
  (data, id, key, targetUrl) => async (dispatch) => {

    let [, , , companyId,] = data.company.split("/");
    try {
      const res = await axios.put(
        `${base_url}/${api_v}${targetUrl}/${id}`,
        data
      );
      await dispatch(getCompany(companyId))
      dispatch({
        type: UPDATE_SPECIALIZATION,
        id,
        key,
        payload: res.data,
      });
      return true;
    } catch (err) {
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      });
    }
  };

// Delete Specialization
export const deleteSpecialization =
  (id, targetUrl, key, fileName, companyId) => async (dispatch) => {
    try {
      await axios.delete(`${base_url}/${api_v}${targetUrl}/${id}`);
      if (fileName !== undefined) {
        await dispatch(getCompany2(companyId))
      }
      dispatch({
        type: DELETE_SPECIALIZATION,
        payload: id,
        key,
      });
      return id;
    } catch (err) {
      dispatch({
        type: GLOBAL_ERROR,
        payload: err,
      });
      return false;
    }
  };

// ---------- Licences --------------------------------------------------------------------------------------

// Create Licence
export const createLicence = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}licences`, data);

    dispatch({
      type: ADD_LICENCE,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Update Licence
export const updateLicence = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}licences/${id}`, data);

    dispatch({
      type: UPDATE_LICENCE,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// Delete Licence
export const deleteLicence = (id) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/${api_v}licences/${id}`);
    dispatch({
      type: DELETE_LICENCE,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
  }
};

// --------- Documents --------------------------------------------------------------------------------------

// Delete Document
export const deleteDocument = (id) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/${api_v}documents/${id}`);
    dispatch({
      type: DELETE_DOCUMENT,
      payload: id,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

// ---------- Fees --------------------------------------------------------------------------------------

// CREATE a new Fee(importhandover || fob || dap || rates)
export const createFee = (data, targetUrl, key) => async (dispatch) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}${targetUrl}`, data);
    dispatch({
      type: ADD_FEE,
      key: key,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
  }
};

// DELETE Fee(importhandover || fob || dap || rates)
export const deleteFee = (targetUrl, id, key) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/${api_v}${targetUrl}/${id}`);
    dispatch({
      type: DELETE_FEE,
      key: key,
      payload: { id: id },
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
  }
};

// UPDATE Fee(importhandover || fob || dap || rates)
export const updateFee = (data, targetUrl, id, key) => async (dispatch) => {
  try {
    const res = await axios.put(`${base_url}/${api_v}${targetUrl}/${id}`, data);
    dispatch({
      type: UPDATE_FEE,
      key: key,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
  }
};

// ---------- Utilities --------------------------------------------------------------------------------------

// clear Company
export const clearCompany = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY,
  });
};

// Set company updating state
export const setCompanyUpdating = () => {
  return {
    type: COMPANY_UPDATING,
  };
};
// Set loading state
export const setCompanyLoading = () => {
  return {
    type: COMPANY_LOADING,
  };
};

export const getAllCompanies = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}companies`);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const getAllPromoContacts = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}promo_keyPersonnel`);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const getAllCurrencies = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}currencies`);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const getAllCurrenciesList = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}currenciesList`);
    return res.data;
  } catch (err) {
    return null;
  }
};

// ---------- Events --------------------------------------------------------------------------------------
export const getAllEvents = async () => {
  try {
    const res = await axios.get(`${base_url}/${api_v}company_events`);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const createEvents = async (data) => {
  try {
    const res = await axios.post(`${base_url}/${api_v}company_events`, data);
    return res.data;
  } catch (err) {
    return null;
  }
};
export const editEvents = async (data, id) => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}company_events/${id}`,
      data
    );
    return res.data;
  } catch (err) {
    return null;
  }
};

export const addSubGroupCompany = (data) => (dispatch) => {
  return axios
    .post(`${base_url}/${api_v}sub_industry_rels`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addEventCompany = (data) => (dispatch) => {
  return axios
    .post(`${base_url}/${api_v}company_events`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createEmailVerification = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${base_url}/${api_v}send_registration_email`,
      data
    );
    // dispatch({
    //   type: SEND_EMAIL,
    //   payload: res.data,
    // });

    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });

    return false;
  }
};
//update Trigger
export const UpdateTrigger = (data) => async (dispatch) => {
  try {
    //BASEURL/v1/company/partners/trigger
    const res = await axios.put(
      `${base_url}/${api_v}company/partners/trigger`,
      data
    );
    dispatch({
      type: UPDATE_TRIGGER,
      // key,
      payload: res.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err,
    });
    return false;
  }
};

