
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Radio, Select, Checkbox, Switch, InputNumber, Slider, DatePicker, TimePicker, Upload, Icon } from 'antd';
import moment from "moment";
import NumberFormat from "react-number-format";
import AsyncSelect from "react-select/async";
import { fetchCities } from "actions/dataActions";

const { TextArea } = Input;
const customStyles = {
    option: (provided, state) => (
        {
            ...provided,
            borderBottom: "1px dotted #e0e0e0",
            padding: "14px 20px"
        }),
    container: provided => ({
        ...provided,
        lineHeight: "1.2",
        minWidth: 180
    }),
    menu: provided => ({
        ...provided,
        zIndex: 99
    }),
    control: provided => ({
        ...provided,
        borderColor: "#d9d9d9"
    }),
    indicatorSeparator: () => ({
        border: "none"
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    }
};
const InputFieldList = (props) => {

    //    const [fileList, setFileList] = useState([]);   
    const [selectFile, setSelectFile] = useState(false)
    // console.log("localStorage.getItemrolesUser", localStorage.getItem("rolesUser"))

    const beforeUpload = (file, fileList) => {
        if (fileList.length === 1) {
            setSelectFile(true)
            // message.error('You can only upload up to 3 files at once');
            return false;
        }
        // return true;
    };

    const handleRemoveFile = () => {
        setSelectFile(false)
    }

    let inputElement = null;
    switch (props.elementType) {
        case ('TEXTBOX'):

            inputElement = <Input /* disabled={props.disable} */ name="textbox" onChange={(e) => props.changed(e)} value={props.value} />
            break;
        case ('TEXTAREA'):
            inputElement = <TextArea name="textarea" onChange={(e) => props.changed(e)} value={props.value} />
            break;
        case ('CHECKBOX'):
            inputElement =
                <Checkbox.Group /*  onChange={props.changed} */ onChange={(event, value) => { props.changed(event, value, 'checkbox') }} value={props.value}>
                    {props?.elementConfig.options?.map((element, index) => {

                        return <Checkbox value={element.value} key={`checkbox_${index}`}>{element.displayValue}</Checkbox>
                    })}
                </Checkbox.Group>
            break;
        case ('RADIO'):
            // { console.log("props>>>>>>", props.fileStatus) }
            inputElement = <Radio.Group onChange={props.changed} value={props.value}>
                {props.elementConfig.options.map((element, index) => {
                    return <Radio.Button disabled={props.fileStatus === "expertise" && localStorage.getItem("rolesUser") !== 'ROLE_ADMIN' ? true : false} value={element.value} key={`radio_${index}`}>{element.displayValue}</Radio.Button>
                })}
            </Radio.Group>
            break;
        case ('SINGLE_SELECT'):
            inputElement = <Select
                // onChange={props.changed}
                onChange={(event, value) => { props.changed(event, value, 'singleselect') }}
                value={props.value}
            >
                {props.elementConfig?.options.map((element, index) => {
                    return (
                        <Select.Option value={element.value} key={`select_${index}`}>{element.displayValue}</Select.Option>)
                })}
            </Select>
            break;
        case ('SINGLE_SELECT_SEARCH'):
            (props.fieldStatus === 'airline' || props.fieldStatus === 'airport')
                ?
                inputElement = <AsyncSelect
                    className="customSelectWithChips"
                    styles={customStyles}
                    cacheOptions
                    defaultInputValue={props.value}
                    // onChange={props.changed}
                    onChange={(event, value) => { props.changed(event, value, 'singleSelectSearch') }}
                    loadOptions={(value, cb) =>
                        props.loadOptions(value, cb)
                    }
                    formatOptionLabel={props.formatOptionLabel}
                    getOptionValue={props.getOptionValue}
                />
                :
                inputElement =
                <AsyncSelect
                    className="customSelectWithChips"
                    styles={customStyles}
                    cacheOptions
                    defaultInputValue={props.value}
                    // onChange={props.changed}
                    onChange={(event, value) => { props.changed(event, value, 'singleSelectSearch') }}
                    loadOptions={(value, cb) =>
                        props.loadOptions(value, cb)
                    }
                    getOptionLabel={props.getOptionLabel}
                    getOptionValue={props.getOptionValue}
                />
            break;
        case ('MULTI_SELECT'):
            inputElement = <Select
                mode="multiple"
                style={{
                    width: '100%',
                }}
                // className="multiselect"

                name="dfdf"
                key="fdfd"
                label="multiselect"
                value={props.value?.length ? props.value : []}
                onChange={(event, value) => { props.changed(event, value, 'multiselect') }}
                //   onChange={(event,value) => { props.changed(event,value, 'multiselect') }}
                optionLabelProp="label"
            >
                {props.elementConfig.options.map((element, index) =>
                    <Select.Option value={element.displayValue} label={element.displayValue} key={`select_${index}`} clearSelectedItems>
                        {element.displayValue}
                    </Select.Option>)}
            </Select>
            break;
        case ('MULTI_SELECT_SEARCH'):
            (props.fieldStatus === 'airline' || props.fieldStatus === 'airport')
                ?
                inputElement = <AsyncSelect
                    className="customSelectWithChips"
                    styles={customStyles}
                    name='multiSelectSearch'
                    isMulti={true}
                    value={props.value}
                    onChange={props.changed}
                    /*    
                       isClearable={false}
                       // async={true}
                       // autoload={false}
                       // menuPlacement="auto"
                       // menuIsOpen={true}
                       // onSelectResetsInput = {false}
                       // leading={ true}
                       // cacheOptions={false}                              
                       loadOptions={(value, cb) =>
                           props.loadOptions(value, cb)
                       }
                       getOptionLabel={props.getOptionLabel}
                       getOptionValue={props.getOptionvalue}
                       formatOptionLabel={props.formatOptionLabel}
                       formatGroupLabel={props.formatGroupLabel} */
                    {...props}
                />
                :
                inputElement = <AsyncSelect
                    className="customSelectWithChips"
                    styles={customStyles}
                    name='multiSelectSearch'
                    isMulti={true}
                    value={props.value}
                    onChange={props.changed}
                    isClearable={false}
                    // async={true}
                    // autoload={false}
                    // menuPlacement="auto"
                    // menuIsOpen={true}
                    // onSelectResetsInput = {false}
                    // leading={ true}
                    // cacheOptions={false}
                    loadOptions={(value, cb) =>
                        props.loadOptions(value, cb)
                    }
                    getOptionLabel={props.getOptionLabel}
                    getOptionValue={props.getOptionvalue}
                // formatOptionLabel={props.formatOptionLabel}
                // formatGroupLabel={props.formatGroupLabel}
                // {...props}
                />
            break;
        case ('NUMBER'):
            inputElement = <InputNumber /* min={props.minLimit} max={props.maxLimit} */ /* onChange={props.changed} */ onChange={(event, value) => { props.changed(event, value, 'number') }} value={props.value} />;
            break;
        case ('EMAIL'):
            inputElement = <Input name="email" onChange={(event) => { props?.changed(event) }} value={props.value} />;
            break;
        case ('PASSWORD'):
            /*  eslint no-unused-expressions: off */
            inputElement = <Input.Password name="password" autoComplete='new-password' onChange={(event) => { props?.changed(event) }} value={props.value} />;
            break;
        case ('PERCENTAGE_SLIDER'):
            inputElement = <Slider /* onChange={props.changed} */ onChange={(event, value) => { props.changed(event, value, 'percentageSlider') }} value={props.value} />;
            break;
        case ('NUMBER_FORMAT'):
            inputElement = <NumberFormat
                name="numberFormat"
                customInput={Input}

                thousandSeparator={true}
                prefix={"$"}
                style={{ maxWidth: 300 }}
                onChange={props.changed}
                value={props.value}
            ></NumberFormat>
            break;
        case ('DATE'):
            inputElement = <DatePicker onChange={(event, value) => { props.changed(event, value, 'date') }} defaultValue={props.value ? moment(moment(props.value).format('YY-MM-DD'), 'YY-MM-DD') : ''} />
            break;
        case ('TIME'):
            inputElement = <TimePicker onChange={(event, value) => { props.changed(event, value, 'date') }} defaultValue={props.value ? moment(props.value, 'HH:mm:ss') : ''} />
            break;
        case ('DATE_TIME'):
            inputElement = <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(event, value) => { props.changed(event, value, 'date') }} defaultValue={props.value ? moment(moment(props.value).format('YY-MM-DD HH:mm:ss'), 'YY-MM-DD HH:mm:ss') : ''}
            />
            break;
        case ('SINGLE_FILE_UPLOAD'):
            inputElement = <Upload
                /*    name="uploadFile"
                   action=""
                   listType="picture"
                   defaultFileList={[...fileList]}
                   beforeUpload={beforeUpload}
                   onRemove={() => handleRemoveFile()}
                   onChange={() => handleChange()}
                   fileList={fileList} */
                /*   name="avatar"
                  listType="picture-card" */
                //    className="avatar-uploader"
                // showUploadList={false}
                name="uploadFile"
                action=""
                listType="picture"
                beforeUpload={beforeUpload}
                onRemove={() => handleRemoveFile()}
                onChange={props.changed}
                value={props.value}
            >
                <Button disabled={selectFile ? true : false} > <Icon type="upload" />Upload</Button>
            </Upload>
            break;
        case ('MULTI_FILE_UPLOAD'):
            inputElement = <Upload
                name="uploadFile"
                multiple
                action=""
                listType="picture"
                // beforeUpload={beforeUpload}
                // onRemove={() => handleRemoveFile()}
                onChange={props.changed}
                value={props.value}
            >
                <Button disabled={selectFile ? true : false} > <Icon type="upload" />Upload</Button>
            </Upload>
            break;
        case ('PHONE_NUMBER'):
            inputElement = <Input name="phoneNumber" value={props.value} onChange={props.changed} />
            break;
        default:
            inputElement = <></>;
    }
    return <>

        {!props.disable ?
            <>
                <Form.Item label={props.label} key={props} required={props?.shouldValidate?.required}>
                    {inputElement}
                </Form.Item>
                {props.error != '' && props.error != undefined && (<p className="error">{props.error}</p>)}
            </>
            : ""
        }

        {props.isCrossBtn == 1 && (
            <Button onClick={props.deleteMethod}>X</Button>
        )}
    </>;
};

export default InputFieldList;