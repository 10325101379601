import React, { Component } from "react";
import { Menu } from "antd";
import CustomSelect from "components/ui-elements/Select";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { compose } from "redux";
import { connect } from "react-redux";

const sortOptions = [
  { value: "date", label: "Name" },
  { value: "exports", label: "Exports focused" },
  { value: "imports", label: "Imports focused" },
  { value: "cross-trade", label: "Cross-trade focused" },
  { value: "e-commerce", label: "E-commerce focused" },
];

class NetworkSubHeader extends Component {
  state = {
    searchValue: "",
    current: "allCompanies",
    sortValue: "",
    sortLabel: "",
  };

  onInput = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  onSelectChange = (e) => {
    this.setState({ sortValue: e.value });
    this.setState({ sortLabel: e.label });

    const url = this.props.filterResults("sorting", e.value);
    this.props.updateURLAndData(url);
  };

  updateTab = (key) => {
    if (key === "allCompanies") {
      const url = this.props.filterResults("status", "any", "remove");
      return this.props.updateURLAndData(url);
    }
    if (key === "expired" || key === "expiredNoPaidDate") {
      console.log("key...", key);
      const url = this.props.filterResults(
        "isExpired",
        key === "expired" ? 1 : 2,
        "expired"
      );
      return this.props.updateURLAndData(url);
    }
    const url = this.props.filterResults("status", key);
    this.props.updateURLAndData(url);
  };

  onMenuClick = (e) => {
    this.setState({
      current: e.key,
    });
    this.updateTab(e.key);
  };

  componentDidMount() {
    if (this.props.history.location.search) {
      const searchParams = qs.parse(
        this.props.history.location.search.slice(1)
      );
      if (searchParams.hasOwnProperty("status")) {
        this.setState({ current: searchParams.status });
      }
      if (searchParams.hasOwnProperty("isExpired")) {
        this.setState({ current: "expired" });
      }
      if (searchParams.hasOwnProperty("sorting")) {
        sortOptions.map((val) => {
          if (val.value === searchParams.sorting)
            this.setState({ sortLabel: val.label });
        });
        this.setState({ sortValue: searchParams.sorting });
      }
    }
  }

  handleOnClick = () => { };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.tab !== this.props.tab) {
      this.setState({ current: "allCompanies" });
      this.setState({ sortLabel: "Sort By" });
    }
  };

  render() {
    const { totalItems, tab } = this.props;
    const { user } = this.props.auth;
    const { current, sortValue, sortLabel } = this.state;
   
    return (
      <div className="f-s-b v-space-1 px-4 pt-3">
        {user.roles.length && user.roles.includes("ROLE_ADMIN") ? (
          <div className="show-filters">
            <Menu
              onClick={this.onMenuClick}
              selectedKeys={[this.state.current]}
              mode="horizontal"
            >
              <Menu.Item key="allCompanies">
                All companies{" "}
                {current === "allCompanies" ? <> ({totalItems})</> : null}
              </Menu.Item>
              <Menu.Item key="Approved">
                Active {current === "Approved" ? <> ({totalItems})</> : null}
              </Menu.Item>
              <Menu.SubMenu key="application" title={<span>Application</span>}>
                <Menu.Item key="Pending">
                  Pending {current === "Pending" ? <> ({totalItems})</> : null}
                </Menu.Item>
                <Menu.Item key="Not Finished">
                  Not Finished{" "}
                  {current === "Not Finished" ? <> ({totalItems})</> : null}
                </Menu.Item>
                <Menu.Item key="InReview">
                  In Review{" "}
                  {current === "InReview" ? <> ({totalItems})</> : null}
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key="expired" title={<span>Expired</span>}>
                <Menu.Item key="expired">
                  Expired {current === "expired" ? <> ({totalItems})</> : null}
                </Menu.Item>
                <Menu.Item key="expiredNoPaidDate">
                  Expired no paid date{" "}
                  {current === "expiredNoPaidDate" ? (
                    <> ({totalItems})</>
                  ) : null}
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key="other" title={<span>Other</span>}>
                <Menu.Item key="Resigned">
                  Discontinued{" "}
                  {current === "Resigned" ? <> ({totalItems})</> : null}
                </Menu.Item>
                <Menu.Item key="Suspended">
                  Suspended{" "}
                  {current === "Suspended" ? <> ({totalItems})</> : null}
                </Menu.Item>
                <Menu.Item key="Blocked">
                  Blocked {current === "Blocked" ? <> ({totalItems})</> : null}
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </div>
        ) : (
          <div className="show-filters">
            <Menu
              onClick={this.onMenuClick}
              selectedKeys={[this.state.current]}
              mode="horizontal"
            >
              <Menu.Item key="allCompanies">
                All companies{" "}
                {current === "allCompanies" ? <> ({totalItems})</> : null}
              </Menu.Item>

              <Menu.Item key="NewMember">
                New members{" "}
                {current === "NewMember" ? <> ({totalItems})</> : null}
              </Menu.Item>

              <Menu.Item key="Suspended">
                Suspended{" "}
                {current === "Suspended" ? <> ({totalItems})</> : null}
              </Menu.Item>

              <Menu.Item key="Resigned">
                Discontinued {current === "Resigned" ? <> ({totalItems})</> : null}
              </Menu.Item>
            </Menu>
          </div>
        )}
        {this.props.history.location.pathname === '/network' &&
          <CustomSelect
            className="sort-input"
            variantClass="blank"
            placeholder={sortLabel ? sortLabel : "Sort By"}
            defaultValue={sortOptions[0]}
            options={sortOptions}
            value={sortValue}
            isClearable={false}
            isSearchable={false}
            onChange={this.onSelectChange}
          ></CustomSelect>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tab: state.data.setTab,
});

export default compose(withRouter, connect(mapStateToProps))(NetworkSubHeader);
