import React, { useEffect, useReducer, useState } from "react";
import promoIcon from "theme/assets/promoIcon.svg";
import { Row, Col, Divider } from "antd";

import "./css/index.css";
import {
  Table,
} from "antd";

import PageHeader from "components/ui-elements/PageHeader";
import Sidebar from "components/insurance/sidebar/sidebar";
import MainInsurance from "components/insurance/MainInsurance/MainInsurance";




const { Column } = Table;


const Insurance = (props) => {

  return (
    <div className="insurance-pageContainer">
      <PageHeader icon={promoIcon} title={`INSURANCE`} />
      <Row className="insurance-row">
        <Col className="insurance-col" span={6}>
          <Sidebar />
        </Col>
        <Col className="insurance-col-right" span={18}>
          <MainInsurance />
        </Col>
        

      </Row>
    </div>
  );
};

export default Insurance;
