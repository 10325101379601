import React, { useEffect, useReducer } from "react";
import { getInvitations } from "actions/dataActions";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { variables } from "utils/settings";
import styled from "@emotion/styled";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import { Table, Icon } from "antd";

const { Column } = Table;

const Status = styled("div")`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${props =>
    props.active ? variables.colors.bgSuccess : variables.colors.bgWarning};
`;

const initialState = {
  invitations: null,
  loading: true,
  page: 1,
  total: 100
};

function reducer(state, action) {
  switch (action.type) {
    case "GET_DATA":
      return {
        invitations: action.payload["hydra:member"],
        loading: false,
        total: action.payload["hydra:totalItems"],
        page: action.page
      };
    case "SET_LOADING":
      return {
        loading: true
      };
    default:
      throw new Error();
  }
}

const Invitations = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { invitations, loading, page, total } = state;
  // Fetch data based on PageNumber
  const fetchInvitations = page => {
    dispatch({
      type: "SET_LOADING"
    });
    getInvitations(page).then(data => {
      if (data) {
        dispatch({
          type: "GET_DATA",
          payload: data,
          page
        });
      }
    });
  };
  // Fetch data on mount
  useEffect(() => {
    fetchInvitations(page);
  }, []);
  // Switch Pages
  const changePage = page => {
    window.scrollTo(0, 0);
    fetchInvitations(page);
  };

  return (
    <div>
      <div className="network-header">
        <h1 className="page-title">Invitations</h1>
      </div>
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        {!invitations || loading ? (
          <SkeletonLoading itemsPerPage={30} />
        ) : (
          <>
            <Table dataSource={invitations} rowKey="code" pagination={false}>
              <Column
                title="User Registered"
                dataIndex="active"
                key="active"
                render={active => (
                  <Status active={active} className="f-c-c">
                    <Icon type={active ? "check" : "clock-circle"}></Icon>
                  </Status>
                )}
              ></Column>

              <Column
                title="Invitation sent to"
                dataIndex="email"
                key="email"
              ></Column>
              <Column
                title="Invitation sent from"
                dataIndex="user.email"
                key="user"
              ></Column>
            </Table>
            <Pagination
              onChange={changePage}
              locale={localeInfo}
              current={page}
              defaultPageSize={30}
              total={total}
            ></Pagination>
          </>
        )}
      </div>
    </div>
  );
};

export default Invitations;
