import React, { useState, useEffect } from "react";
import styled from "@emotion/styled-base";
import { Checkbox, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";

const ExpertiseSelection = styled(Checkbox.Group)`
  min-height: 100px;
  display:block;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0.7em;
  margin-bottom: 24px;
`;

const SubIndustriesExpertiese = ({ /* subIndustries, */ handleExpertiseSelection, handleSubIndustry, selectedSubIndustryIds, companyData, selectedExpertieseIds, userRole, checkedValObj }) => {
    let status = companyData?.companySettings[0]?.status;
    const subIndustries = useSelector((state) => state.common.allSubindustry)
    // console.log("selectedSubIndustryIds...................................", selectedSubIndustryIds)
    // console.log("subIndustries", subIndustries)
    // console.log("status", status)
    console.log("selectedExpertieseIds>>>>",selectedExpertieseIds)
    return (
        <div>
            {
                !subIndustries?.length
                    ?
                    <Skeleton active />
                    /*  <SkeletonLoading itemsPerPage='1' /> */
                    :
                    <div className="card-body">
                        <Checkbox.Group
                            disabled={status === "InReview" || userRole ? false : true}
                            onChange={handleSubIndustry}
                            value={selectedSubIndustryIds}
                            className="expertise-box-group"
                        >
                            <Row>
                                {
                                    subIndustries?.map((element, index) => {
                                        return (
                                            <Col span={24} key={index} className="expertise-box">
                                                {/* <Checkbox
                                                    disabled={selectedSubIndustryIds.length >= 3 && (!selectedSubIndustryIds.includes(element.id))}
                                                    value={element.id} key={`subGroup${index}`} className="expertise-box-head"
                                                > */}
                                                {/* {console.log("chewck", selectedSubIndustryIds.includes(1))} */}
                                                <Checkbox
                                                    disabled={(((selectedSubIndustryIds.includes(1) && element.id === 6 ? true : false) ||
                                                        (selectedSubIndustryIds.includes(6) && element.id === 1 ? true : false))
                                                        ||
                                                        (selectedSubIndustryIds.length >= 3 && (!selectedSubIndustryIds.includes(element.id))) ? true : false)
                                                    }
                                                    value={element.id} key={`subGroup${index}`} className="expertise-box-head"
                                                >
                                                    {element.industryLogo ?
                                                        <div className="logoAndBadge">
                                                            <img
                                                                src={
                                                                    element.industryLogo
                                                                }
                                                                className="logo-img"
                                                            /><span className="expertise">Expertise</span>
                                                        </div>
                                                        : "None"}
                                                </Checkbox>

                                                {subIndustries.map((value, index) => {
                                                    if (value.id === element.id && selectedSubIndustryIds.includes(value.id)) {
                                                        let selectedSubIndustryExpertiseList = value.expertieses?.map(a => ({
                                                            label: a.name,
                                                            value: a.id,
                                                            key: value.name,
                                                            disabled: checkedValObj[value?.name] && checkedValObj[value?.name]?.length > 3 && !checkedValObj[value?.name].includes(a.id)
                                                            /*   disabled: selectedExpertieseIds?.length > 3 && !selectedExpertieseIds.includes(a.id) */
                                                        }));

                                                        return (
                                                            < ExpertiseSelection
                                                                key={index}
                                                                className="userExpertise"
                                                                options={selectedSubIndustryExpertiseList}
                                                                value={selectedExpertieseIds}
                                                                onChange={(check) => { handleExpertiseSelection(value.name, check) }}
                                                            ></ExpertiseSelection>
                                                        )
                                                    }
                                                })}
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Checkbox.Group>
                    </div>
            }
        </div>
    )
}
export default SubIndustriesExpertiese;