import React from "react";
import { Collapse, Table } from "antd";

const { Panel } = Collapse;

const { Column } = Table;

function dap(dap, documents, renderDocuments) {
	const data = dap.map(dap => (
		{
			key: dap.id,
			id: '#',
			fee: dap.dapFee,
			price: dap.dapPrice
		}
	));

	return (
		<Collapse defaultActiveKey={['1']} bordered={false} expandIconPosition="right" className="rates-fees-collapse" style={{ marginTop: '2.5rem', backgroundColor: 'transparent' }}>
			<Panel header={<h2 className="mb-0">DAP/DDP</h2>} key="1">
				{data.length > 0 ?
					<Table dataSource={data} pagination={false}>
						<Column title="#" dataIndex="id" key="#" />
						<Column title="Fees" dataIndex="fee" key="fees" />
						<Column title="Price" dataIndex="price" key="price" />
					</Table>:null}
				{documents.length ? renderDocuments(documents) : null}
			</Panel>
		</Collapse>
	);
}

const DAP = ({ company, getSourceWiseDocument, renderDocuments }) => {
	const dapDocuments = getSourceWiseDocument(company.document, 'dap');

	return (company.dap.length || dapDocuments.length) ? dap(company.dap, dapDocuments, renderDocuments) : <></>;
};

export default DAP;
