import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Button, Drawer, Skeleton, message, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import ConnectionCard from "./ConnectionCard"
import EditConnection from "./EditConnection"
import { showRegisterLoading } from "actions/commonActions";
import { getNetworkBrevoList, deleteNetworkBrevoList, getSyncContacts } from '../../actions/questionAction';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Connection = () => {
    const dispatch = useDispatch()

    const [listName, setListName] = useState([])
    const [drawer, setDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const [progressStatus, setProgressStatus] = useState(null)
    const [connectionData, setConnectionData] = useState(null)
    const [networkBrevoList, setNetworkBrevoList] = useState([])
    const showRegisterLoadingAction = (value) => showRegisterLoading(dispatch, value);
    const { logo } = useSelector((state) => state?.common?.domain)
    const registerLoading = useSelector(state => state.common.registerLoading);

    const initialFunction = async () => {
        const networkBrevoResponse = await dispatch(getNetworkBrevoList())
        setNetworkBrevoList(networkBrevoResponse)
        setLoading(false)
    }

    useEffect(() => {
        initialFunction()
    }, [])

    const handleOpenDrawer = (data) => {
        setDrawer(true)
        setConnectionData(data)
    }
    const handleCloseDrawer = () => {
        setDrawer(false)
    }
    /*   const handleDelete = async (data) => {
          await dispatch(deleteNetworkBrevoList(data?.id))
          setLoading(true)
          initialFunction()
          message.success("Deleted successfully");
      } */
    const handleListName = (e, id, index) => {
        e.persist()
        setListName((state) => {
            const newObject = { ...state?.listName };
            newObject[`${index}`] = { value: e?.target?.value, key: id }
            return { listName: newObject }
        });
    }

    const handleSyncContact = async (data, index) => {
        setProgressStatus(data.id)
        showRegisterLoadingAction(true);
        const response = await dispatch(getSyncContacts(listName?.listName[`${index}`]?.value, data?.id))
        if (!response) {
            message.error("Invalid detail");
            setProgressStatus(null)
            showRegisterLoadingAction(false);
        } else {
            message.success("Sync contacts successfully");
            setProgressStatus(null)
            showRegisterLoadingAction(false);
        }
    }

    return (
        <>
            {loading ?
                <div className='formMaininner appformMaininner'>
                    <Skeleton className="portalsetting-skelton"/>
                </div>
                :
                networkBrevoList?.map((element, index) => {
                    return (
                        <div className='formMaininner appformMaininner' style={{ marginBottom: 12 }} key={index}>
                            <Row gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }} className="connection-main">
                                <Col className="gutter-row" span={24}>
                                    <Row gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }} className="topRow">
                                        <Col className="gutter-row" span={14}>
                                            <img className="headernetworkimage" src={element?.isNap ? logo : element?.subIndustry?.industryLogo} />
                                        </Col>

                                        <Col className="gutter-row sync-contacts" span={10}>
                                            <Input size="default" name='listName' key={element.id} placeholder="List name" style={{ width: 200, marginRight: 13 }} onChange={((e) => handleListName(e, element.id, index))} defaultValue={listName[`${index}`]?.value || ''} />
                                            <Button type="primary" key={element.id} className="create-btn" onClick={() => handleSyncContact(element, index)}
                                                disabled={(
                                                    (listName.length !== 0 && listName.listName[index]?.value !== '' && element.id === listName.listName[`${index}`]?.key)
                                                        ? false
                                                        :
                                                        true
                                                )}
                                                loading={(
                                                    (progressStatus === element?.id)
                                                        ? registerLoading
                                                        :
                                                        false
                                                )}
                                            >Sync contacts</Button>
                                        </Col>
                                    </Row>
                                    <Col className="gutter-row" span={24}>
                                        <div className="connection-header-child">{/* {(progressStatus === element?.id) && <Spin indicator={antIcon} />} */}</div>
                                    </Col>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <div className="connection-card-parent">
                                        <ConnectionCard title="API Key" data={element?.apiKey} />
                                        <ConnectionCard title="Folder id" data={element?.folderId} />
                                        <Button
                                            className="invite-user-cancel-btn connection-edit-btn"
                                            type="secondary"
                                            onClick={() => handleOpenDrawer(element)}
                                        >
                                            Edit
                                        </Button>
                                        {/*  <Button
                                            className="invite-user-cancel-btn connection-edit-btn"
                                            type="secondary"
                                            onClick={() => handleDelete(element)}
                                        >
                                            Delete
                                        </Button> */}
                                    </div>
                                </Col>
                            </Row >
                        </div >
                    )
                })
            }
            <Drawer
                width={700}
                closable={false}
                visible={drawer}
                destroyOnClose={true}
                onClose={() => handleCloseDrawer()}
            >
                <EditConnection data={connectionData} closeDrawer={() => handleCloseDrawer()} /* setLoading={setLoading} */ initialFunction={initialFunction} />
            </Drawer>
        </>
    )
}
export default Connection;