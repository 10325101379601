import React from "react";
import { connect } from "react-redux";
import { Menu, Dropdown, Modal, Icon, Input, Button } from "antd";
import {
  deleteCompany,
  updateCompanySettings,
  updateCompanyStatus,
} from "actions/dataActions";
import styled from "@emotion/styled";
import moment from "moment";
import { variables } from "utils/settings";
import { useLocation } from "react-router-dom";

const { confirm } = Modal;

const MenuItem = styled(Menu.Item)`
  i {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

const Status = styled("div")`
  padding: 10px 15px;
`;

const StatusButton = styled(Button)`
  border-radius: 20px;
  border: none;
  box-shadow: none;
  ${(props) =>
    props.status === "Blocked" &&
    `background-color: ${variables.colors.bgDanger};
    `}

  ${(props) =>
    props.status === "Approved" &&
    `background-color: ${variables.colors.bgSuccess};
      `}
  ${(props) =>
    props.status === "InReview" &&
    `background-color: ${variables.colors.bgWarning};
      `}

  ${(props) =>
    props.status === "Not Finished" &&
    `background-color: ${variables.colors.bgWarning};
      `}

  ${(props) =>
    props.status === "Pending" &&
    `background-color: ${variables.colors.bgInfo};
      `}
`;

const CompanyStatus = ({
  companyName,
  selectCard,
  companyId,
  companySettings,
  companyInfo,
  auth,
  deleteCompany,
  updateCompanyStatus,
}) => {
  let location = useLocation();
  const { status, resigned, approvedDate, paidDate } = companySettings;

  const printContent = (key) => {
    let data;
    switch (key) {
      case "approve":
        data = "Approval confirmation email will be sent to company admin";
        break;

      case "block":
        data = (
          <>
            <label htmlFor="block-message">
              Reason for declining company profile
            </label>
            <Input.TextArea id="block-message"></Input.TextArea>
          </>
        );
        break;

      case "decline":
        data = (
          <>
            <label htmlFor="block-message">
              Reason for declining company profile
            </label>
            <Input.TextArea id="block-message"></Input.TextArea>
          </>
        );
        break;

      case "Discontinue":
        data = "Discontinue message example...";
        break;
    }

    return data;
  };

  const showConfirm = (key) => {
    let data;

    confirm({
      title: `Are you sure you want to ${key.toUpperCase()} - ${companyName}`,
      content: printContent(key),
      onOk: () => {
        switch (key) {
          case "delete":
            return deleteCompany(companyId).then((res) => {
              selectCard();
            });
            break;

          case "approve":
            data = {
              status: 1,
              adminMessage: "",
            };
            break;

          case "InReview":
            data = {
              status: 6,
              adminMessage: "",
            };
            break;

          case "silent-approve":
            data = {
              status: 4,
              adminMessage: "",
            };
            break;

          case "block":
            data = {
              status: 2,
              adminMessage: document.getElementById("block-message").value,
            };
            break;

          case "decline":
            data = {
              status: 0,
              adminMessage: document.getElementById("block-message").value,
            };
            break;

          case "suspend":
            data = {
              status: 5,
              adminMessage: "",
            };
            break;

          case "Discontinue":
            data = {
              status: 3,
              adminMessage: "",
            };
            break;
          default:
            data = false;
        }

        if (!data) {
          return;
        }

        return updateCompanyStatus(data, companyId).then(() => {
          selectCard();
        });
      },
      onCancel() {
        selectCard();
      },
    });
  };

  const menu = () => {
    // Not Finished || Pending
    if (
      status === "Not Finished" ||
      status === "Pending" ||
      status === "InReview"
    ) {
      return (
        <Menu>
          <MenuItem key="approve" onClick={() => showConfirm("approve")}>
            Approve
          </MenuItem>
          <MenuItem
            key="sapprove"
            onClick={() => showConfirm("silent-approve")}
          >
            Silent Approve
          </MenuItem>
          <MenuItem key="suspend" onClick={() => showConfirm("suspend")}>
            Suspend
          </MenuItem>
          <MenuItem key="block" onClick={() => showConfirm("block")}>
            Block
          </MenuItem>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="delete" onClick={() => showConfirm("delete")}>
            <Icon type="delete"></Icon>Delete
          </MenuItem>
        </Menu>
      );
    }

    // Approved
    if (status === "Approved") {
      return (
        <Menu>
          <MenuItem key="block" onClick={() => showConfirm("block")}>
            Block
          </MenuItem>
          <MenuItem key="InReview" onClick={() => showConfirm("InReview")}>
            In Review
          </MenuItem>
          <MenuItem key="resign" onClick={() => showConfirm("Discontinue")}>
            Discontinue
          </MenuItem>
          <MenuItem key="suspend" onClick={() => showConfirm("suspend")}>
            Suspend
          </MenuItem>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="delete" onClick={() => showConfirm("delete")}>
            <Icon type="delete"></Icon>Delete
          </MenuItem>
        </Menu>
      );
    }

    // Blocked || Resigned
    if (status === "Blocked" || status === "Resigned") {
      return (
        <Menu>
          <MenuItem key="approve" onClick={() => showConfirm("approve")}>
            Approve
          </MenuItem>
          <MenuItem
            key="sapprove"
            onClick={() => showConfirm("silent-approve")}
          >
            Silent Approve
          </MenuItem>
          <MenuItem key="suspend" onClick={() => showConfirm("suspend")}>
            Suspend
          </MenuItem>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="delete" onClick={() => showConfirm("delete")}>
            <Icon type="delete"></Icon>Delete
          </MenuItem>
        </Menu>
      );
    }

    // Suspended
    if (status === "Suspended") {
      return (
        <Menu>
          <MenuItem key="approve" onClick={() => showConfirm("approve")}>
            Approve
          </MenuItem>
          <MenuItem
            key="sapprove"
            onClick={() => showConfirm("silent-approve")}
          >
            Silent Approve
          </MenuItem>
          <MenuItem key="block" onClick={() => showConfirm("block")}>
            Block
          </MenuItem>
          <MenuItem key="resign" onClick={() => showConfirm("Discontinue")}>
            Discontinue
          </MenuItem>
          <MenuItem key="decline" onClick={() => showConfirm("decline")}>
            Decline
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="delete" onClick={() => showConfirm("delete")}>
            <Icon type="delete"></Icon>Delete
          </MenuItem>
        </Menu>
      );
    }
  };

  const findStatus = () => {
    if (status === "Resigned") {
      return "Discontinue";
    } else {
      return status;
    }
    // console.log("stat", status)
    // if (!resigned || status === "Suspended") {
    //   return status;
    // }
    // return "Resigned";
  };

  const { user } = auth;
  return (
    <Status className="f-s-b" status={status}>
      {location.pathname == "/network" ? (
        <div
          className={
            user.roles.length && user.roles.includes("ROLE_ADMIN")
              ? "show-filters"
              : "hide-filters"
          }
        >
          <span
            className="text-secondary"
            style={{ fontSize: 12, marginRight: 6 }}
          >
            {paidDate ? (
              <>
                {`Exp. date: ${moment(paidDate)
                  .add(1, "y")
                  .format("DD/MM/YYYY")}`}
              </>
            ) :
              companyInfo?.parentCompany?.paidDate ?
                <>
                  {`Exp. date: ${moment(companyInfo?.parentCompany?.paidDate)
                    .add(1, "y")
                    .format("DD/MM/YYYY")}`}
                </>
                :
                approvedDate !== null ? (
                  <>
                    {`Exp. date: ${moment(approvedDate)
                      .add(1, "y")
                      .format("DD/MM/YYYY")}`}
                  </>
                ) : (
                  <>{`Exp. date: Not Available`}</>
                )}
            {paidDate ? (
              <div>
                {`NAP paid date: ${moment(paidDate).format("DD/MM/YYYY")}`}
              </div>
            ) :
              companyInfo?.parentCompany?.paidDate ?
                <div>
                  {`NAP paid date: ${moment(companyInfo?.parentCompany?.paidDate).format("DD/MM/YYYY")}`}
                </div>
                :
                <div>{`NAP paid date: Not Available`}</div>
            }
          </span>
        </div>
      ) : (
        ""
      )}

      <div style={{ display: "flex", flexDirection: "column" }} className={`${location?.pathname != "/network" && "review-inner"}`}>
        {location?.pathname == "/network" ?
          <span
            className="text-secondary"
            style={{ fontSize: 12, marginRight: 6 }}
          >
            {resigned && `${moment(resigned).format("DD/MM/YYYY")}`}
          </span> :
          ''}

        {auth.user.roles.includes("ROLE_ADMIN") && (
          <Dropdown
            overlay={menu}
            onVisibleChange={(visible) => selectCard(visible)}
            trigger={["click"]}
            placement="topRight"
            overlayStyle={{ minWidth: 200 }}
          >
            <StatusButton status={status} className="ant-dropdown-link">
              {findStatus()} <Icon type="down" />
            </StatusButton>
          </Dropdown>
        )}
        {location?.pathname != "/network" ? (
          paidDate ? (
            <>
              {`Exp. date: ${moment(paidDate)
                .add(1, "y")
                .format("DD/MM/YYYY")}`}
            </>
          ) :
            companyInfo?.parentCompany?.paidDate ?
              <>
                {`Exp. date: ${moment(companyInfo?.parentCompany?.paidDate)
                  .add(1, "y")
                  .format("DD/MM/YYYY")}`}
              </>
              :
              approvedDate !== null ? (
                <>
                  {`Exp. date: ${moment(approvedDate)
                    .add(1, "y")
                    .format("DD/MM/YYYY")}`}
                </>
              ) : (
                <>{`Exp. date: Not Available`}</>
              )
        ) : (
          ""
        )}
      </div>
    </Status>
  );
};

export default connect(null, {
  updateCompanySettings,
  updateCompanyStatus,
  deleteCompany,
})(CompanyStatus);
