import React from "react";
import { connect } from "react-redux";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Divider,
  Button,
  Icon,
  Form,
  Typography,
  message
} from "antd";
import Dropzone from "components/ui-elements/Dropzone";
import FilePreview from "components/ui-elements/FilePreview";
import { findRateUrl, generateId } from "utils/functions";

const { Title } = Typography;
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input placeholder={`Enter ${this.props.title}`} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      isNew,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing || isNew ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(record.id + dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newItems: [], editingKey: "", loading: {}, deleting: {} };
    this.columns = [
      {
        title: "Description",
        dataIndex: `${this.props.name}Fee`,
        width: "35%",
        editable: true
      },
      {
        title: "Rate",
        dataIndex: `${this.props.name}Price`,
        width: "35%",
        editable: true
      },
      {
        title: "Operation",
        align: "right",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <>
              {record.isNew ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <Button
                        type="primary"
                        className="create-btn" 
                        loading={this.state.loading[`${record.id}`]}
                        onClick={() => this.createNew(form, record.id)}
                      >
                        Create
                      </Button>
                    )}
                  </EditableContext.Consumer>
                  <Button  className="cancel-btn" type="link" onClick={() => this.removeNew(record.id)}>
                    Cancel
                  </Button>
                </span>
              ) : (
                <span>
                  {editable ? (
                    <span>
                      <Button
                        type="link"
                        onClick={() => this.cancel(record.id)}
                      >
                        Cancel
                      </Button>
                      <EditableContext.Consumer>
                        {form => (
                          <Button
                            type="primary"
                            loading={this.state.loading[`${record.id}`]}
                            onClick={() => this.save(form, record.id)}
                          >
                            Save
                          </Button>
                        )}
                      </EditableContext.Consumer>
                    </span>
                  ) : (
                    <span>
                      <Button
                        disabled={editingKey !== ""}
                        type="link"
                        className="btn-edit"
                        shape="circle"
                        onClick={() => this.edit(record.id)}
                      >
                        <Icon type="edit" theme="filled"></Icon>
                      </Button>

                      <Popconfirm
                        title="Sure to delete?"
                        placement="topRight"
                        onConfirm={() => this.delete(record.id)}
                      >
                        <Button
                          disabled={editingKey !== ""}
                          className="btn-edit"
                          loading={this.state.deleting[`${record.id}`]}
                          shape="circle"
                          type="link"
                        >
                          <Icon type="delete" theme="filled"></Icon>
                        </Button>
                      </Popconfirm>
                    </span>
                  )}
                </span>
              )}
            </>
          );
        }
      }
    ];
  }

  isEditing = record => record.id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  add = () => {
    const id = generateId();

    this.setState({
      newItems: [...this.state.newItems, { id, isNew: true }]
    });
  };

  removeNew = id => {
    this.setState({
      newItems: this.state.newItems.filter(item => item.id !== id)
    });
  };

  createNew = (form, id) => {
    form.validateFields(async (error, row) => {
      if (error) {
        if (
          error[`${id}${this.props.name}Fee`] ||
          error[`${id}${this.props.name}Price`]
        ) {
          return;
        }
      }

      // Set loading for ID
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true }
      });
      let promise = this.props.onCreateFee(row, id, this.props.name);
      promise.then(res => {
        message.success("Record created successfully")
      }).catch(err => {
        message.error("Something went wrong")
      })
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`${id}`]: false
        },
        newItems: prevState.newItems.filter(newItem => newItem.id !== id)
      }));
    });
  };

  delete = async id => {
    this.setState({
      deleting: { ...this.state.deleting, [`${id}`]: true }
    });
    let promise = this.props.onDeleteFee(id, this.props.name);
    promise.then(res => {
      message.success("Record deleted successfully")
    }).catch(err => {
      message.error("Something went wrong")
    })
  };

  save(form, id) {
    form.validateFields(async (error, row) => {
      if (error) {
        if (
          error[`${id}${this.props.name}Fee`] ||
          error[`${id}${this.props.name}Price`]
        ) {
          return;
        }
      }
      // Set loading for ID
      this.setState({
        loading: { ...this.state.loading, [`${id}`]: true }
      });
      // API request
      let promise = this.props.onUpdateFee(row, id, this.props.name);
      promise.then(res => {
        message.success("Information updated successfully")
      }).catch(err => {
        message.error("Something went wrong")
      })
      // Clean up after
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`${id}`]: false
        },
        editingKey: ""
      }));
    });
  }

  // On Dropzone
  uploadFiles = files => {
    this.props.onUpload(files, this.props.name);
  };

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          isNew: record.isNew,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    const { documents, onDocumentDelete, fees } = this.props;

    return (
      <div className="card">
        <EditableContext.Provider value={this.props.form}>
          <div className="card-header">
            <h2>{this.props.title}</h2>
          </div>

          <Table
            components={components}
            rowKey="id"
            dataSource={fees.concat(this.state.newItems)}
            columns={columns}
            pagination={false}
            rowClassName="editable-row"
          />
          <div className="card-body">
            <Button
              className="add-new-btn"
              type="dashed"
              onClick={this.add}
              style={{ width: "40%", marginBottom: 40 }}
            >
              <Icon type="plus" /> Add new
            </Button>

            <Title level={4}>Documents</Title>

            {documents.map(document => (
              <FilePreview
                key={document.id}
                id={document.id}
                onDelete={onDocumentDelete}
                name={document.originalName}
                link={document.path}
              />
            ))}

            <Dropzone handleDrop={this.uploadFiles} />
          </div>
        </EditableContext.Provider>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
