import React, { useState } from "react";
import Icon from "components/ui-elements/Icon";
import { variables } from "utils/settings";
import styled from "@emotion/styled";

const Container = styled("section")`
  border-bottom: 1px solid ${variables.colors.border};
  &:last-child {
    border-bottom: none;
  }
`;
const Header = styled("div")`
  display: flex;
  overflow: hidden;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    background-color: ${variables.colors.light};
  }
`;
const Body = styled("div")`
  padding-left: 38px;

  .ant-form-item-label {
    line-height: inherit;
    text-align: left;
    white-space: inherit;
  }
`;
const PuzzleIcon = styled(Icon)`
  font-size: 1.8em;
  margin-right: 0.18em;
  color: orange;
`;

const ArrowIcon = styled(Icon)`
  padding: 10px;
  transition: 0.3s ease;
  transform: ${props => props.toggle && "rotate(180deg)"};
`;

const ExpertiseItem = props => {
  const [toggle, setToggle] = useState(true);

  const handleToggle = () => {
    setToggle(toggle => !toggle);
  };

  return (
    <Container>
      <Header onClick={handleToggle}>
        <div>
          <PuzzleIcon name="puzzle-piece"></PuzzleIcon>
          {props.title}
        </div>
        <ArrowIcon name="angle-down" toggle={toggle}></ArrowIcon>
      </Header>
      {toggle && <Body>{props.children}</Body>}
    </Container>
  );
};

export default ExpertiseItem;
