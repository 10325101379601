import React from "react";
import tradelaneIcon from 'theme/assets/tradelaneIcon.svg';

const CompanyTradelane = ({ items }) => {
  if (items.length === 0) {
    return <></>;
  }
  return (
    <div>
      <img src={tradelaneIcon} className="sectionIcon" />
      <h4>Tradelane specialization</h4>
      <ul className="list-blank">
        {items.map(item => (
          <li key={item.id}>{item.specialization}</li>
        ))}
      </ul>
    </div>
  );
};

export default CompanyTradelane;
