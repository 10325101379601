import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Typography, Checkbox, Skeleton } from "antd";
import { api_v } from "utils/settings";
import { fetchCities } from "actions/dataActions";
import { createBranch } from "actions/branchActions";
import CustomSelect from "components/ui-elements/Select";
import { getSubGroupList } from "actions/dataActions";
import { DOMAIN } from "utils/enum";

const { Title } = Typography;

const CreateBranch = (props) => {
  const dispatch = useDispatch()
  const { companyInfo, companyId, isAdmin } = props;
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [networkLoading, setNetworkLoading] = useState(false);
  const [filteredSubIndustry, setFilteredSubIndustry] = useState([])
  const [selectedSubIndustry, setSelectedSubIndustry] = useState([])
  const [subIndustries, setSubIndustries] = useState([])
  const { domain } = useSelector((state) => state.common.domain)
  console.log("domain..", domain)

  useEffect(() => {
    initialFunction()
    /*  if (companyInfo) {
       if (isAdmin) {
 
         const { data } = companyInfo
         console.log("data.....", data)
         setFilteredSubIndustry(data?.subIndustry)
 
         // const { companies } = companyInfo
         // const companyData = companies?.filter((item) => item.id === props.companyId)
         // setFilteredSubIndustry(companyData[0]?.subIndustry)
       } else {
         const { data } = companyInfo
         setFilteredSubIndustry(data?.subIndustry)
 
       }
     } */

  }, [])
  const initialFunction = async () => {
    const response = await dispatch(getSubGroupList());
    setSubIndustries(response);
  };
  // console.log("subIndustries", subIndustries)
  useEffect(() => {
    if (subIndustries?.length === 0) {
      setNetworkLoading(true)
    } else {
      setNetworkLoading(false)
    }
  }, [subIndustries])

  const handleSubIndustries = (value) => {
    let params = {
      "subIndustry": []
    }
    params["subIndustry"] = value.map((element) => "/v1/sub_industries/" + element);
    setSelectedSubIndustry(params)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = {
          branchCity: values.branchCity.cityAsciiname,
          country: `${api_v}countries/${props.countryId}`,
          company: `${api_v}company/${props.companyId}/profile`,
          branchName: values.branchName,
          subIndustry: selectedSubIndustry.subIndustry
        };

        setLoading(true);
        props.createBranch(data).then((success) => {
          setLoading(false);
          if (success) {
            props.onClose();
            props.form.resetFields();
          }
        });
      }
    });
  };

  return (
    <div style={{ maxWidth: 500, margin: "0 auto" }}>
      <Title level={2}>Add a new branch</Title>

      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator("branchName", {
            initialValue: "",
            rules: [
              {
                required: true,
                message: "Please input name",
                whitespace: true,
              },
            ],
          })(<Input size="large" placeholder="Name your branch" />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("branchCity", {
            initialValue: "",
            rules: [
              {
                required: true,
                type: "object",
                message: "Please choose branch City",
              },
            ],
          })(
            <CustomSelect
              async={true}
              placeholder={"Select City"}
              cacheOptions
              loadOptions={(input, callback) =>
                fetchCities(input, props.countryId, callback)
              }
              noOptionsMessage={(input) => "Type to search"}
              getOptionLabel={(option) => option.cityAsciiname}
              getOptionValue={(option) => option.id}
            ></CustomSelect>
          )}
        </Form.Item>
        {networkLoading
          ?
          <Skeleton />
          :
          <Checkbox.Group
            //  type="checkbox"
            //   value={selectedSubIndustry}
            onChange={handleSubIndustries}
            className="expertise-box-group expertise-box-group-another"
          >
            {domain === DOMAIN.NEX
              ?
              // filteredSubIndustry?.map((element, index) => {
              subIndustries?.map((element, index) => {
                return (
                  <Checkbox value={element.id} key={`subGroup${index}`} className=/* {selectedSubIndustry.includes(element.id) ? "ant-checkbox-wrapper-checked": */"expertise-box-head"/* } */>
                    {element.industryLogo ?
                      <div className="logoAndBadge">
                        <img
                          src={
                            element.industryLogo
                          }
                          className="logo-img"
                        />
                      </div>
                      : "None"}
                  </Checkbox>
                )
              })
              :
              subIndustries?.map((element, index) => {
                return (
                  element?.name !== 'NeX Academy'
                  &&
                  <Checkbox value={element.id} key={`subGroup${index}`} className=/* {selectedSubIndustry.includes(element.id) ? "ant-checkbox-wrapper-checked": */"expertise-box-head"/* } */>
                    {element.industryLogo ?
                      <div className="logoAndBadge">
                        <img
                          src={
                            element.industryLogo
                          }
                          className="logo-img"
                        />
                      </div>
                      : "None"}
                  </Checkbox>
                )
              })
            }
          </Checkbox.Group>
        }
        <Form.Item>
          <Button
            className="invite-user-cancel-btn"
            type="secondary"
            style={{ marginRight: 8 }}
            htmlType="button"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button className="create-btn" type="primary" htmlType="submit" loading={loading}>
            Create branch
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const WrappedCreateBranch = Form.create({ name: "createBranch" })(CreateBranch);

export default connect(null, { createBranch })(WrappedCreateBranch);
