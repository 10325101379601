import { getDomainList } from 'actions/commonActions';
// import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import { DOMAIN } from "./utils/enum";
// import { api_v, base_url } from 'utils/settings';
// import defaultStyle from './theme/css/style.css';
// import defaultStyle from './theme/css/style.css';
// import NexStyle from './theme/css/stylenex.css';
// import indexCss from './index.css';
// import indexRedCss from './indexred.css';

const DynamicCSS = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const { cssLink } = useSelector((state) => state.common.domain)

  const initialFunction = async (domain) => {
    const response = await dispatch(getDomainList(domain));
  };

  useEffect(() => {
    const domain = window.location.hostname;
    // console.log("domain", domain)
    // const domain = DOMAIN.NEX;
    initialFunction(domain);
    if (domain === DOMAIN.NEX) {
      const url = `./theme/css/${cssLink}.css`;
      import('./theme/css/stylenex.css').then(() => {
        loadCSS('./theme/css/stylenex.css');
      });
      setLoading(false)
    } else if (domain === DOMAIN.NAP) {
      const url = `./theme/css/${cssLink}.css`;
      import('./theme/css/style.css').then(() => {
        loadCSS('./theme/css/style.css');
      });
    } else if (domain === DOMAIN.STAGE_NEX) {
      const url = `./theme/css/${cssLink}.css`;
      import('./theme/css/stylenex.css').then(() => {
        loadCSS('./theme/css/stylenex.css');
      });
    } else if (domain === DOMAIN.STAGE_NAP) {
      const url = `./theme/css/${cssLink}.css`;
      import('./theme/css/style.css').then(() => {
        loadCSS('./theme/css/style.css');
      });
    } else {
      const url = `./theme/css/${cssLink}.css`;
      import('./theme/css/style.css').then(() => {
        loadCSS('./theme/css/style.css');
      });
      setLoading(false)
    }
    // Function to load CSS dynamically
    function loadCSS(css) {
      const head = document.querySelector('head');
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = css;
      head.appendChild(link);
    }
  }, []);

  return <>
    {children}
  </>; // Since this component doesn't render anything, return null
};

export default DynamicCSS;