import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, message } from "antd";
import Header from "components/layout/Header";
import mysendle from "theme/assets/partnerLogo/Logo-groen.png";
import { getUserMySendle, RegitserMySendle } from "actions/commonActions";
import { ProdLinkMySendle} from "utils/enum";
import { updateMySendleKey } from "actions/dataActions";

const MySendle = () => {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('Something went wrong, please try again after some time...')

  const initialFunction = async () => {
    const response = await getUserMySendle();
    if (response.parcelLoginKey) {
        window.location.href = `${ProdLinkMySendle.LOGIN}?key=${response.parcelLoginKey}`;
    }else{
      const {data, status} = await RegitserMySendle(response);
      if(data?.data && status === 200){
        await updateMySendleKey(data.data, response.userSettingsId)
        .then(() => {
          window.location.href = `${ProdLinkMySendle.LOGIN}?key=${data.data}`;
        })
        .catch(error => {
          console.error("Failed to update MySendle key:", error);
        });
      }else if(status=== 400){
        setErrMsg(data.data)
        setError(true)
      }
    }
  };
  
  useEffect(() => {
    initialFunction();
  }, []);

  useEffect(() => {}, [error]);

  return (
    <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
      className="loadingNexHubRow"
    >
      <Col
        className="gutter-row loadingCol loadingTop"
        span={24}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        {/* <NexHubHeader data={data} /> */}
        <Header />
      </Col>
      <Col className="gutter-row loadingCol loadingArea" span={24}>
        <div className="loadingParent">
          <img src={mysendle} alt={mysendle} className="partnerIcon" />
          {error ? (
            <p className="errloading">
              Something went wrong...
              {message.error(errMsg)}
            </p>
          ) : (
            <p className="loading">Connecting...</p>
            
          )}
        </div>
      </Col>
    </Row>
  );
};
export default MySendle;
