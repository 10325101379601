import React, { useState } from "react";
import { Row, Col } from "antd";
import LeftPane from "./left-pane/LeftPane";
import RightPane from "./right-pane/RightPane";

const Content = ({ company, cities, auth }) => {
	const [activeTab, setActiveTab] = useState('Overview');

	return (
		<Row className="compnay-profile-inner">
			<Col span={12}>
				<LeftPane company={company} setActiveTab={setActiveTab} auth={auth} />
			</Col>
			<Col span={12} className="rightContent">
				<RightPane company={company} cities={cities} activeTab={activeTab} />
			</Col>


		</Row>
	);
};

export default Content;
