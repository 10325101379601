import axios from "axios";
import { base_url, api_v } from "utils/settings";

import {
  UPDATE_USER,
  UPDATE_USER_INFO,
  CREATE_KEY_PERSONEL,
  UPDATE_KEY_PERSONEL,
  DELETE_USER,
  GLOBAL_ERROR
} from "./types";

// Create new User (keyPersonel)
export const createUser = (data, branchId) => async dispatch => {
  try {
    const res = await axios.post(`${base_url}/${api_v}users`, data);

    dispatch({
      type: CREATE_KEY_PERSONEL,
      payload: res.data,
      branchId
    });

    return true;
  } catch (err) {
    throw "User with this email allrady exists";
  }
};

// Update User
export const updateUser = (data, id, branchId) => async dispatch => {
  try {
    const res = await axios.put(`${base_url}/${api_v}users/${id}`, data);

    dispatch({
      type: UPDATE_USER,
      branchId,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

// Update UserInfo
export const updateUserInfo = (data, id, branchId) => async dispatch => {
  try {
    const res = await axios.put(`${base_url}/${api_v}user_infos/${id}`, data);

    dispatch({
      type: UPDATE_USER_INFO,
      id,
      branchId,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

// Update KeyPersonel
export const updateKeyPersonel = (data, id, branchId) => async dispatch => {
  try {
    const res = await axios.put(
      `${base_url}/${api_v}key_personels/${id}`,
      data
    );
    localStorage.setItem("nexHub", res?.data?.nexHub)
    dispatch({
      type: UPDATE_KEY_PERSONEL,
      branchId,
      payload: res.data
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

// Reset Password
export const resetPassword = data => async dispatch => {
  try {
    await axios.put(`${base_url}/${api_v}update_password`, data);
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};

// Delete User
export const deleteUser = (id, branchId) => async dispatch => {
  try {
    await axios.delete(`${base_url}/${api_v}users/${id}`);
    dispatch({
      type: DELETE_USER,
      branchId,
      payload: id
    });
    return true;
  } catch (err) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: err
    });
    return false;
  }
};
