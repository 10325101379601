import React, { useEffect, useReducer, useState } from "react";
import { deleteCas, getAlerts, getCas, updateCas } from "actions/dataActions";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import styled from "@emotion/styled";
import SkeletonLoading from "components/ui-elements/SkeletonLoading";
import CasStatus from "./CasStatus";
import {
  Button,
  Col,
  Drawer,
  Empty,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Table,
  Tabs,
  Tag as AntTag,
  Tooltip,
} from "antd";
import moment from "moment";
import produce from "immer";
import PageHeader from "components/ui-elements/PageHeader";

import creditAlertIcon from "theme/assets/creditAlertIcon.svg";
import incomingG from "theme/assets/incomingG.png";
import outgoingG from "theme/assets/outgoingG.png";
import archive from "theme/assets/archive.svg";
import resolveActionIcon from "theme/assets/resolveActionIcon.png";
import allAlerts from "theme/assets/allAlerts.png";
import plusIcon from "theme/assets/plus-icon.svg";
import leftActiveArrow from "theme/assets/leftActiveArrow.svg";
import downloadIcon from "theme/assets/downloadIcon.png";
import editIcon from "theme/assets/editIcon.svg";
import envelopeIcon from "theme/assets/envelope-icon.svg";
import deleteIcon from "theme/assets/delete-icon.svg";
import deleteIconRed from "theme/assets/deleteIconRed.svg";

import incoming from "theme/assets/incoming.svg";
import canceledIncoming from "theme/assets/canceled-incoming.svg";
import declinedIncoming from "theme/assets/declined-incoming.svg";
import resolvedIncoming from "theme/assets/resolved-incoming.svg";

import outgoing from "theme/assets/outgoing.svg";
import pendingOutgoing from "theme/assets/pending-outgoing.svg";
import canceledOutgoing from "theme/assets/canceled-outgoing.svg";
import declinedOutgoing from "theme/assets/declined-outgoing.svg";
import resolvedOutgoing from "theme/assets/resolved-outgoing.svg";

import jwt_decode from "jwt-decode";
import AddAlert from "components/alerts/AddAlert";
import { Link } from "react-router-dom";

const requestStatus = {
  Resolved: "Resolved",
  Canceled: "Canceled",
  In_Progress: "In Progress",
  Pending: "Pending",
  Declined: "Declined",
};

const { confirm } = Modal;
const { Column } = Table;
const { TabPane } = Tabs;

const Tag = styled(AntTag)`
  margin-bottom: 5px;
`;

const initialState = {
  cas: null,
  loading: true,
  updating: [],
  page: 1,
  total: 100,
};

function reducer(state, action) {
  switch (action.type) {
    case "GET_DATA":
      const { isAdmin } = action.payload;
      return {
        ...state,
        cas: isAdmin ? action.payload["hydra:member"] : action.payload,
        loading: false,
        total: isAdmin ? action.payload["hydra:totalItems"] : 0,
        page: action.page,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_STATUS":
      return produce(state, (draftState) => {
        const targetIndex = state.cas.findIndex(
          (a) => a.id === action.payload.id
        );

        draftState.cas[targetIndex].status = action.payload.status;
      });

    default:
      throw new Error();
  }
}

const CreditAlerts = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { cas, loading, updating, page, total } = state;
  const [isAdd, setIsAdd] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [columns, setColumns] = useState([]);
  const [editData, setEditData] = useState({});
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

  useEffect(() => {
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
    const { useTools } = companyInfo;
    if (!useTools) {
      props.history.push("/");
    }
  }, []);

  const statusActions = (status, row) => {
    return (
      <CasStatus
        status={row.status}
        updating={updating}
        id={row.id}
        handleUpdate={handleUpdate}
      ></CasStatus>
    );
  };

  const displayActions = (status, row) => {
    const companyInfo = JSON.parse(localStorage.companyInfo);
    const companyId = companyInfo.company;
    const { company, selectedCompany } = row;
    let mailToEmail = "";
    if (selectedCompany.id === companyId) {
      mailToEmail = selectedCompany.coordinator?.email;
    }
    if (company.id === companyId) {
      mailToEmail = company.coordinator?.email;
    }
    return (
      <div className="gridActions">
        {activeTab === 2 && (
          <a className="action" onClick={() => editAlert(row)}>
            <img src={editIcon} className="actionIcon" />
            Edit
          </a>
        )}

        {activeTab === 2 && (
          <a className="action" onClick={() => showConfirm("resolve", row.id)}>
            <img src={resolveActionIcon} className="actionIcon" />
            Resolve Alert
          </a>
        )}
        {activeTab === 1 && (
          <a className="action" href={`mailTo:${mailToEmail}`}>
            <img src={envelopeIcon} className="actionIcon" />
            Send Message
          </a>
        )}
        {activeTab === 3 && (
          <a
            className="action deleteBtn"
            onClick={() => deleteAlert("delete", row.id)}
          >
            <img src={deleteIconRed} className="actionIcon" />
            Delete Alert
          </a>
        )}
      </div>
    );
  };

  const displayCompanyNameWithIcon = (row) => {
    const companyInfo = JSON.parse(localStorage.companyInfo);
    const companyId = companyInfo.company;
    const { company, selectedCompany, attention, status } = row;
    let icon;
    let companyName = "";
    let toolTipTxt = "";
    // debugger;
    if (selectedCompany.id === companyId) {
      // Incoming Alerts
      companyName = company.companyName;
      if (status === requestStatus.In_Progress && attention) {
        icon = incoming;
        toolTipTxt = "In Progress";
      } else if (status === requestStatus.Resolved) {
        icon = resolvedIncoming;
        toolTipTxt = "Resolved";
      } else if (status === requestStatus.Canceled) {
        icon = canceledIncoming;
        toolTipTxt = "Canceled";
      } else if (status === requestStatus.Declined) {
        icon = declinedIncoming;
        toolTipTxt = "Declined";
      }
    }

    if (company.id === companyId) {
      // Outgoing Alerts
      companyName = selectedCompany.companyName;
      if (status === requestStatus.Pending) {
        icon = pendingOutgoing;
        toolTipTxt = "Pending";
      } else if (status === requestStatus.In_Progress) {
        icon = outgoing;
        toolTipTxt = "In Progress";
      } else if (status === requestStatus.Resolved) {
        icon = resolvedOutgoing;
        toolTipTxt = "Resolved";
      } else if (status === requestStatus.Canceled) {
        icon = canceledOutgoing;
        toolTipTxt = "Canceled";
      } else if (status === requestStatus.Declined) {
        icon = declinedOutgoing;
        toolTipTxt = "Declined";
      }
    }
    return (
      <Tooltip placement="right" title={toolTipTxt}>
        <div className="companyName">
          {icon && <img src={icon} className="companyNameIcon" />}
          <span>{companyName}</span>
        </div>
      </Tooltip>
    );
  };

  const editAlert = (row) => {
    setEditData(row);
    setIsAdd(true);
  };

  // Fetch data based on PageNumber
  const fetchData = (page) => {
    dispatch({
      type: "SET_LOADING",
    });
    getCas(page).then((data) => {
      if (data) {
        data["isAdmin"] = isAdmin;

        dispatch({
          type: "GET_DATA",
          payload: data,
          page,
        });
      }
    });
  };
  // Fetch data on mount
  useEffect(() => {
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    const { roles } = decoded;
    if (roles && roles.length > 0) {
      if (roles.indexOf("ROLE_ADMIN") > -1) {
        setIsAdmin(true);
      }
    } else {
      setColumns([
        {
          title: "Company Name",
          dataIndex: "company.companyName",
          key: "companyName",
          // width: 400,
          render: (_, row) => <>{displayCompanyNameWithIcon(row)}</>,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          // width: 150,
          render: (amount, row) => (
            <>
              {row.currency.code} {amount}
            </>
          ),
        },
        {
          title: "Date",
          dataIndex: "createDate",
          key: "date",
          // width: 250,
          render: (date) => <>{moment(date).format("MMM DD, YYYY")}</>,
        },
        {
          title: "Documents",
          dataIndex: "documents",
          key: "documents",
          // width: 200,
          render: (_, row) => printDocuments(row),
        },
        {
          title: "Action",
          key: "operation",
          dataIndex: "operation",
          // fixed: 'right',
          // width: 250,
          render: (status, row) => displayActions(activeTab, row),
        },
      ]);
      fetchAlerts(page, activeTab);
    }
  }, [activeTab]);
  useEffect(() => {
    if (isAdmin) {
      fetchData(page);
      addMoreColumns();
    }
  }, [isAdmin]);
  // useEffect(() => {
  //   if (activeTab) {
  //     fetchAlerts(1, activeTab)
  //   }
  // }, [activeTab])
  // Switch Pages
  const changePage = (page) => {
    window.scrollTo(0, 0);
    if (isAdmin) {
      fetchData(page);
    } else {
      fetchAlerts(page, 1);
    }
  };

  const addMoreColumns = () => {
    setColumns([
      {
        title: "From Company",
        dataIndex: "company.companyName",
        key: "fCompany",
        width: 250,
        render: (name, row) => <>{name}</>,
      },
      {
        title: "To Company",
        dataIndex: "selectedCompany.companyName",
        key: "toCompany",
        width: 250,
        render: (name, row) => <>{name}</>,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 250,
        render: (amount, row) => (
          <>
            {row.currency?.code} {amount}
          </>
        ),
      },
      {
        title: "Date",
        dataIndex: "createDate",
        key: "date",
        width: 250,
        render: (date) => <>{moment(date).format("MMM DD, YYYY")}</>,
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        width: 300,
        render: (_, row) => printDocuments(row),
      },
      {
        title: "Action",
        key: "operation",
        dataIndex: "operation",
        // fixed: 'right',
        // width: 250,
        render: (status, row) => statusActions(status, row),
      },
    ]);
  };

  // Update CAS status
  const handleUpdate = (item) => {
    dispatch({
      type: "UPDATE_STATUS",
      payload: item,
    });
  };

  const printInvocies = (row) => {
    return (
      <>
        {row.invoiceOver15k && <Tag color="blue">Invoice over $15.000</Tag>}
        {row.monthsEndStatement && <Tag>Between 60-90 days</Tag>}
        {row.overdueInvoices && <Tag>Over 60 days</Tag>}
      </>
    );
  };

  const printDocuments = (row) => {
    return (
      <div className="downloadItems" key={`downloadItems_${row.id}`}>
        {row.correspondenceDocument && (
          <a
            className="item"
            key={`Correspondence_${row.id}`}
            target="blank"
            href={row.correspondenceDocument}
          >
            <span>Correspondence</span>
            <img src={downloadIcon} className="downloadIcon" />
          </a>
        )}

        {row.statementDocument && (
          <a
            className="item"
            key={`Statement_${row.id}`}
            target="blank"
            href={row.statementDocument}
          >
            <span>Statement</span>
            <img src={downloadIcon} className="downloadIcon" />
          </a>
        )}

        {row.payablesDocument && (
          <a
            className="item"
            key={`Payables_${row.id}`}
            target="blank"
            href={row.payablesDocument}
          >
            <span>Payables</span>
            <img src={downloadIcon} className="downloadIcon" />
          </a>
        )}
      </div>
    );
  };

  const printAmount = (amount, row) => {
    return (
      <>
        {row.currency.code} {amount}
      </>
    );
  };

  // Fetch data based on PageNumber
  const fetchAlerts = (page, type) => {
    dispatch({
      type: "SET_LOADING",
    });
    getAlerts(page, type).then((data) => {
      if (data) {
        dispatch({
          type: "GET_DATA",
          payload: data,
          page,
        });
      }
    });
  };

  const onAddUpdate = () => {
    fetchAlerts(1, activeTab);
    setIsAdd(false);
    setEditData({});
  };

  const switchTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabHeader = (icon, text) => {
    return (
      <div className={`leftPanelTitle`}>
        <div className="tabDetails">
          <img src={icon} className="panelIcon" />
          <span>{text}</span>
        </div>
        <img src={leftActiveArrow} className="activeIcon" />
      </div>
    );
  };

  const tabsList = [
    // {
    //   icon: allAlerts,
    //   title: 'ALL ALERTS',
    //   tabIndex: 0
    // },
    {
      icon: incoming,
      title: "INCOMING",
      tabIndex: 1,
    },
    {
      icon: outgoing,
      title: "OUTGOING",
      tabIndex: 2,
    },
    {
      icon: archive,
      title: "ARCHIVE",
      tabIndex: 3,
    },
  ];

  const printContent = (key) => {
    let data;
    switch (key) {
      case "approve":
        data = "";
        break;

      case "decline":
        data = (
          <>
            <label htmlFor="block-message">
              Reason for declining this credit alert
            </label>
            <Input.TextArea id="block-message"></Input.TextArea>
          </>
        );
        break;

      case "resolve":
        data = "";
        break;
    }

    return data;
  };

  const deleteAlert = (key, id) => {
    confirm({
      title: `Are you sure you want to ${key.toUpperCase()} `,
      content: printContent(key),
      className: 'confirm',
      onOk: () => {
        deleteCas(id).then((res) => {
          message.success("Credit alert deleted successfully");
          fetchAlerts(1, activeTab);
        });
      },
      onCancel() { },
    });
  };

  const showConfirm = (key, id) => {
    let data;

    confirm({
      title: `Are you sure you want toggg ${key.toUpperCase()} `,
      content: printContent(key),
      className: "abc",
      onOk: () => {
        switch (key) {
          case "approve":
            data = {
              status: "In Progress",
              attention: true,
            };
            break;

          case "decline":
            data = {
              status: "Declined",
              attention: false,
            };
            break;

          case "resolve":
            data = {
              status: "Resolved",
              attention: false,
            };
            break;
          default:
            data = false;
        }

        if (!data) {
          return;
        }

        updateCas(data, id).then((res) => {
          message.success("Status updated successfully");
          handleUpdate(res);
          if (key === "resolve") {
            fetchAlerts(1, activeTab);
          }
        });
      },
      onCancel() { },
    });
  };

  const closeDrawer = () => {
    setEditData({});
    setIsAdd(false);
  };

  return (
    <div className="pageContainer">
      <PageHeader icon={creditAlertIcon} title={`MY CREDIT ALERTS`} />
      <Row className="user-credit-alert">
        {!isAdmin && (
          <Col sm={24} xs={24} lg={4} className="leftPanel">
            <div className="leftPanelNav">
              <Button
                type="danger"
                className="addBtn"
                onClick={() => setIsAdd(true)}
              >
                ADD ALERT
                <img src={plusIcon} />
              </Button>
              <div className="ant-tabs-nav ant-tabs-nav-animated alertTabs">
                {tabsList.map((tab, index) => {
                  return (
                    <div
                      className={`${activeTab === tab.tabIndex ? "ant-tabs-tab-active" : ""
                        } ant-tabs-tab`}
                      key={`tab_${index}`}
                      onClick={() => switchTab(tab.tabIndex)}
                    >
                      {tabHeader(tab.icon, tab.title)}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="leftPanelLink">
              <Link to="#">CAS Terms and conditions</Link>
            </div>
          </Col>
        )}
        <Col
          sm={24}
          xs={24}
          lg={isAdmin ? 24 : 20}
          className={`contentPanel ${isAdmin && "adminAlerts"} `}
        >
          {!cas || loading ? (
            <Skeleton />
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={cas}
                scroll={{ y: 500, x: 250 }}
                loading={loading}
                rowKey="id"
                pagination={!isAdmin}
              />
              {isAdmin && (
                <Pagination
                  onChange={changePage}
                  locale={localeInfo}
                  current={page}
                  defaultPageSize={30}
                  total={total}
                ></Pagination>
              )}
            </>
          )}
          <Drawer
            width={800}
            destroyOnClose={true}
            closable={false}
            onClose={() => closeDrawer()}
            visible={isAdd}
          >
            <AddAlert
              onAddUpdateCallback={onAddUpdate}
              editableInfo={editData}
            />
          </Drawer>
        </Col>
      </Row>
    </div>
  );
};

export default CreditAlerts;
