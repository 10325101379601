import React,{useEffect, useState} from "react";
import { connect, useDispatch,useSelector } from "react-redux";
import { updateUserInfo } from "actions/userActions";
import { Form as AutoSaveWrapper } from "react-final-form";
import AutoSave from "components/helper/AutoSave";
import FormField from "components/ui-elements/FormField";
import { Input, Form, Select, message } from "antd";
const { Option } = Select;


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const UserInformation = props => {
  const { userInfo } = props;
  const { companyFunction, telephone, cellphone, fax, skype,telDialCode, cellDialCode } = userInfo[0];
  const countryList= useSelector(state=>state.data.countries)
  const companyId= useSelector(state=>state.company.data)
  
 
  const save = async values => {
 
    props.updateUserInfo(
      values,
      props.userInfo[0].id,
      props.branchId
    ).then(value => {
      if (value) {
        return message.success("The information has been updated");
      }
    });
  };

  let telCountryCodeSelect = (
    <FormField
    name="telDialCode"
  >
    <Select showSearch style={{ width: 90 }}
      value={telDialCode}
      // onChange={handleCountryCodeSelect} size="large"
      >
      {countryList.map((item, index) => {
        return (
          <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
            {item.dialCode ? item.dialCode : `no code${item.id}`}
          </Option>
        );
      })}
    </Select>
    </FormField>
  );

  let cellCountryCodeSelect = (
    <FormField
    name="cellDialCode"
  >
    <Select showSearch style={{ width: 90 }}
      value={telDialCode}
      // onChange={handleCountryCodeSelect} size="large"
      >
      {countryList.map((item, index) => {
        return (
          <Option value={item.dialCode ? item.dialCode : `no code${item.id}`} key={`dial_${item.id}`}>
            {item.dialCode ? item.dialCode : `no code${item.id}`}
          </Option>
        );
      })}
    </Select>
    </FormField>
  );
  if (userInfo.length === 0) {
    return (
      <>
        <p>User has not entered contact information</p>
        {/* <Button type="primary">Add now</Button> */}
      </>
    );
  }



  return (
    <AutoSaveWrapper
      onSubmit={save}
      initialValues={{
        telDialCode:telDialCode?telDialCode:companyId.countries.dialCode,
        cellDialCode:cellDialCode?cellDialCode:companyId.countries.dialCode,
        companyFunction,
        telephone,
        cellphone,
        fax,
        skype
      }}
      subscription={{}}
    >
      {() => (
        <Form {...formItemLayout} style={{ maxWidth: 600 }}>
          <AutoSave
            debounce={1000}
            save={save}
            debounced={[
                "telDialCode",
                "cellDialCode",
                "companyFunction",
                "telephone",
                "cellphone",
                "fax",
                "skype"
            ]}
          />
          <FormField
            name="companyFunction"
            label="Company function"
            validate={["required"]}
          >
            <Input></Input>
          </FormField>

          <FormField name="telephone" label="Telephone" validate={["required"]}>
            <Input addonBefore={telCountryCodeSelect}></Input>
          </FormField>

          <FormField name="cellphone" label="Cellphone" validate={["required"]}>
            <Input addonBefore={cellCountryCodeSelect}></Input>
          </FormField>

          <FormField name="fax" label="Fax">
            <Input></Input>
          </FormField>

          <FormField name="skype" label="Skype">
            <Input></Input>
          </FormField>
        </Form>
      )}
    </AutoSaveWrapper>
  );
};
// const mapStateToProps = state => ({
//   country: state.data.countries,
// });
export default connect(null, { updateUserInfo })(UserInformation);
